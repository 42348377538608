import './Style.scss';
import logo from '../../../../assets/img/IconosAppGIAMovil/logo.svg'
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';
//Imagenes


export const ViewMain = () => {
    const languageTranslate = LanguageTranslate();

    return (
        <div className="ViewSplash">
            <div className="fondo"></div>
            <div className="login-block">

                <div className="row text-center">
                    <div className="col-12 text-center">
                        <img src={logo} alt="Logo" className='img-fluid' width={110} />
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="col-12 text-center">
                        <span className="textLoader">{languageTranslate.textoConfigurandoAreaTrabajo}</span>
                    </div>
                </div>
            </div>
        </div >
    )
};