import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { Comparativo } from "../Domain/Comparativo";
import { DtoRequestComparativo } from "../Domain/DtoRequestComparativo";
import { RepositoryMain } from "../Domain/RepositoryMain";
import { TipoDocumentoTributario } from "../Domain/TipoDocumentoTributario";
import { TipoIVA } from "../Domain/TipoIVA";
import { Valorization } from "../Domain/Valorization";

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {
    public async getList(params: DtoRequestComparativo): Promise<Comparativo[] | null> {
        return this._getList(params);
    }
    private async _getList(params: DtoRequestComparativo): Promise<Comparativo[] | null> {
        const newParams = JSON.stringify(params)
        return await this.service.bgCall<any>('POST', `${this.urlBase}/catalogo/proveedor/selectComparativos`, newParams, 'bearer', "json", "json", {}, 0);
    }
    public async getDatosTributarios(params: any): Promise<TipoDocumentoTributario[] | null> {
        return this._getDatosTributarios(params);
    }
    private async _getDatosTributarios(params: any): Promise<TipoDocumentoTributario[] | null> {
        const newParams = JSON.stringify(params)
        return await this.service.bgCall<any>('POST', `${this.urlBase}/catalogo/tipoDocumentoTributario/select`, newParams, 'bearer', "json", "json", {}, 0);
    }
    public async getTipoIVA(params: any): Promise<TipoIVA[] | null> {
        return this._getTipoIVA(params);
    }
    private async _getTipoIVA(params: any): Promise<TipoIVA[] | null> {
        const newParams = JSON.stringify(params)
        return await this.service.bgCall<any>('POST', `${this.urlBase}/catalogo/tipoIva/select`, newParams, 'bearer', "json", "json", {}, 0);
    }
}