const SCHEMA: string = '';
const ENTITY: string = '';
const URL_LOGIN: string = process.env.REACT_APP_URL_LOGIN || '';
const AUTH_BASIC_USER: string = process.env.REACT_APP_URL_LOGIN_AUTH_BASIC_USER || '';
const AUTH_BASIC_PASS: string = process.env.REACT_APP_URL_LOGIN_AUTH_BASIC_PASS || '';

export const AdapterConfigure = {
    SCHEMA,
    ENTITY,
    URL_LOGIN,
    AUTH_BASIC_USER,
    AUTH_BASIC_PASS,
};