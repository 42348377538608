import { DtoRequesFindByName } from "../Domain/DtoRequesFindByName";
import { DtoResponseFindByName } from "../Domain/DtoResponseFindByName";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseFindByName {
   private repository: RepositoryMain;

   constructor(_repository: RepositoryMain) {
      this.repository = _repository;
   }

   public async exec(params: DtoRequesFindByName): Promise<DtoResponseFindByName | null> {
      return await this._exec(params);
   }

   public async _exec(params: DtoRequesFindByName): Promise<DtoResponseFindByName | null> {
      return await this.repository.finByName(params);
   }

}