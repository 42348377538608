import React from 'react'
import { Dialog } from 'primereact/dialog';
interface PropsViewDialogDocument {
    visibleDocument: boolean;
    onHideDocument: any;
    textValorizacion: string;
    pdf:any;
}

export const DialogViewDocument = (props: PropsViewDialogDocument) => {
    
    return (
        <Dialog
            header={props.textValorizacion}
            visible={props.visibleDocument}
            style={{ width: '80vw', height: '100vh' }}
            onHide={() => { props.onHideDocument() }}
        >
            <iframe src={props.pdf} style={{ width: '100%', height: '100%' }} ></iframe>
        </Dialog>
    )
}


