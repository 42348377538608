// Imagenes
import logo from '../../../assets/img/IconosAppGIAMovil/logo.svg';

//Style
import './ObjectHeader.scss';
import { EntityDataUsuario } from '../Domain/EntityDataUsuario';
import { useState } from 'react';
import { Navbar } from './Navbar';
import { SidebarMenu } from './SidebarMenu';
import { SidebarGLPI } from './SidebarGLPI';

interface ViewProps {
    auth: boolean;
    user: EntityDataUsuario;

    iconMenu: boolean;
    onHideIconMenu: Function;
    onShowIconMenu: Function;

    online: boolean;
    countProcess: number;

    onClickHome?: Function;
    onClickHelp?: Function;
    onClickReport?: Function;
    onClickProfile?: Function;
    onClickVariable?: Function;
    onClickLogout?: Function;
    showSaludo: boolean;
    children?: any;
};

export const ObjectHeader = (props: ViewProps) => {
    const [show, setShow] = useState(false);
    const [showTicket, setShowTicket] = useState(false);

    return (
        <>
            <div className='block-content'>
                <Navbar setShow={() => setShow(!show)} logo={logo} setShowTicket={() => setShowTicket(!showTicket)} />
            </div>

            <div className='min-h-screen flex relative lg:static surface-ground'>
                <div>
                    <SidebarMenu
                        show={show}
                        setShow={() => setShow(!show)}
                        logo={logo}
                        nombre={props.user.usuario.nombre}
                        username={props.user?.usuario.username}
                        onClickLogout={props.onClickLogout}
                        identificacion={props.user?.usuario.identificacion}
                    />
                    <SidebarGLPI
                        showTicket={showTicket}
                        setShowTicket={() => setShowTicket(false)}
                    />
                </div>
                <div className='min-h-screen flex flex-column relative flex-auto'>
                    <main>
                        {props.children}
                    </main>
                </div>
            </div>
        </>
    )
}