import { DtoRequestComparativo } from "../Domain/DtoRequestComparativo";
import { RepositoryMain } from "../Domain/RepositoryMain";
import { Comparativo } from "../Domain/Comparativo";

export class UseCaseComparativo {
   private repository: RepositoryMain;
   
   constructor (_repository: RepositoryMain){
    this.repository = _repository;
   }

   public async exec(params: DtoRequestComparativo): Promise<Comparativo[] | null>{
    return await this._exec(params);
   }

   public async _exec(params: DtoRequestComparativo): Promise<Comparativo[] | null>{
      return await this.repository.getListComparativo(params);
   }

}