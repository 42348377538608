import { DtoRequestInsertFactura } from "../Domain/DtoRequestInsertFactura";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseInsertFactura {
    private repository: RepositoryMain;
    
    constructor (_repository: RepositoryMain){
     this.repository = _repository;
    }
 
    public async exec(params: DtoRequestInsertFactura): Promise<any[] | null>{
     return await this._exec(params);
    }
 
    public async _exec(params: DtoRequestInsertFactura): Promise<any[] | null>{
       return await this.repository.insertFactura(params);
    }
 
 }