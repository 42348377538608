import Swal, { SweetAlertIcon, SweetAlertOptions } from "sweetalert2";
import { LanguageTranslate } from "./LanguageTranslate";

export class AdapterGenerico {
    public static async createMessage(title: string = '', html: string = '', icon: SweetAlertIcon = 'info', buttonCancel: boolean = false, target: string = 'body') {
        let language = LanguageTranslate();
        let config: SweetAlertOptions = {
            title: title,
            html: html,
            icon,
            allowOutsideClick: true,
            allowEscapeKey: true,
            allowEnterKey: true,
            confirmButtonText: language.code === 'es' ? 'Aceptar' : language.code === 'en' ? 'Accept' : 'Accept',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            backdrop: true,
            reverseButtons: false,
            focusConfirm: true,
            target: target,
        };

        if (buttonCancel === true) { Object.assign(config, { showCancelButton: true, cancelButtonColor: '#f44336', cancelButtonText: 'Cancelar', }); }

        let Message = await Swal.fire(config);

        return Reflect.has(Message, 'dismiss') ? false : true;
    }

    public static createToast(html: string = '', icon: SweetAlertIcon = 'info', target: string = 'body') {
        let Toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            target,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        });

        Toast.fire({ icon, html });
    }

    public static randomString(longitud: number = 11) {
        let caracteres = "abcdefghijkmnpqrtuvwxyz2346789";
        let cadena = "";
        for (let i = 0; i < longitud; i++) cadena += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
        return cadena;
    }

    public static isJSON(str: string) {
        try {
            str = JSON.parse(str);
            return true;
        } catch (error) {
            return false;
        }
    }

    public static calcularEdad(fecha: string) {
        var hoy = new Date();
        var cumpleanos = new Date(fecha);
        var edad = hoy.getFullYear() - cumpleanos.getFullYear();
        var m = hoy.getMonth() - cumpleanos.getMonth();

        if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
            edad--;
        }

        return edad;
    }

    public static toBase64(file: File) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    public static convertStringToDate(stringDate: string = '01/01/1900') {
        try {
            let i = 0;
            let f = 0;
            let y: any = '';
            let m: any = '';
            let d: any = '';
            let h: any = '';
            let mi: any = '';
            let s: any = '';
            let Tipo = 0;

            Tipo = stringDate.indexOf('-') !== -1
                ? 1
                : stringDate.indexOf('/') !== -1
                    ? 2
                    : 0;
            switch (Tipo) {
                case 1:
                    f = 4;
                    y = stringDate.slice(i, f);
                    if (y.indexOf('-') !== -1) { f--; y = stringDate.slice(i, f); }
                    else { y = y === '' ? 1 : parseInt(y); }
                    i = f + 1;
                    f = i + 2;

                    m = stringDate.slice(i, f);
                    if (m.indexOf('-') !== -1) { f -= 1; m = stringDate.slice(i, f); }
                    else { m = m === '' ? 0 : parseInt(m) - 1; }
                    i = f + 1;
                    f = i + 2;

                    d = stringDate.slice(i, f);
                    if (d.indexOf('/') !== -1) { f -= 1; d = stringDate.slice(i, f); }
                    else { d = d === '' ? 1 : parseInt(d); }
                    i = f + 1;
                    f = i + 2;

                    h = stringDate.slice(i, f);
                    h = h === '' ? 0 : parseInt(h);
                    i = f + 1;
                    f = i + 2;

                    mi = stringDate.slice(i, f);
                    mi = mi === '' ? 0 : parseInt(mi);
                    i = f + 1;
                    f = i + 2;

                    s = stringDate.slice(i, f);
                    s = s === '' ? 0 : parseInt(s);
                    break;
                case 2:
                    f = 2;
                    d = stringDate.slice(i, f);
                    if (d.indexOf('/') !== -1) { f--; d = stringDate.slice(i, f); }
                    else { d = d === '' ? 1 : parseInt(d); }
                    i = f + 1;
                    f = i + 2;

                    m = stringDate.slice(i, f);
                    if (m.indexOf('/') !== -1) { f -= 1; m = stringDate.slice(i, f); }
                    else { m = m === '' ? 0 : parseInt(m) - 1; }
                    i = f + 1;
                    f = i + 4;

                    y = stringDate.slice(i, f);
                    if (y.indexOf('/') !== -1) { f--; y = stringDate.slice(i, f); }
                    else { y = y === '' ? 1 : parseInt(y); }
                    i = f + 1;
                    f = i + 2;

                    h = stringDate.slice(i, f);
                    h = h === '' ? 0 : parseInt(h);
                    i = f + 1;
                    f = i + 2;

                    mi = stringDate.slice(i, f);
                    mi = mi === '' ? 0 : parseInt(mi);
                    i = f + 1;
                    f = i + 2;

                    s = stringDate.slice(i, f);
                    s = s === '' ? 0 : parseInt(s);
                    break;
                default:
                    throw new Error('formato desconocido');
            }
            let resp: Date = new Date(y, m, d, h, mi, s, 0);
            if (Number.isNaN(resp.getTime())) {
                throw Error('Fecha no es Válida')
            }
            return resp;
        } catch (error) {
            throw error;
        }
    }

    public static convertDateToString(Fecha: Date = new Date(), Tipo: number = 1) {

        try {
            Fecha = typeof Fecha === 'string' ? new Date(Fecha) : Fecha;
            let meses = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
            let y = Fecha.getFullYear();
            let m = Fecha.getMonth() + 1;
            let d = Fecha.getDate();

            let h = Fecha.getHours();
            let mi = Fecha.getMinutes();
            let s = Fecha.getSeconds();

            let am = h > 11 ? false : true;

            let h2 = h > 12 ? h - 12 : h;

            let result = '';

            switch (Tipo) {
                case 1:
                    result = Fecha.toISOString();
                    break;
                case 2:
                    result = `${(d < 10 ? '0' : '') + d}/${(m < 10 ? '0' : '') + m}/${y} ${(h < 10 ? '0' : '') + h}:${(mi < 10 ? '0' : '') + mi}:${(s < 10 ? '0' : '') + s}`;
                    break;
                case 3:
                    result = `${(d < 10 ? '0' : '') + d}/${(m < 10 ? '0' : '') + m}/${y}`;
                    break;
                case 4:
                    result = `${(d < 10 ? '0' : '') + d} de ${meses[m - 1]} del ${y}`;
                    break;
                case 5:
                    result = `${(d < 10 ? '0' : '') + d}/${(m < 10 ? '0' : '') + m}/${y} ${(h2 < 10 ? '0' : '') + h2}:${(mi < 10 ? '0' : '') + mi} ${am ? 'AM' : 'PM'}`;
                    break;
                case 6:
                    result = `${y}-${(m < 10 ? '0' : '') + m}-${(d < 10 ? '0' : '') + d}`;
                    break;
                default:
                    throw new Error('Datos no correctos');
            }
            return result;
        } catch (error) {
            throw error;
        }
    }

    public static convertFormat(value: string, format: string) {
        try {
            if (typeof format !== 'string') { throw new Error('formato debe estar en string.'); }
            value = typeof value !== 'string' ? `${value}` : value;
            return value.length > format.length ? value : format.substring(0, format.length - value.length) + value;
        } catch (error) {
            throw error;
        }
    }

    public static decodeTokenJWT(Token: string) {
        try {
            let data = Token.split('.')[1];
            data = atob(data);
            data = AdapterGenerico.isJSON(data) ? JSON.parse(data) : data;
            return data;
        } catch (error) {
            throw error;
        }
    }

    public static getBase64ImageFromURL(url: string) {
        return new Promise((resolve, reject) => {
            let img = new Image();
            img.setAttribute("crossOrigin", "anonymous");
            img.onload = () => {
                let canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                let ctx = canvas.getContext("2d");
                if (ctx !== null) {
                    ctx.drawImage(img, 0, 0);
                    let dataURL = canvas.toDataURL("image/png");
                    resolve(dataURL);
                }
                else { reject(new Error('ctx null')) }
            };
            img.onerror = error => {
                reject(error);
            };
            img.src = url;
        });
    }

    public static summa(arrayJson: Array<any>, Clave: string, noincluir: any, incluir: any) {
        try {
            if (arrayJson.length === 0) { return 0 };
            let ClaveArrai = Clave.split('.');
            if (arrayJson.length === 0) {
                return 0;
            } else {
                switch (ClaveArrai.length) {
                    case 1:
                        return arrayJson.map((a) => {
                            if (typeof noincluir !== 'undefined') {
                                if (a[noincluir.key] === noincluir.value) {
                                    return 0
                                } else {
                                    return a[ClaveArrai[0]];
                                }
                            } else if (typeof incluir !== 'undefined') {
                                let ClaveArraiIncluir = incluir.key.split('.');
                                switch (ClaveArraiIncluir.length) {
                                    case 1:
                                        if (a[ClaveArraiIncluir[0]] === incluir.value) {
                                            return a[ClaveArrai[0]];
                                        } else {
                                            return 0
                                        }
                                    case 2:
                                        if (a[ClaveArraiIncluir[0]][ClaveArraiIncluir[1]] === incluir.value) {
                                            return a[ClaveArrai[0]];
                                        } else {
                                            return 0
                                        }
                                    default:
                                        return 0;
                                }
                            } else {
                                return a[ClaveArrai[0]];
                            }
                        }).reduce((b, c) => {
                            return b + c
                        })
                    case 2:
                        return arrayJson.map((a) => {
                            if (typeof noincluir !== 'undefined') {
                                let ClaveArraiNoIncluir = noincluir.key.split('.');
                                switch (ClaveArraiNoIncluir.length) {
                                    case 1:
                                        if (a[ClaveArraiNoIncluir[0]] === noincluir.value) {
                                            return 0
                                        } else {
                                            return a[ClaveArrai[0]][ClaveArrai[1]];
                                        }
                                    case 2:
                                        if (a[ClaveArraiNoIncluir[0]][ClaveArraiNoIncluir[1]] === noincluir.value) {
                                            return 0
                                        } else {
                                            return a[ClaveArrai[0]][ClaveArrai[1]];
                                        }
                                    default:
                                        return 0;
                                }
                            } else if (typeof incluir !== 'undefined') {
                                let ClaveArraiIncluir = incluir.key.split('.');
                                switch (ClaveArraiIncluir.length) {
                                    case 1:
                                        if (a[ClaveArraiIncluir[0]] === incluir.value) {
                                            return a[ClaveArrai[0]][ClaveArrai[1]];
                                        } else {
                                            return 0
                                        }
                                    case 2:
                                        if (a[ClaveArraiIncluir[0]][ClaveArraiIncluir[1]] === incluir.value) {
                                            return a[ClaveArrai[0]][ClaveArrai[1]];
                                        } else {
                                            return 0
                                        }
                                    default:
                                        return 0;
                                }
                            } else {
                                return a[ClaveArrai[0]][ClaveArrai[1]];
                            }
                        }).reduce((b, c) => {
                            return b + c
                        })
                    default:
                        return 0;
                }
            }
        } catch (error: any) {
            throw Error(error.message);
        }
        /* Ejemplos:
        //Sin exclusion
            SummationValuesinArrayJson(Documentos, 'DatosImporte.Monto')
        //Con Exclusion 1 nivel
            SummationValuesinArrayJson(Documentos, 'DatosImporte.Monto', {
                key: 'ide',
                value: Documento_Editar.ide
            })
        //Con Exclusion 2 niveles
        SummationValuesinArrayJson(a.Documentos, 'DatosImporte.Monto', {
            key: 'Status.IdStatus',
            value: 10
        });
        //Con Inclusión
        await SummationValuesinArrayJson(a.Documentos, 'DatosImporte.Monto', undefined, {
            key: 'Status.IdStatus',
            value: 1
        });
        */
    }

    public static convertFormatDecimal(number: number, decimal: number) {
        return new Intl.NumberFormat("en-EN", { maximumFractionDigits: decimal, minimumFractionDigits: decimal }).format(number);
    }

    public static ordenarArray(data: Array<any> = [], campo: string = '', dir: number = 1) {
        data.sort((a, b) => a[campo] < b[campo] ? (dir * -1) : a[campo] > b[campo] ? (dir * 1) : 0);
        return data;
    }

    public static patternSoloNumeros(minLength = 0, maxLength = 50) {
        return `^[0-9]{${minLength},${maxLength}}$`;
    }

    public static patternSoloLetras(minLength = 0, maxLength = 50) {
        return `^[a-zA-ZÀ-ÿ\u00f1\u00d1]{${minLength},${maxLength}}$`;
    }

    public static patternLetrasEspacio(minLength = 0, maxLength = 50) {
        return `^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]{${minLength},${maxLength}}$`;
    }

    public static patternLetrasCaracteres(minLength = 0, maxLength = 50) {
        return `^[\\D ]{${minLength},${maxLength}}$`;
    }

    public static patternLetrasNumeros(minLength = 0, maxLength = 50) {
        return `^[a-zA-ZÀ-ÿ\u00f1\u00d10-9]{${minLength},${maxLength}}$`;
    }

    public static patternLetrasNumerosEspacio(minLength = 0, maxLength = 50) {
        return `^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]{${minLength},${maxLength}}$`;
    }

    public static patternLetrasNumerosCaracteres(minLength = 0, maxLength = 50) {
        return `^[\\D0-9$]{${minLength},${maxLength}}$`;
    }

    public static calculoTiempo(date: Date, language: 'es' | 'en' = 'es') {
        const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);

        let interval = seconds / 31536000;

        if (interval > 1) {
            return language === 'es'
                ? `Hace ${Math.floor(interval)} año${Math.floor(interval) > 1 ? 's' : ''}`
                : language === 'en'
                    ? `${Math.floor(interval)} year ago`
                    : '';
        }

        interval = seconds / 2592000;
        if (interval > 1) {
            return language === 'es'
                ? `Hace ${Math.floor(interval)} mes${Math.floor(interval) > 1 ? 'es' : ''}`
                : language === 'en'
                    ? `${Math.floor(interval)} month ago`
                    : '';
        }

        interval = seconds / 86400;
        if (interval > 1) {
            return language === 'es'
                ? `Hace ${Math.floor(interval)} día${Math.floor(interval) > 1 ? 's' : ''}`
                : language === 'en'
                    ? `${Math.floor(interval)} day ago`
                    : '';
        }

        interval = seconds / 3600;
        if (interval > 1) {
            return language === 'es'
                ? `Hace ${Math.floor(interval)} hora${Math.floor(interval) > 1 ? 's' : ''}`
                : language === 'en'
                    ? `${Math.floor(interval)} hour ago`
                    : '';
        }

        interval = seconds / 60;
        if (interval > 1) {
            return language === 'es'
                ? `Hace ${Math.floor(interval)} minuto${Math.floor(interval) > 1 ? 's' : ''}`
                : language === 'en'
                    ? `${Math.floor(interval)} minute ago`
                    : '';
        }

        return language === 'es'
            ? `Hace ${Math.floor(seconds)} segundo${Math.floor(seconds) > 1 ? 's' : ''}`
            : language === 'en'
                ? `${Math.floor(seconds)} second ago`
                : '';
    }

    public static formatoDinero(amount: number) {
        return `${amount}`.split('.').length > 1 ?
            `${amount}`.split('.')[1].length === 1 ? `${amount.toLocaleString()}0` : amount.toLocaleString()
            :
            `${amount.toLocaleString()}.00`
    }

    public static scrollTopByClassName(className: string = "") {
        document.getElementsByClassName(className)[0]?.scrollIntoView();
    }

    public static getIdFechaGlobal = (Fecha = new Date()) => {
        let y = Fecha.getFullYear(), m = Fecha.getMonth() + 1, d = Fecha.getDate();
        return parseInt('' + y + (m < 10 ? '0' : '') + m + (d < 10 ? '0' : '') + d);
    };

    public static readFile(file: any, mb: number = 5, type: string = 'PDF') {
        return new Promise(async (resolve, reject) => {
            try {
                if (file.size / 1024 / 1024 > mb) {
                    throw Error(`El archivo a adjuntar no debe exceder los ${mb} MB`);
                };
                let typeFile = file.type.split('/');
                let reader = new FileReader();
                reader.onload = (e: Event) => {
                    try {
                        let target: any = e.target;
                        switch (type) {
                            case 'PDF':
                                if (typeFile[0] === 'application' && typeFile[1] === 'pdf') {
                                    resolve({
                                        resp: 'Ok',
                                        message: '',
                                        file: file,
                                        name: file.name,
                                        size: file.size,
                                        base64: target.result,
                                    });
                                } else {
                                    throw Error(`El archivo a cargar debe ser en formato .PDF`);
                                }
                                break;
                            case 'DOC':
                            case 'DOCX':
                            case 'WORD':
                                if (typeFile[0] === 'application' && (typeFile[1].includes('.doc') || typeFile[1].includes('.docx'))) {
                                    resolve({
                                        resp: 'Ok',
                                        message: '',
                                        file: file,
                                        name: file.name,
                                        size: file.size,
                                        base64: target.result,
                                    });
                                } else {
                                    throw Error(`El archivo a cargar debe ser en formato .DOCX`);
                                }
                                break;
                            case 'XLS':
                            case 'XLSX':
                            case 'EXCEL':
                                if (typeFile[0] === 'application' && (typeFile[1].includes('.sheet') || typeFile[1].includes('.excel'))) {
                                    resolve({
                                        resp: 'Ok',
                                        message: '',
                                        file: file,
                                        name: file.name,
                                        size: file.size,
                                        base64: target.result,
                                    });
                                } else {
                                    throw Error(`El archivo a cargar debe ser en formato .XLSX`);
                                }
                                break;
                            default:
                                resolve({
                                    resp: 'Ok',
                                    message: '',
                                    file: file,
                                    name: file.name,
                                    size: file.size,
                                    base64: target.result,
                                });
                                break;
                        };
                    } catch (error) {
                        reject({
                            resp: 'Error',
                            message: (error as Error).message,
                            name: file.name,
                            size: file.size,
                            // base64: e.target.result
                        });
                    }
                };
                reader.readAsDataURL(file);
            } catch (error) {
                reject(error)
            };
        });
    }

    public static async convertBlobToBase64(blob: Blob, pdf: boolean = true) {
        return new Promise(async (resolve, reject) => {
            try {
                const reader: any = new FileReader;
                reader.onerror = reject;
                reader.onload = async () => {
                    let result = await reader.result
                    if (pdf) {
                        result = result.replace("data:application/octet-stream;base64,", "data:application/pdf;base64,");
                    };
                    resolve(result);
                };
                reader.readAsDataURL(blob);
            } catch (error) {
                reject(error);
            }
        });
    }

    public static async base64ToURLBlob(base64: any) {
        try {
            let f = await fetch(base64);
            let blob = await f.blob();
            let urlBlob = URL.createObjectURL(blob);
            return urlBlob;
        } catch (error) {
            throw error;
        }
    }

    public static padLeft(text: string, length: number, padding: string) {
        let str = text + "";
        let resp: any = (length <= str.length) ? str : this.padLeft(padding + str, length, padding);
        return resp;
    };

    public static addDays(date: Date, days: number): Date {
        const newDate = new Date(date);
        newDate.setDate(date.getDate() + days);
        return newDate;
    };

    public static addMonths(date: Date, months: number): Date {
        const newDate = new Date(date);
        newDate.setMonth(date.getMonth() + months);
        return newDate;
    };

    public static addYears(date: Date, years: number): Date {
        const newDate = new Date(date);
        newDate.setFullYear(date.getFullYear() + years);
        return newDate;
    };

    public static increaseDate(date: Date, days: number, months: number, years: number): Date {
        let newDate = new Date(date);
        newDate = this.addDays(newDate, days);
        newDate = this.addMonths(newDate, months);
        newDate = this.addYears(newDate, years);
        return newDate;
    };
}
