import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseEliminarFileControldocumental {
   private repository: RepositoryMain;
   
   constructor (_repository: RepositoryMain){
    this.repository = _repository;
   }

   public async exec(params: any): Promise<any>{
    return await this._exec(params);
   }

   public async _exec(params: any): Promise<any>{
      return await this.repository.getEleiminarileControlDocumento(params);
   }

}