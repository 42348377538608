//Dependency External
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useSelector } from 'react-redux';
import { NavigateFunction, useNavigate } from 'react-router-dom';

//Adapters
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico';
import { addLoading, removeLoading } from '../../../shared/Infraestructure/SliceGenerico';
import { AdapterConfigure } from './AdapterConfigure';
import { AdapterValidator } from '../../../shared/Infraestructure/AdapterValidator';
import { RootState } from '../../../shared/Infraestructure/AdapterStore';

//Repository
import { RepositoryImplMain } from './RepositoryImplMain';
import { UseCaseRecuperarPassword } from '../Application/UseCaseRecuperarPassword';

//UseCase

//Entities


export const Controller = () => {
    const { websocket, dbLocal } = useSelector((state: RootState) => state.generico);
    const dispatch: Dispatch = useDispatch();
    const navigate: NavigateFunction = useNavigate();

    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);

    const form = useFormik({
        initialValues: {
            identificacion: '',
            email: '',
            pais: { _id: '', code: '', name: '' },
        },
        validationSchema: Yup.object({
            identificacion: Yup.string().required('Ingresar Número Doc. Identidad (RUT)').matches(/^[a-zA-Z0-9]{8,}$/, 'El Número Doc. Identidad es incorrecto (RUT)').min(8, `Ingresar Número Doc. Identidad (RUT)`),
            email: Yup.string().matches(/^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i, 'Correo Corporativo no es correcto').required('Ingresar Correo Corporativo'),
            pais: Yup.object({ _id: Yup.string().required('Seleccionar País') }),
        }),

        onSubmit: (values, formikHelpers) => { },
    });

    const onChange = (name: string, value: any) => {
        if (value === null) { return; }
        form.setFieldValue(name, value);
    };

    const onChangeValuePais = (name: string, value: any) => {
        form.setValues((values: any) => ({
            ...values,
            pais: !value?._id ? form.initialValues['pais'] : { _id: value._id, code: value.code, name: value.name },
        }));
    };

    const onSubmit = async (e: Event) => {
        try {
            e.preventDefault();
            e.stopPropagation();

            try { await form.submitForm(); } catch (error) { }
            try { AdapterValidator.validate(await form.validateForm()); } catch (error) { AdapterGenerico.createMessage('Incompleto', (error as Error).message, 'warning', false); return null; }

            dispatch(addLoading({ textLoading: 'Cargando...' }));

            await (new UseCaseRecuperarPassword(repository)).exec({ identificacion: form.values.identificacion, email: form.values.email, pais: form.values.pais.code });

            dispatch(removeLoading());
            form.resetForm();
            AdapterGenerico.createMessage('Exitoso', `<p>La recuperación de contraseña se ha <b>realizado exitosamente</b>. Pronto recibirá un correo electrónico con su nueva contraseña</p>`, 'success');
            onClickLogin();
        } catch (error) {
            console.error(error);

            dispatch(removeLoading());
            AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false);
        };
    };

    const onClickLogin = () => {
        navigate(`/${process.env.REACT_APP_ROUTE_PATH_LOGIN}`, { replace: true });
    };


    return {
        form,
        onSubmit,
        onClickLogin,
        onChange,
        onChangeValuePais,
    };
}