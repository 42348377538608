import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { EntityMain } from "../Domain/EntityMain";
import { DtoReqChangePassword } from "../Domain/DtoReqChangePassword";
import { Buffer } from 'buffer'

export class RepositoryImplMain extends RepositoryImplGenerico<EntityMain> implements RepositoryMain {

    public async changePassword(params: DtoReqChangePassword): Promise<void> {
        try {
            await this.service.call("POST", `${process.env.REACT_APP_URL_MASTER}/${process.env.REACT_APP_URL_MASTER_SERVICES}/catalogo/proveedor/cambiarContrasenia`, JSON.stringify(params), "bearer", "json", "json", { "request-decrypt-response": "yes" }, 0);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    public async getStatusVendro(params: any): Promise<any> {
        return this._getStatusVendrot(params);
    }
    private async _getStatusVendrot(params: any): Promise<any> {
        const newParams = JSON.stringify(params)
        return await this.service.bgCall<any>('POST', `${this.urlBase}/catalogo/proveedor/selectStatusVendor`, newParams, 'bearer', "json", "json", {}, 0);
    }
}