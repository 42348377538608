import { EntityProcessOffline } from "../../context/shared/Domain/EntityProcessOffline";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseExecuteProcess<T>{
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(params: EntityProcessOffline): Promise<T> {
        return await this._exec(params);
    }

    private async _exec(params: EntityProcessOffline): Promise<T> {
        return await this.repository.executeProcess<T>(params);
    }
}