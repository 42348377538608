const URL_GIA: string = process.env.REACT_APP_URL_GIA || '';
const URL_GIA_SERVICES: string = process.env.REACT_APP_URL_GIA_SERVICES || '';
const AUTH_BASIC_USER_GIA: string = process.env.REACT_APP_AUTH_BASIC_USER_GIA || '';
const AUTH_BASIC_PASS_GIA: string = process.env.REACT_APP_AUTH_BASIC_PASS_GIA || '';
const SCHEMA: string = process.env.REACT_APP_MASTER_LOGIN_SCHEMA || '';
const ENTITY: string = process.env.REACT_APP_MASTER_LOGIN_ENTITY || '';

export const AdapterConfigure = {
    URL_GIA,
    URL_GIA_SERVICES,
    AUTH_BASIC_USER_GIA,
    AUTH_BASIC_PASS_GIA,
    SCHEMA,
    ENTITY,
};