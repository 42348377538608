import { AdapterGenerico } from '../Infraestructure/AdapterGenerico';
import './ElementInputFileImage.scss'

interface PropsView {
    //onChange: (fileList: FileList) => any;
    onChange: any;
    icon: string;
    disabled?: boolean;
    size?: number;
    capture?: boolean;
    multiple?: boolean;
    accept?: string //| "xml/*"| "image/*"
    className?: string;
    labelInput?: string;
};


export const ElementInputFileImage = (props: PropsView) => {
    const isImage = (fileList: FileList | null) => {
        if (!fileList) return;

        let error = { isError: false, fileName: '' };
        Array.from(fileList).forEach(file => {
            if (!file.type.match(props.accept ? props.accept : 'image/*')) error = { isError: true, fileName: file.name }
        });

        if (error.isError) {
            AdapterGenerico.createMessage('Alerta', `${error.fileName} no es una imagen`, 'warning', false);
        }

        props.onChange(fileList);
    }

    return (
        // <div className='p-fileupload p-fileupload-basic p-component ml-2 w-full'>
        //     <div><div></div></div>
        //     <span className='p-button p-component p-fileupload-choose p-button-success p-button-sm w-full'>
        //         <span className='p-button-icon p-button-icon-left pi pi-upload'></span>
        //         <span className='p-button-label p-clickable'>{props.labelInput ? props.labelInput :'Importar XML'}</span>
        //         <input type='file' accept={props.accept}/>
        //     </span>
        // </div>
        <label className={`ElementInputFileImage ${props.className}`} >
            <input type="file" accept={props.accept ? props.accept : 'image/*'} multiple={props.multiple} capture={props.capture} onChange={props.onChange} disabled={props.disabled} value="" />
            <div className='flex align-items-center	'>
                <i className={props.icon} style={{ fontSize: props.size || 30 }} />

                <span style={{ fontSize: props.size || 30 }} className='px-1 w-full text-center'>{props.labelInput ? props.labelInput : 'Importar XML'}</span>
            </div>

        </label>
    );
};
