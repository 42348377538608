import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RootState } from '../../../context/shared/Infraestructure/AdapterStore';

interface Props {
    element: JSX.Element;
    isLogin?: boolean;
    privateContent?: boolean;
};

export const AuthGuard = ({ element, isLogin, privateContent }: Props) => {
    const { auth } = useSelector((state: RootState) => state.auth);

    return privateContent
        ? auth
            ? element
            : <Navigate replace to={`/${process.env.REACT_APP_ROUTE_PATH_LOGIN}`} />
        : auth
            ? <Navigate replace to={`/${process.env.REACT_APP_ROUTE_PATH_MAIN}`} />
            : element;
};
