import { DtoRequestLogin } from "../Domain/DtoRequestLogin";
import { DtoResponseLogin } from "../Domain/DtoResponseLogin";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseLogin {
   private repository: RepositoryMain;
   
   constructor (_repository: RepositoryMain){
    this.repository = _repository;
   }

   public async exec(params: DtoRequestLogin): Promise<DtoResponseLogin | null>{
    return await this._exec(params);
   }

   public async _exec(params: DtoRequestLogin): Promise<DtoResponseLogin | null>{
    let result: DtoResponseLogin | null = await this.repository.login(params);
    return result;
   }

}