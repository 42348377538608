import * as React from 'react';
import { Controller } from "./Infraestructure/Controller";
import { ViewMain } from "./Components/View";
import { DialogEditFactura } from './Components/DialogEditFactura';
import { DialogViewDocument } from './Components/DialogViewDocument';

const ManagerEntity = () => {
    const controller = Controller();

    React.useEffect(() => {
         controller.init();
        return () => {
        }
        // eslint-disable-next-line
    }, [controller.countProcess]);

    return (
        <div>
        <ViewMain
        data={controller.data}
        reloadData={controller.loadData}
        globalFilter={controller.globalFilter}
        setGlobalFilter={controller.setGlobalFilter}
        home={controller.home}
        items={controller.items}
        getStatus={controller.getStatus}
        formatCurrency={controller.formatCurrency}
        TransformMoneda={controller.TransformMoneda}
        TransformDate={controller.TransformDate}
        openEditFactura={controller.openEditFactura}
        openVerMotivoRechazo={controller.openVerMotivoRechazo}
        openEnviarDevolucion={controller.openEnviarDevolucion}
        
    />

    <DialogEditFactura
        dataBill={controller.dataBill}
        onHideBill={controller.onHideBill}
        visibleBill={controller.visibleBill}
        setTabIndex={controller.setTabIndex}
        tabIndex={controller.tabIndex}
        itemsDialogSteps={controller.itemsDialogSteps}
        onSubmit={controller.onSubmit}
        formatCurrency={controller.formatCurrency}
        formatCurrenc_step={controller.formatCurrenc_step}
        formatDate={controller.formatDate}
        onChange={controller.onChange}
        selectedValorizaciones={controller.selectedValorizaciones}
        tipoIVA={controller.tipoIVA}
        onChangeTipoIva={controller.onChangeTipoIva}
        onChangeValueImporteIva={controller.onChangeValueImporteIva}
        onCellEditCompleteImporte={controller.onCellEditCompleteImporte}
        disabledImporteIva={controller.disabledImporteIva}
        disabled={controller.disabled}
        formInvoice= {controller.formInvoice}
        tipoDocumentoTributario={controller.tipoDocumentoTributario}
        setFacturaFile= {controller.setFacturaFile}
        //onHideDialogSteps= {controller.onHideDialogSteps}

        setAnexoFile={controller.setAnexoFile}
        onSelectAnexo={controller.onSelectAnexo}
        onRemoveAnexo={controller.onRemoveAnexo}
        onClearAnexo={controller.onClearAnexo}
        ListaControlDocumental={controller.ListaControlDocumental}
        onSelectDocuementoFactura={controller.onSelectDocuementoFactura}
        onRemoveDocuementoFactura={controller.onRemoveDocuementoFactura}
        onClearDocuementoFactura={controller.onClearDocuementoFactura}
  
        verDocumento={controller.verDocumento}
        CargarFileControlDocumental={controller.CargarFileControlDocumental}
        setDocumentosFile={controller.setDocumentosFile}
        eliminarDocumento={controller.eliminarDocumento}
        AlertSizeFileUpload={controller.AlertSizeFileUpload}
        AlertTypeFileUpload={controller.AlertTypeFileUpload}
    />

            <DialogViewDocument
                pdf={controller.pdf}
                onHideDocument={controller.onHideDocument}
                visibleDocument={controller.visibleDocument}
                textValorizacion={controller.dataText.textValorizacion}
                
            />
    </div>
);
};

export default ManagerEntity;