import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { DtoRequestLogin } from "../Domain/DtoRequestLogin";
import { DtoResponseLogin } from "../Domain/DtoResponseLogin";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { removeLoading } from "../../../shared/Infraestructure/SliceGenerico";
import { EntityMenu } from "../../../shared/Domain/EntityMenu";
import { AdapterConfigure } from "./AdapterConfigure";
import { Buffer } from "buffer";
// Define una interfaz que describa la estructura de la respuesta esperada

export class RepositoryImplMain
  extends RepositoryImplGenerico<any>
  implements RepositoryMain
{
  public async login(
    params: DtoRequestLogin
  ): Promise<any | null> {
    return this._login(params);
  }

  private async _login(
    params: DtoRequestLogin
  ): Promise<any | null> {
    if (!window.navigator.onLine) {
      this.dispatch(removeLoading());
      AdapterGenerico.createMessage(
        "Sin Conexión",
        "No posee conexión a internet",
        "warning"
      );
      return null;
    }
    
    let response = await this.service.call("POST", `${process.env.REACT_APP_URL_MASTER}/${process.env.REACT_APP_URL_MASTER_SERVICES}/catalogo/proveedor/login`, JSON.stringify(params), "bearer", "json", "json", { "request-decrypt-response": "yes" }, 0);
    return response

  }
}
