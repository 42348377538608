import React from 'react'
import { DtoResponseInvoice } from '../../Home/Domain/DtoResponseInvoice';
interface PropsLiDashboard{
  openDocumentHome:Function;
    value:DtoResponseInvoice;
    TransformDate:Function;
    color:string;
    codeApproval:number;
    
}
const LiDashboard = (props:PropsLiDashboard) => {
    const statusBodyTemplate = () => {
        const arr_status=['En Aprobación', 'Aprobado', 'Pagado','Anulado'];
        let status='';
        
        if(props.codeApproval===1){
          
          status=arr_status[1];
        }
        if(props.codeApproval===0){
          status=arr_status[0];
        }
        if(props.codeApproval===103){
          status=arr_status[1];
        }
        if(props.codeApproval===101){
          status=arr_status[2];
        }
        if(props.codeApproval===4){
          status=arr_status[3];
        }
        return status;
      }
  return (
    <li className={`flex flex-column md:flex-row md:align-items-center md:justify-content-between p-3 border-1 mb-3 bg-${props.color}-50 border-${props.color}-500`} style={{borderRadius: '10px'}}>
                <div>
                    
                    <div>
                    
                        {/* <span 
                            className='cursor-pointer inline-flex justify-content-center align-items-center w-2rem h-2rem border-circle border-1 border-red-200'
                            onClick={(e)=>{props.openDocumentHome(props.value)}}
                        >
                            <i className={`pi pi-file-pdf text-red-500`}></i>
                        </span> */}
                        <span className={`text-${props.color}-700 font-bold ml-2`}>{props.value.Codigo}</span>
                    </div>
                    <p className={`text-${props.color}-700 mt-2 mb-0`}>
                        Fecha
                        <span className='font-medium'> {props.TransformDate(props.value.Dates.Registry.Date)}</span>
                    </p>
                </div>
                <div className='flex align-items-center justify-content-between md:justify-content-end mt-3 md:mt-0'>
                    <span className={`bg-${props.color}-400 text-white font-bold text-sm py-1 px-2`} style={{borderRadius: '10px'}}>{statusBodyTemplate()}</span>
                    <div className='text-right ml-3'>
                        <span className={`text-${props.color}-700 font-bold`}>
                            {props.value.DatosPago.Importe.Monto?.toLocaleString('en-US',{minimumFractionDigits:2,maximumFractionDigits:2})}
                        </span>
                        <p className={`mt-1 mb-0 text-${props.color}-700`}>{props.value.DatosPago.Moneda.Codigo==='' ? 'PEN' : props.value.DatosPago.Moneda.Codigo}</p>
                    </div>
                </div>
            </li>
  )
}

export default LiDashboard
