import { useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { NavLink } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Avatar } from 'primereact/avatar';
import HomeIcon from '@mui/icons-material/Home';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import DescriptionIcon from '@mui/icons-material/Description';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LogoutIcon from '@mui/icons-material/Logout';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TicketIcon from '@mui/icons-material/Receipt';
import './SidebarMenu.scss';

interface ViewProps {
  show: boolean;
  setShow: () => void;
  logo: string;
  identificacion: string;
  nombre: string;
  username: string;
  onClickLogout?: Function;
}

interface MenuItem {
  name: string;
  path: string | null | undefined;
  icon: JSX.Element;
  subMenu: MenuItem[];
}

const SidebarMenu = (props: ViewProps) => {
  const menuData: MenuItem[] = [
    // {
    //   name: "Factura",
    //   path: ``,
    //   icon: <RequestQuoteIcon sx={{ mr: 2 }} />,
    //   subMenu: [
    //     {
    //       name: "Ingreso",
    //       path: `${process.env.REACT_APP_ROUTE_PATH_MAIN_INVOICE_ENTRY}`,
    //       icon: <RequestQuoteIcon sx={{ mr: 2 }} />,
    //       subMenu: []
    //     },
    //     {
    //       name: "Estado",
    //       path: `${process.env.REACT_APP_ROUTE_PATH_MAIN_INVOICE_STATUS}`,
    //       icon: <DescriptionIcon sx={{ mr: 2 }} />,
    //       subMenu: []
    //     },
    //   ]
    // },
    // {
    //   name: "Ingreso Factura",
    //   path: `${process.env.REACT_APP_ROUTE_PATH_MAIN_INVOICE_ENTRY}`,
    //   icon: <RequestQuoteIcon sx={{ mr: 2 }} />,
    //   subMenu: []
    // },
     
    // {
    //   name: "Valorizaciones",
    //   path: `${process.env.REACT_APP_ROUTE_PATH_MAIN_VALORIZATION}`,
    //   icon: <RequestQuoteIcon sx={{ mr: 2 }} />,
    //   subMenu: []
    // },
    // {
    //   name: "Recepciones",
    //   path: `${process.env.REACT_APP_ROUTE_PATH_MAIN_RECEPTION}`,
    //   icon: <RequestQuoteIcon sx={{ mr: 2 }} />,
    //   subMenu: []
    // },
    {
      name: "Recepciones y Valorizaciones",
      path: `${process.env.REACT_APP_ROUTE_PATH_MAIN_RECEPTION_VALORIZATION}`,
      icon: <RequestQuoteIcon sx={{ mr: 2 }} />,
      subMenu: []
    },
    {
      name: "Historico Factura",
      path: `${process.env.REACT_APP_ROUTE_PATH_MAIN_INVOICE_STATUS}`,
      icon: <DescriptionIcon sx={{ mr: 2 }} />,
      subMenu: []
    },
    {
      name: "Control Documental",
      path: `${process.env.REACT_APP_ROUTE_PATH_MAIN_CONTROL_DOCUMENT}`,
      icon: <PictureAsPdfIcon sx={{ mr: 2 }} />,
      subMenu: []
    },
    // {
    //   name: "Tickets",
    //   path: `${process.env.REACT_APP_ROUTE_PATH_MAIN_TICKET}`,
    //   icon: <TicketIcon sx={{ mr: 2 }} />,
    //   subMenu: []
    // },
    {
      name: "Perfil",
      path: `${process.env.REACT_APP_ROUTE_PATH_MAIN_PROFILE}`,
      icon: <AccountBoxIcon sx={{ mr: 2 }} />,
      subMenu: []
    },
    
  ];

  const avatarLabel = (nombre: string): string => {
    let label = '';
    nombre.split(' ').map((value: any, index: number) => {
      if (index < 2) {
        label += value.substring(0, 1);
      }
    });
    return label;
  }

  const customIcons = (
    <div className='pl-4 flex align-items-center gap-3 text-sm'>
      <div className='border-circle bg-white color-white'>
        <img alt="logo" src={props.logo} height="40" className="p-1" />
      </div>
      <div>
        <p className='font-bold m-0 text-sm'>GIA PROVEEDORES</p>
      </div>
    </div>
  );

  const [isOpen, setIsOpen] = useState(menuData.map(() => false));

  const toggleSubmenu = (index: number) => {
    const newIsOpen = [...isOpen];
    if (!newIsOpen[index]) {
      for (let i = 0; i < newIsOpen.length; i++) {
        newIsOpen[i] = false;
      }
    }
    newIsOpen[index] = !newIsOpen[index];
    setIsOpen(newIsOpen);
  }

  const closeSubmenu = () => {
    const newIsOpen = [...isOpen];
    for (let i = 0; i < newIsOpen.length; i++) {
      newIsOpen[i] = false;
    }
    setIsOpen(newIsOpen);
  }

  const generarMenuItems = () => {
    try {
      return (
        <>
          {menuData.map((menuItem, index) => (
            <li key={index}>
              {menuItem.path ? (
                <NavLink
                  className='menu-item p-ripple flex align-items-center cursor-pointer p-3 text-bluegray-100 transition-duration-150 transition-colors w-full'
                  to={menuItem.path}
                  onClick={() => { props.setShow(); closeSubmenu(); }}
                >
                  {menuItem.icon}
                  <span>{menuItem.name}</span>
                </NavLink>
              ) : (
                <div
                  className='menu-item p-ripple flex align-items-center cursor-pointer p-3 text-bluegray-100 transition-duration-150 transition-colors w-full'
                  onClick={() => toggleSubmenu(index)}
                >
                  {menuItem.icon}
                  <span>{menuItem.name}</span>
                  <div className={`ml-auto arrow-icon ${isOpen[index] ? 'rotate' : ''}`}><ArrowDropDownIcon /></div>
                </div>
              )}
              {menuItem.subMenu.length > 0 && (
                <div className={`sub-menu ${isOpen[index] ? 'open' : ''}`}>
                  <ul className='list-none p-0 m-0'>
                    {menuItem.subMenu.map((subMenuItem, subIndex) => (
                      <li key={subIndex}>
                        <NavLink
                          to={!subMenuItem.path ? "" : subMenuItem.path}
                          className='p-ripple flex align-items-center cursor-pointer p-3 text-bluegray-100 transition-duration-150 transition-colors w-full'
                          onClick={() => { props.setShow(); closeSubmenu(); }}
                        >
                          {subMenuItem.icon}
                          <span>{subMenuItem.name}</span>
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </li>
          ))}
        </>
      );
    } catch (error) {
      throw error;
    }
  }

  const home = <li>
    <NavLink
      className='menu-item p-ripple flex align-items-center cursor-pointer p-3 text-bluegray-100 transition-duration-150 transition-colors w-full'
      to={`/${process.env.REACT_APP_ROUTE_PATH_MAIN}`}
      onClick={() => { props.setShow(); closeSubmenu(); }}
    >
      <HomeIcon sx={{ mr: 2 }} />
      <span className=''>Inicio</span>
    </NavLink>
  </li>

  const logout = <li onClick={() => !!props.onClickLogout ? props.onClickLogout() : undefined}>
    <div className='menu-item p-ripple flex align-items-center cursor-pointer p-3 text-bluegray-100 transition-duration-150 transition-colors w-full'>
      <span>
        <LogoutIcon sx={{ mr: 2 }} />
        <span className=''>Cerrar Sesión</span>
      </span>
    </div>
  </li>

  let menuItems = generarMenuItems();
  let menu = <div className='flex flex-column'>
    <div className='slider-bottom border-y-1 border-white'>
      <div className='mt-2 pl-4 flex gap-2'>
        <Avatar className="mr-3 mt-1 font-semibold" size="normal" style={{ backgroundColor: '#fff', color: '#1B458F' }} shape="circle" label={avatarLabel(props.nombre)} />
        <div>
          <p className='font-semibold text-white mb-1 text-sm'> {props.nombre}</p>
          <p className='font-semibold text-white  mb-1 text-sm'> {props.identificacion}</p>
        </div>
      </div>
    </div>
    <div>
      <ul className='list-none p-3 m-0'>
        {home}
        {menuItems}
        {logout}
      </ul>
    </div>
  </div>

  return (
    <>
      <div className='slider-manual hidden lg:block h-screen flex-shrink-0 absolute lg:static left-0 top-0 z-1 select-none bg-gia text-white sidebar-menu'>
        <div className='flex flex-column h-full'>
          {menu}
        </div>
      </div>
      <Sidebar modal={false} visible={props.show} onHide={props.setShow} className='sidebar-bar md:block lg:hidden sm:block bg-gia text-white sidebar-menu' icons={customIcons}>
        {menu}
      </Sidebar>
    </>
  );
};

export { SidebarMenu };
