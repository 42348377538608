import { EntityLanguage } from "../Domain/EntityLanguage";
import { AdapterStorage } from './AdapterStorage';

declare var window: any;
const DEFAULT_LANGUAGE = 'es';
const LANGUAGE_NAME_FROM_LOCAL_STORAGE = 'language';

export const LanguageTranslate = ():EntityLanguage => {
    const { language } = AdapterStorage.get([LANGUAGE_NAME_FROM_LOCAL_STORAGE]);
    const languageTranslate:EntityLanguage = window.config.listLanguage[language || DEFAULT_LANGUAGE];
    return languageTranslate;
};
