import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import { EntityMenu } from '../../../shared/Domain/EntityMenu';
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico';
import { RootState } from '../../../shared/Infraestructure/AdapterStore';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';
import { changeSaludo, hideIconMenu,addLoading, removeLoading  } from '../../../shared/Infraestructure/SliceGenerico';
import { DtoRequestInvoice } from '../Domain/DtoRequestInvoice';
import { UseCaseFacturas } from '../Application/UseCaseFacturas';
import { useState } from 'react';
import { DtoResponseInvoice } from '../Domain/DtoResponseInvoice';
import { RepositoryImplMain } from './RepositoryImplMain';
import { AdapterConfigure } from './AdapterConfigure';
import { Comparativo } from '../Domain/Comparativo';
import { ListaControlDocumental } from '../Domain/ListaControlDocumental';
import { DtoRequestComparativo } from '../Domain/DtoRequestComparativo';
import { DtoRequestControlDocumental} from '../Domain/DtoRequestControlDocumental';
import { UseCaseComparativo } from '../Application/UseCaseComparativo';
import { UseCaseListaControlDocumental } from '../Application/UseCaseListaControlDocumental';
import { UseCaseCargaFileControldocumental } from '../Application/UseCaseCargaFileControldocumental'; 
import { UseCaseEliminarFileControldocumental } from '../Application/UseCaseEliminarFileControldocumental'; 
import {Controller as ControllerValorizacionRecepcion} from '../../../Extranet/ReceptionsValorizations/Infraestructure/Controller';
import {Controller as ControllerStatusInvoice} from '../../../Extranet/StatusInvoice/Infraestructure/Controller';
import { FileUploadSelectEvent } from "primereact/fileupload";

export const Controller = () => {
    let { auth: { menu } } = useSelector((state: RootState) => state);
    const languageTranslate = LanguageTranslate();
    const dispatch: Dispatch = useDispatch();
    const navigate = useNavigate();
    const { generico: { websocket, dbLocal, countProcess }, auth: { user } } = useSelector((state: RootState) => state);
    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);
    const [data, setData] = useState<Array<DtoResponseInvoice>>([]);
    const [dataComparativo,setDataComparativo] = useState<Array<Comparativo>>([]);
    const [ListaControlDocumental,setDataListaControlDocumental] = useState<Array<ListaControlDocumental>>([]);
    const [visibleDialogSteps,setVisibleDialogSteps] = useState<boolean>(false);
    const [visibleDialogCargarDocumento,setvisibleDialogCargarDocumento] = useState<boolean>(false);
    const [pdf,setPdf] = useState<any>();
    const [visibleDocument, setVisibleDocument] = useState<boolean>(false);
    const [DocumentosFile,setDocumentosFile]= useState<FileUploadSelectEvent | null>();
    //menu = menu.filter(row => row.esOpcion && !row.padre);
    
    
    const controllerValorizacionRecepcion = ControllerValorizacionRecepcion();
    const controllerStatusInvoice=ControllerStatusInvoice();
    const init = async () => {
        dispatch(hideIconMenu());
        dispatch(changeSaludo(true));
        await controllerValorizacionRecepcion.init();
        await controllerStatusInvoice.init();
        
        await loadData();
        await loadDataComparativo();
        await loadDataListaControlDocumental();
    };

    const onClickAyuda = async () => {
        let confirmacion: boolean = await AdapterGenerico.createMessage(languageTranslate.moduloMain.textoTituloPreguntaAyuda, languageTranslate.moduloMain.textoDescripcionPreguntaAyuda, 'question', true);
        if (!confirmacion) return;
        window.open(AdapterConfigure.URL_HELP, '_blank');
    };

    const onClickReport = async () => {
        let confirmacion: boolean = await AdapterGenerico.createMessage(languageTranslate.moduloMain.textoTituloPreguntaReporte, languageTranslate.moduloMain.textoDescripcionPreguntaReporte, 'question', true);
        if (!confirmacion) return;
        window.open(AdapterConfigure.URL_GLPI, '_blank')
    };

    const onClickMenu = (element: EntityMenu) => {
        navigate(`/${element.ruta}`, { replace: true });
    };

    const verControlDocumental = () =>{
        setVisibleDialogSteps(true);
    }
    const onHideDialogSteps = ()=>{
        setVisibleDialogSteps(false);
    }

    const verCargaControlDocumental = () =>{
        setvisibleDialogCargarDocumento(true);
    }
    const onHideDialogCargaDocumento = ()=>{
        setvisibleDialogCargarDocumento(false);
    }

    

    const loadData = async () => {
        try {
            const param: DtoRequestInvoice = {
                _id:user.usuario._id
            }
            dispatch(addLoading({ textLoading: 'Cargando...' }));
            const response = await (new UseCaseFacturas(repository).exec(param));
            if (response === null) return;
            response?.map(value => {
                let file=null; 
                file = value?.Files?.PreRegistroCompra[value?.Files?.PreRegistroCompra.length-1];
                value.Files=file;

            });
            let result = response.filter((value,index)=>(index<10 && (value.Status.Nav.IdStatus===101 && value.Status.Solicitud.IdStatus===1)))
            .sort((a, b) => new Date(b.Dates.Registry.Date).getTime() - new Date(a.Dates.Registry.Date).getTime())
            setData(result);
        } catch (err) {
        } finally {
            dispatch(removeLoading());
        }

    };
    const loadDataComparativo = async () => {
        try {
            const param: DtoRequestComparativo = {
                _id: user.usuario._id
            }

            dispatch(addLoading({ textLoading: 'Cargando...' }));
            const response = await (new UseCaseComparativo(repository).exec(param));
            

            if (response === null) return;
            setDataComparativo(response);
        } catch (error) {

            //documentos pendientes por facturar
            //pendiente a facturar

            //colocar en gris bloqueado

            // Badge donde indique diferecnie el contrato de una orde de compra
            //no se puede seleccionar ordenes de compra por contratos
            //mover la segunda fila del otro

            //gia
            // activar Gia Proveedores
            // setear el correo
        }finally {
            dispatch(removeLoading());
        }
    }

    const loadDataListaControlDocumental = async () => {
        try {
            dispatch(addLoading({ textLoading: 'Cargando...' }));
            // console.log("user", user)
             const param: DtoRequestControlDocumental = {
                 _id: user.usuario._id,
                 pais: user.usuario.pais,
                 delegacion: user.usuario.datosTrabajo.delegacion,
                 ot:user.usuario.datosTrabajo.ot,
                 "Supplier classification":user.usuario["Supplier classification"],
                 Tipo:"Proveedores"
             }
             
             //dispatch(addLoading({ textLoading: 'Cargando...' }));
             const response_doc = await (new UseCaseListaControlDocumental(repository).exec(param));
             if (response_doc === null) return;
             console.log("response_doc", response_doc);
             let result = response_doc.filter((value,index)=>((value.EstadoCarga==='Pendiente') && value.Obligatoriedad==='SI')  || value.EstadoCarga==='Vencido' )
             if (response_doc === null) return;
             setDataListaControlDocumental(result);
             dispatch(removeLoading());
        } catch (error) {
       
        }finally {
            dispatch(removeLoading());
        }

    }

    const TransformDate = (value:string|Date) => {
        return new Date(value).toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const openDocumentHome = async (rowData: any) => {
        //console.log("rowData",rowData)
        //"NAVISI","OPERACIONES","O90","O90-C013-24000000028","PreRegistroCompra"
        const requestOptions = {
            "method": 'POST',
            "headers": {
                'authorization': `Basic dXNlci5naWE6Z3I4cDBDMGJyNCEhJA==`,
                'request-decrypt-response': 'yes',
                'Content-Type': 'application/json',
            },
            "body": JSON.stringify({ "directory": [
                "NAVISI",
                "OPERACIONES",
                `${rowData.Codigo?.split('-')[0]}`,
                //rowData.CodigoFactura,
                 `${rowData.Codigo}`,
                // "O96-C0LA-95002349450",
                `PreRegistroCompra`
                // "Albaran"

            ],
            //"fileName": `77d7bd49-c5cb-4328-810a-9723254a4427.PDF`, 
            "fileName":`${(rowData.Files?.PreRegistroCompra)?rowData.Files?.PreRegistroCompra[rowData.Files?.PreRegistroCompra.length-1].LoadName:""}`,
            user:null }),
        };
        
        const urlDev = AdapterConfigure.URL_FILES_DOWNLOAD;
        const document = await fetch(urlDev, requestOptions);
        if(!document.ok){
          AdapterGenerico.createMessage('Sin Archivo', '¡Sin Archivo!', 'warning', false)
          return;
        }
        let  type = 'base64';
        let blob = await document.blob();
        let result;

                switch (type) {
                    case 'blob':
                        result = blob;
                        break;
                    case 'urlBlob':
                        result = URL.createObjectURL(blob);
                        break;
                    case 'base64':
                        result = await convertBlobToBase64(blob);
                        break;
                    default:
                        break;
                };
        
        
        setPdf(result);
        setVisibleDocument(true);
        

    }

    const onHideDocument = () => {
        setVisibleDocument(false);
    }

    const verDocumento = async (rowData: any) => {

        try {
            dispatch(addLoading({ textLoading: 'Cargando...' }));
            const requestOptions = {
                "method": 'POST',
                "headers": {
                    'authorization': `Basic dXNlci5naWE6Z3I4cDBDMGJyNCEhJA==`,
                    'request-decrypt-response': 'yes',
                    'Content-Type': 'application/json',
                },
                "body": JSON.stringify({ "directory": [
                    "NAVISI",
                    "OPERACIONES",          
                    `${(rowData.Proveedor.TipoOperacion==="")?"0102":rowData.Proveedor.TipoOperacion}`,
                    `${rowData.Proveedor.Codigo}`,
                     `${rowData.Codigo}`,
    
                ],
                "fileName":`${rowData.Files.loadName}`,
                user:null }),
            };
            const urlDev = AdapterConfigure.URL_FILES_DOWNLOAD;
            const document = await fetch(urlDev, requestOptions);
            if(!document.ok){
              AdapterGenerico.createMessage('Sin Archivo', '¡Sin Archivo!', 'warning', false)
              return;
            }
            let  type = 'base64';
            let blob = await document.blob();
            let result;
    
            switch (type) {
                case 'blob':
                    result = blob;
                    break;
                case 'urlBlob':
                    result = URL.createObjectURL(blob);
                    break;
                case 'base64':
                    result = await convertBlobToBase64(blob);
                    break;
                default:
                    break;
            };
    
            console.log("result****",result);
            setPdf(result);    
            setVisibleDocument(true);
        } catch (error) {
            
        }finally{
            dispatch(removeLoading());
        }
    }

    const convertBlobToBase64 = async (blob:any) => {
        return new Promise(async (resolve, reject) => {
            try {
                const reader = new FileReader;
                reader.onerror = reject;
                reader.onload = async () => {
                    let result = await reader.result?.toString() || '';
                    resolve(result.replace("data:application/octet-stream;base64,", "data:application/pdf;base64,"));
                };
                reader.readAsDataURL(blob);
            } catch (error) {
                reject(error);
            }
        });
      };

    const CargarFileControlDocumental = async (file:any,parametroCodigo:string,parametroDocumento:any) => {
        try {
            dispatch(addLoading({ textLoading: 'Cargando...' }));
            setDocumentosFile(file);
            //console.log("parametroCodigo", parametroCodigo)
            await (new UseCaseCargaFileControldocumental(repository)).exec({ 
                _id: user.usuario._id,
                documento: file,
                NombreDocumento:parametroDocumento,
                CodigoDocumento:parametroCodigo
            });
            await loadDataListaControlDocumental();
        } catch (error) {
            
        }finally{
            dispatch(removeLoading());
        }

    };

    const eliminarDocumento = async (data:any) => {
        try {
            
            let confirmacion: boolean = await AdapterGenerico.createMessage('Confirmación', '¿Está seguro de que desea eliminar este documento?', 'question', true, '.sliderbarGLPI');
            if (!confirmacion) return;
            dispatch(addLoading({ textLoading: 'Cargando...' }));
            await (new UseCaseEliminarFileControldocumental(repository)).exec({ 
                _id: user.usuario._id,
                CodigoDocumento:data.Codigo
            });
            await loadDataListaControlDocumental();

        } catch (error) {
            
        }finally{
           dispatch(removeLoading());
        }

    };

        
    const AlertSizeFileUpload = () => {
        AdapterGenerico.createMessage('Alerta', "El Archivo debe pesar maximo 50M!", 'warning', false);
      };
  
      const AlertTypeFileUpload = () => {
        AdapterGenerico.createMessage('Alerta', "Debe subir un archivo PDF!", 'warning', false);
      };
      
    return {
        init,
        user,
        menu,
        data,
        dataStatusInvoice:controllerStatusInvoice.data,
        onClickAyuda,
        onClickReport,
        onClickMenu,
        onHideDialogSteps,
        TransformDate,
        verControlDocumental,
        verCargaControlDocumental,
        onHideDialogCargaDocumento,
        dataComparativo,
        valorizacionRecepciones:controllerValorizacionRecepcion.data.length,
        dataComparativos:controllerValorizacionRecepcion.data,
        ListaControlDocumental,
        visibleDialogSteps,
        visibleDialogCargarDocumento,
        pdf,
        visibleDocument,
        openDocumentHome,
        onHideDocument,
        verDocumento,
        CargarFileControlDocumental,
        setDocumentosFile,
        eliminarDocumento,
        AlertSizeFileUpload,
        AlertTypeFileUpload
    };
}