import { Card } from 'primereact/card'
import React, { useEffect, useState } from 'react'
import { DataTable,DataTableDataSelectableEvent,DataTableSelectionChangeEvent  } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import './Style.scss';
import { Comparativo } from '../Domain/Comparativo';
import { BreadCrumb } from 'primereact/breadcrumb';
import { DialogForm } from './DialogForm';
import { ToggleButton } from 'primereact/togglebutton';
import { MenuItem } from 'primereact/menuitem';
import { TabView, TabPanel } from 'primereact/tabview';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Sidebar } from 'primereact/sidebar';
import { RadioButton } from "primereact/radiobutton";
import ArticleIcon from '@mui/icons-material/Article';
import { Fieldset } from 'primereact/fieldset';
import { Toast } from 'primereact/toast';
import DialogSteps from './DialogSteps';
interface propsViewMain {
  data: Comparativo[];
  reloadData: Function;
  tipoDatosTributarios:any;
  loadTipoDocumentoTributario:Function;
  tipoIVA:any;
  loadTipoIVA:Function;
  user:any;
  home:MenuItem;
  items:MenuItem[];
  visible:boolean;
  setVisible:Function;
  transformMoneda:Function;
  transformCurrency:Function;
  TransformDate:Function;
  setSelectedComparativos:Function;
  selectedComparativos:Comparativo | any;
  isSelectable:Function;
  formInvoice:{ values: any; touched: any; errors: any; handleBlur: Function; handleChange:Function;handleSubmit:Function; setValues:Function;initialValues:any,isValid:boolean};
  onSubmit:Function;
  onChangeValueMontoFormInvoice:Function;
  activeIndex:number;
  setActiveIndex:Function;
  setTabIndex:Function;
  tabIndex:number;
  setGlobalFilter:Function;
  globalFilter:string;
  onNextStep:Function;
  toast:Toast|any;
  onSave:Function;
  onChange:Function;
  onChangeValueTipoIVAFormInvoice:Function;
  onClickCancel:Function
  disabled:boolean|any;
  setDisabled:Function;
  resultTab:any[];
}


  
export const ViewMain = (props:propsViewMain) => {

  
  const [dto,setDto]=useState<any>();
  const startContent = (
    <React.Fragment>
        <Button label="Nuevo" icon="pi pi-plus" className='bg-gia' disabled={(props.selectedComparativos === null) || (props.selectedComparativos.length===0)} onClick={() => transformData(props.selectedComparativos)} />
        
    </React.Fragment>
  );

  const transformData = (array:any[]) => {
    let contracts:any=[];
    props.setVisible(true);
    if(array[0].MovimientosRecepcion.length!==0){
      array.map((row) => {
        row.MovimientosRecepcion.map((row2:any)=>{
          return contracts=contracts.concat(row2);
        });
      });
    }
    else if(array[0].Valorizaciones.length!==0){
      array.map((row) => {
        row.Valorizaciones.map((row2:any)=>{
          return contracts=contracts.concat(row2);
        });
      });
      
    }else{
      contracts=[];
    }
    let d = {
      fechaEmision: new Date().toISOString().slice(0,10),
      moneda: array[0].DatosPago.Moneda.Codigo.length===0 ? 'PEN' : array[0].DatosPago.Moneda.Codigo,
      contracts: contracts,
    }
    setDto(d);
    
  }
    
    //OK
    const endContent = (
      <React.Fragment>
          <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText placeholder="Buscar..." onInput={(e) => props.setGlobalFilter(e.currentTarget.value)}/>
              </span>
      </React.Fragment>
    );
    const formatDate=(rowData:Comparativo)=>{
      return props.TransformDate(rowData.Dates.Registry?.Date);
    }
    const priceBodyTemplate = (rowData: any) => {
      
      return (
        <div className='flex justify-content-between align-items-center'>
          
            <span className=''>{props.transformMoneda(rowData)}</span>
            <p className='text-right mb-0 pr-6'>{props.transformCurrency(rowData.DatosPago?.Importe?.Monto)}</p>
          
        </div>
      )
    };
    const isRowSelectable = (event: any) => (event.data ? props.isSelectable(event.data) : true);

    const rowClassName = (data: any) => (props.isSelectable(data) ? '' : 'p-disabled');

    const tabSelectedTable = (codeMoneda:string,tipoCompraCode:string)=>{
      let select = props.data.filter(value => value?.DatosPago?.Moneda?.Codigo===codeMoneda && value?.TipoCompra.Code===tipoCompraCode);
      if (codeMoneda==='' && tipoCompraCode===''){
        return props.data
      }
      else{
        return select;
      }
      
    }
    const tableData = (codeMoneda:string,tipoCompraCode:string)=>{
      return <DataTable 
            paginatorClassName="paginator-gia"
            value={tabSelectedTable(codeMoneda,tipoCompraCode)} 
            selectionMode='multiple' 
            selection={props.selectedComparativos} 
            onSelectionChange={(e: DataTableSelectionChangeEvent<Comparativo | any>) => {props.setSelectedComparativos(e.value);console.log(e.value)}}
            //dataKey="id" 
            tableStyle={{ minWidth: '50rem' }}
            tableClassName='custombar1 mt-2'
            paginator
            rows={10}
            scrollable 
            scrollHeight="700px"
            isDataSelectable={isRowSelectable}
            rowClassName={rowClassName}
            size={'small'}
            //showGridlines 
            rowsPerPageOptions={[10, 20, 30, 40]}
            //columnResizeMode="expand" 
            resizableColumns
            globalFilter={props.globalFilter}
            
          >
            <Column  selectionMode="multiple"  headerStyle={{width: '3em'}} headerClassName={`bg-gia text-white ${(props.selectedComparativos===null || props.selectedComparativos?.length===0) ?'p-disabled':''}`}></Column>
            <Column sortable  field="Codigo" className='pr-0' bodyClassName={'text-sm'} header="Código" headerClassName='bg-gia text-white text-white text-sm'></Column>
            <Column sortable  field="Dates.Registry.Date" bodyClassName={'text-sm'} className='p-0' header="Fecha Registro" headerClassName='bg-gia text-white text-sm' body={formatDate}></Column>
            <Column sortable  field="TipoCompra.Description" bodyClassName={'text-sm'} className='p-0' header="Tipo Compra" headerClassName='bg-gia text-white text-sm'></Column>
            <Column sortable  field="Status.Solicitud.Status" bodyClassName={'text-sm'} className='p-0' header="Estado Solicitud" headerClassName='bg-gia text-white text-sm'></Column>
            <Column sortable  field="DatosPago.Importe.Monto" bodyClassName={'text-sm'} className='p-0' header="Importe" headerClassName='bg-gia text-white text-sm' body={priceBodyTemplate}></Column>
          </DataTable>
    }
    const onHide = ()=>{
      
      
        props.setVisible(false);props.setTabIndex(0);
      
    }
    
  return (
    <>
     <div className='flex justify-content-center'>
      
     </div>
     
    <div className='flex justify-content-center flex-column px-5'>
      <BreadCrumb home={props.home} model={props.items} className='border mb-3'/>
        <Card
          //title='Ingreso Factura'
        >
          <div className='mb-3'>
            <Toolbar className='bg-white border-none py-1 px-0' start={startContent} end={endContent}/>
          </div>
          <div className='mb-3'>
            
            
          </div>
          <TabView activeIndex={props.activeIndex} onTabChange={(e) => props.setActiveIndex(e.index)}>
            <TabPanel header="Todos">
              {tableData('','')}
            </TabPanel>
            {
              props.resultTab?.map((dat)=>(
                
                <TabPanel header={`${dat.tipoCompraCode==='01' ? "Orden Compra "+ (dat.codeMoneda==='' ? "Divisa Local" : dat.codeMoneda ) :"Contrato "+ (dat.codeMoneda==='' ? "Divisa Local" : dat.codeMoneda )}`}>
                {tableData(dat.codeMoneda,dat.tipoCompraCode)}
              </TabPanel>
              ))
            }

          </TabView>
        </Card>
        
    </div>
    
    <DialogSteps
      user={props.user}
      formData = {dto}
      visible={props.visible} 
      onHide={props.onClickCancel} 
      loadTipoDocumentoTributario={()=>props.loadTipoDocumentoTributario}
      tipoDatosTributarios ={props.tipoDatosTributarios}
      tipoIVA = {props.tipoIVA}
      loadTipoIVA = {props.loadTipoIVA}
      setTabIndex={props.setTabIndex} 
      tabIndex={props.tabIndex}
      formInvoice={props.formInvoice}
      onSubmit= {props.onSubmit}
      onChangeValueMontoFormInvoice= {props.onChangeValueMontoFormInvoice}
      TransformDate={props.TransformDate}
      transformCurrency={props.transformCurrency}
      onNextStep={props.onNextStep}
      toast={props.toast}
      onChange={props.onChange}
      onChangeValueTipoIVAFormInvoice={props.onChangeValueTipoIVAFormInvoice}
      onClickCancel={props.onClickCancel}
      disabled={props.disabled}
      setDisabled = {props.setDisabled}
    />
    

    </>
    


  )
}
