import React, { ChangeEvent } from 'react'
import { Row } from 'react-bootstrap';
import { ElementInputFormCostume } from '../../../shared/Components/ElementInputFormCostume';
import ElementDropdownPrimereact from '../../../shared/Components/ElementDropdownPrimereact';
import { DataTable } from 'primereact/datatable';
import { Valorization } from '../Domain/Valorization';
import { Column, ColumnEditorOptions, ColumnEvent } from 'primereact/column';
import ElementCurrencyPrimeReact from '../../../shared/Components/ElementCurrencyPrimeReact';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { ElementInputFileImage } from '../../../shared/Components/ElementInputFileImage';
import xml2js from 'xml2js';
import Col from 'react-bootstrap/Col';

interface InterfaceStepOne {
  formInvoice: { values: any; touched: any; errors: any; handleBlur: Function; handleChange: Function; handleSubmit: Function; setFieldValue: Function; };
  onChange: any;
  selectedValorizaciones: Valorization[] | any;
  formatDate: Function;
  formatCurrency: Function;
  setImporteAsociado:any;
  importeAsociado: number;
  exportExcel:Function;
  onCellEditCompleteImporte:any;
  importExcel:Function;
  handleFileUpload2:Function;
  disabled:boolean;
}

function StepOne(props: InterfaceStepOne) {
  const formatDate = (rowData: Valorization) => {
    return props.formatDate(rowData.Dates.Registry.Date);
  }
  const priceBodyTemplate = (rowData: Valorization) => {
    return (
      <div className='flex justify-content-between align-items-center'>


        <p className='text-right mb-0 pr-6'>{props.formatCurrency(rowData.Importe, rowData.Moneda)}</p>

      </div>
    )
  };
  const bodyImporteAsociar = (rowData: any) => {
    return (
      <ElementCurrencyPrimeReact
          currency={rowData.Moneda}
          value={rowData.ImporteAsociar}
          disabled={props.disabled}
          lg={12}
        />

        
      
    )
  }
  const cellEditorImporte = (options: ColumnEditorOptions | any) => {
    return (
      <ElementCurrencyPrimeReact
          currency={props.selectedValorizaciones[0].Moneda}
          value={options.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement> | any) => {
            return options.editorCallback(e.value);
            }
          }
          lg={12}
        />
     
      
    )  
  }
  const onCellEditCompleteImporte = (e: ColumnEvent) => {
    let { rowData, newValue, field, originalEvent: event } = e;
    let sumImporteAsociado=0;
    switch (field) {
      case 'ImporteAsociar':
        //props.formInvoice.setFieldValue('importeAsociado',newValue),
        props.setImporteAsociado(newValue);
        rowData[field] = newValue;
        break;
      default:
        if (newValue.trim().length > 0) rowData[field] = newValue;
    }
    sumImporteAsociado = props.selectedValorizaciones.reduce((accumulator:any, current:any) => accumulator + (current?.ImporteAsociar === undefined ? 0 : current?.ImporteAsociar), 0);
    
    props.formInvoice.setFieldValue('importeAsociado',sumImporteAsociado);
    props.formInvoice.setFieldValue('importeFactura', sumImporteAsociado);
    props.formInvoice.setFieldValue('importe', sumImporteAsociado);
    props.formInvoice.setFieldValue('importePendiente',(props.formInvoice.values.montoTotal - sumImporteAsociado));
    
    
    //setImporteAsociado(sumImporteAsociado);
    
  }
  
  return (
    <>
      
      <Row xs={12} md={4} lg={4}>
        <Col className='pb-1'>
          <ElementCurrencyPrimeReact
          
            name={'importeFactura'}
            label={'Importe Factura'}
            currency={props.selectedValorizaciones[0]?.Moneda}
            value={props.formInvoice?.values['importeFactura']}
            onBlur={props.formInvoice?.handleBlur}
            isValid={!!props.formInvoice?.touched['importeFactura'] && !props.formInvoice?.errors['importeFactura']}
            isInvalid={props.formInvoice?.touched['importeFactura'] && !!props.formInvoice?.errors['importeFactura']}
            invalid={props.formInvoice?.touched['importeFactura'] && props.formInvoice?.errors['importeFactura']}
            disabled
            lg={4}
          />
        
        </Col>
        <Col className='pb-1'></Col>
        <Col className='pb-1'></Col>
      </Row>
      <Row className="" xs={12} md={4} lg={4}>
      <Col className='mb-1'>
      <Button
            size="small" 
            label='Descargar Plantilla' 
            icon='pi pi-file-export' 
            className='p-button-info w-full' 
            type='button'

            onClick={()=>props.exportExcel()} 
          />
      
      </Col>
      <Col className='mb-1'>
        <FileUpload
              chooseOptions={{ label: 'Importar Plantilla', icon: 'pi pi-file-import', className: 'p-button-success p-button-sm w-full' }}
              mode="basic"
              auto
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              className="w-full"
              name="demo[]"
              //onUpload={(e)=>props.importExcel(e)}
              customUpload
              uploadHandler={(e) => props.importExcel(e)}
              
            />
      </Col>
      <Col className='mb-1'>
            <ElementInputFileImage
              size={13} 
              icon='pi pi-upload px-1' 
              accept='text/xml'
               onChange={(e: ChangeEvent<HTMLInputElement>) => props.handleFileUpload2(e)} 
              className='w-full text-white element-file-upload border-1 border-solid border-round cursor-pointer transition-all transition-duration-200 hover:bg-primary-600 hover:border-primary-600 active:bg-primary-700 active:border-primary-700 text-sm p-button-gia p-button-label p-c' 
            />
      </Col>
      
      </Row>
      <Row className="mb-1">

        <DataTable
          value={props.selectedValorizaciones}
          editMode="cell"
          tableStyle={{ minWidth: '30rem' }}
          size='small'
        >
          <Column 
            bodyClassName={'text-sm py-0'} 
            field="CodeValorizacion" 
            header="Código" 
            //style={{ width: '100%' }} 
            headerClassName='bg-gia text-white text-sm font-semibold' 
            className='lg:w-4 sm:w-2 ' 
          ></Column>
          <Column 
            className='lg:w-auto sm:w-1' 
            field="Observacion" 
            bodyClassName={'text-sm py-0'} 
            header="Observación" 
            headerClassName='bg-gia text-white text-sm font-semibold'
          ></Column>
          <Column 
            className='lg:w-2 sm:w-1 pr-0' 
            field="Date.Date" 
            bodyClassName={'text-sm py-0'} 
            header="Fecha" 
            headerClassName='bg-gia text-white text-sm font-semibold' 
            body={formatDate}
          ></Column>
          <Column 
            className='lg:w-2 sm:w-1 sm:px-0' 
            field="Importe" 
            bodyClassName={'text-sm py-0'} 
            header="Importe" 
            headerClassName='bg-gia text-white text-sm font-semibold' 
            body={priceBodyTemplate}
          ></Column>

          <Column 
            className='lg:w-3 sm:w-1' 
            field="ImporteAsociar" 
            bodyClassName={'text-sm lg:w-3 sm:w-1 sm:px-0 py-0'} 
            header="Importe Asociar" 
            headerClassName='bg-gia text-white text-sm font-semibold' 
            body={bodyImporteAsociar} 
            editor={(options) => cellEditorImporte(options)} 
            onCellEditComplete={props.onCellEditCompleteImporte}
          ></Column>



        </DataTable>
      </Row>
    </>
  )
}

export default StepOne
