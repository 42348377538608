import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import React from 'react'
import { ElementInputFormCostume } from '../../../shared/Components/ElementInputFormCostume';
import { ElementRichEditorCostume } from '../../../shared/Components/ElementRichEditorCostume';
import { InputGroup,Form } from 'react-bootstrap';
interface PropsDialogCreateTicket{
    visibleDialogSteps:boolean;
    onHideDialogSteps:Function;
    onSubmit:Function;
    formTicket:any;
    onChange:any;
    
}
const DialogCreateTicket = (props:PropsDialogCreateTicket) => {
    const footerContent = () => {
        return(
            <div>
              <Button 
                    label="Cancelar" 
                     onClick={(e)=>props.onHideDialogSteps(e)} 
                    severity="danger" 
                />
              <Button 
                label="Guardar"
                onClick={(e)=>props.onSubmit(e)}
                type="submit"
                className='bg-gia' 
              />
            </div>
        )
    }
  return (
    <Dialog header="Generar Ticket" visible={props.visibleDialogSteps} style={{ width: '50vw',zIndex:"1059" }} onHide={()=>props.onHideDialogSteps()}  className='form-dialog' footer={footerContent}>
        <form 
            className='sliderbarGLPI' 
            noValidate
        >
          <label className="fw-bold mt-3 mb-2" style={{ color: "#1B458F", textDecoration: "underline" }}>{"Generar Ticket"}</label>

          <ElementInputFormCostume
            classForm={"mt-2 text-truncate"}
            classLabel={'fw-bold fs-14'}
            sm={12} md={12} lg={12}
            name={'title'}
            value={props.formTicket.values['title']}
            text={'Título'}
            size={'sm'}
            type={'text'}
            onChange={props.onChange}
            onBlur={props.formTicket.handleBlur}
            isValid={!!props.formTicket.touched['title'] && !props.formTicket.errors['title']}
            isInvalid={!!props.formTicket.touched['title'] && !!props.formTicket.errors['title']}
            invalid={!!props.formTicket.touched['title'] && props.formTicket.errors['title'] ? props.formTicket.errors['title'].toString() : ''}
            addColClass={false}
          />

          <ElementInputFormCostume
            classForm={"mt-2 text-truncate"}
            classLabel={'fw-bold fs-14'}
            sm={12} md={12} lg={12}
            name={'mail'}
            value={props.formTicket.values['mail']}
            text={'Correo Corporativo'}
            maxLength={100}
            size={'sm'}
            type={'text'}
            onChange={props.onChange}
            onBlur={props.formTicket.handleBlur}
            isValid={!!props.formTicket.touched['mail'] && !props.formTicket.errors['mail']}
            isInvalid={!!props.formTicket.touched['title'] && !!props.formTicket.errors['mail']}
            invalid={!!props.formTicket.touched['mail'] && props.formTicket.errors['mail'] ? props.formTicket.errors['mail'].toString() : ''}
            addColClass={false}
          />

          <ElementInputFormCostume
            classForm={"mt-2 text-truncate"}
            classLabel={'fw-bold fs-14'}
            sm={12} md={12} lg={12}
            name={'mobilePhone'}
            value={props.formTicket.values['mobilePhone']}
            text={'Teléfono Móvil'}
            maxLength={9}
            pattern={`^[0-9]{0,9}$`}
            size={'sm'}
            type={'text'}
            onChange={props.onChange}
            onBlur={props.formTicket.handleBlur}
            isValid={!!props.formTicket.touched['mobilePhone'] && !props.formTicket.errors['mobilePhone']}
            isInvalid={!!props.formTicket.touched['mobilePhone'] && !!props.formTicket.errors['mobilePhone']}
            invalid={!!props.formTicket.touched['mobilePhone'] && props.formTicket.errors['mobilePhone'] ? props.formTicket.errors['mobilePhone'].toString() : ''}
            addColClass={false}
          />

          <Form.Group className={"mt-2 text-truncate"} >
            <Form.Label className={"fw-bold fs-14"} dangerouslySetInnerHTML={{ __html: 'Mensaje' }}></Form.Label>
            <InputGroup hasValidation>
              <ElementRichEditorCostume
                initialValue=""
                setValue={(value: any) => props.onChange('message', value)}
              />
            </InputGroup>
          </Form.Group>
          <br />

          <ElementInputFormCostume
            classForm={"pt-0 text-truncate"}
            sm={12} md={12} lg={12}
            name={'message'}
            value={props.formTicket.values['message']}
            size={'sm'}
            type={'text'}
            onChange={(e: Event) => { }}
            onBlur={props.formTicket.handleBlur}
            isValid={!!props.formTicket.touched['message'] && !props.formTicket.errors['message']}
            isInvalid={!!props.formTicket.touched['message'] && !!props.formTicket.errors['message']}
            invalid={!!props.formTicket.touched['message'] && props.formTicket.errors['message'] ? props.formTicket.errors['message'].toString() : ''}
            text={''}
            classText={"d-none"}
            disabled={true}
            hidden={false}
          />

        </form>
    </Dialog>
  )
}

export default DialogCreateTicket
