import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../context/shared/Infraestructure/RepositoryImplGenerico";
import { EntityMain } from "../Domain/EntityMain";
import { EntityParams } from "../Domain/EntityParams";
import { EntityProcessOffline } from "../../context/shared/Domain/EntityProcessOffline";
import { AdapterStorage } from "../../context/shared/Infraestructure/AdapterStorage";
import { EntityInformationDataInitial } from "../../context/shared/Domain/EntityInformationDataInitial";

export class RepositoryImplMain extends RepositoryImplGenerico<EntityMain> implements RepositoryMain {

    public async initialService(params: EntityParams): Promise<void> {
        await this._initialService(params);
    }

    private async _initialService(params: EntityParams): Promise<void> {
        let { servicesCalleds }: { servicesCalleds: EntityInformationDataInitial } = AdapterStorage.get('servicesCalleds');
        AdapterStorage.set('servicesCalleds', servicesCalleds);
    }

    public async executeProcess<T>(params: EntityProcessOffline): Promise<T> {
        let response: T | null = null;
        switch (params.type) {
            case 'api':
                response = await this.service.call<any>(params.method, this.urlBase + params.url, params.body, params.typeAuth, params.typeRequest, params.typeResponse, {}, 3);
                break;
            case 'ws':
                response = await this.websocket.emit(params.url, params.body);
                break;
            default:
                break;
        }
        return response as T;
    }
}