import ReactDOM from 'react-dom/client';
import './index.scss';
import ManagerApp from './app';
import { Provider } from 'react-redux';
import { AdapterStore } from './context/shared/Infraestructure/AdapterStore';
// import reportWebVitals from './reportWebVitals';
//imports primereact
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";  
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css"; 
//import primeflex
import '/node_modules/primeflex/primeflex.css';

window.addEventListener('load', () => window.history.pushState({ noBackExitsApp: true }, ''));
window.addEventListener('popstate', (event: PopStateEvent) => (event.state && event.state.noBackExitsApp) ? window.history.pushState({ noBackExitsApp: true }, '') : undefined);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  // <React.StrictMode>
  <Provider store={AdapterStore}>
    <ManagerApp />
  </Provider>
  // </React.StrictMode>
);

// reportWebVitals();
