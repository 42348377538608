import { DtoRequestComparativo } from "../Domain/DtoRequestComparativo";
import { Comparativo } from "../Domain/Comparativo";
import { RepositoryMain } from "../Domain/RepositoryMain";
import { TipoDocumentoTributario } from "../Domain/TipoDocumentoTributario";

export class UseCaseTipoDocumentoTributario {
   private repository: RepositoryMain;
   
   constructor (_repository: RepositoryMain){
    this.repository = _repository;
   }

   public async exec(params: any): Promise<TipoDocumentoTributario[] | null>{
    return await this._exec(params);
   }

   public async _exec(params: any): Promise<TipoDocumentoTributario[] | null>{
      return await this.repository.getDatosTributarios(params);
   }

}