import React,{ useState } from 'react'
import './FileUpload.scss';
import { Col, Row } from 'react-bootstrap';
import { ElementInputFormCostume } from '../../../shared/Components/ElementInputFormCostume';
import ElementDropdownPrimereact from '../../../shared/Components/ElementDropdownPrimereact';
import ElementCurrencyPrimeReact from '../../../shared/Components/ElementCurrencyPrimeReact';
import { Reception } from '../Domain/Reception';
import { TipoDocumentoTributario } from '../Domain/TipoDocumentoTributario';
import { TipoIVA } from '../Domain/TipoIVA';
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { DataTable  } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ListaControlDocumental } from '../Domain/ListaControlDocumental';

interface InterfaceStepTwo{
    formInvoice: { values: any; touched: any; errors: any; handleBlur: Function; handleChange: Function; handleSubmit: Function; setFieldValue: Function; };
    onChange: any;
    selectedValorizaciones: Reception[] | any;
    tipoDocumentoTributario:TipoDocumentoTributario[] | null;
    
    setFacturaFile:any;
    disabled:boolean;
    setAnexoFile:any;
    onSelectAnexo:any;
    onRemoveAnexo:any;
    onClearAnexo:any;
    ListaControlDocumental:any;
    onSelectDocuementoFactura:Function;
    onRemoveDocuementoFactura:Function;
    onClearDocuementoFactura:Function;

    onHideBill:Function;

    verDocumento:Function;
    CargarFileControlDocumental:Function
    setDocumentosFile:Function;
    eliminarDocumento:Function;
    AlertSizeFileUpload:Function;
    AlertTypeFileUpload:Function;
    
}

function truncateFileName(fileName: string) {
  const maxLength = 25; // Máxima longitud del nombre del archivo
  const extension = fileName.substring(fileName.lastIndexOf('.') + 1); // Se obtiene la extensión del archivo
  const fileNameWithoutExtension = fileName.substring(0, fileName.lastIndexOf('.')); // Se obtiene el nombre del archivo sin la extensión
  let truncatedName = fileNameWithoutExtension.substring(0, maxLength); // Se corta el nombre del archivo a los primeros 30 caracteres

  // Si el nombre del archivo sin extensión ya tiene 30 caracteres, se agrega la extensión
  if (truncatedName.length === maxLength) {
    truncatedName += `.${extension}`;
  }
  return truncatedName; // Se devuelve el nombre truncado, con extensión si es necesario, y número de archivo si corresponde
}


const StepTwo = (props: InterfaceStepTwo) => {
    const [fileInputKey, setFileInputKey] = useState<number>(0);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileInput = event.target;
        const parametroCodigo = fileInput.dataset.parametro;//fileInput.getAttribute('data-parametro');
        const parametroDocumento = fileInput.dataset.documento;//fileInput.getAttribute('data-parametro');
        
        const fileList = event.target.files;
        if (fileList && fileList.length > 0) {
            const selectedFile = fileList[0];
            const fileNameParts = selectedFile.name.split('.');
            const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();
            //alert(selectedFile.size)
            if(selectedFile.size>50000000){
              props.AlertSizeFileUpload();
              return;
            }else{
              if (fileExtension !== 'pdf') {
                props.AlertTypeFileUpload();
                return;
              }else{
                if(parametroCodigo === "Anexos0"){
                  props.CargarFileControlDocumental(fileList,parametroCodigo,parametroDocumento);
                }else{
                  props.CargarFileControlDocumental(selectedFile,parametroCodigo,parametroDocumento);
                }
              }
            }
            // Aquí puedes manejar el archivo seleccionado
            
        }
        // Restablecer el valor del input para permitir la selección del mismo archivo nuevamente
        //setFileInputKey(fileInputKey + 1);
    };
    const DocumentoBodyTemplate = (rowData:ListaControlDocumental)=>{

        return (
          <div>
            <Button icon="pi pi-file-pdf" style={{ cursor: 'pointer' }}  rounded text severity={`${rowData.EstadoCarga==="Cargado"? 'danger':'secondary'}`} aria-label="Ver Documento" onClick={(e)=> {
                  if(rowData.EstadoCarga==="Cargado"){
                    props.verDocumento(rowData);
                  }
            }}/>

              {rowData.EstadoCarga==="Cargado" && ( 
                <Button icon="pi pi-trash" style={{ cursor: 'pointer' }}  rounded text severity='danger' aria-label="Eliminar Archivo" onClick={(e)=> {
                    
                    props.eliminarDocumento(rowData);
                
                }}/>
              )}
          
            {rowData.EstadoCarga==="Pendiente" && ( 
              <Button icon="pi pi-upload" style={{ cursor: 'pointer' }}  rounded text severity='info' aria-label="Cargar Documento" onClick={(e)=> {
                let fileInput:any;
                if(rowData.Codigo === "Anexos0"){
                  fileInput = document.getElementById("fileInputAnexos") as HTMLInputElement;
                }else{
                  fileInput = document.getElementById("fileInput") as HTMLInputElement;
                }
                
                console.log("atributo",rowData);
                fileInput.dataset.parametro = rowData.Codigo;
                fileInput.dataset.documento = rowData.Documento;
                if (fileInput) {
                    fileInput.click();
                }
              }} /> 
            )}
            
            {rowData.Codigo === "Anexos0" ? (
              <input
                key={fileInputKey}
                id="fileInputAnexos"
                type="file"
                accept=".pdf"
                style={{ display: 'none' }}
                onChange={handleFileChange}
                multiple
              />
            ) : (
              <input
                key={fileInputKey}
                id="fileInput"
                type="file"
                accept=".pdf"
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
            )}
          </div>

            
            
        )
    }
    const footerContent = () => {
        return (
          <div>
            <Button 
                  label="Cancelar" 
                    onClick={(e)=>props.onHideBill(e)} 
                  severity="danger" 
              />
              
          </div>
        );

    }
    // const [errorMessage, setErrorMessage] = useState('');

    // const handleUploadError = (event:any) => {
    //     setErrorMessage('¡El archivo que intenta cargar es demasiado grande! Por favor, seleccione un archivo más pequeño.');
    // };
    console.log("props.formInvoice.values.tipoDocumentoTributario", props.formInvoice.values.tipoDocumentoTributario)
  return (
    <>
      <Row lg={4} md={4} xs={12}>
        <ElementInputFormCostume
          as={"input"}
          //disabled
          classForm={"mb-0"}
          classLabel={"fw-bold"}
          // sm={3} md={3} lg={3}
          type={"date"}
          name={"fechaEmision"}
          value={props.formInvoice?.values["fechaEmision"]}
          text={"Fecha Emisión"}
          max={20}
          upper={false}
          size={"sm"}
          onChange={props.onChange}
          onBlur={props.formInvoice?.handleBlur}
          isValid={
            !!props.formInvoice?.touched["fechaEmision"] &&
            !props.formInvoice?.errors["fechaEmision"]
          }
          isInvalid={
            props.formInvoice?.touched["fechaEmision"] &&
            !!props.formInvoice?.errors["fechaEmision"]
          }
          invalid={
            props.formInvoice?.touched["fechaEmision"] &&
            props.formInvoice?.errors["fechaEmision"]
          }
        />

        <ElementDropdownPrimereact
          label="Tipo Documento Tributario"
          inputId="tipoDocumentoTributario"
          name="tipoDocumentoTributario"
          options={props.tipoDocumentoTributario}
          optionLabel="Descripcion"
          value={props.formInvoice.values.tipoDocumentoTributario}
          onChange={(e: any) => {
            props.formInvoice.handleChange(e);
            console.log(e);
          }}
          onBlur={props.formInvoice?.handleBlur}
          isValid={
            !!props.formInvoice?.touched["tipoDocumentoTributario"] &&
            !props.formInvoice?.errors["tipoDocumentoTributario"]
          }
          isInvalid={
            props.formInvoice?.touched["tipoDocumentoTributario"] &&
            !!props.formInvoice?.errors["tipoDocumentoTributario"]
          }
          invalid={
            props.formInvoice?.touched["tipoDocumentoTributario"] &&
            props.formInvoice?.errors["tipoDocumentoTributario"]
          }
        />
        <ElementInputFormCostume
          as={"input"}
          // classForm={"mb-2"}
          classLabel={"fw-bold"}
          sm={3}
          md={3}
          lg={3}
          type={"text"}
          name={"serie"}
          value={props.formInvoice?.values["serie"]}
          text={"Serie"}
          max={10}
          upper={false}
          size={"sm"}
          onChange={props.onChange}
          onBlur={props.formInvoice?.handleBlur}
          isValid={
            !!props.formInvoice?.touched["serie"] &&
            !props.formInvoice?.errors["serie"]
          }
          isInvalid={
            props.formInvoice?.touched["serie"] &&
            !!props.formInvoice?.errors["serie"]
          }
          invalid={
            props.formInvoice?.touched["serie"] &&
            props.formInvoice?.errors["serie"]
          }
          disabled={props.disabled}
        />
        <ElementInputFormCostume
          as={"input"}
          // classForm={"mb-2"}
          classLabel={"fw-bold"}
          sm={3}
          md={3}
          lg={3}
          type={"text"}
          name={"numeroDocumentoTributario"}
          value={props.formInvoice?.values["numeroDocumentoTributario"]}
          text={"Número Documento Tributario"}
          max={20}
          upper={false}
          size={"sm"}
          onChange={props.onChange}
          onBlur={props.formInvoice?.handleBlur}
          isValid={
            !!props.formInvoice?.touched["numeroDocumentoTributario"] &&
            !props.formInvoice?.errors["numeroDocumentoTributario"]
          }
          isInvalid={
            props.formInvoice?.touched["numeroDocumentoTributario"] &&
            !!props.formInvoice?.errors["numeroDocumentoTributario"]
          }
          invalid={
            props.formInvoice?.touched["numeroDocumentoTributario"] &&
            props.formInvoice?.errors["numeroDocumentoTributario"]
          }
          disabled={props.disabled}
        />
        <ElementInputFormCostume
          as={"input"}
          // classForm={"mb-2"}
          classLabel={"fw-bold"}
          sm={3}
          md={3}
          lg={3}
          type={"text"}
          name={"observacion"}
          value={props.formInvoice?.values["observacion"]}
          text={"Observaciones"}
          max={50}
          upper={false}
          size={"sm"}
          onChange={props.onChange}
          onBlur={props.formInvoice?.handleBlur}
          isValid={
            !!props.formInvoice?.touched["observacion"] &&
            !props.formInvoice?.errors["observacion"]
          }
          isInvalid={
            props.formInvoice?.touched["observacion"] &&
            !!props.formInvoice?.errors["observacion"]
          }
          invalid={
            props.formInvoice?.touched["observacion"] &&
            props.formInvoice?.errors["observacion"]
          }
          disabled={props.disabled}
        />


      </Row>
      {/* <Row>

      </Row>
      <Row className=""></Row> */}

      <Row className="mb-1" lg={6} xs={12}>
        <Col>
          <div className="dialog-form-steps-invoice_two" >
            <DataTable
              //autoLayout={true}
              paginatorClassName="paginator-gia"
              value={props.ListaControlDocumental}
              dataKey="id"
              tableStyle={{border: "1px solid #dee2e6" }}
              paginator
              rows={11}
              scrollable={true}
              //scrollHeight="300px" // Altura del área desplazable
              //scrollWidth="100%" // Ancho del área desplazable
              size="small" //, 20, 30, 40,50,50,100
              rowsPerPageOptions={[11,25,50]}
              className="custombar1"
              resizableColumns
            >
              {/* <Column
                //className="lg:w-1"
                //bodyClassName={"text-sm"}
                className="pr-0" 
                bodyClassName={'text-sm'}
                header=""
                headerClassName="bg-gia text-white text-white text-sm"
                body={DocumentoBodyTemplate}
                //style={{ width: '150px' }}
              ></Column> */}
               <Column className='pr-0' bodyClassName={'text-sm'} header="" headerClassName='bg-gia text-white text-white text-sm' body={DocumentoBodyTemplate}></Column>
              <Column
                sortable
                className='pr-0'
                bodyClassName={"text-sm text-striped"}
                field="Documento"
                header="Documentos"
                headerClassName="bg-gia text-white text-white text-sm"
                //style={{ height: "20x" }} // Aquí puedes ajustar el ancho de la columna
              />
              <Column
                sortable
                className='pr-0'
                bodyClassName={"text-sm text-striped"}
                field="Obligatoriedad"
                header="Obligatoriedad"
                headerClassName="bg-gia text-white text-white text-sm"
                //style={{ height: "20px" }} // Aquí puedes ajustar el ancho de la columna
              />
            </DataTable>
          </div>      
        </Col>
      </Row>
    </>
  );
}

export default StepTwo
