import React, { useRef } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Button } from 'primereact/button';
import { addLoading, removeLoading } from '../Infraestructure/SliceGenerico';
import { AdapterValidator } from '../Infraestructure/AdapterValidator';
import { DtoRequestInsertTicketExtranet } from '../Domain/DtoRequestInsertTicketExtranet';
import { AdapterGenerico } from '../Infraestructure/AdapterGenerico';
import { ElementRichEditorCostume } from '../Components/ElementRichEditorCostume';
import { ElementInputFormCostume } from './ElementInputFormCostume';
import { Form, InputGroup } from 'react-bootstrap';

import './SliderbarGLPI.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../Infraestructure/AdapterStore';

export const SidebarGLPI = (props: any) => {
  const dispatch: Dispatch = useDispatch();
  const { auth: { user } } = useSelector((state: RootState) => state);
  const formTicket = useFormik({
    initialValues: {
      title: '',
      message: '',
      mail: '',
      mobilePhone: '',
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Ingresar Título').min(3, 'Mínimo 3 caracteres'),
      message: Yup.string().required('Ingresar Mensaje').min(3, 'Mínimo 3 caracteres'),
      mail: Yup.string().matches(/^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i, 'Correo Corporativo no es correcto').required('Ingresar Correo Corporativo'),
      mobilePhone: Yup.string().required('Ingresar Teléfono Móvil').length(9, 'Teléfono Móvil no es correcto').matches(/^[9]\d{8}$/, 'Teléfono Móvil debe empezar en 9'),
    }),
    onSubmit: (values, formikHelpers) => { },
  });

  const onChange = (name: string, value: any) => {
    if (value === null) { return; }
    formTicket.setFieldValue(name, value);
  };

  const onSubmit = async (e: Event) => {
    try {

      e.preventDefault();
      e.stopPropagation();

      try { await formTicket.submitForm(); } catch (error) { }
      try { AdapterValidator.validate(await formTicket.validateForm()); } catch (error) { console.error(error); dispatch(removeLoading()); return null; }

      let confirmacion: boolean = await AdapterGenerico.createMessage('Confirmación de envío de incidencia', '¿Está seguro de que desea enviar esta incidencia?', 'question', true, '.sliderbarGLPI');
      if (!confirmacion) return;

      dispatch(addLoading({ textLoading: 'Cargando...' }));

      let params: DtoRequestInsertTicketExtranet = {
        email: user.usuario.datosGenerales.email[0],
        title: formTicket.values.title,
        message: formTicket.values.message,
        emailInfo: formTicket.values.mail,
        mobilePhoneInfo: formTicket.values.mobilePhone,
        country: user.usuario.pais.nombre,
        category: 'Extranet GIA',
        ot: user.usuario.datosTrabajo.ot.key,
        delegacion: user.usuario.datosTrabajo.delegacion.key,
      };

      let myHeaders = new Headers();
      myHeaders.append("request-decrypt-response", "yes");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Basic TUFTVEVSOnVROWZEOE0ydFFMMktiaDdqUExGbllCYXI5UDV6eEtk");

      let requestOptions: any = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(params),
        redirect: 'follow'
      };

      let response: any = await fetch(`${process.env.REACT_APP_URL_MASTER_GLPI}/Ticket/insertExtranet`, requestOptions);
      if (!response.ok) { throw Error("Error GLPI"); };
      let json: any = await response.json();
      dispatch(removeLoading());
      await AdapterGenerico.createMessage('Exitoso', `Se ha creado el ticket número <b>${json.ticketId}</b> exitosamente`, 'success', false, '.sliderbarGLPI');
      props.setShowTicket(false);
      formTicket.resetForm();
    } catch (error) {
      dispatch(removeLoading());
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false, '.sliderbarGLPI');
    }
  };

  return (
    <div>
      <Sidebar visible={props.showTicket} onHide={() => { props.setShowTicket(); formTicket.resetForm(); }} className='' position="right">
        <form className='sliderbarGLPI' noValidate onSubmit={(evt: any) => { onSubmit(evt); }}>
          <label className="fw-bold mt-3 mb-2" style={{ color: "#1B458F", textDecoration: "underline" }}>{"Generar Ticket"}</label>

          <ElementInputFormCostume
            classForm={"mt-2 text-truncate"}
            classLabel={'fw-bold fs-14'}
            sm={12} md={12} lg={12}
            name={'title'}
            value={formTicket.values['title']}
            text={'Título'}
            size={'sm'}
            type={'text'}
            onChange={onChange}
            onBlur={formTicket.handleBlur}
            isValid={!!formTicket.touched['title'] && !formTicket.errors['title']}
            isInvalid={!!formTicket.touched['title'] && !!formTicket.errors['title']}
            invalid={!!formTicket.touched['title'] && formTicket.errors['title'] ? formTicket.errors['title'].toString() : ''}
            addColClass={false}
          />

          <ElementInputFormCostume
            classForm={"mt-2 text-truncate"}
            classLabel={'fw-bold fs-14'}
            sm={12} md={12} lg={12}
            name={'mail'}
            value={formTicket.values['mail']}
            text={'Correo Corporativo'}
            maxLength={100}
            size={'sm'}
            type={'text'}
            onChange={onChange}
            onBlur={formTicket.handleBlur}
            isValid={!!formTicket.touched['mail'] && !formTicket.errors['mail']}
            isInvalid={!!formTicket.touched['title'] && !!formTicket.errors['mail']}
            invalid={!!formTicket.touched['mail'] && formTicket.errors['mail'] ? formTicket.errors['mail'].toString() : ''}
            addColClass={false}
          />

          <ElementInputFormCostume
            classForm={"mt-2 text-truncate"}
            classLabel={'fw-bold fs-14'}
            sm={12} md={12} lg={12}
            name={'mobilePhone'}
            value={formTicket.values['mobilePhone']}
            text={'Teléfono Móvil'}
            maxLength={9}
            pattern={`^[0-9]{0,9}$`}
            size={'sm'}
            type={'text'}
            onChange={onChange}
            onBlur={formTicket.handleBlur}
            isValid={!!formTicket.touched['mobilePhone'] && !formTicket.errors['mobilePhone']}
            isInvalid={!!formTicket.touched['mobilePhone'] && !!formTicket.errors['mobilePhone']}
            invalid={!!formTicket.touched['mobilePhone'] && formTicket.errors['mobilePhone'] ? formTicket.errors['mobilePhone'].toString() : ''}
            addColClass={false}
          />

          <Form.Group className={"mt-2 text-truncate"} >
            <Form.Label className={"fw-bold fs-14"} dangerouslySetInnerHTML={{ __html: 'Mensaje' }}></Form.Label>
            <InputGroup hasValidation>
              <ElementRichEditorCostume
                initialValue=""
                setValue={(value: any) => onChange('message', value)}
              />
            </InputGroup>
          </Form.Group>
          <br />

          <ElementInputFormCostume
            classForm={"pt-0 text-truncate"}
            sm={12} md={12} lg={12}
            name={'message'}
            value={formTicket.values['message']}
            size={'sm'}
            type={'text'}
            onChange={(e: Event) => { }}
            onBlur={formTicket.handleBlur}
            isValid={!!formTicket.touched['message'] && !formTicket.errors['message']}
            isInvalid={!!formTicket.touched['message'] && !!formTicket.errors['message']}
            invalid={!!formTicket.touched['message'] && formTicket.errors['message'] ? formTicket.errors['message'].toString() : ''}
            text={''}
            classText={"d-none"}
            disabled={true}
            hidden={false}
          />

          <div className='w-full mt-1'>
            <Button type='submit' className='w-full' style={{ backgroundColor: '#1B458F' }} label={"Guardar"}></Button>
          </div>
        </form>
      </Sidebar>
    </div>
  );
};

