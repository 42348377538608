import * as React from 'react';
import { Controller } from "./Infraestructure/Controller";
import { ViewMain } from "./Components/View";


const ManagerEntity = () => {
    const controller = Controller();

    React.useEffect(() => {
         controller.init();
         controller.loadTipoDocumentoTributario();
         controller.loadTipoIVA();
         
        return () => {
        }
        // eslint-disable-next-line
    }, [controller.countProcess]);

    return (<ViewMain
        data={controller.data}
        reloadData={controller.loadData}
        tipoDatosTributarios ={controller.tipoDocumentoTributario}
        loadTipoDocumentoTributario={controller.loadTipoDocumentoTributario}
        tipoIVA = {controller.tipoIVA}
        loadTipoIVA = {controller.loadTipoIVA}
        user={controller.user}
        home={controller.home}
        items={controller.items}
        visible={controller.visible}
        setVisible={controller.setVisible}
        transformMoneda={controller.transformMoneda}
        transformCurrency={controller.transformCurrency}
        TransformDate={controller.TransformDate}
        selectedComparativos={controller.selectedComparativos}
        setSelectedComparativos={controller.setSelectedComparativos}
        isSelectable = {controller.isSelectable}
        formInvoice = {controller.formInvoice}
        onSubmit = {controller.onSubmit}
        onChangeValueMontoFormInvoice = {controller.onChangeValueMontoFormInvoice}
        activeIndex={controller.activeIndex}
        setActiveIndex={controller.setActiveIndex}
        tabIndex={controller.tabIndex}
        setTabIndex={controller.setTabIndex}
        setGlobalFilter={controller.setGlobalFilter}
        globalFilter={controller.globalFilter}
        onNextStep={controller.onNextStep}
        toast={controller.toast}
        onSave={controller.onSave}
        onChange={controller.onChange}
        onChangeValueTipoIVAFormInvoice={controller.onChangeValueTipoIVAFormInvoice}
        onClickCancel={controller.onClickCancel}
        disabled={controller.disabled}
        setDisabled = {controller.setDisabled}
        resultTab={controller.resultTab}
        
    />);
};

export default ManagerEntity;