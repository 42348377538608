import { Suspense } from "react";
import { BrowserRouter, Navigate, Route } from "react-router-dom"
import { AuthGuard } from "./AuthGuard"
import { RoleGuard } from "./RoleGuard";
import { ElementLoader } from "../../../context/shared/Components/ElementLoader"
import { ElementLoaderLazy } from "../../../context/shared/Components/ElementLoaderLazy";
import { RoutesWithNotFound } from "../../../context/shared/Components/RoutesWithNotFound"

//Module Master
import ViewMasterLogin from '../../../context/Master/Login'
import ViewMasterRecuperarPassword from '../../../context/Master/RecuperarPassword'
import ViewMasterNuevoProveedor from '../../../context/Master/NuevoProveedor'
import ViewMasterMain from '../../../context/Master/Main';
import ViewMasterProfile from '../../../context/Master/Profile';
import ViewExtranetInvoiceEntry from '../../../context/Extranet/Invoice';
import ViewExtranetInvoiceStatus from '../../../context/Extranet/StatusInvoice';
import ViewExtranetTicket from '../../../context/Extranet/Ticket';
import ViewProveedorValorization from '../../../context/Extranet/Valuations'
import ViewProveedorReception from '../../../context/Extranet/Receptions'
import ViewProveedorReceptionValorization from '../../../context/Extranet/ReceptionsValorizations';
import ViewProveedorControlDocument from '../../../context/Extranet/ControlDocument';
//Module Home
import ViewMasterHome from '../../../context/Master/Home';
import StepOne from "../../../context/Extranet/Invoice/Components/StepOne";

//Module Approvals

export const AppRoutes = () => {
    return (
        <Suspense fallback={<ElementLoaderLazy />}>
            <BrowserRouter basename={`${process.env.PUBLIC_URL}`}>
                <ElementLoader />
                <RoutesWithNotFound>
                
                    <Route path="" element={<Navigate to={`/${process.env.REACT_APP_ROUTE_PATH_LOGIN}`} />} />
                    <Route path={`${process.env.REACT_APP_ROUTE_PATH_LOGIN}`} element={<AuthGuard element={<ViewMasterLogin />} privateContent={false} />} />
                    <Route path={`${process.env.REACT_APP_ROUTE_PATH_RECOVERPASSWORD}`} element={<AuthGuard element={<ViewMasterRecuperarPassword />} privateContent={false} />} />
                    <Route path={`${process.env.REACT_APP_ROUTE_PATH_NEWSUPPLIER}`} element={<AuthGuard element={<ViewMasterNuevoProveedor />} privateContent={false} />} />
                    
                    <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN}`} element={<AuthGuard element={<ViewMasterMain />} privateContent={true} />}>
                        <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_HOME}`} element={<ViewMasterHome />} />
                        <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_PROFILE}`} element={<ViewMasterProfile />} />
                        <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_INVOICE_ENTRY}`} element={<ViewExtranetInvoiceEntry />} />
                        <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_VALORIZATION}`} element={<ViewProveedorValorization />} />
                        <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_RECEPTION}`} element={<ViewProveedorReception />} />
                        <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_RECEPTION_VALORIZATION}`} element={<ViewProveedorReceptionValorization />} />

                        <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_CONTROL_DOCUMENT}`} element={<ViewProveedorControlDocument />} />
                        
                        <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_INVOICE_STATUS}`} element={<ViewExtranetInvoiceStatus />} />
                        <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_TICKET}`} element={<ViewExtranetTicket />} />
                        
                        
                        <Route element={<RoleGuard />}>
                            <Route path={``} element={<ViewMasterHome />} />
                            <Route path={`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES}`}>
                                
                            </Route>
                        </Route>
                    </Route>
                </RoutesWithNotFound>
            </BrowserRouter>
        </Suspense>
    )
}