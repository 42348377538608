import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseLogout {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(params: string): Promise<void> {
        return await this._exec(params);
    }

    private async _exec(params: string): Promise<void> {
        await this.repository.logout(params);
    }
}