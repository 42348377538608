import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { ElementInputFormCostume } from '../../../shared/Components/ElementInputFormCostume';
import ElementDropdownPrimereact from '../../../shared/Components/ElementDropdownPrimereact';
import ElementCurrencyPrimeReact from '../../../shared/Components/ElementCurrencyPrimeReact';
import { Reception } from '../Domain/Reception';
import { TipoDocumentoTributario } from '../Domain/TipoDocumentoTributario';
import { TipoIVA } from '../Domain/TipoIVA';
import { FileUpload } from 'primereact/fileupload';

interface InterfaceStepTwo{
    formInvoice: { values: any; touched: any; errors: any; handleBlur: Function; handleChange: Function; handleSubmit: Function; setFieldValue: Function; };
    onChange: any;
    selectedValorizaciones: Reception[] | any;
    tipoDocumentoTributario:TipoDocumentoTributario[] | null;
    tipoIVA:TipoIVA[] | null | undefined;
    onChangeTipoIva:Function;
    disabled:boolean;
}

const StepTwo = (props: InterfaceStepTwo) => {
  return (
    <>
      <Row lg={3} md={3} xs={12}>
        <Col>
          <ElementInputFormCostume
            as={"input"}
            //disabled
             classForm={"mb-0"}
            classLabel={'fw-bold'}
            // sm={3} md={3} lg={3}
            type={'date'}
            name={'fechaEmision'}
            value={props.formInvoice?.values['fechaEmision']}
            text={"Fecha Emisión"}
            max={20}
            upper={false}
            size={'sm'}
            onChange={props.onChange}
            onBlur={props.formInvoice?.handleBlur}
            isValid={!!props.formInvoice?.touched['fechaEmision'] && !props.formInvoice?.errors['fechaEmision']}
            isInvalid={props.formInvoice?.touched['fechaEmision'] && !!props.formInvoice?.errors['fechaEmision']}
            invalid={props.formInvoice?.touched['fechaEmision'] && props.formInvoice?.errors['fechaEmision']}
            disabled={props.disabled}
          />
        </Col>
        <Col>
        
          <ElementDropdownPrimereact
            label='Tipo Documento Tributario'
            inputId='tipoDocumentoTributario'
            name='tipoDocumentoTributario'
            options={props.tipoDocumentoTributario} 
            optionLabel='Descripcion'
            value={props.formInvoice.values.tipoDocumentoTributario}
            onChange={(e:any)=>{props.formInvoice.handleChange(e);console.log(e)}} 
            onBlur={props.formInvoice?.handleBlur}
            isValid={!!props.formInvoice?.touched['tipoDocumentoTributario'] && !props.formInvoice?.errors['tipoDocumentoTributario']}
            isInvalid={props.formInvoice?.touched['tipoDocumentoTributario'] && !!props.formInvoice?.errors['tipoDocumentoTributario']}
            invalid={props.formInvoice?.touched['tipoDocumentoTributario'] && props.formInvoice?.errors['tipoDocumentoTributario']}
          />
          
        </Col>   

        <Col>
        <ElementInputFormCostume
          as={"input"}
          // classForm={"mb-2"}
          classLabel={'fw-bold'}
          sm={3} md={3} lg={3}
          type={'text'}
          name={'numeroDocumentoTributario'}
          value={props.formInvoice?.values['numeroDocumentoTributario']}
          text={"Número Documento Tributario"}
          max={20}
          upper={false}
          size={'sm'}
          onChange={props.onChange}
          onBlur={props.formInvoice?.handleBlur}
          isValid={!!props.formInvoice?.touched['numeroDocumentoTributario'] && !props.formInvoice?.errors['numeroDocumentoTributario']}
          isInvalid={props.formInvoice?.touched['numeroDocumentoTributario'] && !!props.formInvoice?.errors['numeroDocumentoTributario']}
          invalid={props.formInvoice?.touched['numeroDocumentoTributario'] && props.formInvoice?.errors['numeroDocumentoTributario']}
          disabled={props.disabled}
        />
        </Col>    
        <Col></Col>  
            

        </Row>
        <Row className="">
          <Col>
          <Col>
            <ElementCurrencyPrimeReact
                name={'importe'}
                label={'Importe'}
                currency={props.selectedValorizaciones[0]?.Moneda}
                value={props.formInvoice?.values['importe']}
                //onChange={props.onChangeValueMontoFormInvoice}
                onValueChange={(e:any)=>console.log(e)}
                onBlur={props.formInvoice?.handleBlur}
                isValid={!!props.formInvoice?.touched['importe'] && !props.formInvoice?.errors['importe']}
                isInvalid={props.formInvoice?.touched['importe'] && !!props.formInvoice?.errors['importe']}
                invalid={props.formInvoice?.touched['importe'] && props.formInvoice?.errors['importe']}
                disabled
            />
            </Col>
            </Col>
            <Col>
            
            <ElementDropdownPrimereact
                label='Tipo IVA'
                inputId='tipoIva'
                name='tipoIva'
                options={props.tipoIVA}
                optionLabel='VATProd_PostingGroup'
                value={props.formInvoice.values.tipoIva}
                onChange={(e:any)=>{props.onChangeTipoIva(e);console.log(e.value)}}
                onBlur={props.formInvoice?.handleBlur}
                isValid={!!props.formInvoice?.touched['tipoIva'] && !props.formInvoice?.errors['tipoIva']}
                isInvalid={props.formInvoice?.touched['tipoIva'] && !!props.formInvoice?.errors['tipoIva']}
                invalid={props.formInvoice?.touched['tipoIva'] && props.formInvoice?.errors['tipoIva']}
                disabled={props.disabled}
            />
            </Col>
            <Col>
            <Col>
            <ElementCurrencyPrimeReact
                name={'importeIva'}
                label={'Importe IVA'}
                currency={props.selectedValorizaciones[0].Moneda}
                value={props.formInvoice?.values['importeIva']}
                onValueChange={(e:any)=>console.log(e)}
                onBlur={props.formInvoice?.handleBlur}
                isValid={!!props.formInvoice?.touched['importeIva'] && !props.formInvoice?.errors['importeIva']}
                isInvalid={props.formInvoice?.touched['importeIva'] && !!props.formInvoice?.errors['importeIva']}
                invalid={props.formInvoice?.touched['importeIva'] && props.formInvoice?.errors['importeIva']}
                disabled
            />
            </Col>
            </Col>
  <Col>
  <Col>
            <ElementCurrencyPrimeReact
                name={'importeTotal'}
                label={'Importe Total'}
                currency={props.selectedValorizaciones[0].Moneda}
                value={props.formInvoice?.values['importeTotal']}
                onValueChange={(e:any)=>console.log(e)}
                onBlur={props.formInvoice?.handleBlur}
                isValid={!!props.formInvoice?.touched['importeTotal'] && !props.formInvoice?.errors['importeTotal']}
                isInvalid={props.formInvoice?.touched['importeTotal'] && !!props.formInvoice?.errors['importeTotal']}
                invalid={props.formInvoice?.touched['importeTotal'] && props.formInvoice?.errors['importeTotal']}
                disabled
            />
            </Col>
            </Col>

        </Row>
        <Row className='mb-1' lg={6} xs={12}>
          <Col>
            <label className='fw-bold form-label'>Factura</label>
            <FileUpload
              name="demo[]" 
              url={'/api/upload'} 
              accept="image/*" 
              
              maxFileSize={50000000} 
              chooseOptions={{ label: 'Seleccion', icon: 'pi pi-file-import', className: 'p-button-success p-button-sm .p-files' }}
              uploadOptions={{ label: 'Cargar', icon: 'pi pi-upload', className: 'p-button-primary p-button-sm' }}
              cancelOptions={{ label: ' ', icon: 'pi pi-times', className: 'p-button-danger p-button-sm' }}
              emptyTemplate={<p className="m-0">Arrastre y suelte los archivos.</p>} 
            />
          </Col>
          <Col>
          <label className='fw-bold form-label'>Anexos</label>
          <FileUpload 
            name="demo[]" 
            url={'/api/upload'} 
            multiple 
            accept="image/*" 
            maxFileSize={50000000} 
            emptyTemplate={<p className="m-0">Arrastre y suelte los archivos.</p>} 
            chooseOptions={{ label: 'Seleccionar', icon: 'pi pi-file-import', className: 'p-button-success p-button-sm .p-files' }}
            uploadOptions={{ label: 'Cargar', icon: 'pi pi-upload', className: 'p-button-primary p-button-sm' }}
            cancelOptions={{ label: ' ', icon: 'pi pi-times', className: 'p-button-danger p-button-sm' }}
          />
          </Col>       
        </Row>
    </>
  )
}

export default StepTwo
