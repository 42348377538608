import * as React from 'react';
import { ViewMain } from "./Components/View";
import { Controller } from "./Infraestructure/Controller";
import { ScrollPanel } from 'primereact/scrollpanel';


const ManagerEntity = () => {
    const controller = Controller();

    React.useEffect(() => {
        controller.init();
        return () => { }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <ScrollPanel style={{ width: '100%', height: '100vh' }}>
                <ViewMain
                    user={controller.user}
                    menu={controller.menu}
                    onClickAyuda={controller.onClickAyuda}
                    onClickReport={controller.onClickReport}
                    onClickMenu={controller.onClickMenu}
                    data={controller.data}
                    TransformDate={controller.TransformDate}
                    dataComparativo={controller.dataComparativo}
                    countValorPendiente={controller.valorizacionRecepciones}
                    dataComparativos={controller.dataComparativos}
                    dataStatusInvoice={controller.dataStatusInvoice}
                    ListaControlDocumental= {controller.ListaControlDocumental}
                    visibleDialogSteps={controller.visibleDialogSteps}
                    onHideDialogSteps={controller.onHideDialogSteps}
                    verControlDocumental={controller.verControlDocumental}
                    pdf={controller.pdf}
                    visibleDocument={controller.visibleDocument}
                    openDocumentHome={controller.openDocumentHome}
                    onHideDocument={controller.onHideDocument}
                    verDocumento={controller.verDocumento}
                    CargarFileControlDocumental={controller.CargarFileControlDocumental}
                    setDocumentosFile={controller.setDocumentosFile}
                    eliminarDocumento={controller.eliminarDocumento}
                    AlertSizeFileUpload={controller.AlertSizeFileUpload}
                    AlertTypeFileUpload={controller.AlertTypeFileUpload}
                />
            </ScrollPanel>
        </>);
};

export default ManagerEntity;