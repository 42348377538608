import "./Navbar.scss";
import { Toolbar } from 'primereact/toolbar';
import HelpIcon from '@mui/icons-material/Help';
import { useNavigate } from "react-router-dom";
export const Navbar = (props: any) => {
    const navigate=useNavigate();
    const start = <div className='flex align-items-center gap-3'>

        <div className='md:block lg:hidden'>
            <i className="pi pi-bars p-toolbar-separator mr-2 cursor-pointer" onClick={props.setShow} />
        </div>
        <div className='border-circle bg-white color-white lg:ml-3'>
            <img alt="logo" src={props.logo} height="40" className="p-1" />
        </div>
        <div className=''>
            <p className='font-bold m-0 text-sm'>GIA PROVEEDORES</p>
        </div>
    </div>;
    const end = <div>
    <div >
        <i 
            className='mr-4 cursor-pointer' 
            //onClick={props.setShowTicket}
            onClick={()=>navigate(`${process.env.REACT_APP_ROUTE_PATH_MAIN_TICKET}`)}
        ><HelpIcon sx={{color:'white'}}/></i>
    </div>
</div>
    return (
        <Toolbar start={start} className='border-noround bg-gia p-1 text-white' end={end}/>

    )
}