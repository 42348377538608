import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import { MenuItem } from 'primereact/menuitem';
import { Steps } from 'primereact/steps';
import React from 'react'
import StepOne from './StepOne';
import { TabPanel, TabView } from 'primereact/tabview';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
interface PropsViewDialogForm {
  formInvoice: { values: any; touched: any; errors: any; handleBlur: Function; handleChange: Function; handleSubmit: Function; setFieldValue: Function;};
  onSubmit: Function;
  onChangeValueMontoFormInvoice: Function;
  TransformDate:Function;
  transformCurrency:Function;
  onNextStep:Function;
  onSave:Function;
  onChange:Function;
  onChangeValueTipoIVAFormInvoice:Function;
  onClickCancel:Function;
  disabled:boolean|any;
  setDisabled:Function;
    
  }
function DialogSteps(props:PropsViewDialogForm|any) {
  const items: MenuItem[] = [
    {
      label: 'Factura',
      command: () => {
        props.setTabIndex(0);
      },

    },
    {
      label: 'Asociación',
      command: () => {
        props.setTabIndex(1);
      },
    },
    {
      label: 'Archivos',
      command: () => {
        props.setTabIndex(2);
      },
    }

  ];
  const footerContent = () => {
    if (props.tabIndex === 0) {
      return (
        <div>
          <Button label="Cancelar" onClick={props.onClickCancel} severity="danger" />
          <Button 
            label="Siguiente"
            onClick={props.onSubmit}
            type="submit"
            className='bg-gia' 
          />
        </div>
      );
    } else if (props.tabIndex === items.length - 1) {
      return (
        <div>
          <Button label="Atras" onClick={() => props.setTabIndex(props.tabIndex - 1)} severity="danger" />
          <Button label="Guardar" className='bg-gia' type='submit' 
          onClick={props.onSubmit} 
          />
        </div>
      );
    }
    else {
      return (
        <div>
          <Button label="Atras" onClick={() => props.setTabIndex(props.tabIndex - 1)} severity="danger" />
          <Button label="Siguiente" onClick={props.onSubmit} className='bg-gia' />
        </div>
      );
    }
  }
  return (
    <Dialog header="Ingreso de Factura" visible={props.visible} style={{ width: '60vw',zIndex:"1059" }} onHide={props.onHide}  className='form-dialog' footer={footerContent}>
      <div className='dialog-form-steps-invoice'>
        <Steps
            model={items}
            activeIndex={props.tabIndex}
            onSelect={(e) => props.setTabIndex(e.index)}
          />
          <TabView
            activeIndex={props.tabIndex}
            onTabChange={(e) => props.setTabIndex(e.index)}
          >
              <TabPanel disabled headerStyle={{ display: "none", border: 'none' }}>
                <StepOne
                  formInvoice={props.formInvoice}
                  onChangeValueMontoFormInvoice={props.onChangeValueMontoFormInvoice}
                  TransformDate={props.TransformDate}
                  transformCurrency={props.transformCurrency}
                  onChangeValueTipoIVAFormInvoice={props.onChangeValueTipoIVAFormInvoice}    
                  disabled={props.disabled}
                  setDisabled={props.setDisabled}
                  user={props.user}
                  tipoIVA={props.tipoIVA}
                  tipoDatosTributarios={props.tipoDatosTributarios}
                  onChange={props.onChange}
                  formData={props.formData}
                  
                />
              </TabPanel>
              <TabPanel disabled headerStyle={{ display: "none", border: 'none' }}>
                <StepTwo
                  formInvoice={props.formInvoice}
                  onChangeValueMontoFormInvoice={props.onChangeValueMontoFormInvoice}
                  TransformDate={props.TransformDate}
                  transformCurrency={props.transformCurrency}
                  onChangeValueTipoIVAFormInvoice={props.onChangeValueTipoIVAFormInvoice}    
                  disabled={props.disabled}
                  setDisabled={props.setDisabled}
                  user={props.user}
                  tipoIVA={props.tipoIVA}
                  tipoDatosTributarios={props.tipoDatosTributarios}
                  onChange={props.onChange}
                  formData={props.formData}
                  
                />
              </TabPanel>
              <TabPanel disabled headerStyle={{ display: "none", border: 'none' }}>
                <StepThree
                  // formInvoice={props.formInvoice}
                  // onChangeValueMontoFormInvoice={props.onChangeValueMontoFormInvoice}
                  // TransformDate={props.TransformDate}
                  // transformCurrency={props.transformCurrency}
                  // onChangeValueTipoIVAFormInvoice={props.onChangeValueTipoIVAFormInvoice}    
                  // disabled={props.disabled}
                  // setDisabled={props.setDisabled}
                  // user={props.user}
                  // tipoIVA={props.tipoIVA}
                  // tipoDatosTributarios={props.tipoDatosTributarios}
                  // onChange={props.onChange}
                  // formData={props.formData}
                  
                />
              </TabPanel>
            </TabView>
          
      </div>

    </Dialog>
  )
}

export default DialogSteps
