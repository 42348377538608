import React from 'react';
import { Col, Form, InputGroup } from 'react-bootstrap';

interface PropsView {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
    xxl?: number;
    size?: 'sm' | 'lg' | undefined;

    name: string;
    data: Array<any>;
    fieldKey: string;
    fieldLabel: string;

    disabled?: boolean;
    placeholder?: string;
    classForm?: string;

    classText?: string;
    text?: string;

    childrenPrepend?: React.ReactNode;
    childrenPostpend?: React.ReactNode;

    value: any;
    onChange: Function;
    onClick?: Function;
    onBlur?: any;
    isValid: boolean;
    isInvalid: boolean;
    invalid: string;

    hidden?: boolean;
    classLabel?: string;
};

const ElementSelectCostume = (props: PropsView) => {
    return (
        <Form.Group hidden={props.hidden} className={props.classForm} as={Col} xs={props.xs} sm={props.sm} md={props.md} lg={props.lg} xl={props.xl} xxl={props.xxl}>
            {!props.text ? null : <Form.Label className={props.classLabel} dangerouslySetInnerHTML={{ __html: props.text || '' }}></Form.Label>}
            <InputGroup hasValidation>
                {props.childrenPrepend}
                <Form.Select id={props.name}
                    name={props.name}
                    size={props.size}
                    disabled={props.disabled}
                    value={props.value !== null && props.value !== '' ? props.value[props.fieldKey] : ''}
                    onChange={async (e) => {
                        let obj = props.data.filter(row => row[props.fieldKey] === e.target.value);
                        let value = !!obj.length ? obj[0] : { [props.fieldKey]: '' };
                        if (!!props.onChange) props.onChange(props.name, value);
                    }}
                    // onBlur={props.onBlur}
                    isValid={props.isValid}
                    isInvalid={props.isInvalid}
                >
                    <option value="">Seleccionar</option>
                    {
                        props.data.map(row => (
                            <option key={row[props.fieldKey]} value={row[props.fieldKey]}>
                                {Array.isArray(props.fieldLabel) ? props.fieldLabel.map(subrow => row[subrow]).join(' - ') : row[props.fieldLabel]}
                            </option>
                        ))
                    }
                </Form.Select>
                {props.childrenPostpend}
                <Form.Control.Feedback type="invalid" style={{ display: props.isInvalid ? 'block' : 'none' }}>
                    {props.invalid}
                </Form.Control.Feedback>
            </InputGroup>
        </Form.Group>
    );
}

export { ElementSelectCostume };
