import { DtoRequestInvoice } from "../Domain/DtoRequestInvoice";
import { DtoResponseInvoice } from "../Domain/DtoResponseInvoice";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseFacturas {
   private repository: RepositoryMain;
   
   constructor (_repository: RepositoryMain){
    this.repository = _repository;
   }

   public async exec(params: DtoRequestInvoice): Promise<DtoResponseInvoice[] | null>{
    return await this._exec(params);
   }

   public async _exec(params: DtoRequestInvoice): Promise<DtoResponseInvoice[] | null>{
      return await this.repository.getList(params);
   }

}