import * as React from 'react';
import { Controller } from "./Infraestructure/Controller";
import { ViewApp } from "./Components/View";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const ManagerApp = () => {
    const controller = Controller();

    React.useEffect(() => {
        controller.init();
        // controller.termns()
        return () => {
            controller.end();
        }
        // eslint-disable-next-line
    }, []);

    return (<ViewApp loadingMaestros={controller.loadingMaestros} availableUpdate={controller.availableUpdate} updateApp={controller.updateApp} />);
};

export default ManagerApp;