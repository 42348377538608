import { ElementBreadCrumb } from '../../../shared/Components/ElementBreadCrumb';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';
import './Style.scss';

import foto from '../../../../assets/img/user.png';
import { EntityDataUsuario } from '../../../shared/Domain/EntityDataUsuario';
import esFlag from '../../../../assets/img/es.png';
import usFlag from '../../../../assets/img/us.png';
import { Collapse } from 'react-bootstrap';
//import { Avatar, Box, Card, CardContent, Grid, Typography,Tabs,Tab, TextField, CardActions, Button, FormGroup, FormControlLabel, Checkbox } from '@mui/material';

import React, { useState } from 'react'
import TabPanelForm from './TabPanelForm';
import { DatosGeneralesForm } from './DatosGeneralesForm';
import { DatosSunat } from './DatosSunat';
import { ChangePassword } from './ChangePassword';


import { Card } from 'primereact/card';
import { TabView, TabPanel } from 'primereact/tabview';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import './Profile.scss'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { ScrollPanel } from 'primereact/scrollpanel';
import { BreadCrumb } from 'primereact/breadcrumb';
interface PropsViewMain {
    auth: boolean;
    user: EntityDataUsuario;

    showLanguage: boolean;
    setShowLanguage: Function;
    language?: string;
    onChangeLanguage?: Function;

    showChangePassword: boolean;
    setShowChangePassword: Function;
    formChangePassword: { values: any; touched: any; errors: any; handleBlur: Function; };
    onChangePassword: Function;
    onSubmitChangePassword: Function;
};


// const animatedComponents = makeAnimated();
export const ViewMain = (props: PropsViewMain) => {
    const languageTranslate = LanguageTranslate();

    const flags = {
        es: esFlag,
        en: usFlag,
    };



    //prime
    const [activeIndex1, setActiveIndex1] = useState(0);
    const [header, setHeader] = useState(['Detalle del Perfil', 'Datos Sunat', 'Cambio Contraseña']);
    const [checked, setChecked] = useState<boolean | any>(false);
    const [visibility, setVisibility] = useState(false);
    const [visibility2, setVisibility2] = useState(false);
    const items = [{ label: 'Perfil', icon: 'pi pi-file' }];
    const home = { icon: 'pi pi-home', url: '/main' }
    return (
        <>
            <div className='w-full'>
                <div className='flex justify-content-center m-3 profile flex-column px-8 mx-8'>
                    <BreadCrumb home={home} model={items} className='border mb-3' />
                    <Card
                        className='justify-content-center scrollpanel-demo'
                        //title={header[activeIndex1]} 
                        style={{ marginBottom: '2em' }}
                    >
                        <TabView className="tabview-header-icon" activeIndex={activeIndex1} onTabChange={(e) => setActiveIndex1(e.index)}>
                            <TabPanel header={header[0]} leftIcon='pi pi-user'>
                                <ScrollPanel style={{ width: '100%', height: '500px' }} className="custombar1">
                                <form className={"mt-3"}>
                                        <div className="field">
                                            <label className="block">Razón Social</label>
                                            <InputText placeholder="Razon Social" className='w-full disabled' value={props.user.usuario.nombre} />
                                        </div>
                                        <div className="field">
                                            <label className="block">RUC</label>
                                            <InputText placeholder="RUC" className='w-full disabled' value={props.user.usuario.username} />
                                        </div>
                                        <div className='grid'>
                                            <div className='col-12 md:col-6'>
                                                <div className="field">
                                                    <label className="block">País</label>
                                                    <InputText placeholder="País" className='w-full disabled' value={props.user.usuario.geografia.pais} />
                                                </div>
                                            </div>
                                            <div className='col-12 md:col-6'>
                                                <div className="field">
                                                    <label className="block">Departamento</label>
                                                    <InputText placeholder="Departamento" className='w-full disabled' value={props.user.usuario.geografia.departamento} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='grid'>
                                            <div className='col-12 md:col-6'>
                                                <div className="field">
                                                    <label className="block">Provincia</label>
                                                    <InputText placeholder="Provincia" className='w-full disabled' value={props.user.usuario.geografia.provincia} />
                                                </div>
                                            </div>
                                            <div className='col-12 md:col-6'>
                                                <div className="field">
                                                    <label className="block">Distrito</label>
                                                    <InputText placeholder="Distrito" className='w-full disabled' value={props.user.usuario.geografia.distrito} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="field">
                                            <label className="block">Dirección</label>
                                            <InputText placeholder="Dirección" className='w-full disabled' value={props.user.usuario.datosGenerales.direccion} />
                                        </div>
                                        <div className="field">
                                            <label className="block">Correo</label>
                                            <InputText placeholder="Correo" className='w-full disabled' value={props.user.usuario.datosGenerales.email[0]} />
                                        </div>
                                        <div className="field">
                                            <label className="block">Telefono</label>
                                            <InputText placeholder="Telefono" className='w-full disabled' value={props.user.usuario.datosGenerales.telefono[0]} />
                                        </div>
                               
                                    </form>
                                </ScrollPanel>

                            </TabPanel>
                            <TabPanel header={` ${header[1]}`} leftIcon="pi pi-check-square">
                                <form className={"mt-3"}>
                                    <div className="field-checkbox">
                                        <Checkbox checked={props.user.usuario.datosTributacion.buenContribuyenye} onChange={e => setChecked(e.checked)} readOnly className='disabled'/>
                                        <label>Buen Contribuyente</label>
                                    </div>
                                    <div className="field-checkbox">
                                        <Checkbox checked={props.user.usuario.datosTributacion?.agenteRetenedor} onChange={e => setChecked(e.checked)} readOnly className='disabled'/>
                                        <label>Agente Retenedor</label>
                                    </div>
                                    <div className="field-checkbox">
                                        <Checkbox checked={props.user.usuario.datosTributacion.bloqueado} onChange={e => setChecked(e.checked)} readOnly className='disabled'/>
                                        <label>Bloqueado</label>
                                    </div>
                                    <div className="field">
                                        <label className="block">Condición Sunat</label>
                                        <InputText placeholder="Condición Sunat" className='w-full disabled' value={props.user.usuario.datosTributacion.condicionContribuyente.nombre} />
                                    </div>
                                    <div className="field">
                                        <label className="block">Estado Sunat</label>
                                        <InputText placeholder="Estado Sunat" className='w-full disabled' value={props.user.usuario.datosTributacion.estadoContribuyente.nombre} />
                                    </div>
                               
                                </form>
                            </TabPanel>
                            <TabPanel header={header[2]} leftIcon="pi pi-lock">
                                <form noValidate onSubmit={(e) => props.onSubmitChangePassword(e)}>
                                    <div className="field">
                                        <label className="block">Contraseña Actual</label>
                                        <div className="p-inputgroup">
                                            <InputText
                                                placeholder="Contraseña Actual"
                                                className='w-full'
                                                type={visibility ? 'text' : 'password'}
                                                value={props.formChangePassword.values['newPassword']}
                                                onChange={(evt) => { props.onChangePassword('newPassword', evt.target.value) }}
                                                name="newPassword"
                                            />
                                            <span className="p-inputgroup-addon cursor-pointer" onClick={() => setVisibility(!visibility)} style={{ backgroundColor: '#1B458F', color: '#fff' }}>
                                                {visibility ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                            </span>

                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="block">Contraseña Nueva</label>
                                        <div className="p-inputgroup">
                                            <InputText
                                                placeholder="Contraseña Nueva"
                                                className='w-full'
                                                type={visibility2 ? 'text' : 'password'}
                                                value={props.formChangePassword.values['reNewPassword']}
                                                onChange={(evt) => { props.onChangePassword('reNewPassword', evt.target.value) }}

                                                name="reNewPassword"
                                            />
                                            <span className="p-inputgroup-addon cursor-pointer" onClick={() => setVisibility2(!visibility2)} style={{ backgroundColor: '#1B458F', color: '#fff' }}>
                                                {visibility2 ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                            </span>

                                        </div>
                                    </div>
                 
                                    <Button label="Guardar" aria-label="Guardar" />
                                </form>
                            </TabPanel>
                        </TabView>
                    </Card>
                </div>

            </div>
        </>

    )
};