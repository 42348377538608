const SCHEMA: string = '';
const ENTITY: string = '';
const URL_LOGOUT: string = process.env.REACT_APP_URL_LOGOUT || '';
const URL_REFRESH_TOKEN: string = process.env.REACT_APP_URL_REFRESH_TOKEN || '';
const URL_EXISTS_TOKEN: string = process.env.REACT_APP_URL_EXISTS_TOKEN || '';
const AUTH_BASIC_USER: string = process.env.REACT_APP_URL_LOGIN_AUTH_BASIC_USER || '';
const AUTH_BASIC_PASS: string = process.env.REACT_APP_URL_LOGIN_AUTH_BASIC_PASS || '';
const URL_HELP: string = process.env.REACT_APP_URL_HELPCENTER || '';
const URL_GLPI: string = process.env.REACT_APP_URL_GLPI || '';

export const AdapterConfigure = {
    SCHEMA,
    ENTITY,
    URL_LOGOUT,
    URL_REFRESH_TOKEN,
    URL_EXISTS_TOKEN,
    AUTH_BASIC_USER,
    AUTH_BASIC_PASS,
    URL_HELP,
    URL_GLPI
};