import React, { ChangeEvent } from 'react'
import { Row } from 'react-bootstrap';
import { ElementInputFormCostume } from '../../../shared/Components/ElementInputFormCostume';
import ElementDropdownPrimereact from '../../../shared/Components/ElementDropdownPrimereact';
import { DataTable } from 'primereact/datatable';
import { Reception } from '../Domain/Reception';
import { Column, ColumnEditorOptions, ColumnEvent } from 'primereact/column';
import ElementCurrencyPrimeReact from '../../../shared/Components/ElementCurrencyPrimeReact';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { ElementInputFileImage } from '../../../shared/Components/ElementInputFileImage';
import xml2js from 'xml2js';
import Col from 'react-bootstrap/Col';
import { Valorization } from '../Domain/Valorization';
import { TipoIVA } from '../Domain/TipoIVA';

interface InterfaceStepOne {
  formInvoice: { values: any; touched: any; errors: any; handleBlur: Function; handleChange: Function; handleSubmit: Function; setFieldValue: Function; };
  onChange: any;
  selectedValorizaciones: Reception[] | any;
  formatDate: Function;
  formatCurrency: Function;
  setImporteAsociado:any;
  importeAsociado: number;
  exportExcel:Function;
  onCellEditCompleteImporte:any;
  //onCellEditComplete:any;
  importExcel:Function;
  handleFileUpload2:Function;
  disabled:boolean;
  tipoIVA:TipoIVA[] | null | undefined;
  onChangeTipoIva:Function;
  disabledImporteIva:boolean;
    onChangeValueImporteIva:any;
}

function StepOne(props: InterfaceStepOne) {

  const formatDate = (rowData: Reception | Valorization | any) => {
    if(rowData.TipoCompra?.Code==='01'){
      return props.formatDate(rowData.Date.Date);
    }
      if(rowData.TipoCompra?.Code==='02'){
        return props.formatDate(rowData.Dates.Registry.Date);
    }
  }
  const priceBodyTemplate = (rowData: Reception) => {
    let sumaAsociados = 0;
    for(const index in rowData.FacturaProveedor){
      let itemAsociado = rowData.FacturaProveedor[index];
      let flatingreso = 0;
      if(rowData.FacturasAsociados){
        let items_:any = rowData.FacturasAsociados.filter(a=>a.Factura===itemAsociado.Factura);
        if(items_.length>0){
          flatingreso = 1;
        }
      }      
      if(flatingreso===0){
        sumaAsociados += itemAsociado.importeAsociado;
      }    
      
    }
    //rowData.Pendiente-sumaAsociados
    return (
      <div className='flex justify-content-between align-items-center'>

        <p className='text-right mb-0 pr-6'>{props.formatCurrency(rowData.Pendiente, rowData?.Moneda)}</p>

      </div>
    )
  };
  const bodyImporteAsociar = (rowData: any) => {
    return (
      <ElementCurrencyPrimeReact          
          currency={rowData?.Moneda}
          value={rowData.ImporteAsociar}
          disabled={props.disabled}
        />

       
    )
  }
  const cellEditorImporte = (options: ColumnEditorOptions | any) => {
    return (
      <ElementCurrencyPrimeReact
          //name={'montoTotal'}
          
          currency={props.selectedValorizaciones[0]?.Moneda}
          value={options.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement> | any) => {
            return options.editorCallback(e.value);
            }
          }
          lg={12}
        />
      
    )  
  }


  console.log("props.selectedValorizaciones***", props.selectedValorizaciones)

  
  return (
    <>
      
      <Row xs={12} md={4} lg={4}>
        <Col className='pb-1'>
          <ElementCurrencyPrimeReact
          
            name={'importeFactura'}
            label={'Importe Factura'}
            currency={props.selectedValorizaciones[0]?.Moneda}
            value={props.formInvoice?.values['importeFactura']}
            onBlur={props.formInvoice?.handleBlur}
            isValid={!!props.formInvoice?.touched['importeFactura'] && !props.formInvoice?.errors['importeFactura']}
            isInvalid={props.formInvoice?.touched['importeFactura'] && !!props.formInvoice?.errors['importeFactura']}
            invalid={props.formInvoice?.touched['importeFactura'] && props.formInvoice?.errors['importeFactura']}
            disabled
            lg={4}
          />

        </Col>
        
            
            <ElementDropdownPrimereact
                label='Tipo IVA'
                inputId='tipoIva'
                name='tipoIva'
                options={props.tipoIVA}
                optionLabel='VATProd_PostingGroup'
                value={props.formInvoice.values.tipoIva}
                onChange={(e:any)=>{props.onChangeTipoIva(e);console.log(e.value)}}
                onBlur={props.formInvoice?.handleBlur}
                isValid={!!props.formInvoice?.touched['tipoIva'] && !props.formInvoice?.errors['tipoIva']}
                isInvalid={props.formInvoice?.touched['tipoIva'] && !!props.formInvoice?.errors['tipoIva']}
                invalid={props.formInvoice?.touched['tipoIva'] && props.formInvoice?.errors['tipoIva']}
                disabled={props.disabled}
            />
            <Col>
            <ElementCurrencyPrimeReact
                name={'importeIva'}
                label={'Importe IVA'}
                currency={props.selectedValorizaciones[0]?.Moneda}
                value={props.formInvoice?.values['importeIva']}
                disabled = {props.disabledImporteIva}
                onValueChange={(e:any)=>{props.onChangeValueImporteIva(e);console.log(e);}}
                onBlur={props.formInvoice?.handleBlur}
                isValid={!!props.formInvoice?.touched['importeIva'] && !props.formInvoice?.errors['importeIva']}
                isInvalid={props.formInvoice?.touched['importeIva'] && !!props.formInvoice?.errors['importeIva']}
                invalid={props.formInvoice?.touched['importeIva'] && props.formInvoice?.errors['importeIva']}
                
            />
            </Col>
            <Col>
            <ElementCurrencyPrimeReact
                name={'importeTotal'}
                label={'Importe Total'}
                currency={props.selectedValorizaciones[0]?.Moneda}
                value={props.formInvoice?.values['importeTotal']}
                onValueChange={(e:any)=>console.log(e)}
                onBlur={props.formInvoice?.handleBlur}
                isValid={!!props.formInvoice?.touched['importeTotal'] && !props.formInvoice?.errors['importeTotal']}
                isInvalid={props.formInvoice?.touched['importeTotal'] && !!props.formInvoice?.errors['importeTotal']}
                invalid={props.formInvoice?.touched['importeTotal'] && props.formInvoice?.errors['importeTotal']}
                disabled
            />
            </Col>
      </Row>
      <Row className="" xs={12} md={4} lg={4}>
      <Col className='mb-1'>
      <Button
            size="small" 
            label='Descargar Plantilla' 
            icon='pi pi-file-export' 
            className='p-button-info w-full' 
            type='button'

            onClick={()=>props.exportExcel()} 
          />
      
      </Col>
      <Col className='mb-1'>
        <FileUpload
              chooseOptions={{ label: 'Importar Plantilla', icon: 'pi pi-file-import', className: 'p-button-success p-button-sm w-full' }}
              mode="basic"
              auto
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              className="w-full"
              name="demo[]"
              //onUpload={(e)=>props.importExcel(e)}
              customUpload
              uploadHandler={(e) => props.importExcel(e)}
              
            />
      </Col>
      <Col className='mb-1'>
            <ElementInputFileImage
              size={13} 
              icon='pi pi-upload px-1' 
              accept='text/xml' 
              // onChange={()=>console.log('asd')}
               onChange={(e: ChangeEvent<HTMLInputElement>) => props.handleFileUpload2(e)} 
              className='w-full text-white element-file-upload border-1 border-solid border-round cursor-pointer transition-all transition-duration-200 hover:bg-primary-600 hover:border-primary-600 active:bg-primary-700 active:border-primary-700 text-sm p-button-gia p-button-label p-c' 
            />
      </Col>
      
      </Row>
      <Row className="mb-1">

        <DataTable
          value={props.selectedValorizaciones}
          editMode="cell"
          tableStyle={{ minWidth: '30rem' }}
          size='small'
        >
          <Column 
            bodyClassName={'text-sm py-0'} 
            //field="CodeValorizacion"
            field="CodigoFactura" 
            header="Código" 
            //style={{ width: '100%' }} 
            headerClassName='bg-gia text-white text-sm font-semibold' 
            className='lg:w-4 sm:w-2 ' 
          ></Column>
          <Column 
            className='lg:w-auto sm:w-1' 
            field="Observacion" 
            bodyClassName={'text-sm py-0'} 
            header="Observación" 
            headerClassName='bg-gia text-white text-sm font-semibold'
          ></Column>
          <Column 
            className='lg:w-2 sm:w-1 pr-0' 
            field="Date.Date" 
            bodyClassName={'text-sm py-0'} 
            header="Fecha" 
            headerClassName='bg-gia text-white text-sm font-semibold' 
            body={formatDate}
          ></Column>
          <Column 
            className='lg:w-2 sm:w-1 sm:px-0' 
            field="Importe" 
            bodyClassName={'text-sm py-0'} 
            header="Importe" 
            headerClassName='bg-gia text-white text-sm font-semibold' 
            body={priceBodyTemplate}
          ></Column>

          <Column 
            className='lg:w-3 sm:w-1' 
            field="ImporteAsociar" 
            bodyClassName={'text-sm lg:w-3 sm:w-1 sm:px-0 py-0'} 
            header="Importe Asociar" 
            headerClassName='bg-gia text-white text-sm font-semibold' 
            body={bodyImporteAsociar} 
            editor={(options) => cellEditorImporte(options)} 
            onCellEditComplete={props.onCellEditCompleteImporte}
          ></Column>

  

        </DataTable>
      </Row>
    </>
  )
}

export default StepOne
