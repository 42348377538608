import React from 'react'
import CardDashboard from './CardDashboard'
import CardDashboardDoc from './CardDashboardDoc'
import ListDashboard from './ListDashboard'
import { DtoResponseInvoice } from '../../Home/Domain/DtoResponseInvoice';
import { Comparativo } from '../../Home/Domain/Comparativo';
import { EntityDataUsuario } from '../../../shared/Domain/EntityDataUsuario';
import { ScrollPanel } from 'primereact/scrollpanel';
interface PropsDashboard{
    user: EntityDataUsuario;
    openDocumentHome:Function;
    data:DtoResponseInvoice[];
    TransformDate:Function;
    dataComparativo:Comparativo[];
    countValorPendiente:number;
    dataComparativos:any;
    dataStatusInvoice:any[];
    ListaControlDocumental: any[];
    visibleDialogSteps: boolean;
    onHideDialogSteps:Function;
    verControlDocumental:Function;
    onHideDocument:Function;
    
}
const Dashboard = (props:PropsDashboard) => {

    const statusBodyTemplate = (rowData: DtoResponseInvoice) => {
        const arr_status=['En Aprobación', 'Aprobado', 'Pagado','Anulado'];
        let status='';
        
        if(rowData.Status?.Solicitud.IdStatus===1){
          
          status=arr_status[1];
        }
        if(rowData.Status?.Nav.IdStatus===0){
          status=arr_status[0];
        }
        if(rowData.Status?.Nav.IdStatus===103){
          status=arr_status[1];
        }
        if(rowData.Status?.Nav.IdStatus===101){
          status=arr_status[2];
        }
        if(rowData.Status?.Nav.IdStatus===4){
          status=arr_status[3];
        }
      }

  return (
    <div className='p-5 flex flex-column flex-auto'>
        <div className='grid'>
            <div className='col-12'>
                <div className='grid'>
                    <div className='col-12 md:col-6 lg:col-3 p-3'>
                    <CardDashboard 
                            background='bg-indigo-500'
                            title='Pendiente por Facturar'
                            total={props.countValorPendiente}
                            icon='pi pi-book'
                            url={`${process.env.REACT_APP_ROUTE_PATH_MAIN_RECEPTION_VALORIZATION}`}
                        />
                        
                    </div>
                    <div className='col-12 md:col-6 lg:col-3 p-3'>
                        <CardDashboard
                            background='bg-blue-500'
                            title='Contratos Abiertos'
                            total={props.dataComparativos.filter((value:any) =>(value.TipoCompra.Code==='02')).length}
                            icon='pi pi-file'
                            url={`${process.env.REACT_APP_ROUTE_PATH_MAIN_RECEPTION_VALORIZATION}`}
                        />
                    </div>
                    <div className='col-12 md:col-6 lg:col-3 p-3'>
                        <CardDashboard 
                            background='bg-gia'
                            title='Ordenes Abiertas '
                            total={props.dataComparativos.filter((value:any) =>(value.TipoCompra.Code==='01')).length}
                            icon='pi pi-inbox'
                            
                            url={`${process.env.REACT_APP_ROUTE_PATH_MAIN_RECEPTION_VALORIZATION}`}
                            />
                        
                    </div>
                    <div className='col-12 md:col-6 lg:col-3 p-3'>
                        <CardDashboardDoc 
                            background='bg-gia'
                            title='Documentos Pendientes'
                            total= ''
                            icon='pi-file-pdf'
                            data={props.ListaControlDocumental}
                            visibleDialogSteps={props.visibleDialogSteps}
                            onHideDialogSteps={props.onHideDialogSteps}
                            verControlDocumental={props.verControlDocumental}
                            url={`${process.env.REACT_APP_ROUTE_PATH_MAIN_CONTROL_DOCUMENT}`}
                         />
                        
                    </div>
                    <div className='col-12 md:col-6 lg:col-6 p-3'>
                        {
                            props.dataStatusInvoice.length!==0 && <ListDashboard 
                            codeApproval={0}
                            color={'purple'}
                            title="Facturas en aprobación"
                            openDocumentHome={props.openDocumentHome} 
                            data={props.data} 
                            TransformDate={props.TransformDate}
                            dataStatusInvoice={props.dataStatusInvoice}
                        />
                        }
                        
                    </div>
                    <div className='col-12 md:col-6 lg:col-6 p-3'>
                        {
                            props.dataStatusInvoice.length!==0 && <ListDashboard 
                            color={'blue'}
                            codeApproval={100}
                            title="Facturas aprobadas"
                            openDocumentHome={props.openDocumentHome} 
                            data={props.data} 
                            TransformDate={props.TransformDate}
                            dataStatusInvoice={props.dataStatusInvoice}
                        />
                        }
                        
                    </div>
                    {/* <div className='col-12 md:col-6 lg:col-6 p-3'>
                        {
                            props.dataStatusInvoice.length!==0 && <ListDashboard 
                            codeApproval={101}
                            color={'green'}
                            title="Facturas Pagadas"
                            onClickViewDocument={props.onClickViewDocument} 
                            data={props.data} 
                            TransformDate={props.TransformDate}
                            dataStatusInvoice={props.dataStatusInvoice}
                        />
                        }
                        
                    </div> */}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Dashboard
