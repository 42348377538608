import { useState } from 'react';
import { EntityMenu } from '../../../shared/Domain/EntityMenu';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';
import { EntityDataUsuario } from '../../../shared/Domain/EntityDataUsuario';
import Dashboard from '../../Main/Components/Dashboard';
import './Style.scss';
import { DialogViewDocument } from '../../Main/Components/DialogViewDocument';
import { DialogViewControlDocument } from '../../Main/Components/DialogViewControlDocument';
import { DtoResponseInvoice } from '../Domain/DtoResponseInvoice';
import { Comparativo } from '../Domain/Comparativo';

interface PropsView {
    user: EntityDataUsuario;
    menu: Array<EntityMenu>;
    onClickAyuda: Function;
    onClickReport: Function;
    onClickMenu: Function;
    data:DtoResponseInvoice[];
    TransformDate:Function;
    dataComparativo:Comparativo[];
    countValorPendiente: number;
    dataComparativos:any;
    dataStatusInvoice:any;
    ListaControlDocumental:any;
    visibleDialogSteps: boolean;
    onHideDialogSteps:Function;
    verControlDocumental:Function;
    pdf:any;
    visibleDocument:boolean;
    openDocumentHome:Function;
    onHideDocument:Function;
    verDocumento:Function;
    CargarFileControlDocumental:Function;
    setDocumentosFile:Function;
    eliminarDocumento:Function;
    AlertSizeFileUpload:Function;
    AlertTypeFileUpload:Function;
};

export const ViewMain = (props: PropsView) => {
    const languageTranslate = LanguageTranslate();
    const [visible,setVisible] = useState<boolean>(false);

    return (
        <>
            <div className="ViewMasterHome">
                <div className="container">
                    <Dashboard 
                        user={props.user}
                        openDocumentHome={props.openDocumentHome} 
                        onHideDocument={props.onHideDocument}
                        data={props.data} 
                        countValorPendiente={props.countValorPendiente} 
                        TransformDate={props.TransformDate} 
                        dataComparativo={props.dataComparativo} 
                        dataComparativos={props.dataComparativos}
                        dataStatusInvoice={props.dataStatusInvoice}
                        ListaControlDocumental={props.ListaControlDocumental}
                        visibleDialogSteps={props.visibleDialogSteps}
                        onHideDialogSteps={props.onHideDialogSteps}
                        verControlDocumental={props.verControlDocumental}
                        
                    />


                </div>
            </div>
            <DialogViewDocument
                pdf={props.pdf}
                visibleDocument={props.visibleDocument}
                textValorizacion='Document'
                onHideDocument={props.onHideDocument}
            />
            <DialogViewControlDocument 
            data={props.ListaControlDocumental}                
            visibleDialogSteps={props.visibleDialogSteps}
            onHideDialogSteps={props.onHideDialogSteps}
            verDocumento={props.verDocumento}
            CargarFileControlDocumental={props.CargarFileControlDocumental}  
            setDocumentosFile={props.setDocumentosFile} 
            eliminarDocumento={props.eliminarDocumento}
            AlertSizeFileUpload={props.AlertSizeFileUpload}
            AlertTypeFileUpload={props.AlertTypeFileUpload}
            />
        </>
        
    )
};