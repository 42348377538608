import React from 'react'
import { DataTable, DataTableSelectionChangeEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Reception } from '../Domain/Reception';
import { Comparativo } from '../Domain/Comparativo';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { DialogViewDocument } from './DialogViewDocument';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { DialogViewSteps } from './DialogViewSteps';
import { TipoDocumentoTributario } from '../Domain/TipoDocumentoTributario';
import { TipoIVA } from '../Domain/TipoIVA';
import { Valorization } from '../Domain/Valorization';
import { Tag } from 'primereact/tag';
interface PropsViewDialogForm {
    data: Reception[];
    formatDate:Function;
    formatCurrency: Function;
    openDocument:any;
    selectedValorizaciones: Reception[] | any;
    setSelectedValorizaciones: Function;
    onSelectionChange:any;
    setGlobalFilter: Function;
    home:any;
    items:any;
    globalFilter:string;
    isSelectable:Function;
    onIngresoFactura:Function;
    //dialog visible
    visibleDialogSteps:boolean;
    onHideDialogSteps:Function;
    setTabIndex:Function;
    tabIndex:number;
    formInvoice: { values: any; touched: any; errors: any; handleBlur: Function; handleChange: Function; handleSubmit: Function; setFieldValue: Function;};
    onChange:any;
    importeAsociado:number;
    setImporteAsociado:any;
    onSubmit:Function;
    exportExcel:Function;
    tipoDocumentoTributario:TipoDocumentoTributario[] | null;
    tipoIVA:TipoIVA[] | null | undefined;
    onChangeTipoIva: Function;
    onCellEditCompleteImporte:Function;
    importExcel:Function;
    handleFileUpload2:Function;
    disabled:boolean;
    itemsDialogSteps:any;
    disabledImporteIva:boolean;
    onChangeValueImporteIva:any;
    setFacturaFile:any;
    setAnexoFile:any;
    onSelectAnexo:any;
    loadData:any;
    onRemoveAnexo:any;
    onClearAnexo:any;
    ListaControlDocumental:any;
    onSelectDocuementoFactura:Function;
    onRemoveDocuementoFactura:Function;
    onClearDocuementoFactura:Function;

    verDocumento:Function;
    CargarFileControlDocumental:Function
    setDocumentosFile:Function;
    eliminarDocumento:Function;
    AlertSizeFileUpload:Function;
    AlertTypeFileUpload:Function;

}
export const ViewDataTable = (props: PropsViewDialogForm) => {
    const headerTemplate = (data: Comparativo) => {
        return (
            <div className="flex align-items-center gap-2">

                <span className="font-bold">{data.Codigo}</span>
            </div>
        );
    };
    const dateBodyTemplate = (rowData: Valorization | Reception | any) => {
        
        if(rowData.TipoCompra?.Code==='01'){
            return props.formatDate(rowData.Date.Date);
        }
        if(rowData.TipoCompra?.Code==='02'){
            return props.formatDate(rowData.Dates.Registry.Date);
        }
        
    };
    const ImporteBodyTemplate = (rowData: Reception) => {
        let sumaPendientes_ = 0;
        
        console.log("rowData?.FacturaProveedor", rowData?.FacturaProveedor);

        for(const index in rowData?.FacturaProveedor){
             let itemPendiente = rowData?.FacturaProveedor[index];
             console.log("itemPendiente", itemPendiente.importeAsociado)
             let flatingreso = 0;
             if(rowData.FacturasAsociados){
               let items_:any = rowData.FacturasAsociados.filter(a=>a.Factura===itemPendiente.Factura);
               if(items_.length>0){
                 flatingreso = 1;
               }
             }
             if(flatingreso===0){
                sumaPendientes_ += itemPendiente.importeAsociado;
             }
        }

        console.log("sumaPendientes_", sumaPendientes_)
        let valorPendienteGiaProvedores = rowData?.Pendiente;// - sumaPendientes_;
        
        console.log("valorPendienteGiaProvedores", valorPendienteGiaProvedores);

        return props.formatCurrency(valorPendienteGiaProvedores, rowData.Moneda);
    };
    const DocumentoBodyTemplate = (rowData:Reception)=>{
        return (
            <Button disabled={rowData.File===null} icon="pi pi-file-pdf" rounded text severity={`${rowData.File!==null ? 'danger':'secondary'}`} aria-label="Cancel" onClick={(e)=> {
                props.openDocument(rowData);
            }}/>
        )
    }
    const TagValorizacionRecepcionTemplate = (rowData:any)=>{
        let label='';
        let color='';
        if(rowData.TipoCompra?.Code==='01'){
            label='Recepcion';
            color='blue';
        }
        if(rowData.TipoCompra?.Code==='02'){
            label='Valorización';
            color='indigo';
        }
        return (
            <Tag value={label} className={`bg-${color}-100 text-${color}-500`}></Tag>
        )
    }
    
    const startContent = (
        <React.Fragment>
            <Button 
                label="Ingreso Factura" 
                icon="pi pi-plus" 
                className='bg-gia' 
                disabled={(props.selectedValorizaciones === null) || (props.selectedValorizaciones.length===0)} 
                //onClick={() => transformData(props.selectedComparativos)} 
                onClick= {()=> props.onIngresoFactura()}
            />
      
        </React.Fragment>
      );
      const endContent = (
        <React.Fragment>
            <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText placeholder="Buscar..." onInput={(e) => props.setGlobalFilter(e.currentTarget.value)}/>
                </span>
        </React.Fragment>
      );
      const isRowSelectable = (event: any) => (event.data ? props.isSelectable(event.data) : true);
      const rowClassName = (data: any) => (props.isSelectable(data) ? '' : 'p-disabled');
      const paginatorLeft = <Button type="button" icon="pi pi-refresh" text onClick={()=>props.loadData()}/>;
      const paginatorRigth = <Button type="button" icon="" text />;

      
    return (
        <div className='flex justify-content-center flex-column px-5'>
            <BreadCrumb home={props.home} model={props.items} className='border mb-3' />
            <Card
                
            >
                <div className='mb-3'>
                    <Toolbar className='bg-white border-none py-1 px-0' start={startContent} end={endContent}/>
                </div>
                <DataTable
                    paginatorLeft={paginatorLeft}
                    paginatorRight={paginatorRigth}
                    paginatorClassName="paginator-gia"
                    value={props.data}
                    //value={tabSelectedTable(codeMoneda, tipoCompraCode)}
                    selectionMode='multiple'
                    selection={props.selectedValorizaciones}
                    onSelectionChange={(e: DataTableSelectionChangeEvent<Reception | any>) => { props.onSelectionChange(e);  }}
                    //dataKey="id" 
                    tableStyle={{ minWidth: '50rem' }}
                    tableClassName='custombar1 mt-2'
                    paginator
                    rows={10}
                    scrollable
                    scrollHeight="420px"
                    isDataSelectable={isRowSelectable}
                    rowClassName={rowClassName}
                    size={'small'}
                    //showGridlines 
                    rowsPerPageOptions={[10, 20, 30, 40]}
                    //columnResizeMode="expand" 
                    resizableColumns
                    globalFilter={props.globalFilter}
                    

                >
                    
                    <Column selectionMode="multiple" headerStyle={{ width: '3em' }} headerClassName={`bg-gia text-white ${(props.selectedValorizaciones === null || props.selectedValorizaciones?.length === 0) ? 'p-disabled' : ''}`}></Column>
                    <Column className='pr-0' bodyClassName={'text-sm'} header="" headerClassName='bg-gia text-white text-white text-sm' body={DocumentoBodyTemplate}></Column>
                    <Column sortable field="Dates.Registry.Date" className='pr-0' bodyClassName={'text-sm'} header="Fecha" headerClassName='bg-gia text-white text-white text-sm' body={dateBodyTemplate}></Column>
                    <Column sortable field="CodigoFactura" className='pr-0' bodyClassName={'text-sm'} header="Código" headerClassName='bg-gia text-white text-white text-sm'></Column>
                    <Column sortable field="Observacion" className='pr-0' bodyClassName={'text-sm'} header="Observación" headerClassName='bg-gia text-white text-white text-sm'></Column>
                    <Column sortable field="" className='pr-0' bodyClassName={'text-sm'} header="Documento" headerClassName='bg-gia text-white text-white text-sm' body={TagValorizacionRecepcionTemplate}></Column>
                    <Column sortable field="Pendiente" className='pr-0' bodyClassName={'text-sm'} header="Importe" headerClassName='bg-gia text-white text-white text-sm' body={ImporteBodyTemplate}></Column>
                    
                </DataTable>
            </Card>

            <DialogViewSteps
                onChange={props.onChange}
                visibleDialogSteps={props.visibleDialogSteps}
                selectedValorizaciones={props.selectedValorizaciones}
                onHideDialogSteps={props.onHideDialogSteps}
                setTabIndex={props.setTabIndex}
                tabIndex={props.tabIndex}
                formatDate={props.formatDate}
                formatCurrency={props.formatCurrency}
                formInvoice={props.formInvoice}
                importeAsociado={props.importeAsociado}
                setImporteAsociado={props.setImporteAsociado}
                onSubmit={props.onSubmit}
                exportExcel={props.exportExcel}
                tipoDocumentoTributario={props.tipoDocumentoTributario}
                tipoIVA={props.tipoIVA}
                onChangeTipoIva={props.onChangeTipoIva}
                onCellEditCompleteImporte={props.onCellEditCompleteImporte}
                importExcel={props.importExcel}
                handleFileUpload2={props.handleFileUpload2}
                disabled={props.disabled}
                itemsDialogSteps={props.itemsDialogSteps}
                disabledImporteIva={props.disabledImporteIva}
                onChangeValueImporteIva={props.onChangeValueImporteIva}
                setFacturaFile={props.setFacturaFile}
                setAnexoFile={props.setAnexoFile}
                onSelectAnexo={props.onSelectAnexo}
                onRemoveAnexo={props.onRemoveAnexo}
                onClearAnexo={props.onClearAnexo}
                ListaControlDocumental={props.ListaControlDocumental}
                onSelectDocuementoFactura={props.onSelectDocuementoFactura}
                onRemoveDocuementoFactura={props.onRemoveDocuementoFactura}
                onClearDocuementoFactura={props.onClearDocuementoFactura}
                verDocumento={props.verDocumento}
                CargarFileControlDocumental={props.CargarFileControlDocumental}
                setDocumentosFile={props.setDocumentosFile}
                eliminarDocumento={props.eliminarDocumento}
                AlertSizeFileUpload={props.AlertSizeFileUpload}
                AlertTypeFileUpload={props.AlertTypeFileUpload}
            />
        </div>
    )
}
