import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EntityDataUsuario } from '../Domain/EntityDataUsuario';
import { EntityFuncionalidad } from '../Domain/EntityFuncionalidad';
import { EntityMenu } from '../Domain/EntityMenu';
import { EntityRol } from '../Domain/EntityRol';
import { AdapterStorage } from './AdapterStorage';

var { auth, token, tokenRefresh, user, menu, rol, funcionalidad, permisoVariables } = AdapterStorage.get(['auth', 'token', 'tokenRefresh', 'user', 'menu', 'rol', 'funcionalidad', 'permisoVariables']);

if (!!auth) {
    var { [`preference${(user as EntityDataUsuario).usuario._id}`]: preference } = AdapterStorage.get(`preference${(user as EntityDataUsuario).usuario._id}`);
}

interface SliceAuthenticationState {
    auth: boolean;
    token: string | null;
    tokenRefresh: string | null;

    user: EntityDataUsuario;

    menu: Array<EntityMenu>;
    rol: EntityRol;
    funcionalidad: EntityFuncionalidad;

    permisoVariables: {
        arrIdPaises: Array<number>;
        arrIdGrupos: Array<number>;
        arrIdDelegaciones: Array<number>;
        arrIdOT: Array<number>;
    };
    preferencia: {};
};

const userDefault: EntityDataUsuario = {
    usuario: {
        _id: '',
        nombre: '',
        identificacion: '',
        username: '',
        tipoDocumentoIdentidad: {
            _id: null,
            codigo: '',
            nombre: ''
        },
        datosGenerales: {
            direccion: '',
            telefono: '',
            email: '',
            contacto: '',
        },
        datosTributacion: {

        },
        geografia: {
            pais: '',
            ciudad: '',
            departamento: '',
            provincia: '',
            distrito: '',
        },
        pais: {
            _id: '',
            codigo: '',
            nombre: '',
        },
        datosTrabajo: {
            delegacion: {
                codigo: '',
                key: '',
                nombre: '',
            },
            ot: {
                codigo: '',
                key: '',
                nombre: '',
            },
        },
        datosContabilidad: {
            VATBus:{
                Key:''
            },
            Vendor:{
                Key:''
            }

        },
        "Supplier classification":''


    },
    token: {
        tokenType: '',
        accessToken: '',
        expAccessToken: new Date(),
        refreshToken: '',
        expRefreshToken: new Date()
    },

};

const menuDefault: Array<EntityMenu> = [];

const initialState: SliceAuthenticationState = {
    auth: !!auth ? true : false,
    token,
    tokenRefresh,
    user: !!user ? user : userDefault,
    menu: !!menu ? menu : menuDefault,
    // menu: !!menu ? menu : [],
    rol: !!rol ? rol : {},
    funcionalidad: !!funcionalidad ? funcionalidad : [],

    permisoVariables: !!permisoVariables ? permisoVariables : {
        arrIdPaises: [],
        arrIdGrupos: [],
        arrIdDelegaciones: [],
        arrIdOT: [],
    },

    preferencia: !!preference ? preference : { pais: null, delegacion: null, ot: null }
};


const authenticationSlice = createSlice({
    name: 'authenticationSlice',
    initialState,
    reducers: {
        signIn: (state, { payload }: PayloadAction<any>) => {
            AdapterStorage.set('auth', true);
            AdapterStorage.set('user', payload.user);
            AdapterStorage.set('menu', payload.menu);
            AdapterStorage.set('token', payload.token);
            AdapterStorage.set('tokenRefresh', payload.tokenRefresh);

            return {
                ...state,
                auth: true,
                token: payload.token,
                tokenRefresh: payload.tokenRefresh,
                user: payload.user,
                menu: payload.menu,
            };
        },
        signOut: state => {
            AdapterStorage.remove(['auth', 'token', 'tokenRefresh', 'user', 'menu']);

            return {
                ...state,
                user: userDefault,
                menu: menuDefault,
                auth: false,
                token: null,
                tokenRefresh: null,
            };
        },
        refreshToken: (state, { payload }: PayloadAction<any>) => {
            AdapterStorage.set('token', payload.token);
            AdapterStorage.set('tokenRefresh', payload.tokenRefresh);

            return {
                ...state,
                token: payload.token,
                tokenRefresh: payload.tokenRefresh,
            };
        },

        changePreference: (state, { payload }: PayloadAction<any>) => {
            let preferencia = {
                ...state.preferencia,
                ...payload
            };

            AdapterStorage.set(`preference${state.user.usuario._id}`, preferencia);

            return { ...state, preferencia };
        },

        changePermisoVariable: (state, { payload }: PayloadAction<any>) => {
            let permisoVariables = {
                ...state.permisoVariables,
                arrIdPaises: payload.arrIdPaises,
                arrIdGrupos: payload.arrIdGrupos,
                arrIdDelegaciones: payload.arrIdDelegaciones,
                arrIdOT: payload.arrIdOT,
            };

            AdapterStorage.set('permisoVariables', permisoVariables);

            return { ...state, permisoVariables };
        },

        signInInvite: state => {
            AdapterStorage.set('auth', true);
            return {
                ...state,
                auth: true,
            };
        }
    },
});

export const {
    signIn,
    signOut,
    refreshToken,
    changePreference,
    signInInvite,
    changePermisoVariable
} = authenticationSlice.actions;

export default authenticationSlice.reducer;