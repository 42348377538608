import React, { useEffect, useRef, useState } from 'react'
import { Row } from 'react-bootstrap'
import ElementCurrencyPrimeReact from '../../../shared/Components/ElementCurrencyPrimeReact';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnEditorOptions, ColumnEvent } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
interface PropsViewMain {
  formInvoice: { values: any; touched: any; errors: any; handleBlur: Function; handleChange: Function; handleSubmit: Function; setFieldValue: Function;};
  onChangeValueMontoFormInvoice: Function;
  TransformDate:Function;
  transformCurrency:Function;
  onChangeValueTipoIVAFormInvoice:Function;
  onChange:any;
  disabled:boolean;
  setDisabled:Function;
  tipoIVA:any;
  user:any;
  tipoDatosTributarios:any
  formData:any;
  
};
function StepTwo(props:PropsViewMain) {
  const [contratos, setContratos] = useState<any[]>([]);
  useEffect(() => {
    setContratos(props.formData?.contracts);

  }, [props.formData?.contracts])
  const formatDate = (rowData: any) => {
    return props.TransformDate(rowData.Date?.Date);
  }
  const formatCurrency = (value: number) => {

    //return value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return props.transformCurrency(value);
  };
  const priceBodyTemplate = (rowData: any) => {
    
    
    return (
      <div className='flex justify-content-between align-items-center'>
        
          <span className=''>{props.formData.moneda}</span>
          <p className='text-right mb-0 pr-6'>{formatCurrency(rowData.Importe)}</p>
        
      </div>
    )
  };
  const bodyImporteAsociar = (rowData: any) => {
    return (
      <ElementCurrencyPrimeReact
          
          
          currency={props.formData?.moneda}
          value={rowData.ImporteAsociar}
          lg={12}
        />

    )
  }
  const cellEditorImporte = (options: ColumnEditorOptions | any) => {
    return (
      <ElementCurrencyPrimeReact
          //name={'montoTotal'}
          
          currency={props.formData?.moneda}
          value={options.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement> | any) => {
            return options.editorCallback(e.value);
            }
          }
          lg={12}
        />

      
    )  
  }
  const [importeAsociado, setImporteAsociado] = useState<number>(0);
  const onCellEditCompleteImporte = (e: ColumnEvent) => {
    let { rowData, newValue, field, originalEvent: event } = e;
    let sumImporteAsociado=0;
    switch (field) {
      case 'ImporteAsociar':
        //props.formInvoice.setFieldValue('importeAsociado',newValue),
        setImporteAsociado(newValue);
        rowData[field] = newValue;
        break;
      default:
        if (newValue.trim().length > 0) rowData[field] = newValue;
    }
    sumImporteAsociado = contratos.reduce((accumulator, current) => accumulator + (current?.ImporteAsociar === undefined ? 0 : current?.ImporteAsociar), 0);
    props.formInvoice.setFieldValue('importeAsociado',sumImporteAsociado);
    props.formInvoice.setFieldValue('importePendiente',(props.formInvoice.values.montoTotal - sumImporteAsociado));
  }

  const exportExcel = () => {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataExportTransform(contratos));
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });

      saveAsExcelFile(excelBuffer, 'products');
    });
  };
  const saveAsExcelFile = (buffer: any, fileName: any) => {
    import('file-saver').then((module) => {

      if (module && module.default) {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
          type: EXCEL_TYPE
        });

        module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      }
    });
  };
  const dataExportTransform = (data: any) => {
    let newContratos = contratos.map(value => {
      return {
        Date: new Date(value.Date.Date).toLocaleString("es-MX", { dateStyle: 'short' }),
        Code: value.Code,
        ImporteAsociar: value.Emitido,
        importe: value.Pendiente
      }
    });
    return newContratos;
  }
  const toCapitalize = (s: any) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }
  const fileUploadRefXML = useRef<FileUpload | null>(null);
  const [importedCols, setImportedCols] = useState([{ field: '', header: 'Header' }]);
  const [importedData, setImportedData] = useState<any[]>();
  const importExcel = (e: any) => {
    fileUploadRefXML.current?.clear();
    let file = e.files[0];
    import('xlsx').then(xlsx => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const wb = xlsx.read(e?.target.result, { type: 'array' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = xlsx.utils.sheet_to_json(ws, { header: 1 });

        // Prepare DataTable
        const cols: any = data[0];
        data.shift();

        let _importedCols = cols.map((col: any) => ({ field: col, header: toCapitalize(col) }));
        let _importedData = data.map((d: any) => {
          return cols.reduce((obj: any, c: any, i: any) => {
            obj[c] = d[i];
            return obj;
          }, {});
        });
        if (_importedData.length !== contratos.length) {
          fileUploadRefXML.current?.clear();

          //props.toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Exceso de data en el excel', life: 3000 });
          return;

        } /*else if (_importedData.map((value: any) => {
          
        })) {
          fileUploadRefXML.current?.clear();

          toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Negativos de data en el excel', life: 3000 });
          //return;
        }*/

        setImportedCols(_importedCols);
        setImportedData(_importedData);
        let newImported = contratos.map((value,index)=>{
          if(value.Code!==_importedData[index].Code || value.Importe !== _importedData[index].importe ){
            return {...value}
          }
          return {
            ...value,
            ImporteAsociar:_importedData[index].ImporteAsociar
          }
        })
        
        
        setContratos(newImported);
      };
      
      reader.readAsArrayBuffer(file);
    });
  }
  return (
    <>
      <Row className="mb-1">
        <ElementCurrencyPrimeReact
          name={'montoTotal'}
          label={'Importe Factura'}
          currency={props.formData?.moneda}
          value={props.formInvoice?.values['montoTotal']}
          onBlur={props.formInvoice?.handleBlur}
          isValid={!!props.formInvoice?.touched['montoTotal'] && !props.formInvoice?.errors['monto']}
          isInvalid={props.formInvoice?.touched['montoTotal'] && !!props.formInvoice?.errors['monto']}
          invalid={props.formInvoice?.touched['montoTotal'] && props.formInvoice?.errors['monto']}
          disabled
          lg={4}
        />
        <ElementCurrencyPrimeReact
          name={'importeAsociado'}
          label={'Importe Asociado'}
          currency={props.formData?.moneda}
          value={props.formInvoice?.values['importeAsociado']}
          onChange={props.onChangeValueMontoFormInvoice}
          onValueChange={(e:any)=>console.log(e)}
          onBlur={props.formInvoice?.handleBlur}
          isValid={!!props.formInvoice?.touched['importeAsociado'] && !props.formInvoice?.errors['importeAsociado']}
          isInvalid={props.formInvoice?.touched['importeAsociado'] && !!props.formInvoice?.errors['importeAsociado']}
          invalid={props.formInvoice?.touched['importeAsociado'] && props.formInvoice?.errors['importeAsociado']}
          disabled
          lg={4}
        />
        <ElementCurrencyPrimeReact
          name={'importePendiente'}
          label={'Importe Pendiente'}
          currency={props.formData?.moneda}
          value={props.formInvoice?.values['importePendiente']}
          onChange={props.onChangeValueMontoFormInvoice}
          onValueChange={(e:any)=>console.log(e)}
          onBlur={props.formInvoice?.handleBlur}
          isValid={!!props.formInvoice?.touched['importePendiente'] && !props.formInvoice?.errors['importePendiente']}
          isInvalid={props.formInvoice?.touched['importePendiente'] && !!props.formInvoice?.errors['importePendiente']}
          invalid={props.formInvoice?.touched['importePendiente'] && props.formInvoice?.errors['importePendiente']}
          disabled
          lg={4}
        />
      </Row>
      <Row className="mb-1">
        <div className='mb-2 col-lg-4 col-md-3 col-sm-3'>
          <Button 
            size="small" 
            label='Descargar Plantilla' 
            icon='pi pi-file-export' 
            className='p-button-info w-full' 
            type='button'

            onClick={exportExcel} 
          />
        </div>
        <div className='mb-2 col-lg-4 col-md-3 col-sm-3'>
          <FileUpload
            chooseOptions={{ label: 'Importar Plantilla', icon: 'pi pi-file-import', className: 'p-button-success p-button-sm' }}
            mode="basic"
            auto
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            className="ml-2 w-full"
            name="demo[]"
            onUpload={importExcel}
            customUpload
            uploadHandler={(e) => importExcel(e)}

          />
        </div>
      </Row>

      <Row className="mb-1">

      <DataTable
        value={contratos} 
        editMode="cell" 
        tableStyle={{ minWidth: '40rem' }} 
        size='small'
      >
        {<Column bodyClassName={'text-sm'} field="Code" header="Code" style={{ width: '20%' }} headerClassName='bg-gia text-white text-sm font-semibold' className='lg:w-3 sm:w-2' ></Column>}
        <Column className='lg:w-auto sm:w-1 ' field="Observacion" bodyClassName={'text-sm'} header="Observación" headerClassName='bg-gia text-white text-sm font-semibold'></Column>
        <Column className='lg:w-3 sm:w-1 pr-0' field="Date.Date" bodyClassName={'text-sm'} header="Fecha" headerClassName='bg-gia text-white text-sm font-semibold' body={formatDate}></Column>
        <Column className='lg:w-3 sm:w-1 sm:px-0' field="Importe" bodyClassName={'text-sm'} header="Importe" headerClassName='bg-gia text-white text-sm font-semibold' body={priceBodyTemplate}></Column>
        
        <Column className='lg:w-3 sm:w-1 ' field="ImporteAsociar" bodyClassName={'text-sm lg:w-3 sm:w-1 sm:px-0'} header="Importe Asociar" headerClassName='bg-gia text-white text-sm font-semibold' body={bodyImporteAsociar} editor={(options) => cellEditorImporte(options)} onCellEditComplete={onCellEditCompleteImporte}></Column>
        
       

      </DataTable>
      </Row>
    </>
    
  )
}

export default StepTwo
