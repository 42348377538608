import { Outlet } from 'react-router-dom';

import './Style.scss';
import { ObjectHeader } from '../../../shared/Components/ObjectHeader';
import { EntityDataUsuario } from '../../../shared/Domain/EntityDataUsuario';


interface ViewProps {
    auth: boolean;
    user: EntityDataUsuario;
    iconMenu: boolean;
    showSaludo: boolean;
    online: boolean;
    countProcess: number;
    onClickHome: Function;
    onClickHelp: Function;
    onClickReport: Function;
    onClickProfile: Function;
    onClickVariable: Function;
    onClickLogout: Function;
    onHideIconMenu: Function;
    onShowIconMenu: Function;
};

export const ViewMain = (props: ViewProps) => {
    return (
        <>
            <ObjectHeader
                auth={props.auth}
                user={props.user}
                iconMenu={props.iconMenu}
                online={props.online}
                countProcess={props.countProcess}
                onHideIconMenu={props.onHideIconMenu}
                onShowIconMenu={props.onShowIconMenu}
                onClickHome={props.onClickHome}
                onClickHelp={props.onClickHelp}
                onClickReport={props.onClickReport}
                onClickProfile={props.onClickProfile}
                onClickVariable={props.onClickVariable}
                onClickLogout={props.onClickLogout}
                showSaludo={props.showSaludo}
            >
            <main className='ViewMasterMain'>
                
                <Outlet />
                
            </main>
            
            </ObjectHeader>
        </>
    )
};