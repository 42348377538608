import { InputNumber, InputNumberProps } from 'primereact/inputnumber';
import React from 'react'
import { Form } from 'react-bootstrap';
import './ElementCurrencyPrimeReact.scss';
interface PropsView {
    currency:string;
    label?:string;
    inputId?:string;
    name?:string;
    disabled?:boolean;
    optionLabel?:string;
    onChange?:any;
    invalid?:boolean;
    isInvalid?:boolean;
    options?:any;
    value?:any;
    className?:string;
    onBlur?:Function;
    isValid?:boolean;
    onValueChange?:any;
    lg?:number;
    md?:number;
    sm?:number;
  }
function ElementCurrencyPrimeReact(props:PropsView) {
  return (
    
    // <div className={` ${props.lg ? `col-lg-${props.lg}` : 'col-lg-3'} ${props.md ? `col-md-${props.md}` : 'col-md-3'} ${props.sm ? `col-sm-${props.sm}` : 'col-sm-3'}`}>
    <div>
      <label className='fw-bold form-label'>{props.label}</label>
      <div className="p-inputgroup flex-1 addon-currency">
        <span className="p-inputgroup-addon addon">
          <i className="font-normal text-sm ">{props.currency}</i>
        </span>
        <InputNumber
          name={props.name}
          id={props.name}
          locale="en-US"
          value={props.value}
          minFractionDigits={2}
          onBlur={(event:any) => { if (!!props.onBlur) props.onBlur(event); }}
          inputClassName={`${props.disabled? 'disabled':''} w-full custom-form-currency ${props.isInvalid && 'p-invalid'} ${props.isValid && 'p-valid'} ${props.className} text-right`}
          className={`${props.disabled? 'disabled':''} w-full custom-form-currency ${props.isInvalid && 'p-invalid'} ${props.isValid && 'p-valid'} ${props.className}`}
          onValueChange={props.onValueChange}
          onChange={props.onChange}
          disabled={props.disabled}
        />
    </div>
      
      <Form.Control.Feedback type="invalid" style={{ display: props.isInvalid ? 'block' : 'none' }}>
                    {props.invalid}
                </Form.Control.Feedback>
    </div>
  )
}

export default ElementCurrencyPrimeReact
