import { RepositoryMain } from "../Domain/RepositoryMain";
import { DtoRequestNuevoProveedor } from "../Domain/DtoRequestNuevoProveedor";

export class UseCaseNuevoProveedor {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(params: DtoRequestNuevoProveedor): Promise<void> {
        await this._exec(params);
    }

    private async _exec(params: DtoRequestNuevoProveedor): Promise<void> {
        await this.repository.nuevoProveedor(params);
    }
}