import './Style.scss';

import logo from '../../../../assets/img/IconosAppGIAMovil/logo.svg';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';
import { Card } from 'primereact/card';
import { Row, Button, Form } from 'react-bootstrap';
import { ElementInputFormCostume } from '../../../shared/Components/ElementInputFormCostume';
import { ElementSelectCostume } from '../../../shared/Components/ElementSelectCostume';
import { Link } from 'react-router-dom';

//Imagenes

interface PropsViewMain {
    form: { values: any; touched: any; errors: any; handleBlur: Function; };
    onSubmit: Function;
    onClickLogin: Function;
    onChange: Function;
    onChangeValuePais: Function;
};

export const ViewMain = (props: PropsViewMain) => {
    const languageTranslate = LanguageTranslate();

    return (
        <div className="ViewMasterRecuperarPassword">
            <div className="fondo"></div>
            <div className="login-block">
                <div className='flex flex-column justify-content-center align-items-center text-center'>
                    <img src={logo} alt="logo.png" width={80} />
                    <span className='fw-bold mt-2' style={{ fontSize: 16 }}>Gestor Integral de Administración</span>
                    <span className='fw-bold mt-1' style={{ fontSize: 16 }}>Proveedores</span>
                </div>
                <div className='mt-1 flex justify-content-center w-full'>
                    <div className='sm:w-full md:w-4'>
                        <Card className='rounded-4 mt-5' style={{ padding: "10px" }} >
                            <form noValidate onSubmit={(evt) => { props.onSubmit(evt); }}>
                                <Row className="mb-1">
                                    <ElementInputFormCostume
                                        classForm={"mb-2"}
                                        classLabel={'fw-bold'}
                                        sm={12} md={12} lg={12}
                                        type={'text'}
                                        name={'identificacion'}
                                        value={props.form.values['identificacion']}
                                        text={languageTranslate.moduloRecuperarPassword.textoIdentificacion}
                                        maxLength={11}
                                        upper={true}
                                        size={'lg'}
                                        onChange={props.onChange}
                                        onBlur={props.form.handleBlur}
                                        isValid={!!props.form.touched['identificacion'] && !props.form.errors['identificacion']}
                                        isInvalid={props.form.touched['identificacion'] && !!props.form.errors['identificacion']}
                                        invalid={props.form.touched['identificacion'] && props.form.errors['identificacion']}
                                        childrenPrepend={
                                            <Button className="btn-sm text-white" variant="primary">
                                                <i className='fa fa-id-card' aria-hidden="true" />
                                            </Button>
                                        }
                                    />

                                    <ElementInputFormCostume
                                        classForm={"mb-2"}
                                        classLabel={'fw-bold'}
                                        sm={12} md={12} lg={12}
                                        type={'text'}
                                        name={'email'}
                                        value={props.form.values['email']}
                                        text={languageTranslate.moduloRecuperarPassword.textoCorreoElectronico}
                                        max={250}
                                        upper={false}
                                        size={'lg'}
                                        onChange={props.onChange}
                                        onBlur={props.form.handleBlur}
                                        isValid={!!props.form.touched['email'] && !props.form.errors['email']}
                                        isInvalid={props.form.touched['email'] && !!props.form.errors['email']}
                                        invalid={props.form.touched['email'] && props.form.errors['email']}
                                        childrenPrepend={
                                            <Button className="btn-sm text-white" variant="primary">
                                                <i className='fa fa-envelope' aria-hidden="true" />
                                            </Button>
                                        }
                                    />
                                </Row>

                                <ElementSelectCostume
                                    classLabel={'fw-bold'}
                                    classForm="mb-5 text-truncate"
                                    sm={12} md={12} lg={12}
                                    size='sm'
                                    name={'pais'}
                                    data={[{ _id: '1', code: "004", name: "Alemania" }, { _id: '2', code: "500", name: "Chile" }, { _id: '3', code: "480", name: "Colombia" }, { _id: '41', code: "512", name: "Ecuador" }, { _id: '5', code: "504", name: "Perú" },]}
                                    fieldKey={'_id'}
                                    fieldLabel={'name'}
                                    value={props.form.values['pais']}
                                    onChange={props.onChangeValuePais}
                                    onBlur={props.form.handleBlur}
                                    isValid={!!props.form.touched['pais'] && !props.form.errors['pais']}
                                    isInvalid={props.form.touched['pais'] && !!props.form.errors['pais']}
                                    invalid={(props.form.touched['pais'] || !!props.form.touched['pais']?.['_id']) && props.form.errors['pais'] && props.form.errors['pais']['_id']}
                                    text={languageTranslate.moduloRecuperarPassword.textoPais}
                                    childrenPrepend={
                                        <Button className="btn-sm text-white" variant="primary">
                                            <i className='fa fa-globe' aria-hidden="true" />
                                        </Button>
                                    }
                                />

                                <Row className="mb-3">
                                    <Button variant="primary" className="text-white" type="submit">{languageTranslate.moduloRecuperarPassword.textoAccion}</Button>
                                </Row>
                            </form>
                            <div className='flex justify-content-center mt-3'>
                                <div>
                                    {
                                        <Link to={'/login'} className="text-primary" style={{ fontWeight: 'bold', fontSize: "13px" }}>{languageTranslate.moduloRecuperarPassword.textoRegresarLogin}</Link>
                                    }
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>


            </div>

        </div >
    )
};