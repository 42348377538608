import React, { ElementType, CSSProperties } from 'react';
import { Col, Form, InputGroup, } from 'react-bootstrap';
import './ElementInputFormCostume.scss';

const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

interface PropsView {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
    xxl?: number;
    size?: 'sm' | 'lg' | undefined;

    name: string;
    type?: string;
    pattern?: string | undefined;
    upper?: boolean;
    disabled?: boolean;
    placeholder?: string;
    classForm?: string;

    classText?: string;
    text?: string;

    min?: number | string;
    max?: number | string;
    maxLength?: number;
    minLength?: number;

    childrenPrepend?: React.ReactNode;
    childrenPostpend?: React.ReactNode;

    value: any;
    onChange: Function;
    onClick?: Function;
    onBlur?: Function;
    isValid: boolean;
    isInvalid: boolean;
    invalid: string;

    innerRef?: any;
    tab?: number;
    accept?: string;
    as?: ElementType<any>;
    style?: CSSProperties;
    rows?: number | string;
    hidden?: boolean;
    classLabel?: string;
    addColClass?: boolean;
};

const ElementInputFormCostume = (props: PropsView) => {
    return (
        <Form.Group
            hidden={props.hidden}
            className={props.classForm}
            as={props.addColClass ? Col : undefined}
        // xs={props.xs} 
        // sm={props.sm} 
        // md={props.md} 
        // lg={props.lg} 
        // xl={props.xl} 
        // xxl={props.xxl} 
        >
            {!props.text ? null : <Form.Label className={props.classLabel} dangerouslySetInnerHTML={{ __html: props.text || '' }}></Form.Label>}
            <InputGroup hasValidation>
                {props.childrenPrepend}
                <Form.Control id={props.name}
                    size={props.size}
                    as={props.as}
                    rows={props.rows}
                    style={props.style}
                    ref={props.innerRef}
                    accept={props.accept}
                    className={props.classText}
                    name={props.name}
                    type={props.type}
                    tab={props.tab}
                    pattern={props.pattern}
                    placeholder={props.placeholder || props.text || "..."}
                    disabled={props.disabled}
                    autoComplete="off"
                    value={props.type === 'file' ? undefined : props.value}
                    maxLength={props.maxLength}
                    minLength={props.minLength}
                    min={props.min}
                    max={getCurrentDate()}
                    onClick={() => { if (!!props.onClick) props.onClick(); }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        let value: any = e.target.validity.valid ? !props.upper ? e.target.value.toString() : e.target.value.toUpperCase() : null;
                        value = !e.target.files?.length ? value : e.target.files;
                        const regex = /^[a-zA-Z0-9]*$/; 
                        if(props.name==="serie" || props.name==="numeroDocumentoTributario"){
                            if (regex.test(value)) {
                                if (!!props.onChange) props.onChange(props.name, value);
                            }
                        }else{
                            if (!!props.onChange) props.onChange(props.name, value);
                        }
                    }}
                    onBlur={(event) => { if (!!props.onBlur) props.onBlur(event); }}
                    isValid={props.isValid}
                    isInvalid={props.isInvalid}
                />
                {props.childrenPostpend}
                <Form.Control.Feedback type="invalid" style={{ display: props.isInvalid ? 'block' : 'none' }}>
                    {props.invalid}
                </Form.Control.Feedback>
            </InputGroup>
        </Form.Group>
    );
}

ElementInputFormCostume.defaultProps = {
    type: "text",
    upper: true,
    size: "sm",
    childrenPrepend: null,
    childrenPostpend: null,
    disabled: false,
    classText: "",
    classForm: "",
    xs: null,
    addColClass: true
};

export { ElementInputFormCostume };