import { createSlice } from '@reduxjs/toolkit';
import { AdapterIndexedDB } from './AdapterIndexedDB';
import { AdapterWebSocket } from './AdapterWebSocket';

interface SliceGenericoState {
	iconMenu: boolean;
	showSaludo: boolean;

	loading: boolean;
	textLoading: string;

	websocket: AdapterWebSocket;

	dbLocal: AdapterIndexedDB;

	online: boolean;
	countProcess: number;

	loadingMaestros: boolean;
	availableUpdate: boolean;
};

const initialState: SliceGenericoState = {
	iconMenu: false,
	showSaludo: true,

	loading: false,
	textLoading: '',

	websocket: new AdapterWebSocket(process.env.REACT_APP_WS_HOST, Number(process.env.REACT_APP_WS_PORT) || 80, process.env.REACT_APP_WS_SECURE === 'true' ? true : false, process.env.REACT_APP_WS_PATH, {}),

	dbLocal: new AdapterIndexedDB(`db_${process.env.REACT_APP_SISTEMA}_${process.env.REACT_APP_VERSION_MAJOR}`, Number(process.env.REACT_APP_INDEXEDDB_VERSION) || 1, [
		{ name: 'Comparativo', pk: '_id', index: [], encrpyt: false },
		{ name: 'Factura', pk: '_id', index: [], encrpyt: false },
	]),

	online: navigator.onLine,
	countProcess: 0,

	loadingMaestros: true,
	availableUpdate: false
};

// initialState.websocket.create();
// initialState.websocket.init();

initialState.dbLocal.run();

const genericoSlice = createSlice({
	name: 'genericoSlice',
	initialState,
	reducers: {
		addLoading: (state, action) => {
			return {
				...state,
				loading: true,
				textLoading: action.payload.textLoading,
			};
		},
		removeLoading: state => {
			return {
				...state,
				loading: false,
				textLoading: '',
			};
		},
		showIconMenu: state => {
			return {
				...state,
				iconMenu: true,
			};
		},
		hideIconMenu: state => {
			return {
				...state,
				iconMenu: false,
			};
		},
		changeSaludo: (state, action) => {
			return {
				...state,
				showSaludo: action.payload,
			};
		},
		changeOnline: (state, action) => {
			return {
				...state,
				online: action.payload,
			};
		},
		changeCountProcess: (state, action) => {
			return {
				...state,
				countProcess: action.payload,
			};
		},
		addCountProcess: (state) => {
			let count: number = state.countProcess;
			count += 1;
			return {
				...state,
				countProcess: count,
			};
		},
		removeLoadingMaestros: (state) => {
			return {
				...state,
				loadingMaestros: false
			};
		},
		changeAvailableUpdate: (state, action) => {
			return {
				...state,
				availableUpdate: action.payload,
			};
		},
	},
});

export const {
	showIconMenu,
	hideIconMenu,
	addLoading,
	removeLoading,
	changeSaludo,
	changeOnline,
	changeCountProcess,
	addCountProcess,
	removeLoadingMaestros,
	changeAvailableUpdate
} = genericoSlice.actions;

export default genericoSlice.reducer;