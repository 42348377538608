import { Dropdown } from 'primereact/dropdown';
import React from 'react'
import { Col, Form } from 'react-bootstrap';

interface PropsView {
  label?:string;
  inputId?:string;
  name?:string;
  disabled?:boolean;
  optionLabel?:string;
  onChange?:any;
  invalid?:boolean;
  isInvalid?:boolean;
  options?:any;
  value?:string;
  className?:string;
  onBlur?:Function;
  isValid?:boolean;
}
function ElementDropdownPrimereact(props:PropsView) {
  return (
    <Col>
    <div 
      // className='mb-2 col-lg-3 col-md-3 col-sm-3'
    >
      <label className='fw-bold form-label'>{props.label}</label>
      <Dropdown
        inputId={props.inputId}
        className={`${props.disabled? 'disabled':''} w-full custom-form ${props.isInvalid && 'p-invalid'} ${props.isValid && 'p-valid'} ${props.className}`}
        name={props.name}
        disabled={props.disabled}
        options={props.options}
        optionLabel={props.optionLabel}
        value={props.value}
        onChange={props.onChange}
        
        //isValid={props.isValid}
        //isInvalid={props.isInvalid}
        onBlur={(event) => { if (!!props.onBlur) props.onBlur(event); }}
      />
      <Form.Control.Feedback type="invalid" style={{ display: props.isInvalid ? 'block' : 'none' }}>
                    {props.invalid}
                </Form.Control.Feedback>
    </div>
    </Col>
  )
}

export default ElementDropdownPrimereact
