import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
//import { EntityMain } from "../Domain/EntityMain";

import { DtoRequestInvoice } from "../Domain/DtoRequestInvoice";
import { DtoResponseInvoice } from "../Domain/DtoResponseInvoice";
import { DtoRequestComparativo } from "../Domain/DtoRequestComparativo";
import { DtoRequestControlDocumental } from "../Domain/DtoRequestControlDocumental";
import { ListaControlDocumental } from "../Domain/ListaControlDocumental";
import { Comparativo } from "../Domain/Comparativo";

import { Buffer } from 'buffer';
export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {
    public async getList(params: DtoRequestInvoice): Promise<DtoResponseInvoice[] | null> {
        return this._getList(params);
    }
    private async _getList(params: DtoRequestInvoice): Promise<DtoResponseInvoice[] | null> {
        const newParams = JSON.stringify(params)
        const result = await this.service.bgCall<any>('POST', `${this.urlBase}/catalogo/proveedor/selectFacturas`, newParams, 'bearer', "json", "json", {}, 0);
        return result
    }
    public async getListComparativo(params: DtoRequestComparativo): Promise<Comparativo[] | null> {
        return this._getListComparativo(params);
    }
    private async _getListComparativo(params: DtoRequestComparativo): Promise<Comparativo[] | null> {
        const newParams = JSON.stringify(params)
        return await this.service.bgCall<any>('POST', `${this.urlBase}/catalogo/proveedor/selectComparativos`, newParams, 'bearer', "json", "json", {}, 0);
    }
    public async getListControlDocumental(params: DtoRequestControlDocumental): Promise<ListaControlDocumental[] | null> {
        return this._getListControlDocumental(params);
    }
    private async _getListControlDocumental(params: DtoRequestControlDocumental): Promise<ListaControlDocumental[] | null> {
        const newParams = JSON.stringify(params)
        return await this.service.bgCall<any>('POST', `${this.urlBase}/catalogo/proveedor/selectListaControlDocumental`, newParams, 'bearer', "json", "json", {}, 0);
    }

    public async getCargarFileControlDocumento(params: any): Promise<any> {
        return this._getCargarFileControlDocumento(params);
    }
    private async _getCargarFileControlDocumento(params: any): Promise<any> {
        console.log("params", params)
        let myHeaders = new Headers();
        myHeaders.append("request-decrypt-response", "yes");
        myHeaders.append("Authorization", `Basic ${Buffer.from(process.env.REACT_APP_AUTH_BASIC_USER + ':' + process.env.REACT_APP_AUTH_BASIC_PASS).toString('base64')}`);
        let formdata = new FormData();
        formdata.append("documento",params.documento);
        formdata.append("_id",params._id);
        formdata.append("CodigoDocumento",params.CodigoDocumento);
        formdata.append("NombreDocumento",params.NombreDocumento);
        
        const response =await fetch(`${this.urlBase}/catalogo/proveedor/CargaFileControlDocumental`,{
            method:'post',
            body: formdata,
            headers: {
                "Authorization": "Basic U2lzdGVtYUdJQTpTb3BvcnRlOTMxMg==",
                "request-decrypt-response": "yes",
            },
        });

        console.log("response", response)
        return response;

    }

    public async getEleiminarileControlDocumento(params: any): Promise<any> {
        return this._getEleiminarileControlDocumento(params);
    }
    private async _getEleiminarileControlDocumento(params: any): Promise<any> {
        const newParams = JSON.stringify(params)
        return await this.service.bgCall<any>('POST', `${this.urlBase}/catalogo/proveedor/EliminarFileControlDocumental`, newParams, 'bearer', "json", "json", {}, 0);
    }
    
    
}