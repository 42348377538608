import { Dialog } from 'primereact/dialog'
import React, { useState } from 'react'
import { ElementInputFormCostume } from '../../../shared/Components/ElementInputFormCostume'
import { Row } from 'react-bootstrap';
import { ElementSelectCostume } from '../../../shared/Components/ElementSelectCostume';
import { Dropdown } from 'primereact/dropdown';
import {Form} from 'react-bootstrap';
import ElementDropdownPrimereact from '../../../shared/Components/ElementDropdownPrimereact';
import ElementCurrencyPrimeReact from '../../../shared/Components/ElementCurrencyPrimeReact';
interface PropsViewMain {
    formInvoice: { values: any; touched: any; errors: any; handleBlur: Function; handleChange: Function; handleSubmit: Function; setFieldValue: Function;};
    onChangeValueMontoFormInvoice: Function;
    TransformDate:Function;
    transformCurrency:Function;
    onChangeValueTipoIVAFormInvoice:Function;
    onChange:any;
    disabled:boolean;
    setDisabled:Function;
    tipoIVA:any;
    user:any;
    tipoDatosTributarios:any
    formData:any;
    
};
function StepOne(props:PropsViewMain) {
    
  return (
    <>
        <Row className="mb-1">
            <ElementInputFormCostume
              as={"input"}
              disabled
              classForm={"mb-2"}
              classLabel={'fw-bold'}
              sm={3} md={3} lg={3}
              type={'date'}
              name={'fechaEmision'}
              value={props.formInvoice?.values['fechaEmision']}
              text={"Fecha Emisión"}
              max={20}
              upper={false}
              size={'sm'}
              onChange={props.onChange}
              onBlur={props.formInvoice?.handleBlur}
              isValid={!!props.formInvoice?.touched['fechaEmision'] && !props.formInvoice?.errors['fechaEmision']}
              isInvalid={props.formInvoice?.touched['fechaEmision'] && !!props.formInvoice?.errors['fechaEmision']}
              invalid={props.formInvoice?.touched['fechaEmision'] && props.formInvoice?.errors['fechaEmision']}
              
            />
            <ElementDropdownPrimereact
                label='Tipo Documento Tributario'
                inputId='tipoDocumentoTributario'
                className={`${props.disabled? 'disabled':''} w-full p-inputtext-sm`}
                name='TipoDocumentoTributario'
                disabled={props.disabled}
                options={props.tipoDatosTributarios} 
                optionLabel='Descripcion'
                value={props.formInvoice.values.TipoDocumentoTributario}
                onChange={(e:any)=>{props.formInvoice.handleChange(e);console.log(e)}} 
                onBlur={props.formInvoice?.handleBlur}
                isValid={!!props.formInvoice?.touched['tipoDocumentoTributario'] && !props.formInvoice?.errors['TipoDocumentoTributario']}
                isInvalid={props.formInvoice?.touched['TipoDocumentoTributario'] && !!props.formInvoice?.errors['TipoDocumentoTributario']}
                invalid={props.formInvoice?.touched['TipoDocumentoTributario'] && props.formInvoice?.errors['TipoDocumentoTributario']}
            />
            
            <ElementInputFormCostume
              as={"input"}
              classForm={"mb-2"}
              classLabel={'fw-bold'}
              sm={3} md={3} lg={3}
              type={'text'}
              name={'numeroDocumentoTributario'}
              value={props.formInvoice?.values['numeroDocumentoTributario']}
              text={"Número Documento Tributario"}
              max={20}
              upper={false}
              size={'sm'}
              onChange={props.onChange}
              onBlur={props.formInvoice?.handleBlur}
              isValid={!!props.formInvoice?.touched['numeroDocumentoTributario'] && !props.formInvoice?.errors['numeroDocumentoTributario']}
              isInvalid={props.formInvoice?.touched['numeroDocumentoTributario'] && !!props.formInvoice?.errors['numeroDocumentoTributario']}
              invalid={props.formInvoice?.touched['numeroDocumentoTributario'] && props.formInvoice?.errors['numeroDocumentoTributario']}
              
            />

        </Row>
        <Row className="mb-1">
            <ElementCurrencyPrimeReact
                name={'monto'}
                label={'Monto'}
                currency={props.formData?.moneda}
                value={props.formInvoice?.values['monto']}
                onChange={props.onChangeValueMontoFormInvoice}
                onValueChange={(e:any)=>console.log(e)}
                onBlur={props.formInvoice?.handleBlur}
                isValid={!!props.formInvoice?.touched['monto'] && !props.formInvoice?.errors['monto']}
                isInvalid={props.formInvoice?.touched['monto'] && !!props.formInvoice?.errors['monto']}
                invalid={props.formInvoice?.touched['monto'] && props.formInvoice?.errors['monto']}
            />
            <ElementDropdownPrimereact
                label='Tipo IVA'
                inputId='TipoIVA'
                className={`${props.disabled? 'disabled':''} w-full p-inputtext-sm`}
                name='TipoIVA'
                //disabled={props.disabled}
                options={props.tipoIVA.filter((value: any) => value.VATBus_PostingGroup === props.user.usuario.datosContabilidad.VATBus.Key)}
                optionLabel='VATProd_PostingGroup'
                value={props.formInvoice.values.TipoIVA}
                onChange={(e:any)=>{props.onChangeValueTipoIVAFormInvoice(e);console.log(e.value)}}
                onBlur={props.formInvoice?.handleBlur}
                isValid={!!props.formInvoice?.touched['TipoIVA'] && !props.formInvoice?.errors['TipoIVA']}
                isInvalid={props.formInvoice?.touched['TipoIVA'] && !!props.formInvoice?.errors['TipoIVA']}
                invalid={props.formInvoice?.touched['TipoIVA'] && props.formInvoice?.errors['TipoIVA']}
            />
            <ElementCurrencyPrimeReact
                name={'IVA'}
                label={'IVA'}
                currency={props.formData?.moneda}
                value={props.formInvoice?.values['IVA']}
                onChange={props.onChangeValueMontoFormInvoice}
                onValueChange={(e:any)=>console.log(e)}
                onBlur={props.formInvoice?.handleBlur}
                isValid={!!props.formInvoice?.touched['IVA'] && !props.formInvoice?.errors['IVA']}
                isInvalid={props.formInvoice?.touched['IVA'] && !!props.formInvoice?.errors['IVA']}
                invalid={props.formInvoice?.touched['IVA'] && props.formInvoice?.errors['IVA']}
                disabled
            />

            <ElementCurrencyPrimeReact
                name={'montoTotal'}
                label={'Monto Total'}
                currency={props.formData?.moneda}
                value={props.formInvoice?.values['montoTotal']}
                onChange={props.onChangeValueMontoFormInvoice}
                onValueChange={(e:any)=>console.log(e)}
                onBlur={props.formInvoice?.handleBlur}
                isValid={!!props.formInvoice?.touched['montoTotal'] && !props.formInvoice?.errors['montoTotal']}
                isInvalid={props.formInvoice?.touched['montoTotal'] && !!props.formInvoice?.errors['montoTotal']}
                invalid={props.formInvoice?.touched['montoTotal'] && props.formInvoice?.errors['montoTotal']}
                disabled
            />
 
        </Row>
    </>
  )
}

export default StepOne
