import { Card } from 'primereact/card'
import React from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import './Style.scss';
import { Tag } from 'primereact/tag';
import { BreadCrumb } from 'primereact/breadcrumb';
import { MenuItem } from 'primereact/menuitem';
import { DtoResponseFindByName } from '../Domain/DtoResponseFindByName';
import DialogCreateTicket from './DialogCreateTicket';

interface propsViewMain {
  data: DtoResponseFindByName;
  reloadData: Function;
  globalFilter: string;
  setGlobalFilter: Function;
  items: MenuItem[];
  home: MenuItem;
  getStatus: Function;
  formatCurrency: Function;
  dialogCreateTicket:Function;
  visible:boolean;
  onHideDialogSteps:Function;
  onSubmit:Function;
  formTicket:any;
  onChange:any;
}


export const ViewMain = (props: propsViewMain) => {



  const startContent = (
    <React.Fragment>
      <Button
        label="Generar Ticket"
        icon="pi pi-plus"
        className='bg-gia'
        
        //onClick={() => transformData(props.selectedComparativos)} 
        onClick={() => props.dialogCreateTicket()}
      />
    </React.Fragment>
  );

  const endContent = (
    <React.Fragment>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText placeholder="Buscar..." onInput={(e) => props.setGlobalFilter(e.currentTarget.value)} />
      </span>
    </React.Fragment>
  );

  return (
    <div className='flex justify-content-center flex-column px-5'>
      <BreadCrumb home={props.home} model={props.items} className='border mb-3' />
      <Card
        //title='Estado Factura'
        className='scrollpanel-demo'
      >
        <div className='mb-3'>
          <Toolbar className='bg-white border-none py-1 px-0' end={endContent} start={startContent} />
        </div>
        <DataTable
          paginatorClassName="paginator-gia"
          value={props.data.data}
          dataKey="id"
          tableStyle={{ minWidth: '60rem', border: '1px solid #dee2e6' }}
          paginator
          rows={10}
          globalFilter={props.globalFilter}
          scrollable
          scrollHeight="450px"
          size='small'
          rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
          className='custombar1'
          resizableColumns
        >
          <Column sortable className='lg:w-1' bodyClassName={'text-sm text-striped'} field="2" header="Soporte" headerClassName='bg-gia text-white text-white text-sm'></Column>
          <Column sortable className='lg:w-1' bodyClassName={'text-sm text-striped'} field="15" header="Fecha" headerClassName='bg-gia text-white text-white text-sm'></Column>
          <Column sortable className='lg:w-1' bodyClassName={'text-sm text-striped'} field="1" header="Título" headerClassName='bg-gia text-white text-white text-sm'></Column>
          <Column sortable className='lg:w-1' bodyClassName={'text-sm text-striped'} field="estado" header="Estado" headerClassName='bg-gia text-white text-white text-sm'></Column>


        </DataTable>
      </Card>
      <DialogCreateTicket
        visibleDialogSteps={props.visible}
        onHideDialogSteps={props.onHideDialogSteps}
        onSubmit={props.onSubmit}
        formTicket={props.formTicket}
        onChange={props.onChange}
      />
    </div>
  )
}
