import { RepositoryMain } from "../Domain/RepositoryMain";
import { DtoRequestRecuperarPassword } from "../Domain/DtoRequestRecuperarPassword";

export class UseCaseRecuperarPassword {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(params: DtoRequestRecuperarPassword): Promise<void> {
        await this._exec(params);
    }

    private async _exec(params: DtoRequestRecuperarPassword): Promise<void> {
        await this.repository.recuperarPassword(params);
    }
}