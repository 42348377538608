import { Button } from 'primereact/button'
import React from 'react'
import LiDashboard from './LiDashboard'
import { ScrollPanel } from 'primereact/scrollpanel';
import { DtoResponseInvoice } from '../../Home/Domain/DtoResponseInvoice';
import { NavLink } from 'react-router-dom';

interface PropsListDashboard {
  openDocumentHome: Function;
  data:DtoResponseInvoice[];
  TransformDate:Function;
  dataStatusInvoice:any[];
  title:string;
  codeApproval:number;
  color:string;
}
const ListDashboard = (props: PropsListDashboard) => {
  
  let max=0;
  return (
    
      <div className='surface-card shadow-2 border-round p-4 listDashboard'>
        
        <div className='flex justify-content-between align-items-center mb-5'>
          <span className='text-xl text-900 font-medium'>{props.title}</span>
        </div>
        <ScrollPanel style={{ width: '100%', height: '320px' }}>
        <ul className='list-none p-0 m-0'>
          {
            
            props.dataStatusInvoice.map((value)=> {
               
              if(value.Status?.Nav.IdStatus===props.codeApproval && max<=2){
                  max++;
                  return (<LiDashboard codeApproval={props.codeApproval} color={props.color} openDocumentHome={props.openDocumentHome} value={value} TransformDate={props.TransformDate}/>)
                  
                }
                
              
            })
          }
          {
            max>2 && (<NavLink to={`${process.env.REACT_APP_ROUTE_PATH_MAIN_INVOICE_STATUS}`} className={'p-button-link'}>Ver más</NavLink>)
          }
          
          

        </ul>
        </ScrollPanel>
      </div>
    

  )
}

export default ListDashboard
