import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload'
import { ProgressBar } from 'primereact/progressbar';
import { Tag } from 'primereact/tag';
import { Tooltip } from 'primereact/tooltip'
import React, { useRef, useState } from 'react'
import { Row } from 'react-bootstrap'

function StepThree() {
    const fileUploadRefFactura = useRef(null);
    const [totalSizeFactura, setTotalSizeFactura] = useState(0);
    const [totalSize, setTotalSize] = useState(0);
    const fileUploadRef = useRef(null);
    const onTemplateSelectFactura = (e:any) => {
        let _totalSize = totalSize;
        let files = e.files;
    
        Object.keys(files).forEach((key) => {
          _totalSize += files[key].size || 0;
        });
    
        setTotalSizeFactura(_totalSize);
      };
      const onTemplateUploadFactura = (e: any) => {
        let _totalSize = 0;
    
        e.files.forEach((file: any) => {
          _totalSize += file.size || 0;
        });
    
        setTotalSizeFactura(_totalSize);
    
      };
      const onTemplateClearFactura = () => {
        setTotalSizeFactura(0);
      };
      const headerTemplateFactura = (options: any) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSizeFactura / 10000;
        //const formatedValue = (fileUploadRef && fileUploadRef.current) ? fileUploadRef.current.formatSize(totalSize) : '0 B';
    
        return (
          <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
            {chooseButton}
            {uploadButton}
            {cancelButton}
            <div className="flex align-items-center gap-3 ml-auto">
              <span>{} / 1 MB</span>
              <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
            </div>
          </div>
        );
      };
      const onTemplateRemove = (file: any, callback: any) => {
        setTotalSize(totalSize - file.size);
        callback();
      };
      const itemTemplateFactura = (file: any, props: any) => {
        return (
          <div className="flex align-items-center flex-wrap">
            <div className="flex align-items-center" style={{ width: '40%' }}>
            
              <i className='pi pi-file' />
              <span className="flex flex-column text-left ml-3">
                {file.name}
                <small>{new Date().toLocaleDateString()}</small>
              </span>
            </div>
            <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
            <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
          </div>
        );
      };
      const emptyTemplateFactura = () => {
        return (
          <div className="flex align-items-center flex-column">
            <i className="pi pi-file mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
            <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
              Arrastre y suelte el archivo aquí
            </span>
          </div>
        );
      };
      const chooseOptionsFactura = { icon: 'pi pi-fw pi-file', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
  const uploadOptionsFactura = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
  const cancelOptionsFactura = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };
  const onTemplateUpload = (e: any) => {
    let _totalSize = 0;

    e.files.forEach((file: any) => {
      _totalSize += file.size || 0;
    });

    setTotalSize(_totalSize);

  };
  const onTemplateSelect = (e: any) => {
    let _totalSize = totalSize;
    let files = e.files;

    Object.keys(files).forEach((key) => {
      _totalSize += files[key].size || 0;
    });

    setTotalSize(_totalSize);
  };
  const onTemplateClear = () => {
    setTotalSize(0);
  };
  const headerTemplate = (options: any) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    const value = totalSize / 10000;
    //const formatedValue = (fileUploadRef && fileUploadRef.current) ? fileUploadRef.current.formatSize(totalSize) : '0 B';

    return (
      <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
        {chooseButton}
        {uploadButton}
        {cancelButton}
        <div className="flex align-items-center gap-3 ml-auto">
          <span>{ } / 1 MB</span>
          <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
        </div>
      </div>
    );
  };
  const itemTemplate = (file: any, props: any) => {
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: '40%' }}>
          
          <i className='pi pi-file' />
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
        <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
      </div>
    );
  };
  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i className="pi pi-file mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
        <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
          Arrastre y suelte el archivo aquí
        </span>
      </div>
    );
  };

  const chooseOptions = { icon: 'pi pi-fw pi-file', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
  const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
  const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };
  
  return (
    <>
      <Row className="mb-1">
        <div className='col-sm-12 col-md-12 col-lg-6'>
            <h6>Factura</h6>
            <Tooltip target=".custom-choose-btn" content="Subir Archivo" position="bottom" />
            <Tooltip target=".custom-upload-btn" content="Cargar" position="bottom" />
            <Tooltip target=".custom-cancel-btn" content="Limpiar" position="bottom" />

            <FileUpload 
                ref={fileUploadRefFactura} 
                name="demo[]" 
                url="/api/upload" 
                multiple={false} 
                accept="pdf/*" 
                maxFileSize={50000000}
                onUpload={onTemplateUploadFactura} 
                onSelect={onTemplateSelectFactura} 
                onError={onTemplateClearFactura} 
                onClear={onTemplateClearFactura}
                headerTemplate={headerTemplateFactura} 
                itemTemplate={itemTemplateFactura} 
                emptyTemplate={emptyTemplateFactura}
                chooseOptions={chooseOptionsFactura} 
                uploadOptions={uploadOptionsFactura} 
                cancelOptions={cancelOptionsFactura} 
            />
        </div>
        <div className='col-sm-12 col-md-12 col-lg-6'>
            <h6>Anexos</h6>
            <Tooltip target=".custom-choose-btn" content="Subir Archivo" position="bottom" />
            <Tooltip target=".custom-upload-btn" content="Cargar" position="bottom" />
            <Tooltip target=".custom-cancel-btn" content="Limpiar" position="bottom" />

            <FileUpload 
                ref={fileUploadRef} 
                name="demo[]" 
                url="/api/upload" 
                multiple 
                accept="pdf/*" 
                maxFileSize={50000000}
                onUpload={onTemplateUpload} 
                onSelect={onTemplateSelect} 
                onError={onTemplateClear} 
                onClear={onTemplateClear}
                headerTemplate={headerTemplate} 
                itemTemplate={itemTemplate} 
                emptyTemplate={emptyTemplate}
                chooseOptions={chooseOptions} 
                uploadOptions={uploadOptions} 
                cancelOptions={cancelOptions} 
            />
        </div>
      </Row>
    </>
  )
}

export default StepThree
