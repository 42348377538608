const SCHEMA: string = process.env.REACT_APP_MASTER_LOGIN_SCHEMA || '';
const ENTITY: string = process.env.REACT_APP_MASTER_LOGIN_ENTITY || '';
const URL_FILES_DOWNLOAD: string = process.env.REACT_APP_URL_FILES_DOWNLOAD || '';
const URL_FILES_UPLOAD: string = process.env.REACT_APP_URL_FILES_UPLOAD || '';

export const AdapterConfigure = {
    SCHEMA,
    ENTITY,
    URL_FILES_DOWNLOAD,
    URL_FILES_UPLOAD
};