import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

interface MyEditorProps {
  initialValue: string;
  setValue: (value: string) => void;
}

const ElementRichEditorCostume: React.FC<MyEditorProps> = ({ initialValue, setValue }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
    setValue(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  const uploadImageCallBack = (file: File) => {
    return new Promise(
      (resolve, reject) => {
        if (!file.type.startsWith('image/')) {
          reject("El archivo subido no es una imagen.");
        }
        const reader = new FileReader();
        reader.onload = (evt) => {
          resolve({ data: { link: evt.target?.result } });
        };
        reader.onerror = () => {
          reject("Ocurrió un error al leer el archivo.");
        };
        reader.readAsDataURL(file);
      }
    );
  };

  const editorStyle = {
    fontSize: '13px',
    lineHeight: '1.2',
  };

  const toolbarStyle = {
    // height: '100px', 
    fontSize: '12px',
  };

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      toolbar={{
        options: [ 'inline', 'image', 'colorPicker', 'fontSize','history' ], // Otras opciones de la barra de herramientas
        image: {// Opción de inserción de imagen
          urlEnabled: false,
          uploadEnabled: true,
          alignmentEnabled: true,
          uploadCallback: uploadImageCallBack,
          previewImage: true,
          inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
          alt: { present: false, mandatory: false },
          defaultSize: {
            height: 'auto',
            width: 'auto',
          },
          className: 'toolbar-option',
          title: 'Insertar imagen',
        },
        inline: { // Opciones de formato en línea
          bold: { className: 'toolbar-option', title: 'Negrita' },
          italic: { className: 'toolbar-option', title: 'Cursiva' },
          underline: { className: 'toolbar-option', title: 'Subrayado' },
          strikethrough: { className: 'toolbar-option', title: 'Tachado' },
          monospace: { className: 'toolbar-option', title: 'Monoespaciado' },
          superscript: { className: 'toolbar-option', title: 'Superíndice' },
          subscript: { className: 'toolbar-option', title: 'Subíndice' },
        },
        blockType: { // Opciones de formato de bloque
          className: 'toolbar-option',
          title: 'Estilo de bloque',
        },
        fontSize: { // Opciones de tamaño de fuente
          className: 'toolbar-option',
          title: 'Tamaño de fuente',
        },
        fontFamily: { // Opciones de familia de fuente
          className: 'toolbar-option',
          title: 'Familia de fuente',
        },
        list: { // Opciones de lista
          unordered: { className: 'custom-option-classname', title: 'Lista desordenada' },
          ordered: { className: 'custom-option-classname', title: 'Lista ordenada' },
          indent: { className: 'custom-option-classname', title: 'Aumentar sangría' },
          outdent: { className: 'custom-option-classname', title: 'Reducir sangría' },
          className: 'toolbar-option',
          title: 'Lista',
        },
        textAlign: { // Opciones de alineación de texto
          left: { className: 'custom-option-classname', title: 'Alinear a la izquierda' },
          center: { className: 'custom-option-classname', title: 'Centrar' },
          right: { className: 'custom-option-classname', title: 'Alinear a la derecha' },
          justify: { className: 'custom-option-classname', title: 'Justificar' },
          className: 'toolbar-option',
          title: 'Alineación de texto',
        },
        colorPicker: { // Opciones de selector de color
          className: 'toolbar-option',
          title: 'Selector de color',
        },
        link: { // Opciones de enlace
          className: 'toolbar-option',
          title: 'Enlace',
        },
        emoji: { // Opciones de emoji
          className: 'toolbar-option',
          title: 'Emoji',
        },
        history: { // Opciones de historial
          undo: { className: 'custom-option-classname', title: 'Deshacer' },
          redo: { className: 'custom-option-classname', title: 'Rehacer' },
          className: 'toolbar-option',
          title: 'Historial',
        },
      }}
      editorStyle={editorStyle} // Agregar estilos personalizados
      toolbarClassName="custom-toolbar" // Agregar clase CSS personalizada para el toolbar
      toolbarStyle={toolbarStyle} // Aplicar estilos personalizados al toolbar
    />
  );
};

export { ElementRichEditorCostume };
