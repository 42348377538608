import './ElementLoaderLazy.scss';

export const ElementLoaderLazy = () => {
    return (
        <div className='ElementLoaderLazy'>
            <div className="loader-wrapper" style={{ display: 'block' }}>
                <div className="d-flex justify-content-center align-items-center position-absolute top-50 start-50 translate-middle">
                    <div className="spinner-border text-white" role="status" />
                </div>
            </div>
        </div>
    )
};