import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { RootState } from '../../../context/shared/Infraestructure/AdapterStore';

export const RoleGuard = () => {
    const { menu } = useSelector((store: RootState) => store.auth);
    const location: Location = JSON.parse(JSON.stringify(window.location));

    const existsPath = (privilegios: typeof menu): boolean => {
        let exists: boolean = false;
        for (let row of privilegios) {
            if (!!row) {
                exists = row.permitirSubOpcion ? location.pathname.includes(`${process.env.PUBLIC_URL}/${row.ruta}`) : `${process.env.PUBLIC_URL}/${row.ruta}` === location.pathname;
                if (exists) { break; }
            }
        }
        return exists;
    }

    let valid: boolean = existsPath(menu);

    if (!valid) { valid = location.pathname === `${process.env.PUBLIC_URL}/${process.env.REACT_APP_ROUTE_PATH_MAIN}`; }
    if (!valid) { valid = location.pathname === `${process.env.PUBLIC_URL}/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_HOME}`; }

    return valid
        ? <Outlet />
        : <Navigate replace to={`/${process.env.REACT_APP_ROUTE_PATH_MAIN}`} />;
};
