import * as React from 'react';
import { Controller } from "./Infraestructure/Controller";
import { ViewMain } from "./Components/View";


const ManagerEntity = () => {
    const controller = Controller();

    React.useEffect(() => {
        controller.init();
        return () => {
        }
        // eslint-disable-next-line
    }, [controller.countProcess]);

    return (<ViewMain
        data={controller.data}
        reloadData={controller.loadData}
        globalFilter={controller.globalFilter}
        setGlobalFilter={controller.setGlobalFilter}
        home={controller.home}
        items={controller.items}
        getStatus={controller.getStatus}
        formatCurrency={controller.formatCurrency}
        visible={controller.visible}
        dialogCreateTicket={controller.dialogCreateTicket}
        onHideDialogSteps={controller.onHideDialogSteps}
        onSubmit={controller.onSubmit}
        formTicket={controller.formTicket}
        onChange={controller.onChange}
    />);
};

export default ManagerEntity;