import { AES, enc } from 'crypto-js'

export class AdapterEncrypt {
    public static encrypt(text: string, key: string, replaced: boolean = true): string {
        text = typeof text !== 'string' ? JSON.stringify(text) : text;
        let cipher = AES.encrypt(text, key);
        let encrypted: string = cipher.toString();
        if (replaced) {
            encrypted = encrypted.replace(/U2FsdGVkX1/, '\\');
            encrypted = encrypted.replace(/[+]/, '*');
        }
        return encrypted;
    }

    public static decrypt(text: string, key: string, replaced: boolean = true): string {
        if (replaced) {
            text = text.replace(/[\\]/, 'U2FsdGVkX1');
            text = text.replace(/[*]/, '+');
        }

        let bytes = AES.decrypt(text, key);
        let decrypted: string = bytes.toString(enc.Utf8);
        return decrypted;
    }

}