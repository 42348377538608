//Dependency External
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useSelector } from 'react-redux';
import { NavigateFunction, useNavigate } from 'react-router-dom';

//Adapters
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico';
import { addLoading, removeLoading } from '../../../shared/Infraestructure/SliceGenerico';
import { AdapterConfigure } from './AdapterConfigure';
import { AdapterValidator } from '../../../shared/Infraestructure/AdapterValidator';
import { RootState } from '../../../shared/Infraestructure/AdapterStore';

//Repository
import { RepositoryImplMain } from './RepositoryImplMain';
import { UseCaseNuevoProveedor } from '../Application/UseCaseNuevoProveedor';
import { useState } from 'react';

//UseCase

//Entities


export const Controller = () => {
    const { websocket, dbLocal } = useSelector((state: RootState) => state.generico);
    const dispatch: Dispatch = useDispatch();
    const navigate: NavigateFunction = useNavigate();

    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);
    const [info, setInfo] = useState<{ archivos: Array<any>; inputFileArchivoSustento: any }>({ archivos: [], inputFileArchivoSustento: null });

    const form = useFormik({
        initialValues: {
            identificacion: '',
            email: '',
            pais: { _id: '', code: '', name: '' },
            telefono: '',
        },
        // validationSchema: Yup.object({
        //     identificacion: Yup.string().required('Ingresar Número Doc. Identidad (RUT)').matches(/^[a-zA-Z0-9]{8,}$/, 'El Número Doc. Identidad es incorrecto (RUT)').min(8, `Ingresar Número Doc. Identidad (RUT)`),
        //     email: Yup.string().matches(/^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i, 'Correo Corporativo no es correcto').required('Ingresar Correo Corporativo'),
        //     pais: Yup.object({ _id: Yup.string().required('Seleccionar País') }),
        //     telefono: Yup.string().required('Ingresar Teléfono').length(9, 'Teléfono no es correcto').matches(/^[9]\d{8}$/, 'Teléfono debe empezar en 9'),
        // }),

        onSubmit: (values, formikHelpers) => { },
    });

    const onChange = (name: string, value: any) => {
        if (value === null) { return; }
        form.setFieldValue(name, value);
    };

    const onChangeValuePais = (name: string, value: any) => {
        form.setValues((values: any) => ({
            ...values,
            pais: !value?._id ? form.initialValues['pais'] : { _id: value._id, code: value.code, name: value.name },
        }));
    };

    const onClickFileArchivoSustento = () => {
        const archivos = info.archivos;
        if (archivos.filter((x: any) => x.estado === "pendiente").length >= 2) {
            AdapterGenerico.createMessage('Alerta', 'Estimado Proveedor, se ha alcanzado el límite máximo de archivos', 'warning');
            return; // Agrega un return para detener la ejecución
        }
        info.inputFileArchivoSustento.current?.click();
    };

    const onChangeFileArchivoSustento = async (files: FileList) => {
        try {
            if (files.length > 0) {
                let archivos: any = info.archivos.slice();

                let file: any = await AdapterGenerico.readFile(files[0], 5, 'PDF');
                let pdfExistente: string = archivos.find((x: any) => x.name === file.name);
                if (!!pdfExistente) { throw Error('Archivo ya cargado') };
                archivos.push({
                    ...file,
                    ix: Date.now().toString(),
                    estado: 'pendiente',
                });
                setInfo((values: any) => ({
                    ...values,
                    archivos: archivos,
                }));
            }
            else { };
        } catch (error) {
            AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning');
        }
    };

    const onClickVerArchivoSustento = async (file: any, codigo: string) => {
        try {
            dispatch(addLoading({ textLoading: '' }));
            let urlBlob: any;
            switch (file.estado) {
                case 'pendiente':
                    if (!file.base64) { throw new Error('No se encontró el archivo'); };
                    urlBlob = await AdapterGenerico.base64ToURLBlob(file.base64);
                    window.open(urlBlob, '_blank')
                    break;
                default:
                    break;
            };
            dispatch(removeLoading());
        } catch (error) {
            dispatch(removeLoading());
            AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning');
        }
    };
    const onClickEliminarArchivoSustento = async (file: any) => {
        try {
            let confirmacion = await AdapterGenerico.createMessage('Confirmación', `¿Está seguro(a) que desea eliminar este archivo?`, 'question', true,);
            if (!confirmacion) { return };
            dispatch(addLoading({ textLoading: '' }));
            let archivos: any = info.archivos.slice();
            let archivo: any = archivos.find((a: any) => a.ix === file.ix);
            archivo.estado = 'anulado';

            setInfo((values: any) => ({
                ...values,
                archivos: archivos,
            }));
            dispatch(removeLoading());
        } catch (error) {
            dispatch(removeLoading());
            AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning');
        }
    };

    const onSubmit = async (e: Event) => {
        try {
            e.preventDefault();
            e.stopPropagation();

            try { await form.submitForm(); } catch (error) { }
            try { AdapterValidator.validate(await form.validateForm()); } catch (error) { AdapterGenerico.createMessage('Incompleto', (error as Error).message, 'warning', false); return null; }

            dispatch(addLoading({ textLoading: 'Cargando...' }));

            await (new UseCaseNuevoProveedor(repository)).exec({ identificacion: form.values.identificacion, email: form.values.email, telefono: form.values.telefono, pais: form.values.pais.code, archivos: info.archivos.filter(x => x.estado === 'pendiente') });

            dispatch(removeLoading());
            form.resetForm();
            AdapterGenerico.createMessage('Exitoso', `<p>La solicitud de usuario se ha <b>realizado exitosamente</b>. En breve recibirá un correo electrónico con más información.</p>`, 'success');
            onClickLogin();
        } catch (error) {
            console.error(error);

            dispatch(removeLoading());
            AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false);
        };
    };

    const onClickLogin = () => {
        navigate(`/${process.env.REACT_APP_ROUTE_PATH_LOGIN}`, { replace: true });
    };


    return {
        form,
        info,
        setInfo,
        onSubmit,
        onClickLogin,
        onChange,
        onChangeValuePais,
        onChangeFileArchivoSustento,
        onClickFileArchivoSustento,
        onClickVerArchivoSustento,
        onClickEliminarArchivoSustento,
    };
}