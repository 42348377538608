import { RootState } from '../../../shared/Infraestructure/AdapterStore';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { RepositoryImplMain } from './RepositoryImplMain';
import { AdapterConfigure } from './AdapterConfigure';
import { useState } from 'react';
import { AdapterStorage } from '../../../shared/Infraestructure/AdapterStorage';
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico';
import { addLoading, changeSaludo, hideIconMenu, removeLoading } from '../../../shared/Infraestructure/SliceGenerico';
import { UseCaseFindByName } from '../Application/UseCaseFindByName';
import { MenuItem } from 'primereact/menuitem';
import { DtoResponseFindByName } from '../Domain/DtoResponseFindByName';
import { DtoRequesFindByName } from '../Domain/DtoRequesFindByName';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { DtoRequestInsertTicketExtranet } from '../../../shared/Domain/DtoRequestInsertTicketExtranet';
import { AdapterValidator } from '../../../shared/Infraestructure/AdapterValidator';

import { UseCaseStatusVendor } from "../Application/UseCaseStatusVendor";
import { changePermisoVariable, signOut } from "../../../shared/Infraestructure/SliceAuthentication";
import { NavigateFunction, useNavigate } from "react-router-dom";

export const Controller = () => {
    const navigate: NavigateFunction = useNavigate();
    //const { auth, user } = useSelector((state: RootState) => state.auth);
    const { generico: { websocket, dbLocal, countProcess }, auth: { user } } = useSelector((state: RootState) => state);
    const dispatch: Dispatch = useDispatch();
    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);
    const [showLanguage, setShowLanguage] = useState<boolean>(false);
    const { language } = AdapterStorage.get('language');
    const [data, setData] = useState<DtoResponseFindByName>({
        totalcount: 0,
        count: 0,
        sort: [''],
        order: [''],
        data: [],
        'content-range': ''
    });
    const [globalFilter, setGlobalFilter] = useState<string>('');
    const [visible,setVisible]=useState<boolean>(false);
    const items: MenuItem[] = [{ label: 'Tickets', icon: 'pi pi-file' }]; //items del BreadCrumb
    const home: MenuItem = { icon: 'pi pi-home', url: '/main' } //home del BreadCrumb
    const init = async () => {
        try {
            const param: any = {
                _id: user.usuario._id,
            };
            const proveedor = await new UseCaseStatusVendor(repository).exec(param);
            if (!!proveedor[0].Status.isBaja && proveedor[0].Status.isBaja == true) {
              dispatch(hideIconMenu());
              dispatch(signOut());
              dispatch(changePermisoVariable({ arrIdPaises: [], arrIdGrupos: [], arrIdDelegaciones: [], arrIdOT: [] }));
              navigate(`/${process.env.REACT_APP_ROUTE_PATH_LOGIN}`, { replace: true });
            }
            dispatch(changeSaludo(false));
            dispatch(hideIconMenu());
            dispatch(addLoading('Cargando datos...'));
            await loadData();
            dispatch(removeLoading())
        } catch (error) {
            dispatch(removeLoading())
            AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning');
        }
    };

    const loadData = async () => {
        try {
            const param: DtoRequesFindByName = {
                name: user.usuario.datosGenerales.email[0]
            }
            dispatch(addLoading({ textLoading: 'Cargando...' }));
            const response = await (new UseCaseFindByName(repository).exec(param));
            if (response === null) return;
            response.data.map((x: any) => {
                switch (x['12']) {
                    case 1:
                        x.estado = 'Nuevo';
                        break;
                    case 2:
                        x.estado = 'En procesamiento (asignado)';
                        break;
                    case 3:
                        x.estado = 'En procesamiento (planificado)';
                        break;
                    case 4:
                        x.estado = 'Pendiente';
                        break;
                    case 5:
                        x.estado = 'Resuelto';
                        break;
                    case 6:
                        x.estado = 'Cerrado';
                        break;
                    default:
                        x.estado = 'Estado desconocido';
                }
                return x;
            });
            setData(response);
        } catch (err) {
            //let error: ErrorCostume = new ErrorCostume((err as Error).message);
            //AdapterGenerico.createMessage('Alerta', error.message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }

    };
    /**
     * 
     * @param status 
     * @returns 
     * @description retorna el estado de la factura
     */
    const getStatus = (status: string) => {
        switch (status) {
            case 'Rechazado':
                return 'danger';

            case 'Aprobado':
                return 'success';

            case 'new':
                return 'info';

            case 'negotiation':
                return 'warning';

            case 'renewal':
                return null;
        }
    };
    const formatCurrency = (value: number) => {
        return value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    };
    const onHideDialogSteps=()=>{
        setVisible(false);
    }
    const dialogCreateTicket=()=>{
        setVisible(true);
    }
    const onSubmit = async (e: Event) => {
        try {
          e.preventDefault();
          e.stopPropagation();
    
          try { await formTicket.submitForm(); } catch (error) { }
          try { AdapterValidator.validate(await formTicket.validateForm()); } catch (error) { console.error(error); dispatch(removeLoading()); return null; }
    
          let confirmacion: boolean = await AdapterGenerico.createMessage('Confirmación de envío de incidencia', '¿Está seguro de que desea enviar esta incidencia?', 'question', true, '.sliderbarGLPI');
          if (!confirmacion) return;
    
          dispatch(addLoading({ textLoading: 'Cargando...' }));
    
          let params: DtoRequestInsertTicketExtranet = {
            email: user.usuario.datosGenerales.email[0],
            title: formTicket.values.title,
            message: formTicket.values.message,
            emailInfo: formTicket.values.mail,
            mobilePhoneInfo: formTicket.values.mobilePhone,
            country: user.usuario.pais.nombre,
            category: 'Extranet GIA',
            ot: user.usuario.datosTrabajo.ot.key,
            delegacion: user.usuario.datosTrabajo.delegacion.key,
          };
    
          let myHeaders = new Headers();
          myHeaders.append("request-decrypt-response", "yes");
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", "Basic TUFTVEVSOnVROWZEOE0ydFFMMktiaDdqUExGbllCYXI5UDV6eEtk");
    
          let requestOptions: any = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(params),
            redirect: 'follow'
          };
    
          let response: any = await fetch(`${process.env.REACT_APP_URL_MASTER_GLPI}/Ticket/insertExtranet`, requestOptions);
          if (!response.ok) { throw Error("Error GLPI"); };
          let json: any = await response.json();
          dispatch(removeLoading());
          await AdapterGenerico.createMessage('Exitoso', `Se ha creado el ticket número <b>${json.ticketId}</b> exitosamente`, 'success', false, '.sliderbarGLPI');
          setVisible(false);
          formTicket.resetForm();
        } catch (error) {
          dispatch(removeLoading());
          AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false, '.sliderbarGLPI');
        }
      };
      const formTicket = useFormik({
        initialValues: {
          title: '',
          message: '',
          mail: '',
          mobilePhone: '',
        },
        validationSchema: Yup.object({
          title: Yup.string().required('Ingresar Título').min(3, 'Mínimo 3 caracteres'),
          message: Yup.string().required('Ingresar Mensaje').min(3, 'Mínimo 3 caracteres'),
          mail: Yup.string().matches(/^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i, 'Correo Corporativo no es correcto').required('Ingresar Correo Corporativo'),
          mobilePhone: Yup.string().required('Ingresar Teléfono Móvil').length(9, 'Teléfono Móvil no es correcto').matches(/^[9]\d{8}$/, 'Teléfono Móvil debe empezar en 9'),
        }),
        onSubmit: (values, formikHelpers) => { },
      });
    
      const onChange = (name: string, value: any) => {
        if (value === null) { return; }
        formTicket.setFieldValue(name, value);
      };
    return {
        init,
        loadData,
        countProcess,
        data,
        globalFilter,
        setGlobalFilter,
        home,
        items,
        getStatus,
        formatCurrency,
        visible,
        onHideDialogSteps,
        dialogCreateTicket,
        onSubmit,
        formTicket,
        onChange,
    }
}