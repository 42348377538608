import { useSelector } from "react-redux";
import { RootState } from "../../../shared/Infraestructure/AdapterStore";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { AdapterConfigure } from "./AdapterConfigure";
import { RepositoryImplMain } from "./RepositoryImplMain";
import { Reception } from "../Domain/Reception";
import { ChangeEvent, useState } from "react";
import { addLoading, changeSaludo, hideIconMenu, removeLoading } from "../../../shared/Infraestructure/SliceGenerico";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { DtoRequestComparativo } from "../Domain/DtoRequestComparativo";
import { UseCaseComparativo } from "../Aplication/UseCaseComparativo";
import { Comparativo } from "../Domain/Comparativo";
import { MenuItem } from "primereact/menuitem";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { AdapterValidator } from "../../../shared/Infraestructure/AdapterValidator";
import { ColumnEvent } from "primereact/column";
import { UseCaseTipoDocumentoTributario } from "../Aplication/UseCaseTipoDocumentoTributario";
import { TipoDocumentoTributario } from "../Domain/TipoDocumentoTributario";
import { UseCaseTipoIVA } from "../Aplication/UseCaseTipoIVA";
import { TipoIVA } from "../Domain/TipoIVA";
import xml2js from 'xml2js';
import { Valorization } from "../Domain/Valorization";
import { UseCaseInsertFactura } from "../Aplication/UseCaseInsertFactura";
import { DataTableSelectionChangeEvent } from "primereact/datatable";
import { FileUploadSelectEvent } from "primereact/fileupload";
import { json } from "stream/consumers";
import { ListaControlDocumental } from '../Domain/ListaControlDocumental';
import { DtoRequestControlDocumental} from '../Domain/DtoRequestControlDocumental';
import { UseCaseListaControlDocumental } from '../Aplication/UseCaseListaControlDocumental';
import { UseCaseCargaFileControldocumental } from '../Aplication/UseCaseCargaFileControldocumental'; 
import { UseCaseEliminarFileControldocumental } from '../Aplication/UseCaseEliminarFileControldocumental'; 
import { UseCaseStatusVendor } from "../Aplication/UseCaseStatusVendor";
import { changePermisoVariable, signOut } from "../../../shared/Infraestructure/SliceAuthentication";
import { NavigateFunction, useNavigate } from "react-router-dom";
export const Controller = () => {
  
    const { generico: { websocket, dbLocal, countProcess }, auth: { user } } = useSelector((state: RootState) => state);
    const dispatch: Dispatch = useDispatch();
    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);
    const [data, setData] = useState<Array<any>>([]);
    const [pdf,setPdf] = useState<any>();
    const [dataHome, setDataHome] = useState<Array<any>>([]);
    //const [data, setData] = useState<Array<Comparativo>>([]);
    const [visibleDocument, setVisibleDocument] = useState<boolean>(false);
    const [dataText, setDataText] = useState<any>({
        textValorizacion: ''
    });
    const [selectedValorizaciones, setSelectedValorizaciones] = useState<any[] | null | any>(null);
    const [globalFilter, setGlobalFilter] = useState<string>('');
    const [tabIndex,setTabIndex] = useState<number>(0);
    const [visibleDialogSteps,setVisibleDialogSteps] = useState<boolean>(false);
    const [importeAsociado, setImporteAsociado] = useState<number>(0);
    const [tipoDocumentoTributario,setTipoDocumentoTributario]=useState<TipoDocumentoTributario[] | null>(null);
    const [tipoIVA,setTipoIVA]= useState<TipoIVA[] | null>();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [disabledImporteIva, setDisabledImporteIva] = useState<boolean>(true);
    const [facturaFile,setFacturaFile]= useState<FileUploadSelectEvent | null>();
    const [anexoFile,setAnexoFile]= useState<FileUploadSelectEvent[]>([]);
    const [ListaControlDocumental,setDataListaControlDocumental] = useState<Array<ListaControlDocumental>>([]);
    const [docuemntosCD,setdocuemntosCD]= useState<Array<any>>([]);
    const [DocumentosFile,setDocumentosFile]= useState<FileUploadSelectEvent | null>();
    const navigate: NavigateFunction = useNavigate();
    
    const init = async () => {
        try {
            const param: any = {
              _id: user.usuario._id,
            };
            const proveedor = await new UseCaseStatusVendor(repository).exec(param);
            if (!!proveedor[0].Status.isBaja && proveedor[0].Status.isBaja == true) {
              dispatch(hideIconMenu());
              dispatch(signOut());
              dispatch(changePermisoVariable({ arrIdPaises: [], arrIdGrupos: [], arrIdDelegaciones: [], arrIdOT: [] }));
              navigate(`/${process.env.REACT_APP_ROUTE_PATH_LOGIN}`, { replace: true });
            }
            dispatch(changeSaludo(false));
            dispatch(hideIconMenu());
            dispatch(addLoading('Cargando Recepciones'));
            await loadData();
            await loadTipoDocumentoTributario();
            await loadTipoIVA();


            dispatch(removeLoading());
        } catch (error) {
            dispatch(removeLoading());
            AdapterGenerico.createMessage('Alerte', (error as Error).message, 'warning');
        }
    }

    const loadData = async () => {
        try {
          dispatch(addLoading({ textLoading: 'Cargando...' }));
           const param: DtoRequestControlDocumental = {
               _id: user.usuario._id,
               pais: user.usuario.pais,
               delegacion: user.usuario.datosTrabajo.delegacion,
               ot:user.usuario.datosTrabajo.ot,
               "Supplier classification":user.usuario["Supplier classification"],
               Tipo:"Proveedores"
           }
           const response_doc = await (new UseCaseListaControlDocumental(repository).exec(param));
           if (response_doc === null) return;
           console.log("response_doc", response_doc);
           if (response_doc === null) return;
           setData(response_doc);
           dispatch(removeLoading());
        } catch (error) {
          
          dispatch(removeLoading());
        }
    }

    const loadTipoDocumentoTributario = async () => {
        try {
            const param: any = {
                // cdPais: "504"
                cdPais: user.usuario.pais.codigo,
            }
            dispatch(addLoading({ textLoading: 'Cargando...' }));
            const response = await (new UseCaseTipoDocumentoTributario(repository).exec(param));
            
            if (response === null) return;
            //await dbLocal.clearStore('Factura');
            //await dbLocal.insertDataStore([{ nameStore: 'Factura', data: response }]);
            setTipoDocumentoTributario(response);


        } catch (err) {
            //let error: ErrorCostume = new ErrorCostume((err as Error).message);
            //AdapterGenerico.createMessage('Alerta', error.message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    }

    const loadTipoIVA = async () => {
        try {
            const param: any = {
                // cdPais: "504"
                cdPais: user.usuario.pais.codigo,
            }
            dispatch(addLoading({ textLoading: 'Cargando...' }));
            const response = await (new UseCaseTipoIVA(repository).exec(param));
            
            if (response === null || undefined) return;
            //await dbLocal.clearStore('Factura');
            //await dbLocal.insertDataStore([{ nameStore: 'Factura', data: response }]);
            let tipo= response.filter((value:TipoIVA)=> value.VATBus_PostingGroup===user.usuario.datosContabilidad.VATBus.Key);
            //{props.tipoIVA.filter((value: any) => value.VATBus_PostingGroup === props.user.usuario.datosContabilidad.VATBus.Key)}
            setTipoIVA(tipo);
            
            


        } catch (err) {
            //let error: ErrorCostume = new ErrorCostume((err as Error).message);
            //AdapterGenerico.createMessage('Alerta', error.message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    }
    const loadDocumentoFacturas = async (data:any) => {
        console.log("preorden",data)
        try {
          dispatch(addLoading({ textLoading: 'Cargando...' }));
          //console.log("user", user)
          const param: DtoRequestControlDocumental = {
              _id: user.usuario._id,
              pais: {
                codigo:data.tipoIva.CDPais,
                nombre:data.tipoIva.CDPais
              },//user.usuario.pais, //Pais del el docuemtno de PreOrden
              delegacion: {
                codigo:data.datosTrabajo.Delegacion.Codigo,
                key:data.datosTrabajo.Delegacion.Codigo,
                nombre:data.datosTrabajo.Delegacion.Codigo
              },//user.usuario.datosTrabajo.delegacion,//Delegacion del el docuemtno de PreOrden
              ot:user.usuario.datosTrabajo.ot,
              "Supplier classification":user.usuario["Supplier classification"],
              Tipo:"Facturas"
          }
          //console.log("ata.datosTrabajo.Delegacion.Codigo",data.datosTrabajo.Delegacion.Codigo)
          //console.log("param", param)

          let response_doc:Array<ListaControlDocumental> = [];
          let itemsFact = {
            "IdDocumento": 0,
            "Codigo": "Factura0",
            "Documento": "Factura",
            "TipoDocumento": {
                "Code": "CD0002-3",
                "Name": "Facturas"
            },
            "Renovacion": {
                "Periocidad": "Anio",
                "Cantidad": 1
            },
            "Aviso": {
                "Periocidad": "Meses",
                "Cantidad": 1
            },
            "TiempoGracia": {
                "Periocidad": "Dias",
                "Cantidad": 15
            },
            "Obligatoriedad": "SI",
            "Users": {
                "Registry": {
                    "IdUser": 957,
                    "User": "JCARBAJALE",
                    "LastName": "CARBAJAL ELESCANO",
                    "Name": "JORGE ALEJANDRO",
                    "Perfil": "SISTEMAS"
                },
                "Update": {
                    "IdUser": 957,
                    "User": "JCARBAJALE",
                    "LastName": "CARBAJAL ELESCANO",
                    "Name": "JORGE ALEJANDRO",
                    "Perfil": "SISTEMAS"
                }
            },
            "Status": {
                "IdStatus": 1,
                "Status": "ACTIVO"
            },
            "EstadoCarga": "Pendiente",
            "EstadoVencimiento": "Pendiente",
            "FechaVencimiento": "",
            "Operacion": "",
            "FechaCarga": "",
            "Files": "",
            "Proveedor": {
                "Codigo": 205365,
                "TipoOperacion": ""
            }
          }
          let itemsAnexo = {
            "IdDocumento": 0,
            "Codigo": "Anexos0",
            "Documento": "Anexos",
            "TipoDocumento": {
                "Code": "CD0002-3",
                "Name": "Facturas"
            },
            "Renovacion": {
                "Periocidad": "Anio",
                "Cantidad": 1
            },
            "Aviso": {
                "Periocidad": "Meses",
                "Cantidad": 1
            },
            "TiempoGracia": {
                "Periocidad": "Dias",
                "Cantidad": 15
            },
            "Obligatoriedad": "NO",
            "Users": {
                "Registry": {
                    "IdUser": 957,
                    "User": "JCARBAJALE",
                    "LastName": "CARBAJAL ELESCANO",
                    "Name": "JORGE ALEJANDRO",
                    "Perfil": "SISTEMAS"
                },
                "Update": {
                    "IdUser": 957,
                    "User": "JCARBAJALE",
                    "LastName": "CARBAJAL ELESCANO",
                    "Name": "JORGE ALEJANDRO",
                    "Perfil": "SISTEMAS"
                }
            },
            "Status": {
                "IdStatus": 1,
                "Status": "ACTIVO"
            },
            "EstadoCarga": "Pendiente",
            "EstadoVencimiento": "Pendiente",
            "FechaVencimiento": "",
            "Operacion": "",
            "FechaCarga": "",
            "Files": "",
            "Proveedor": {
                "Codigo": 205365,
                "TipoOperacion": ""
            }
          }
          response_doc.push(itemsFact);
          response_doc.push(itemsAnexo);
          const response_doc_i = await (new UseCaseListaControlDocumental(repository).exec(param));
          // console.log("response_doc___", response_doc);
          response_doc_i?.map(doc=>{
            console.log("doc__________",doc)
            response_doc.push(doc)
          })

          if (response_doc === null) return;
          setData(response_doc);
          dispatch(removeLoading());
      } catch (error) {
    
      }finally {
          dispatch(removeLoading());
      }
  }
  

  const onRemoveDocuementoFactura=(e:any)=>{

    const archivoRemovido = e.file;

    const listFileDocRemove = docuemntosCD.filter((file:any) => file.name !== archivoRemovido.name)
    console.log("listFileDocRemove", listFileDocRemove)

    setdocuemntosCD(listFileDocRemove);

  }
  const onClearDocuementoFactura=(e:any)=>{
    setdocuemntosCD([]);
  }

    const formatDate = (value: string | Date) => {
        return new Date(value).toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };
    const formatCurrency = (value: number, moneda: string) => {
        return value?.toLocaleString('en-US', { style: 'currency', currency: moneda });
    };
    const openDocument = async (rowData: Reception) => {

        //const token='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJZFVzZXIiOjk0OSwiVXNlciI6IkFDUlVaRyIsInVzZXJuYW1lIjoiQUNSVVpHIiwiTGFzdE5hbWVGYXRoZXIiOiJDUlVaIiwiTGFzdE5hbWVNb3RoZXIiOiJHT05aQUxFUyIsIk5hbWUiOiJBTkRSRUpFU1VTIiwiRW1wcmVzYSI6eyJDb2RFbXByZXNhIjoiMDEiLCJDREVtcHJlc2EiOiJDMDEzIiwiRW1wcmVzYSI6IkNvYnJhIFBlcsO6IFNBIiwiR3J1cG8iOiJDT0JSQSJ9LCJEYXRvc1RyYWJham8iOnsiRGVsZWdhY2lvbiI6IjkxMDAiLCJPVCI6IjkxMDAwMDAzIiwiQXJlYSI6IiIsIkNhcmdvIjoiIn0sIlRpcG9Eb2N1bWVudG8iOnsiVGlwb0RvY3VtZW50byI6IjAxIn0sImZ1bGxuYW1lIjoiQ1JVWiBHT05aQUxFUywgQU5EUkVKRVNVUyIsIkZ1bGxOYW1lIjoiQ1JVWiBHT05aQUxFUywgQU5EUkVKRVNVUyIsIklkZW50aWZpY2FjaW9uIjoiNzE1OTU1NjIiLCJQcm9maWxlIjpbeyJJZFBlcmZpbCI6MCwiUGVyZmlsIjoiU0lOIFBFUkZJTCIsIlByaW5jaXBhbCI6IlNJIn1dLCJDaGFuZ2VTdHJ1Y3R1cmVVc2VyIjp0cnVlLCJEYXRlcyI6eyJSZWdpc3RyeSI6eyJEYXRlIjoiMjAyMy0wNi0yNlQxNzoxMjo0OC40MTJaIiwiSWREYXRlIjoyMDIzMDYyNn0sIkxvZ2luU3VjY2VzcyI6eyJEYXRlIjoiIiwiSWREYXRlIjowfSwiTG9naW5FcnJvciI6eyJEYXRlIjoiMjAyMy0wOC0xNFQxNjo0MzoyMi41MjlaIiwiSWREYXRlIjoyMDIzMDgxNH0sIkNoYW5nZVBhc3N3b3JkIjp7IkRhdGUiOiIyMDIzLTA2LTI2VDE3OjE2OjQwLjM2M1oiLCJJZERhdGUiOjIwMjMwNjI2fSwiRm9yZ290UGFzc3dvcmQiOnsiRGF0ZSI6IiIsIklkRGF0ZSI6MH0sIlJlc2V0UGFzc3dvcmQiOnsiRGF0ZSI6IiIsIklkRGF0ZSI6MH0sIkJsb2NrIjp7IkRhdGUiOiIiLCJJZERhdGUiOjB9LCJVcGRhdGUiOnsiRGF0ZSI6IjIwMjMtMDYtMjZUMTc6MTQ6MjYuNTQwWiIsIklkRGF0ZSI6MjAyMzA2MjZ9fSwiQ29udGFkb3JlcyI6eyJDaGFuZ2VQYXNzd29yZCI6MSwiTG9naW5FcnJvciI6MiwiRm9yZ290UGFzc3dvcmQiOjAsIlJlc2V0UGFzc3dvcmQiOjAsIkJsb2NrIjowLCJmaXJzdEluaXRTZXNpb24iOjF9LCJTdGF0dXMiOnsiSWRTdGF0dXMiOjEsIlN0YXR1cyI6IkFjdGl2byJ9LCJFbWFpbCI6ImFuZHJlLmNydXpAY29icmFwZXJ1LmNvbS5wZSIsIlRhc2EiOnRydWUsIkZvdG8iOiIiLCJQYWlzIjp7IkNEUGFpcyI6IjUwNCIsIkNvZGUiOiJQRSIsIk5hbWUiOiJQZXJ1In0sIkNvZGlnbyI6IjcxNTk1NTYyIiwiSWRpb21hIjoiZXMiLCJpYXQiOjE2OTI3NjM1NDcsImV4cCI6MTcxODY4MzU0N30.uodY6ggDKff0L0TOQ1o2iet7KCtdO8F_VUASxawGSjE';
        // const token ='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJZFVzZXIiOjk0OSwiVXNlciI6IkFDUlVaRyIsInVzZXJuYW1lIjoiQUNSVVpHIiwiTGFzdE5hbWVGYXRoZXIiOiJDUlVaIiwiTGFzdE5hbWVNb3RoZXIiOiJHT05aQUxFUyIsIk5hbWUiOiJBTkRSRUpFU1VTIiwiRW1wcmVzYSI6eyJDb2RFbXByZXNhIjoiMDEiLCJDREVtcHJlc2EiOiJDMDEzIiwiRW1wcmVzYSI6IkNvYnJhIFBlcsO6IFNBIiwiR3J1cG8iOiJDT0JSQSJ9LCJEYXRvc1RyYWJham8iOnsiRGVsZWdhY2lvbiI6IjkxMDAiLCJPVCI6IjkxMDAwMDAzIiwiQXJlYSI6IiIsIkNhcmdvIjoiIn0sIlRpcG9Eb2N1bWVudG8iOnsiVGlwb0RvY3VtZW50byI6IjAxIn0sImZ1bGxuYW1lIjoiQ1JVWiBHT05aQUxFUywgQU5EUkVKRVNVUyIsIkZ1bGxOYW1lIjoiQ1JVWiBHT05aQUxFUywgQU5EUkVKRVNVUyIsIklkZW50aWZpY2FjaW9uIjoiNzE1OTU1NjIiLCJQcm9maWxlIjpbeyJJZFBlcmZpbCI6MCwiUGVyZmlsIjoiU0lOIFBFUkZJTCIsIlByaW5jaXBhbCI6IlNJIn1dLCJDaGFuZ2VTdHJ1Y3R1cmVVc2VyIjp0cnVlLCJEYXRlcyI6eyJSZWdpc3RyeSI6eyJEYXRlIjoiMjAyMy0wNi0yNlQxNzoxMjo0OC40MTJaIiwiSWREYXRlIjoyMDIzMDYyNn0sIkxvZ2luU3VjY2VzcyI6eyJEYXRlIjoiIiwiSWREYXRlIjowfSwiTG9naW5FcnJvciI6eyJEYXRlIjoiMjAyMy0wNy0xN1QwODowOTowMS44MjlaIiwiSWREYXRlIjoyMDIzMDcxN30sIkNoYW5nZVBhc3N3b3JkIjp7IkRhdGUiOiIyMDIzLTA2LTI2VDE3OjE2OjQwLjM2M1oiLCJJZERhdGUiOjIwMjMwNjI2fSwiRm9yZ290UGFzc3dvcmQiOnsiRGF0ZSI6IiIsIklkRGF0ZSI6MH0sIlJlc2V0UGFzc3dvcmQiOnsiRGF0ZSI6IiIsIklkRGF0ZSI6MH0sIkJsb2NrIjp7IkRhdGUiOiIiLCJJZERhdGUiOjB9LCJVcGRhdGUiOnsiRGF0ZSI6IjIwMjMtMDYtMjZUMTc6MjI6NTQuODk1WiIsIklkRGF0ZSI6MjAyMzA2MjZ9fSwiQ29udGFkb3JlcyI6eyJDaGFuZ2VQYXNzd29yZCI6MSwiTG9naW5FcnJvciI6MiwiRm9yZ290UGFzc3dvcmQiOjAsIlJlc2V0UGFzc3dvcmQiOjAsIkJsb2NrIjowLCJmaXJzdEluaXRTZXNpb24iOjF9LCJTdGF0dXMiOnsiSWRTdGF0dXMiOjEsIlN0YXR1cyI6IkFjdGl2byJ9LCJFbWFpbCI6ImFuZHJlLmNydXpAY29icmFwZXJ1LmNvbS5wZSIsIlRhc2EiOnRydWUsIkZvdG8iOiIiLCJQYWlzIjp7IkNEUGFpcyI6IjUwNCIsIkNvZGUiOiJQRSIsIk5hbWUiOiJQZXJ1In0sIkNvZGlnbyI6IjcxNTk1NTYyIiwiSWRpb21hIjoiZXMiLCJpYXQiOjE2OTI3NjUwNjksImV4cCI6MTcxODY4NTA2OX0.kzG6q1L05M76Gbd_AAj1_zYmMO6FsiHNp3YRNyIyjf8';
        const requestOptions = {
            "method": 'POST',
            "headers": {
                'authorization': `Basic dXNlci5naWE6Z3I4cDBDMGJyNCEhJA==`,
                'request-decrypt-response': 'yes',
                'Content-Type': 'application/json',
            },
            "body": JSON.stringify({ "directory": [
                "NAVISI",
                "OPERACIONES",
                `${rowData.CodigoFactura?.split('-')[0]}`,
                //rowData.CodigoFactura,
                 `${rowData.CodigoFactura}`,
                // "O96-C0LA-95002349450",
                `${rowData?.TipoCompra?.Code==='01' ?'Albaran' : 'Valorizacion'}`
                // "Albaran"

            ],
            // "fileName": `1694111616001.PDF`, 
             "fileName":`${rowData.File?.LoadName}`,
            user:null }),
        };
        
        const urlDev = AdapterConfigure.URL_FILES_DOWNLOAD;
        const document = await fetch(urlDev, requestOptions);
        if(!document.ok){
          AdapterGenerico.createMessage('Sin Archivo', '¡Sin Archivo!', 'warning', false)
          return;
        }
        let  type = 'base64';
        let blob = await document.blob();
        let result;

                switch (type) {
                    case 'blob':
                        result = blob;
                        break;
                    case 'urlBlob':
                        result = URL.createObjectURL(blob);
                        break;
                    case 'base64':
                        result = await convertBlobToBase64(blob);
                        break;
                    default:
                        break;
                };
        
        
        setPdf(result);
        
        setVisibleDocument(true);
        

    }
    const convertBlobToBase64 = async (blob:any) => {
      return new Promise(async (resolve, reject) => {
          try {
              const reader = new FileReader;
              reader.onerror = reject;
              reader.onload = async () => {
                  let result = await reader.result?.toString() || '';
                  
                  resolve(result.replace("data:application/octet-stream;base64,", "data:application/pdf;base64,"));
                  //resolve(result);
              };
              reader.readAsDataURL(blob);
          } catch (error) {
              reject(error);
          }
      });
  };
    const onHideDocument = () => {
        setVisibleDocument(false);
    }
    const isSelectable = (data: Reception | Valorization | any) => {

        if (selectedValorizaciones === null || selectedValorizaciones === undefined || selectedValorizaciones.length === 0) return true;
        return (
            // data.CodigoFactura === selectedValorizaciones[0]?.CodigoFactura 
            // && 
            data.TipoCompra?.Code===selectedValorizaciones[0].TipoCompra.Code 
            &&
            data?.Moneda === selectedValorizaciones[0]?.Moneda);

    };
    const onHideDialogSteps = ()=>{
        setVisibleDialogSteps(false);
        formInvoice.resetForm();
        setTabIndex(0);
        setSelectedValorizaciones([]);
        setDisabled(false);
        setFacturaFile(null)
        setAnexoFile([]);
        setdocuemntosCD([]);
    }
    const items: MenuItem[] = [{ label: 'Control Documental', icon: 'pi pi-file' }];
    const home: MenuItem = { icon: 'pi pi-home', url: '/main' }

    const onIngresoFactura=()=> {
      
      setVisibleDialogSteps(true);    
      let sumImporteAsociado = selectedValorizaciones.reduce((accumulator:any, current:any) => accumulator + (current?.ImporteAsociar === undefined ? 0 : current?.ImporteAsociar), 0);

      formInvoice.resetForm();
      
      formInvoice.setValues((values:any)=> ({
          ...values,
          importeAsociado:sumImporteAsociado,
          importe:sumImporteAsociado,
          importeFactura:sumImporteAsociado,
          
      }));
      

      if(formInvoice.values.tipoIva===null){
          formInvoice.setFieldValue('importeTotal',sumImporteAsociado );
      }
      
      
    }
    /** Region de Ingreso de factura */
    const formInvoice = useFormik({
        initialValues: {
            //step 1
            importeFactura: 0,
            //step 2
            fechaEmision: new Date().toISOString().slice(0, 10),
            importe:0,
            numeroDocumentoTributario:'',
            importeIva:0,
            importeTotal:0,
            tipoDocumentoTributario:null,
            tipoIva:null,
            // numeroDocumentoTributario: '',
            // monto: 0,
            // TipoIVA: null,
            // IVA: 0,
            // montoTotal: 0,
            // observaciones: '',
            //step 2
            //importeFactura:0, pasa igual a montototal
            importeAsociado:0,
            importePendiente:0,
            detalle:[],
            datosTrabajo: '',
            observacion:'',
            CDEmpresa:'',
            

        },

        onSubmit(values, formikHelpers) {


        },
        
        validationSchema: Yup.object({
            //step 1
            importeFactura:Yup.number().when([],{is:()=> tabIndex===0, then: 
              Yup.number()
              .min(0.01, 'El importe de la factura no puede ser 0')
              // .max(Yup.ref('importeAsociar'),'la factura excede el importe ')
              .required('importe requerido').nullable()
            }),
        })
    });
    const onChange = (name: string, value: any) => {
        if (value === null) { return; }
        formInvoice.setFieldValue(name, value);
    };
    const itemsDialogSteps: MenuItem[] = [
        {
          label: 'Asociación',
          command: () => {
            setTabIndex(0);
          },
    
        },
        {
          label: 'Factura',
          command: () => {
            setTabIndex(1);
          },
        },
        // {
        //   label: 'Archivos',
        //   command: () => {
        //     props.setTabIndex(2);
        //   },
        // }
    
      ];
    const onSubmit = async (e: Event) => {
      e.stopPropagation();
      //setTabIndex(tabIndex + 1);
      if(tabIndex===0){
        try {
          if (
            formInvoice.values.importeAsociado === 0 ||
            formInvoice.values.importeTotal === 0
          ) {
            await AdapterGenerico.createMessage(
              "Alerta",
              "Importe incorrecto!",
              "warning"
            );
            return false;
          }
  
          if (!formInvoice.values.tipoIva) {
            await AdapterGenerico.createMessage(
              "Alerta",
              "Debe seleccionar un Tipo IVA!",
              "warning"
            );
            return false;
          }
  
          //Aqui se debe calcular entre l cantidad total de pendientes.
          let sumaPendientes = 0;
          for (const index in selectedValorizaciones) {
            let itemPendiente = selectedValorizaciones[index];
            //console.log("itemPendiente", itemPendiente);
            sumaPendientes += itemPendiente.Pendiente;
          }
  
          //console.log("sumaPendientes", sumaPendientes);
  
          if (formInvoice.values.importeAsociado > sumaPendientes) {
            await AdapterGenerico.createMessage(
              "Alerta",
              "El importe debe ser menor o igual al importe generado",
              "warning"
            );
            return false;
          }
          
          setTabIndex((tabIndex) => {
            return tabIndex + 1;
          });
        } catch (error) {}
        await loadDocumentoFacturas(formInvoice.values);
      }else{
        if (
          !formInvoice.values.tipoDocumentoTributario ||
          formInvoice.values.tipoDocumentoTributario == null
        ) {
          setTabIndex(tabIndex);
          await AdapterGenerico.createMessage(
            "Alerta",
            "Debe seleccionar tipo documento tributario",
            "warning"
          );
          return false;
        }

        if (
          !formInvoice.values.numeroDocumentoTributario ||
          formInvoice.values.numeroDocumentoTributario == null
        ) {
          setTabIndex(tabIndex);
          await AdapterGenerico.createMessage(
            "Alerta",
            "Debe seleccionar numero documento tributario",
            "warning"
          );
          return false;
        }

        if (
          !formInvoice.values.observacion ||
          formInvoice.values.observacion == null
        ) {
          setTabIndex(tabIndex);
          await AdapterGenerico.createMessage(
            "Alerta",
            "Debe ingresar observacion",
            "warning"
          );
          return false;
        }

        // if (!facturaFile) {
        //   setTabIndex(tabIndex);
        //   await AdapterGenerico.createMessage(
        //     "Alerta",
        //     "Debe cargar una factura.",
        //     "warning"
        //   );
        //   return false;
        // }

        console.log("ListaControlDocumental....", ListaControlDocumental);
        console.log("docuemntosCD", docuemntosCD);

        for(const doc in ListaControlDocumental){
          let obligatoriedad = ListaControlDocumental[doc].Obligatoriedad;
          let documento = ListaControlDocumental[doc].Documento;
          if(obligatoriedad==="SI"){
            let fileUp = docuemntosCD.filter(objFile=>objFile.codigo===documento);
            console.log("fileUp", fileUp);
            //alert(fileUp.length)
            if(fileUp.length===0){
              setTabIndex(tabIndex)
              await AdapterGenerico.createMessage('Alerta', 'Debe cargar una '+documento+'.', 'warning');
              return false;
            }
          }
        }
        dispatch(addLoading({ textLoading: 'Cargando...' }));

        console.log("anexoFile", anexoFile);

        const response = await (new UseCaseInsertFactura(repository)).exec({
          _idProveedor: user.usuario._id,
          importeTotal: formInvoice.values.importeTotal,
          importeIva: ""+formInvoice.values.importeIva,
          tipoIvaKey: formInvoice.values.tipoIva!==null ? formInvoice.values.tipoIva['VATProd_PostingGroup']:'',
          observacion: formInvoice.values.observacion,
          numeroDocumentoTributario: formInvoice.values.numeroDocumentoTributario,
          tipoDocumentoTributario: formInvoice.values.tipoDocumentoTributario!==null ? formInvoice.values.tipoDocumentoTributario['Codigo']:'',
          importe: ""+formInvoice.values.importeFactura,
          iva: formInvoice.values.tipoIva!==null ? "."+formInvoice.values.tipoIva['VAT'] : '',
          monedaKey: '',
          datosTrabajo:formInvoice.values.datosTrabajo,
          detalle: JSON.stringify(formInvoice.values.detalle),//formInvoice.values.detalle
          factura: facturaFile,
          anexo01: anexoFile,
          CDEmpresa: formInvoice.values.CDEmpresa,
          docuemntosCD:docuemntosCD
          // detalle:
          // , archivos: info.archivos.filter(x => x.estado === 'pendiente')
        });

        onHideDialogSteps();
        formInvoice.resetForm();

        await loadData();
        dispatch(removeLoading());

        return;

      }
        

      // try {
      //   //e.preventDefault();
      //   //e.stopPropagation();
      //   // try {
      //   //   await formInvoice.submitForm();
      //   // } catch (error) {}
      //   // try { AdapterValidator.validate(await formInvoice.validateForm()); } catch (error) { AdapterGenerico.createMessage('Incompleto', (error as Error).message, 'warning', false); return null; }


      //     if (tabIndex === itemsDialogSteps.length - 1) {
      //         //Se debe llamar los documentos de tipo factira por pais y delegacion...
      //         await loadDocumentoFacturas(formInvoice.values);


      //       console.log("ListaControlDocumental....", ListaControlDocumental);
      //       console.log("docuemntosCD", docuemntosCD);

      //       for(const doc in ListaControlDocumental){
      //         let obligatoriedad = ListaControlDocumental[doc].Obligatoriedad;
      //         let documento = ListaControlDocumental[doc].Documento;
      //         if(obligatoriedad==="SI"){
      //           let fileUp = docuemntosCD.filter(objFile=>objFile.codigo===documento);
      //           console.log("fileUp", fileUp);
      //           if(fileUp.length===0){
      //             setTabIndex(tabIndex)
      //             await AdapterGenerico.createMessage('Alerta', 'Debe cargar una '+documento+'.', 'warning');
      //             return false;
      //           }
      //         }
      //       }

      //       // if(anexoFile.length===0){
      //       //   setTabIndex(tabIndex)
      //       //   await AdapterGenerico.createMessage('Alerta', 'Debe cargar los anexos.', 'warning');
      //       //   return;
      //       // }

      //       dispatch(addLoading({ textLoading: 'Cargando...' }));

      //       const response = await (new UseCaseInsertFactura(repository)).exec({
      //         _idProveedor: user.usuario._id,
      //         importeTotal: formInvoice.values.importeTotal,
      //         importeIva: ""+formInvoice.values.importeIva,
      //         tipoIvaKey: formInvoice.values.tipoIva!==null ? formInvoice.values.tipoIva['VATProd_PostingGroup']:'',
      //         observacion: formInvoice.values.observacion,
      //         numeroDocumentoTributario: formInvoice.values.numeroDocumentoTributario,
      //         tipoDocumentoTributario: formInvoice.values.tipoDocumentoTributario!==null ? formInvoice.values.tipoDocumentoTributario['Codigo']:'',
      //         importe: ""+formInvoice.values.importeFactura,
      //         iva: formInvoice.values.tipoIva!==null ? "."+formInvoice.values.tipoIva['VAT'] : '',
      //         monedaKey: '',
      //         datosTrabajo:formInvoice.values.datosTrabajo,
      //         detalle: JSON.stringify(formInvoice.values.detalle),//formInvoice.values.detalle
      //         factura: facturaFile,
      //         anexo01: anexoFile,
      //         CDEmpresa: formInvoice.values.CDEmpresa,
      //         docuemntosCD:docuemntosCD
      //         // detalle:
      //         // , archivos: info.archivos.filter(x => x.estado === 'pendiente')
      //       });

      //       onHideDialogSteps();
      //       formInvoice.resetForm();

      //       await loadData();
      //       dispatch(removeLoading());

      //       return false;
      //     }

      //     //       // setTabIndex(tabIndex + 1);
      //   } catch (error) {}
      // } catch (error) {}
    };
    const onChangeTipoIva = (e:any) => {
        
        setDisabledImporteIva(true);
         formInvoice.setValues((values:any)=> ({
             ...values,
             tipoIva:e.value,
             importeIva: (e.value.VAT * formInvoice.values.importe/100),
             importeTotal: (formInvoice.values.importe + (e.value.VAT * formInvoice.values.importe/100))
             
        //     importeAsociado:sumImporteAsociado,
        //     importe:sumImporteAsociado,
        //     importeFactura:sumImporteAsociado,
            
        }));
        if(e.value.CodSunat==='' && e.value.VATProd_PostingGroup==='OTROS'){
          
          setDisabledImporteIva(false);
        }
        if(e.value.CodSunat==='' && e.value.VATProd_PostingGroup==='EXONERADO'){
          
          setDisabledImporteIva(true);
         formInvoice.setValues((values:any)=> ({
             ...values,
             tipoIva:e.value,
             importeIva: 0,
             importeTotal: (formInvoice.values.importe + (e.value.VAT * formInvoice.values.importe/100))
             
        //     importeAsociado:sumImporteAsociado,
        //     importe:sumImporteAsociado,
        //     importeFactura:sumImporteAsociado,
            
        }));
        }
    }
    /** export excel */
    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
          const worksheet = xlsx.utils.json_to_sheet(transformDataExcel(selectedValorizaciones));
          const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
          const excelBuffer = xlsx.write(workbook, {
            bookType: 'xlsx',
            type: 'array'
          });
    
          saveAsExcelFile(excelBuffer, 'products');
        });
    };
    const saveAsExcelFile = (buffer: any, fileName: any) => {
      import('file-saver').then((module) => {
  
        if (module && module.default) {
          let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
          let EXCEL_EXTENSION = '.xlsx';
          const data = new Blob([buffer], {
            type: EXCEL_TYPE
          });
  
          module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        }
      });
    };
    const transformDataExcel = (valorizaciones:Reception[])=>{
      let newValorizations:any[] = valorizaciones.map(value=>{
        

        
        let Fecha_:any = new Date();
        if(value.TipoCompra?.Code==="01"){
          Fecha_ =value.Date?.Date;
        }
        if(value.TipoCompra?.Code==="02"){
          Fecha_ = value.Dates?.Valorizacion.Date;
        }

        
        let sumaPendientes = 0;
        for(const index in value.FacturaProveedor){
          let itemPendiente = value.FacturaProveedor[index];
          console.log("itemPendiente*******", itemPendiente);
          sumaPendientes+=itemPendiente.importeAsociado;
        }
        console.log("sumaPendientes*****",sumaPendientes)
        return {
            Fecha: formatDate(Fecha_),
            //"Codigo Valorizacion": value.CodeValorizacion,
            Observacion: value.Observacion,
            Importe: value.Importe-sumaPendientes,
            "Importe Asociar": value.ImporteAsociar
            
        }
      });
      return newValorizations;
      
    }
    const onCellEditCompleteImporte = (e: ColumnEvent) => {
      let { rowData, newValue, field, originalEvent: event } = e;
      

      let sumImporteAsociado=0;
      let transform:any=[];
      switch (field) {
        case 'ImporteAsociar':
          //props.formInvoice.setFieldValue('importeAsociado',newValue),
          setImporteAsociado(newValue);

          rowData[field] = newValue;

          transform = formInvoice.values.detalle?.map((value:any)=>{
            if(value?.Code===rowData.Code){
              return {
                ...value,
                Importe: newValue,
              }
            }else{
              return value;
            }
          });
          
          
          break;
        default:
          if (newValue.trim().length > 0) rowData[field] = newValue;
      }
      sumImporteAsociado = selectedValorizaciones.reduce((accumulator:any, current:any) => accumulator + (current?.ImporteAsociar === undefined ? 0 : current?.ImporteAsociar), 0);
      formInvoice.setValues((values:any)=> ({
          ...values,
          importeAsociado:sumImporteAsociado,
          importe:sumImporteAsociado,
          importeFactura:sumImporteAsociado,
          importeTotal:(formInvoice.values.tipoIva===null ? sumImporteAsociado : (sumImporteAsociado + (formInvoice.values.tipoIva['VAT'] * sumImporteAsociado/100))),
          importeIva: (formInvoice.values.tipoIva===null ? 0 : (formInvoice.values.tipoIva['VAT']*sumImporteAsociado/100)),
          detalle: transform,
          
      }));
      
  
      
      // //setImporteAsociado(sumImporteAsociado);
      
    }
    /**
     *
     */
    const toCapitalize = (s: any) => {
      return s.charAt(0).toUpperCase() + s.slice(1);
    }
    const importExcel = (e: any) => {
      
      let file = e.files[0];
      import('xlsx').then(xlsx => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const wb = xlsx.read(e?.target.result, { type: 'array' });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = xlsx.utils.sheet_to_json(ws, { header: 1 });
  
          // Prepare DataTable
          const cols: any = data[0];
          data.shift();
  

          let transform:any=[];

          let _importedCols = cols.map((col: any) => ({ field: col, header: toCapitalize(col) }));

          let _importedData = data.map((d: any) => {

            

            return cols.reduce((obj: any, c: any, i: any) => {
              if(c==="Codigo Valorizacion"){
                  c="CodeValorizacion";
                }
                if(c==="Importe Asociar"){
                  c="ImporteAsociar"
                }

              obj[c] = d[i];

              return obj;
              
              
            }, {});
          });
          if (_importedData.length !== selectedValorizaciones.length) {
            //fileUploadRefXML.current?.clear();
  
            //props.toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Exceso de data en el excel', life: 3000 });
            return;
  
          } /*else if (_importedData.map((value: any) => {
            
          })) {
            fileUploadRefXML.current?.clear();
  
            toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Negativos de data en el excel', life: 3000 });
            //return;
          }*/
  
          //setImportedCols(_importedCols);
          //setContratos(_importedData);
          //setImportedData(_importedData);

          let detalleTransform:any = [];
          
          let newImported = selectedValorizaciones.map((value:Reception,index:number)=>{


            detalleTransform.push({
              "comparativo": value.CodigoFactura,
              "Code": value.Code,
              "Importe": _importedData[index].ImporteAsociar
            })
            
            return {
              ...value,
              ImporteAsociar:_importedData[index].ImporteAsociar
            }
          })
          

          

          let sumImporteAsociado = newImported.reduce((accumulator:any, current:any) => accumulator + (current?.ImporteAsociar === undefined ? 0 : current?.ImporteAsociar), 0);

          formInvoice.setValues((values:any)=> ({
              ...values,
              importeAsociado:sumImporteAsociado,
              importe:sumImporteAsociado,
              importeFactura:sumImporteAsociado,
              importeTotal:(formInvoice.values.tipoIva===null ? sumImporteAsociado : (sumImporteAsociado + (formInvoice.values.tipoIva['VAT'] * sumImporteAsociado/100))),
              importeIva: (formInvoice.values.tipoIva===null ? 0 : (formInvoice.values.tipoIva['VAT']*sumImporteAsociado/100)),
              detalle: detalleTransform,
              
          }));
          setSelectedValorizaciones(newImported);
        };
        e.files = null;
        reader.readAsArrayBuffer(file);
        
      });
    }
    const handleFileUpload2 = (e: ChangeEvent<HTMLInputElement>) => {

      if (e.target.files) {


        let reader = new FileReader();
        reader.readAsText(e.target.files[0], 'windows-1251');
        reader.onloadend = async () => {
          try {
            const parser = new xml2js.Parser();
            
            parser.parseString(reader.result as string, function (err, result) {

              if (result["Invoice"]["cbc:UBLVersionID"] != "2.1" || result["Invoice"]["cbc:UBLVersionID"] != "2.0") {
                //props.toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Versión UBL no admitido', life: 3000 });
                return;
              }
  
              let companyRuc = result["Invoice"]["cac:AccountingCustomerParty"][0]["cac:Party"][0]["cac:PartyIdentification"][0]["cbc:ID"][0]["_"];
  
              //let tipoIva=result["Invoice"]['cac:TaxTotal'][0]["cac:TaxSubtotal"][0]['cac:TaxCategory'][0]['cac:TaxScheme'][0]['cbc:ID'][0]['_'];
              let tipoIva:any = tipoIVA?.filter((dat: any) => (dat.CodSunat === result["Invoice"]["cac:TaxTotal"][0]["cac:TaxSubtotal"][0]["cac:TaxCategory"][0]["cac:TaxScheme"][0]["cbc:ID"][0]["_"]) && (dat.VATBus_PostingGroup === user.usuario.datosContabilidad.VATBus.Key) && dat.VAT===18);

              if(tipoIva.length<0){
                  return;
              }
              let iva = result["Invoice"]["cac:TaxTotal"][0]["cac:TaxSubtotal"][0]["cbc:TaxAmount"][0]["_"];

              if(iva===null){
                  return;
              }
              let importe = result["Invoice"]["cac:TaxTotal"][0]["cac:TaxSubtotal"][0]["cbc:TaxableAmount"][0]["_"];
              if(importe===null){
                  return;
              }
              let importeTotal = result["Invoice"]["cac:LegalMonetaryTotal"][0]["cbc:PayableAmount"][0]["_"];
              if(importeTotal ===null){
                  return;
              }

              let tipoMoneda = result["Invoice"]["cbc:DocumentCurrencyCode"][0]["_"];
              if(tipoMoneda===null){
                  return;
              }
              let serieDocumentoTributario = (result["Invoice"]["cbc:ID"])[0].split("-")[0];

              let documentoTributario = result["Invoice"]["cbc:ID"][0];

              

              
              
              let nroDocumentoTributario = (result["Invoice"]["cbc:ID"])[0].split("-")[1];

              let fechaEmision = result["Invoice"]["cbc:IssueDate"][0];

              formInvoice.setValues((values:any)=> ({
                  ...values,
                  numeroDocumentoTributario: documentoTributario,
                  importeIva: iva,
                  importeTotal: importeTotal,
                  importe: importe,
                  fechaEmision: fechaEmision,
                  tipoIva: tipoIva[0],
                  importeFactura: importe,
                  
              }));
              setDisabled(true);

            });


          } catch (error) {
  
          }
        }
      }
  
  
    }
    const onChangeValueImporteIva = (e:any) => {

      formInvoice.setValues((values:any)=> ({
        ...values,
        importeTotal: (e.value + formInvoice.values.importe),
    }));
    }


    const onSelectionChange = (e: DataTableSelectionChangeEvent< any[]>)=> {
      let detalleTransform:any = [];
      


      e.value?.map((value:any)=> {
        detalleTransform.push({
          "comparativo": value.CodigoFactura,
          "Code": value.Code,
          "Importe": value.Pendiente
        })
      });
      formInvoice.setValues((values:any)=> ({
        ...values,
        detalle: detalleTransform,
        datosTrabajo: e?.value[0]?.DatosTrabajo,
        CDEmpresa:e?.value[0]?.CDEmpresa,
      }));

      setSelectedValorizaciones(e.value);


    }

    const onClearAnexo=(e:any)=>{
      setAnexoFile([]);
    }

    const onRemoveAnexo=(e:any)=>{

      const archivoRemovido = e.file;

      const listFileRemove = anexoFile.filter((file:any) => file.name !== archivoRemovido.name)

      setAnexoFile(listFileRemove);

      
    }

    const onSelectAnexo = (e: any) => {
      let listaFile:any = [];

      console.log("filexxxx",e.length)
      
      for (const file of e) {
        console.log("file****",file)
        if (typeof file === "object") {
          if (file.size <= 50000000) {
            listaFile.push(file);
          }
        }
      }

      if (anexoFile.length > 0) {
        for (const file in anexoFile) {
          if (typeof anexoFile[file] === "object") {
            listaFile.push(anexoFile[file]);
          }
        }
      }
      console.log("onSelectAnexo listaFile", listaFile)

      setAnexoFile(listaFile);
    };

    const onSelectDocuementoFactura=(e:any,documento:any)=>{

        // Obtener el archivo seleccionado
        const file:FileUploadSelectEvent = e.files[0];
        const size =e.files[0].size;
        if(size<=50000000){
          let listaFile = [];

          let items = {
            file:file,
            codigo:documento
          }
  
          listaFile.push(items)
          
          if(docuemntosCD.length>0){
            for(const file in docuemntosCD){
              listaFile.push(docuemntosCD[file]);
            }
          }
          console.log("cool",listaFile)
          setdocuemntosCD(listaFile);
        }else{
          console.log("cool","maximo 50000000")
        }

    }

    const fileToBlob = async (file: File) => {
        const blob = file.slice(0, file.size, file.type);
        return blob;
    }

    const verDocumento = async (rowData: any) => {

      try {
          dispatch(addLoading({ textLoading: 'Cargando...' }));
          const requestOptions = {
              "method": 'POST',
              "headers": {
                  'authorization': `Basic dXNlci5naWE6Z3I4cDBDMGJyNCEhJA==`,
                  'request-decrypt-response': 'yes',
                  'Content-Type': 'application/json',
              },
              "body": JSON.stringify({ "directory": [
                  "NAVISI",
                  "OPERACIONES",          
                  `${(rowData.Proveedor.TipoOperacion==="")?"0102":rowData.Proveedor.TipoOperacion}`,
                  `${rowData.Proveedor.Codigo}`,
                   `${rowData.Codigo}`,
  
              ],
              "fileName":`${rowData.Files.loadName}`,
              user:null }),
          };
          const urlDev = AdapterConfigure.URL_FILES_DOWNLOAD;
          const document = await fetch(urlDev, requestOptions);
          if(!document.ok){
            AdapterGenerico.createMessage('Sin Archivo', '¡Sin Archivo!', 'warning', false)
            return;
          }
          let  type = 'base64';
          let blob = await document.blob();
          let result;
  
                  switch (type) {
                      case 'blob':
                          result = blob;
                          break;
                      case 'urlBlob':
                          result = URL.createObjectURL(blob);
                          break;
                      case 'base64':
                          result = await convertBlobToBase64(blob);
                          break;
                      default:
                          break;
                  };
          
                  console.log("result****",result)
          setPdf(result);
  
          setVisibleDocument(true);
      } catch (error) {
          
      }finally{
          dispatch(removeLoading());
      }
  }
  const CargarFileControlDocumental = async (file:any,parametroCodigo:string,parametroDocumento:any) => {
    try {
      const param: any = {
        _id: user.usuario._id,
      };
      const proveedor = await new UseCaseStatusVendor(repository).exec(param);
      if (!!proveedor[0].Status.isBaja && proveedor[0].Status.isBaja == true) {
        dispatch(hideIconMenu());
        dispatch(signOut());
        dispatch(changePermisoVariable({ arrIdPaises: [], arrIdGrupos: [], arrIdDelegaciones: [], arrIdOT: [] }));
        navigate(`/${process.env.REACT_APP_ROUTE_PATH_LOGIN}`, { replace: true });

      }else{
        dispatch(addLoading({ textLoading: 'Cargando...' }));
        setDocumentosFile(file);
        //console.log("parametroCodigo", parametroCodigo)
        await (new UseCaseCargaFileControldocumental(repository)).exec({ 
            _id: user.usuario._id,
            documento: file,
            NombreDocumento:parametroDocumento,
            CodigoDocumento:parametroCodigo
        });
        await loadData();
      }

    } catch (error) {
        
    }finally{
        dispatch(removeLoading());
    }

};


const eliminarDocumento = async (data:any) => {
  try {
    const param: any = {
      _id: user.usuario._id,
    };
    const proveedor = await new UseCaseStatusVendor(repository).exec(param);

    if (!!proveedor[0].Status.isBaja && proveedor[0].Status.isBaja == true) {
      
      dispatch(hideIconMenu());
      dispatch(signOut());
      dispatch(changePermisoVariable({ arrIdPaises: [], arrIdGrupos: [], arrIdDelegaciones: [], arrIdOT: [] }));
      navigate(`/${process.env.REACT_APP_ROUTE_PATH_LOGIN}`, { replace: true });

    }else{
      let confirmacion: boolean = await AdapterGenerico.createMessage('Confirmación', '¿Está seguro de que desea eliminar este documento?', 'question', true, '.sliderbarGLPI');
      if (!confirmacion) return;
      dispatch(addLoading({ textLoading: 'Cargando...' }));
      await (new UseCaseEliminarFileControldocumental(repository)).exec({ 
          _id: user.usuario._id,
          CodigoDocumento:data.Codigo
      });
      await loadData();
    }
  } catch (error) {
      
  }finally{
     dispatch(removeLoading());
  }

};

const AlertSizeFileUpload = () => {
  AdapterGenerico.createMessage('Alerta', "El Archivo debe pesar maximo 50M!", 'warning', false);
};

const AlertTypeFileUpload = () => {
  AdapterGenerico.createMessage('Alerta', "Debe subir un archivo PDF!", 'warning', false);
};

  
    return {
        init,
        countProcess,
        data,
        formatDate,
        formatCurrency,
        openDocument,
        visibleDocument,
        onHideDocument,
        dataText,
        setSelectedValorizaciones,
        selectedValorizaciones,
        globalFilter,
        setGlobalFilter,
        isSelectable,
        tabIndex,
        setTabIndex,
        onHideDialogSteps,
        visibleDialogSteps,
        items,
        home,
        onIngresoFactura,
        formInvoice,
        onChange,
        importeAsociado,
        setImporteAsociado,
        onSubmit,
        exportExcel,
        tipoDocumentoTributario,
        tipoIVA,
        onChangeTipoIva,
        onCellEditCompleteImporte,
        importExcel,
        handleFileUpload2,
        disabled,
        itemsDialogSteps,
        disabledImporteIva,
        onChangeValueImporteIva,
        onSelectionChange,
        setFacturaFile,
        setAnexoFile,
        onSelectAnexo,
        loadData,
        pdf,
        onRemoveAnexo,
        onClearAnexo,
        ListaControlDocumental,
        onSelectDocuementoFactura,
        onRemoveDocuementoFactura,
        onClearDocuementoFactura,
        verDocumento,
        CargarFileControlDocumental,
        setDocumentosFile,
        eliminarDocumento,
        AlertSizeFileUpload,
        AlertTypeFileUpload,
    }
}

