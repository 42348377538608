import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
interface propsCardDashboard{
    background: 'bg-gia'|'bg-blue-500' | 'bg-purple-500' | 'bg-indigo-500' | 'bg-orange-500' | 'bg-green-500' | 'bg-yellow-500' | 'bg-cyan-500' | 'bg-pink-500' | 'bg-teal-500'| 'bg-white',
    total?: number | string;
    title?: string;
    icon:string;
    url?:string;
}
const CardDashboard = (props:propsCardDashboard) => {
  const navigate = useNavigate();
  return (
    <div className={`p-3 text-center ${props.background} border-round`}>
        <span className={`inline-flex justify-content-center align-items-center ${isNaN(Number(props.background.split('-')[2])+100)?'bg-primary-400': props.background.split('-')[0]+'-'+props.background.split('-')[1]+'-'+(Number(props.background.split('-')[2])+100)} border-circle mb-3`} style={{width: '49px', height: '49px'}}>
            <i className={`${props.icon} text-xl text-white`}></i>
        </span>
        <div className='text-2xl font-medium text-white mb-2'>
            {props.total}
        </div>
        <NavLink 
          to={props.url===undefined ? '' : props.url} 
          className={`${props.background==='bg-gia'?'text-blue-100':`text-${props.background.split('-')[1]}-100`} font-medium cursor-pointer`}>
          {props.title}
        </NavLink>

    </div>
  )
}

export default CardDashboard
