import './Style.scss';

import logo from '../../../../assets/img/IconosAppGIAMovil/logo.svg';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';
import esFlag from '../../../../assets/img/es.png';
import usFlag from '../../../../assets/img/us.png';
import { useState } from 'react';
import ElementModalCostume from '../../../shared/Components/ElementModalCostume';
import { Box, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { Card } from 'primereact/card';
import { Row, Button, Form, Col } from 'react-bootstrap';
import { ElementInputFormCostume } from '../../../shared/Components/ElementInputFormCostume';
//Imagenes

interface PropsViewMain {
    formLogin: { values: any; touched: any; errors: any; handleBlur: Function; };
    onChange: Function;
    onSubmit: Function;
    showPassword: boolean;
    setShowPassword: Function;
    onClickRecuperarPassword: Function;
    onClickRegisterRDI: Function;
    language?: string;
    onChangeLanguage?: Function;
};

export const ViewMain = (props: PropsViewMain) => {
    const flags = {
        es: esFlag,
        en: usFlag,
    };
    const languageTranslate = LanguageTranslate();
    const [showModal, setModal] = useState(false);

    return (
        <div className="ViewMasterLogin">
            <div className="fondo"></div>
            <div className="login-block">
                <div className='flex flex-column justify-content-center align-items-center text-center'>
                    <img src={logo} alt="logo.png" width={80} />
                    <span className='fw-bold mt-2' style={{ fontSize: 16 }}>Gestor Integral de Administración</span>
                    <span className='fw-bold mt-1' style={{ fontSize: 16 }}>Proveedores</span>
                </div>
                <div className='mt-1 flex justify-content-center w-full'>
                    <div className='sm:w-full md:w-4'>
                        <Card className='rounded-4 mt-5' style={{ padding: "10px" }} >
                            <form noValidate onSubmit={(evt) => { props.onSubmit(evt); }}>
                                <Row className="mb-1" xl={12}>
                                        <ElementInputFormCostume
                                            classForm={"mb-2"}
                                            classLabel={'fw-bold'}
                                            sm={12} md={12} lg={12}
                                            type={'text'}
                                            name={'username'}
                                            value={props.formLogin.values['username']}
                                            text={languageTranslate.moduloLogin.txtUsuario}
                                            max={11}
                                            upper={false}
                                            size={'lg'}
                                            onChange={props.onChange}
                                            onBlur={props.formLogin.handleBlur}
                                            isValid={!!props.formLogin.touched['username'] && !props.formLogin.errors['username']}
                                            isInvalid={props.formLogin.touched['username'] && !!props.formLogin.errors['username']}
                                            invalid={props.formLogin.touched['username'] && props.formLogin.errors['username']}
                                            childrenPrepend={
                                                <Button className="btn-sm text-white" variant="primary">
                                                    <i className='fa fa-user' aria-hidden="true" />
                                                </Button>
                                            }
                                        />
                                    
                                    </Row>
                                    <Row>
                                    <ElementInputFormCostume
                                        classForm={"mb-2"}
                                        classLabel={'fw-bold'}
                                        sm={12} md={12} lg={12}
                                        name={"password"}
                                        max={20}
                                        text={languageTranslate.moduloLogin.txtPassword}
                                        type={props.showPassword ? 'text' : 'password'}
                                        upper={false}
                                        size={'lg'}
                                        onBlur={props.formLogin.handleBlur}
                                        value={props.formLogin.values['password']}
                                        onChange={props.onChange}
                                        isValid={!!props.formLogin.touched['password'] && !props.formLogin.errors['password']}
                                        isInvalid={props.formLogin.touched['password'] && !!props.formLogin.errors['password']}
                                        invalid={props.formLogin.touched['password'] && props.formLogin.errors['password']}
                                        childrenPrepend={
                                            <Button className="btn-sm text-white" variant="primary">
                                                <i className='fa fa-key' aria-hidden="true" />
                                            </Button>
                                        }
                                        childrenPostpend={
                                            <Button className="btn-sm text-white" variant="primary" onClick={(e) => { props.setShowPassword(!props.showPassword) }} style={{ cursor: "pointer" }}>
                                                <i className={props.showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'} aria-hidden="true" />
                                            </Button>
                                        }
                                    />
                                </Row>

                                <Form.Group className="mb-2">
                                    <Form.Check
                                        id='inputCheckboxRecordar'
                                        className="mb-3"
                                        type="checkbox"
                                        style={{ fontSize: "12px" }}
                                        label={languageTranslate.moduloLogin.chkRecordarUsuario}
                                        checked={props.formLogin.values['recordar']}
                                        onChange={(evt) => props.onChange('recordar', evt.target.checked)}
                                    />
                                </Form.Group>

                                <Row className="mb-2">
                                    <Button variant="primary" className="text-white" type="submit">{languageTranslate.moduloLogin.btnLogin}</Button>
                                </Row>
                            </form>
                            <div className='flex justify-content-center mt-3'>
                                <div>
                                    {
                                        <Link to={'/recuperarPassword'} className="text-primary opcionLogin" style={{ fontWeight: 'bold', fontSize: "13px" }}>{languageTranslate.moduloLogin.textoOlvidoContrasenia}</Link>
                                    }
                                </div>
                            </div>

                            <div className='flex justify-content-center mt-3'>
                                <div>
                                    {
                                        // <Link to={'/nuevoProveedor'} className="text-primary opcionLogin" style={{ fontWeight: 'bold', fontSize: "13px" }}>{languageTranslate.moduloLogin.textoNuevoProveedor}</Link>
                                    }
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </div >
    )
};