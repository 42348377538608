import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
interface propsCardDashboardDoc{
    background: 'bg-gia'|'bg-blue-500'|'bg-purple-500'|'bg-indigo-500'|'bg-orange-500'|'bg-green-500'|'bg-yellow-500'|'bg-cyan-500'| 'bg-pink-500'|'bg-teal-500'|'bg-white',
    total?: number | string;
    title?: string;
    icon:string;
    url?:string;
    data:any;
    visibleDialogSteps: boolean;
    onHideDialogSteps: Function;
    verControlDocumental:Function;
}
const CardDashboardDoc = (props:propsCardDashboardDoc) => {

  //const limitedData = props.data.slice(0, 4);
  return (
    <div className={`p-3 text-center ${props.background} border-round`}>
    <span className={`inline-flex justify-content-center align-items-center ${isNaN(Number(props.background.split('-')[2])+100)?'bg-primary-400': props.background.split('-')[0]+'-'+props.background.split('-')[1]+'-'+(Number(props.background.split('-')[2])+100)} border-circle mb-3`} style={{width: '49px', height: '49px'}}>
        <i className={`${props.icon} text-xl text-white`}></i>
    </span>
    <div className='text-2xl font-medium text-white mb-2'>
        {props.data.length}
    </div>
    <NavLink 
      to={props.url===undefined ? '' : props.url} 
      className={`${props.background==='bg-gia'?'text-blue-100':`text-${props.background.split('-')[1]}-100`} font-medium cursor-pointer`}>
      {props.title}
    </NavLink>

</div>
    //   <div className={`p-3 text-center border-round`} style={{ background: '#D8E1FF', height:'100%' }}>
    //       <ul style={{ listStyleType: 'none', padding: 0, height:'65%' }}>
    //           {limitedData.map((item: any, index: number) => (
    //               <li  className={`circle-list-${item.EstadoVencimiento}`} key={index} style={{ marginBottom: '0.05rem' }}>
    //                   <div className="text-left">{item.Documento}</div>
    //               </li>
    //           ))}
    //       </ul>
    //       {/* {props.data.length > 3 && ( */}
    //           <NavLink to="#" className={` font-medium cursor-pointer`} onClick= {()=> props.verControlDocumental()}>
    //               Control Documental
    //           </NavLink>
    //       {/* )} */}
    //   </div>
               
  );

}

export default CardDashboardDoc
