
import { RepositoryMain } from "../Domain/RepositoryMain";
import { TipoIVA } from "../Domain/TipoIVA";

export class UseCaseTipoIVA {
   private repository: RepositoryMain;
   
   constructor (_repository: RepositoryMain){
    this.repository = _repository;
   }

   public async exec(params: any): Promise<TipoIVA[] | null>{
    return await this._exec(params);
   }

   public async _exec(params: any): Promise<TipoIVA[] | null>{
      return await this.repository.getTipoIVA(params);
   }

}