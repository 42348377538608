import { Dispatch } from "redux";
import { EntityFindGenerico } from "../Domain/EntityFindGenerico";
import { RepositoryGenerico } from "../Domain/RepositoryGenerico";
import { AdapterIndexedDB } from "./AdapterIndexedDB";
import { AdapterService } from "./AdapterService";
import { AdapterWebSocket } from "./AdapterWebSocket";

export abstract class RepositoryImplGenerico<T> implements RepositoryGenerico<T> {
    protected websocket: AdapterWebSocket;
    protected dbLocal: AdapterIndexedDB;
    protected dispatch: Dispatch;
    protected service: AdapterService;
    protected schema: string;
    protected entity: string;
    protected urlBase: string;
    protected data: Array<T>;

    constructor(websocket: AdapterWebSocket, dbLocal: AdapterIndexedDB, dispatch: Dispatch, schema: string, entity: string, urlBase: string = process.env.REACT_APP_URL_MASTER || '', pathService: string = process.env.REACT_APP_URL_MASTER_SERVICES || '', pathFiles: string = process.env.REACT_APP_URL_MASTER_FILES || '' ) {
        this.websocket = websocket;
        this.dbLocal = dbLocal;
        this.dispatch = dispatch;

        this.schema = schema;
        this.entity = entity;

        this.urlBase = `${urlBase}/${pathService}`;

        this.service = new AdapterService(dispatch);

        this.data = [];
    }

    public async find(params: EntityFindGenerico): Promise<Array<T>> {
        let response: Array<T> | T | null = await this.service.bgCall<T>("POST", `${this.urlBase}/${this.schema}/${this.entity}/find`, JSON.stringify(params), "bearer", "json", 'json', {}, 2);
        response = response === null ? [] : !Array.isArray(response) ? [response] : response;
        return response;
    }

    public async saveOne(obj: T): Promise<T | null> {
        let response: Array<T> | T | null = await this.service.bgCall<T>("POST", `${this.urlBase}/${this.schema}/${this.entity}/save`, JSON.stringify(obj), "bearer", "json", 'json', {}, 2);
        response = response as T;
        return response;
    }

    public async saveMany(obj: Array<T>): Promise<Array<T>> {
        let response: Array<T> | T | null = await this.service.bgCall<T>("POST", `${this.urlBase}/${this.schema}/${this.entity}/save`, JSON.stringify(obj), "bearer", "json", 'json', {}, 2);
        response = response as Array<T>;
        return response;
    }

    public async update(filter: object, update: object): Promise<Array<T>> {
        let response: Array<T> | T | null = await this.service.bgCall<T>("PATCH", `${this.urlBase}/${this.schema}/${this.entity}/update`, JSON.stringify({ filter, update }), "bearer", "json", 'json', {}, 2);
        response = response as Array<T>;
        return response;
    }

    public async delete(filter: any): Promise<Array<T>> {
        let response: Array<T> | T | null = await this.service.bgCall<T>("DELETE", `${this.urlBase}/${this.schema}/${this.entity}/delete`, JSON.stringify({ filter }), "bearer", "json", 'json', {}, 2);
        response = response as Array<T>;
        return response;
    }
}