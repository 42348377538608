import { useEffect } from "react";
import { ViewMain } from "./Components/View";
import { Controller } from "./Infraestructure/Controller";

const ManagerEntity = () => {
    const controller = Controller();

    useEffect(() => {
        controller.init();
        controller.validateSession();
        var intervalValidate = setInterval(controller.validateSession, 60000);
        return () => {
            clearInterval(intervalValidate);
        }
    }, []);


    
    return (<ViewMain
        onClickHome={controller.onClickHome}
        onClickHelp={controller.onClickHelp}
        onClickReport={controller.onClickReport}
        onClickProfile={controller.onClickProfile}
        onClickVariable={controller.onClickVariable}
        onClickLogout={controller.onClickLogout}

        auth={controller.auth}
        user={controller.user}
        iconMenu={controller.iconMenu}
        showSaludo={controller.showSaludo}
        online={controller.online}
        countProcess={controller.countProcess}
        onHideIconMenu={controller.onHideIconMenu}
        onShowIconMenu={controller.onShowIconMenu}
    />);
};

export default ManagerEntity;