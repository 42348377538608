import React from 'react'
import { Dialog } from 'primereact/dialog';
interface PropsViewDialogDocument {
    visibleDocument: boolean;
    onHideDocument: any;
    textValorizacion: string;
}
export const DialogViewDocument = (props: PropsViewDialogDocument) => {
    return (
        <Dialog
            header={props.textValorizacion}
            visible={props.visibleDocument}
            style={{ width: '80vw', height: '100vh' }}
            onHide={() => { props.onHideDocument() }}
        >
            <iframe src="https://topodata.com/wp-content/uploads/2019/10/GUIA_PMBok.pdf" style={{ width: '100%', height: '100%' }} ></iframe>
        </Dialog>
    )
}


