import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
//import { EntityMain } from "../Domain/EntityMain";

import { DtoRequestComparativo } from "../Domain/DtoRequestComparativo";
import { Comparativo } from "../Domain/Comparativo";
import { TipoDocumentoTributario } from "../Domain/TipoDocumentoTributario";
import { TipoIVA } from "../Domain/TipoIVA";
import { removeLoading } from "../../../shared/Infraestructure/SliceGenerico";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { DtoRequestInvoice } from "../Domain/DtoRequestInvoice";

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {
    public async getList(params: DtoRequestComparativo): Promise<Comparativo[] | null> {
        return this._getList(params);
    }
    private async _getList(params: DtoRequestComparativo): Promise<Comparativo[] | null> {
        const newParams = JSON.stringify(params)
        return await this.service.bgCall<any>('POST', `${this.urlBase}/catalogo/proveedor/selectComparativos`, newParams, 'bearer', "json", "json", {}, 0);
    }
    public async getDatosTributarios(params: any): Promise<TipoDocumentoTributario[] | null> {
        return this._getDatosTributarios(params);
    }
    private async _getDatosTributarios(params: any): Promise<TipoDocumentoTributario[] | null> {
        const newParams = JSON.stringify(params)
        return await this.service.bgCall<any>('POST', `${this.urlBase}/catalogo/tipoDocumentoTributario/select`, newParams, 'bearer', "json", "json", {}, 0);
    }
    public async getTipoIVA(params: any): Promise<TipoIVA[] | null> {
        return this._getTipoIVA(params);
    }
    private async _getTipoIVA(params: any): Promise<TipoIVA[] | null> {
        const newParams = JSON.stringify(params)
        return await this.service.bgCall<any>('POST', `${this.urlBase}/catalogo/tipoIva/select`, newParams, 'bearer', "json", "json", {}, 0);
    }
    public async save(params: DtoRequestInvoice): Promise<void> {
        return this._save(params);
    }

    private async _save(params: DtoRequestInvoice): Promise<void> {
        if (!navigator.onLine) {
            this.dispatch(removeLoading());
            AdapterGenerico.createMessage('Sin Conexión', 'No posee conexión a internet', 'warning');
            return;
        }
        let myHeaders = new Headers();
        myHeaders.append("request-decrypt-response", "yes");
        myHeaders.append("Authorization", `Basic ${Buffer.from(process.env.REACT_APP_AUTH_BASIC_USER + ':' + process.env.REACT_APP_AUTH_BASIC_PASS).toString('base64')}`);

        let formdata = new FormData();
        formdata.append("_idProveedor", params._idProveedor);
        formdata.append("tipoDocumentoTributario", ""+params.tipoDocumentoTributario);
        formdata.append("monedaKey", params.monedaKey);
        formdata.append("numeroDocumentoTributario", params.numeroDocumentoTributario);
        formdata.append("importe", ""+params.importe);
        formdata.append("iva", ""+params.iva);
        formdata.append("tipoIvaKey", ""+params.tipoIvaKey);
        formdata.append("importeIva", ""+params.importeIva);
        formdata.append("importeTotal", ""+params.importeTotal);
        formdata.append("observacion", ""+params.observacion);
        formdata.append("detalle", ""+params.detalle);
        formdata.append("factura", params.factura);
        params.anexo.forEach((archivo, index) => {
            formdata.append(`file${index}`, archivo.file);
        });

        await this.service.call<any>("POST", `${this.urlBase}/catalogo/proveedor/insertFactura`, formdata, "basic", 'form', "json", {}, 0);
    }
    

    
}