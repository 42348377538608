import { AdapterEncrypt } from "./AdapterEncrypt";
import { AdapterGenerico } from "./AdapterGenerico";

export class AdapterStorage {

    public static set(key: string, item: any, localMode: boolean = true): void {
        try {
            let value: string;
            if (typeof key !== 'string') { return; }
            if (typeof item === 'object') { value = JSON.stringify(item); }
            else { value = item; }
            if (process.env?.REACT_APP_REQUIRE_ENCRYPT === '1') { value = AdapterEncrypt.encrypt(value, process.env.REACT_APP_KEY_ENCRYPT || ''); }
            window[localMode ? 'localStorage' : 'sessionStorage'].setItem(`${key}_${process.env.REACT_APP_SISTEMA}_${process.env.REACT_APP_VERSION_MAJOR}`, value);
        } catch (error) {
            throw error;
        }
    }

    public static get(item: Array<string> | string, localMode: boolean = true): any {
        try {
            let res = {};
            let val = null;
            let value: any;
            if (Array.isArray(item)) {
                for (let key of item) {
                    val = window[localMode ? 'localStorage' : 'sessionStorage'].getItem(`${key}_${process.env.REACT_APP_SISTEMA}_${process.env.REACT_APP_VERSION_MAJOR}`);
                    if (process.env?.REACT_APP_REQUIRE_ENCRYPT === '1') { if (val !== null) { val = AdapterEncrypt.decrypt(val, process.env.REACT_APP_KEY_ENCRYPT || ''); } }
                    value = val === null ? null : AdapterGenerico.isJSON(val) ? JSON.parse(val) : val;
                    Object.assign(res, { [key]: value });
                }
            }
            else {
                val = window[localMode ? 'localStorage' : 'sessionStorage'].getItem(`${item}_${process.env.REACT_APP_SISTEMA}_${process.env.REACT_APP_VERSION_MAJOR}`);
                if (process.env?.REACT_APP_REQUIRE_ENCRYPT === '1') { if (val !== null) { val = AdapterEncrypt.decrypt(val, process.env.REACT_APP_KEY_ENCRYPT || ''); } }
                value = val === null ? null : AdapterGenerico.isJSON(val) ? JSON.parse(val) : val;
                Object.assign(res, { [item]: value });
            }
            return res;
        } catch (error) {
            throw error;
        }
    }

    public static remove(item: Array<string> | string, localMode: boolean = true): void {
        try {
            if (Array.isArray(item)) {
                for (let key of item) {
                    if (typeof key !== 'string') { continue;; }
                    window[localMode ? 'localStorage' : 'sessionStorage'].removeItem(`${key}_${process.env.REACT_APP_SISTEMA}_${process.env.REACT_APP_VERSION_MAJOR}`);
                }
            }
            else {
                if (typeof item !== 'string') { return; }
                window[localMode ? 'localStorage' : 'sessionStorage'].removeItem(`${item}_${process.env.REACT_APP_SISTEMA}_${process.env.REACT_APP_VERSION_MAJOR}`);
            }
        } catch (error) {
            throw error;
        }
    }

    public static clear(localMode: boolean = false): void {
        try {
            window[localMode ? 'localStorage' : 'sessionStorage'].clear();
        } catch (error) {
            throw error;
        }
    }

}
