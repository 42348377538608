type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

export async function register(config?: Config) {
  const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
  if (publicUrl.origin !== window.location.origin) { return; }
  const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
  let registration = await registerValidSW(swUrl, config);
  return registration;
}

function registerValidSW(swUrl: string, config?: Config): Promise<ServiceWorkerRegistration> {
  return new Promise(async (resolve, reject) => {
    try {
      let registration = await navigator.serviceWorker.register(swUrl);
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) { return; }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) if (config && config.onUpdate) config.onUpdate(registration);
            else { if (config && config.onSuccess) config.onSuccess(registration); }
          }
        }
      };
      resolve(registration);
    } catch (error) {
      console.error('Error during service worker registration:', error);
      reject(error);
    }
  });
}

export async function unregister() {
  if ('serviceWorker' in navigator) {
    try {
      let registration = await navigator.serviceWorker.ready;
      registration.unregister();
    } catch (error) {
      throw error;
    }
  }
}
