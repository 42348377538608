import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ListaControlDocumental } from '../../Home/Domain/ListaControlDocumental';

import { DataTable  } from 'primereact/datatable';
import { Column } from 'primereact/column';
import React, { useState } from 'react';

interface PropsViewDialogForm {
    visibleDialogSteps:boolean;
    onHideDialogSteps:Function;
    data: any[];
    verDocumento:Function;
    CargarFileControlDocumental:Function
    setDocumentosFile:Function;
    eliminarDocumento:Function;
    AlertSizeFileUpload:Function;
    AlertTypeFileUpload:Function;
}

export const DialogViewControlDocument = (props : PropsViewDialogForm) => {
  const [fileInputKey, setFileInputKey] = useState<number>(0);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const fileInput = event.target;
      const parametroCodigo = fileInput.dataset.parametro;//fileInput.getAttribute('data-parametro');
      const parametroDocumento = fileInput.dataset.documento;//fileInput.getAttribute('data-parametro');
      
      const fileList = event.target.files;
      if (fileList && fileList.length > 0) {
          const selectedFile = fileList[0];
          const fileNameParts = selectedFile.name.split('.');
          const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();

          if(selectedFile.size>50000000){
            props.AlertSizeFileUpload();
            return;
          }else{
            if (fileExtension !== 'pdf') {
              props.AlertTypeFileUpload();
              return;
            }else{
              console.log("parametroCodigo", parametroCodigo);
              props.CargarFileControlDocumental(selectedFile,parametroCodigo,parametroDocumento);  
            }
          }
          // Aquí puedes manejar el archivo seleccionado
          
      }
      // Restablecer el valor del input para permitir la selección del mismo archivo nuevamente
      //setFileInputKey(fileInputKey + 1);
  };
  const DocumentoBodyTemplate = (rowData:ListaControlDocumental)=>{

      return (
        <div>
          <Button icon="pi pi-file-pdf" style={{ cursor: 'pointer' }}  rounded text severity={`${rowData.EstadoCarga==="Cargado"? 'danger':'secondary'}`} aria-label="Ver Documento" onClick={(e)=> {
                if(rowData.EstadoCarga==="Cargado"){
                  props.verDocumento(rowData);
                }
          }}/>

            {rowData.EstadoCarga==="Cargado" && ( 
              <Button icon="pi pi-trash" style={{ cursor: 'pointer' }}  rounded text severity='danger' aria-label="Eliminar Archivo" onClick={(e)=> {

                  props.eliminarDocumento(rowData);
              
              }}/>
            )}
        
          {rowData.EstadoCarga==="Pendiente" && ( 
            <Button icon="pi pi-upload" style={{ cursor: 'pointer' }}  rounded text severity='info' aria-label="Cargar Documento" onClick={(e)=> {
              const fileInput = document.getElementById("fileInput") as HTMLInputElement;
              console.log("atributo",rowData);
              fileInput.dataset.parametro = rowData.Codigo;
              fileInput.dataset.documento = rowData.Documento;
              if (fileInput) {
                  fileInput.click();
              }
            }} /> 
          )}

          
          <input
              key={fileInputKey}
              id="fileInput"
              type="file"
              accept=".pdf"
              style={{ display: 'none' }}
              onChange={handleFileChange}
           />
        </div>

          
          
      )
  }
  const footerContent = () => {
      return (
        <div>
          <Button 
                label="Cancelar" 
                  onClick={(e)=>props.onHideDialogSteps(e)} 
                severity="danger" 
            />
            
        </div>
      );

  }

  return (
    <Dialog header="Control Documental" visible={props.visibleDialogSteps} style={{ width: '100vw',zIndex:"1059" }} onHide={()=>props.onHideDialogSteps()}  className='form-dialog' footer={footerContent}>
      
      <div className='dialog-form-steps-invoice'>

            <DataTable 
            // paginatorLeft={paginatorLeft}
            // paginatorRight={paginatorRigth}
            paginatorClassName="paginator-gia"
            value={props.data} 
            dataKey="id" 
            tableStyle={{ minWidth: '50rem', border:'1px solid #dee2e6' }}
            paginator 
            rows={4}
            scrollHeight="400px"
            size='large'
            rowsPerPageOptions={[4,10, 20, 30, 40,50,50,100]}
            className='custombar1'
            resizableColumns
          >
       
            <Column className='lg:w-1' bodyClassName={'text-sm'} header="" headerClassName='bg-gia text-white text-white text-sm' body={DocumentoBodyTemplate} ></Column>
            <Column sortable className='lg:w-1' bodyClassName={'text-sm text-striped'} field="Documento" header="Documento" headerClassName='bg-gia text-white text-white text-sm'></Column>
            <Column sortable className='lg:w-1' bodyClassName={'text-sm text-striped'} field="EstadoVencimiento" header="Estado Vencimiento" headerClassName='bg-gia text-white text-white text-sm'></Column>
            <Column sortable className='lg:w-1' bodyClassName={'text-sm text-striped'} field="FechaVencimiento" header="Fecha Vencimietno" headerClassName='bg-gia text-white text-sm' body=''></Column>
            <Column sortable className='lg:w-1' bodyClassName={'text-sm text-striped'} field="Operacion" header="Operacion" headerClassName='bg-gia text-white text-sm'></Column>
            <Column sortable className='lg:w-1' bodyClassName={'text-sm text-striped'} field="FechaCarga" header="Fecha Carga" headerClassName='bg-gia text-white text-sm' body=''></Column>
            <Column sortable className='lg:w-1' bodyClassName={'text-sm text-striped'} field="Obligatoriedad" header="Obligatoriedad" headerClassName='bg-gia text-white text-sm' body=''></Column>
            <Column sortable className='lg:w-1' bodyClassName={'text-sm text-striped'} field="EstadoCarga" header="Estado" headerClassName='bg-gia text-white text-sm' body=''></Column>

          </DataTable>
         



      </div>

    </Dialog>
  )
}