import { useSelector } from "react-redux";
import { RootState } from "../../../shared/Infraestructure/AdapterStore";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { AdapterConfigure } from "./AdapterConfigure";
import { RepositoryImplMain } from "./RepositoryImplMain";
import { Valorization } from "../Domain/Valorization";
import { ChangeEvent, useState } from "react";
import { addLoading, changeSaludo, hideIconMenu, removeLoading } from "../../../shared/Infraestructure/SliceGenerico";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { DtoRequestComparativo } from "../Domain/DtoRequestComparativo";
import { UseCaseComparativo } from "../Aplication/UseCaseComparativo";
import { Comparativo } from "../Domain/Comparativo";
import { MenuItem } from "primereact/menuitem";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { AdapterValidator } from "../../../shared/Infraestructure/AdapterValidator";
import { ColumnEvent } from "primereact/column";
import { UseCaseTipoDocumentoTributario } from "../Aplication/UseCaseTipoDocumentoTributario";
import { TipoDocumentoTributario } from "../Domain/TipoDocumentoTributario";
import { UseCaseTipoIVA } from "../Aplication/UseCaseTipoIVA";
import { TipoIVA } from "../Domain/TipoIVA";
import xml2js from 'xml2js';

export const Controller = () => {
    const { generico: { websocket, dbLocal, countProcess }, auth: { user } } = useSelector((state: RootState) => state);
    const dispatch: Dispatch = useDispatch();
    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);
    const [data, setData] = useState<Array<Valorization>>([]);
    //const [data, setData] = useState<Array<Comparativo>>([]);
    const [visibleDocument, setVisibleDocument] = useState<boolean>(false);
    const [dataText, setDataText] = useState<any>({
        textValorizacion: ''
    });
    const [selectedValorizaciones, setSelectedValorizaciones] = useState<Valorization[] | null | any>(null);
    const [globalFilter, setGlobalFilter] = useState<string>('');
    const [tabIndex,setTabIndex] = useState<number>(0);
    const [visibleDialogSteps,setVisibleDialogSteps] = useState<boolean>(false);
    const [importeAsociado, setImporteAsociado] = useState<number>(0);
    const [tipoDocumentoTributario,setTipoDocumentoTributario]=useState<TipoDocumentoTributario[] | null>(null);
    const [tipoIVA,setTipoIVA]= useState<TipoIVA[] | null>();
    const [disabled, setDisabled] = useState<boolean>(false);

    const init = async () => {
        try {
            dispatch(changeSaludo(false));
            dispatch(hideIconMenu());
            dispatch(addLoading('Cargando Valorizaciones'));
            await loadData();
            await loadTipoDocumentoTributario();
            await loadTipoIVA();
            dispatch(removeLoading());
        } catch (error) {
            dispatch(removeLoading());
            AdapterGenerico.createMessage('Alerte', (error as Error).message, 'warning');
        }
    }

    const loadData = async () => {
        try {
            const param: DtoRequestComparativo = {
                _id: user.usuario._id
            }

            dispatch(addLoading({ textLoading: 'Cargando...' }));
            const response = await (new UseCaseComparativo(repository).exec(param));
            const Valorizaciones: Valorization[] = [];
            response?.map(value => {
                value.Valorizaciones.map((valorizacion) => {

                    Valorizaciones.push({
                        CodigoFactura: value.Codigo,
                        Moneda: value.DatosPago.Moneda.Codigo === '' ? 'PEN' : value.DatosPago.Moneda.Codigo,
                        ImporteAsociar:valorizacion.Importe,
                        TipoCompra:value.TipoCompra,
                        ...valorizacion
                    });
                })
            });

            if (response === null) return;
            setData(Valorizaciones);
        } catch (error) {

        }
    }

    const loadTipoDocumentoTributario = async () => {
        try {
            const param: any = {
                // cdPais: "504"
                cdPais: user.usuario.pais.codigo,
            }
            dispatch(addLoading({ textLoading: 'Cargando...' }));
            const response = await (new UseCaseTipoDocumentoTributario(repository).exec(param));
            if (response === null) return;
            //await dbLocal.clearStore('Factura');
            //await dbLocal.insertDataStore([{ nameStore: 'Factura', data: response }]);
            setTipoDocumentoTributario(response);

        } catch (err) {
            //let error: ErrorCostume = new ErrorCostume((err as Error).message);
            //AdapterGenerico.createMessage('Alerta', error.message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    }

    const loadTipoIVA = async () => {
        try {
            const param: any = {
                // cdPais: "504"
                cdPais: user.usuario.pais.codigo,
            }
            dispatch(addLoading({ textLoading: 'Cargando...' }));
            const response = await (new UseCaseTipoIVA(repository).exec(param));
            if (response === null || undefined) return;
            //await dbLocal.clearStore('Factura');
            //await dbLocal.insertDataStore([{ nameStore: 'Factura', data: response }]);
            let tipo= response.filter((value:TipoIVA)=> value.VATBus_PostingGroup===user.usuario.datosContabilidad.VATBus.Key);
            //{props.tipoIVA.filter((value: any) => value.VATBus_PostingGroup === props.user.usuario.datosContabilidad.VATBus.Key)}
            setTipoIVA(tipo);
        } catch (err) {
            //let error: ErrorCostume = new ErrorCostume((err as Error).message);
            //AdapterGenerico.createMessage('Alerta', error.message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    }

    const formatDate = (value: string | Date) => {
        return new Date(value).toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };
    const formatCurrency = (value: number, moneda: string) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: moneda });
    };
    const openDocument = async (rowData: Valorization) => {
        //const token='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJZFVzZXIiOjk0OSwiVXNlciI6IkFDUlVaRyIsInVzZXJuYW1lIjoiQUNSVVpHIiwiTGFzdE5hbWVGYXRoZXIiOiJDUlVaIiwiTGFzdE5hbWVNb3RoZXIiOiJHT05aQUxFUyIsIk5hbWUiOiJBTkRSRUpFU1VTIiwiRW1wcmVzYSI6eyJDb2RFbXByZXNhIjoiMDEiLCJDREVtcHJlc2EiOiJDMDEzIiwiRW1wcmVzYSI6IkNvYnJhIFBlcsO6IFNBIiwiR3J1cG8iOiJDT0JSQSJ9LCJEYXRvc1RyYWJham8iOnsiRGVsZWdhY2lvbiI6IjkxMDAiLCJPVCI6IjkxMDAwMDAzIiwiQXJlYSI6IiIsIkNhcmdvIjoiIn0sIlRpcG9Eb2N1bWVudG8iOnsiVGlwb0RvY3VtZW50byI6IjAxIn0sImZ1bGxuYW1lIjoiQ1JVWiBHT05aQUxFUywgQU5EUkVKRVNVUyIsIkZ1bGxOYW1lIjoiQ1JVWiBHT05aQUxFUywgQU5EUkVKRVNVUyIsIklkZW50aWZpY2FjaW9uIjoiNzE1OTU1NjIiLCJQcm9maWxlIjpbeyJJZFBlcmZpbCI6MCwiUGVyZmlsIjoiU0lOIFBFUkZJTCIsIlByaW5jaXBhbCI6IlNJIn1dLCJDaGFuZ2VTdHJ1Y3R1cmVVc2VyIjp0cnVlLCJEYXRlcyI6eyJSZWdpc3RyeSI6eyJEYXRlIjoiMjAyMy0wNi0yNlQxNzoxMjo0OC40MTJaIiwiSWREYXRlIjoyMDIzMDYyNn0sIkxvZ2luU3VjY2VzcyI6eyJEYXRlIjoiIiwiSWREYXRlIjowfSwiTG9naW5FcnJvciI6eyJEYXRlIjoiMjAyMy0wOC0xNFQxNjo0MzoyMi41MjlaIiwiSWREYXRlIjoyMDIzMDgxNH0sIkNoYW5nZVBhc3N3b3JkIjp7IkRhdGUiOiIyMDIzLTA2LTI2VDE3OjE2OjQwLjM2M1oiLCJJZERhdGUiOjIwMjMwNjI2fSwiRm9yZ290UGFzc3dvcmQiOnsiRGF0ZSI6IiIsIklkRGF0ZSI6MH0sIlJlc2V0UGFzc3dvcmQiOnsiRGF0ZSI6IiIsIklkRGF0ZSI6MH0sIkJsb2NrIjp7IkRhdGUiOiIiLCJJZERhdGUiOjB9LCJVcGRhdGUiOnsiRGF0ZSI6IjIwMjMtMDYtMjZUMTc6MTQ6MjYuNTQwWiIsIklkRGF0ZSI6MjAyMzA2MjZ9fSwiQ29udGFkb3JlcyI6eyJDaGFuZ2VQYXNzd29yZCI6MSwiTG9naW5FcnJvciI6MiwiRm9yZ290UGFzc3dvcmQiOjAsIlJlc2V0UGFzc3dvcmQiOjAsIkJsb2NrIjowLCJmaXJzdEluaXRTZXNpb24iOjF9LCJTdGF0dXMiOnsiSWRTdGF0dXMiOjEsIlN0YXR1cyI6IkFjdGl2byJ9LCJFbWFpbCI6ImFuZHJlLmNydXpAY29icmFwZXJ1LmNvbS5wZSIsIlRhc2EiOnRydWUsIkZvdG8iOiIiLCJQYWlzIjp7IkNEUGFpcyI6IjUwNCIsIkNvZGUiOiJQRSIsIk5hbWUiOiJQZXJ1In0sIkNvZGlnbyI6IjcxNTk1NTYyIiwiSWRpb21hIjoiZXMiLCJpYXQiOjE2OTI3NjM1NDcsImV4cCI6MTcxODY4MzU0N30.uodY6ggDKff0L0TOQ1o2iet7KCtdO8F_VUASxawGSjE';
        const token ='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJZFVzZXIiOjk0OSwiVXNlciI6IkFDUlVaRyIsInVzZXJuYW1lIjoiQUNSVVpHIiwiTGFzdE5hbWVGYXRoZXIiOiJDUlVaIiwiTGFzdE5hbWVNb3RoZXIiOiJHT05aQUxFUyIsIk5hbWUiOiJBTkRSRUpFU1VTIiwiRW1wcmVzYSI6eyJDb2RFbXByZXNhIjoiMDEiLCJDREVtcHJlc2EiOiJDMDEzIiwiRW1wcmVzYSI6IkNvYnJhIFBlcsO6IFNBIiwiR3J1cG8iOiJDT0JSQSJ9LCJEYXRvc1RyYWJham8iOnsiRGVsZWdhY2lvbiI6IjkxMDAiLCJPVCI6IjkxMDAwMDAzIiwiQXJlYSI6IiIsIkNhcmdvIjoiIn0sIlRpcG9Eb2N1bWVudG8iOnsiVGlwb0RvY3VtZW50byI6IjAxIn0sImZ1bGxuYW1lIjoiQ1JVWiBHT05aQUxFUywgQU5EUkVKRVNVUyIsIkZ1bGxOYW1lIjoiQ1JVWiBHT05aQUxFUywgQU5EUkVKRVNVUyIsIklkZW50aWZpY2FjaW9uIjoiNzE1OTU1NjIiLCJQcm9maWxlIjpbeyJJZFBlcmZpbCI6MCwiUGVyZmlsIjoiU0lOIFBFUkZJTCIsIlByaW5jaXBhbCI6IlNJIn1dLCJDaGFuZ2VTdHJ1Y3R1cmVVc2VyIjp0cnVlLCJEYXRlcyI6eyJSZWdpc3RyeSI6eyJEYXRlIjoiMjAyMy0wNi0yNlQxNzoxMjo0OC40MTJaIiwiSWREYXRlIjoyMDIzMDYyNn0sIkxvZ2luU3VjY2VzcyI6eyJEYXRlIjoiIiwiSWREYXRlIjowfSwiTG9naW5FcnJvciI6eyJEYXRlIjoiMjAyMy0wNy0xN1QwODowOTowMS44MjlaIiwiSWREYXRlIjoyMDIzMDcxN30sIkNoYW5nZVBhc3N3b3JkIjp7IkRhdGUiOiIyMDIzLTA2LTI2VDE3OjE2OjQwLjM2M1oiLCJJZERhdGUiOjIwMjMwNjI2fSwiRm9yZ290UGFzc3dvcmQiOnsiRGF0ZSI6IiIsIklkRGF0ZSI6MH0sIlJlc2V0UGFzc3dvcmQiOnsiRGF0ZSI6IiIsIklkRGF0ZSI6MH0sIkJsb2NrIjp7IkRhdGUiOiIiLCJJZERhdGUiOjB9LCJVcGRhdGUiOnsiRGF0ZSI6IjIwMjMtMDYtMjZUMTc6MjI6NTQuODk1WiIsIklkRGF0ZSI6MjAyMzA2MjZ9fSwiQ29udGFkb3JlcyI6eyJDaGFuZ2VQYXNzd29yZCI6MSwiTG9naW5FcnJvciI6MiwiRm9yZ290UGFzc3dvcmQiOjAsIlJlc2V0UGFzc3dvcmQiOjAsIkJsb2NrIjowLCJmaXJzdEluaXRTZXNpb24iOjF9LCJTdGF0dXMiOnsiSWRTdGF0dXMiOjEsIlN0YXR1cyI6IkFjdGl2byJ9LCJFbWFpbCI6ImFuZHJlLmNydXpAY29icmFwZXJ1LmNvbS5wZSIsIlRhc2EiOnRydWUsIkZvdG8iOiIiLCJQYWlzIjp7IkNEUGFpcyI6IjUwNCIsIkNvZGUiOiJQRSIsIk5hbWUiOiJQZXJ1In0sIkNvZGlnbyI6IjcxNTk1NTYyIiwiSWRpb21hIjoiZXMiLCJpYXQiOjE2OTI3NjUwNjksImV4cCI6MTcxODY4NTA2OX0.kzG6q1L05M76Gbd_AAj1_zYmMO6FsiHNp3YRNyIyjf8';
        const requestOptions = {
            "method": 'POST',
            "headers": {
                'authorization': `Basic dXNlci5naWE6Z3I4cDBDMGJyNCEhJA==`,
                'request-decrypt-response': 'yes',
                'Content-Type': 'application/json',
            },
            "body": JSON.stringify({ "directory": [
                "NAVISI",
                "OPERACIONES",
                "O96",
                //rowData.CodigoFactura,
                `${rowData.CodigoFactura}`,
                "Valorizacion"

            ],
            //"fileName": `${rowData.Code}.pdf`, 
            "fileName":`${rowData.Code}.PDF`,
            user:null }),
        };
        const urlDev = AdapterConfigure.URL_FILES_DOWNLOAD;
        const document = await fetch(urlDev, requestOptions);
        let blob = await document.blob();
        setDataText({
            textValorizacion: `Valorización ${rowData.CodeValorizacion}`
        });
        setVisibleDocument(true);
    }
    const onHideDocument = () => {
        setVisibleDocument(false);
    }
    const isSelectable = (data: Valorization) => {

        if (selectedValorizaciones === null || selectedValorizaciones === undefined || selectedValorizaciones.length === 0) return true;
        return (
            // data.CodigoFactura === selectedValorizaciones[0]?.CodigoFactura 
            // && 
            data?.Moneda === selectedValorizaciones[0]?.Moneda);

    };
    const onHideDialogSteps = ()=>{
        setVisibleDialogSteps(false);
        formInvoice.resetForm();
        setTabIndex(0);
        setSelectedValorizaciones([]);
        setDisabled(false);
        
    }
    const items: MenuItem[] = [{ label: 'Valorizaciones', icon: 'pi pi-file' }];
    const home: MenuItem = { icon: 'pi pi-home', url: '/main' }

    const onIngresoFactura=()=> {
        setVisibleDialogSteps(true);
        let sumImporteAsociado = selectedValorizaciones.reduce((accumulator:any, current:any) => accumulator + (current?.ImporteAsociar === undefined ? 0 : current?.ImporteAsociar), 0);
        formInvoice.setValues((values:any)=> ({
            ...values,
            importeAsociado:sumImporteAsociado,
            importe:sumImporteAsociado,
            importeFactura:sumImporteAsociado,
            
        }));
        if(formInvoice.values.tipoIva===null){
            formInvoice.setFieldValue('importeTotal',sumImporteAsociado );
        }
    }
    

    /** Region de Ingreso de factura */
    const formInvoice = useFormik({
        initialValues: {
            //step 1
            importeFactura: 0,
            //step 2
            fechaEmision: new Date().toISOString().slice(0, 10),
            importe:0,
            numeroDocumentoTributario:'',
            importeIva:0,
            importeTotal:0,
            tipoDocumentoTributario:null,
            tipoIva:null,
            // numeroDocumentoTributario: '',
            // monto: 0,
            // TipoIVA: null,
            // IVA: 0,
            // montoTotal: 0,
            // observaciones: '',
            //step 2
            //importeFactura:0, pasa igual a montototal
            importeAsociado:0,
            importePendiente:0,

        },

        onSubmit(values, formikHelpers) {
            //onSubmit(values);
            //onNextStep(values);

        },
        
        validationSchema: Yup.object({
            //step 1
            //importeFactura:Yup.number().when([],{is:()=> tabIndex===0, then: Yup.number().min(Yup.ref('monto'),'la factura excede al monto ingresado').required('importe requerido').nullable()}),
            // numeroDocumentoTributario:Yup.string().when([], {is: () => (tabIndex===0 ), then: Yup.string().required('Número documento tributario es requerido')}),
            // TipoDocumentoTributario:Yup.object().when([], {is: ()=> tabIndex===0, then: Yup.object().required('Seleccione un tipo de documento tributario')}),
            // monto:Yup.number().when([],{is:() =>tabIndex===0, then: Yup.number().min(1,'el monto debe ser minimo 1').required('monto es requerido').nullable()}),
            // TipoIVA:Yup.object().when([], {is: ()=> tabIndex===0, then: Yup.object().required('Seleccione un tipo de IVA')}),
            // IVA:Yup.number().when([],{is:() =>tabIndex===0, then: Yup.number().required('IVA es requerido').nullable()}),
            // montoTotal:Yup.number().when([],{is:() =>tabIndex===0, then: Yup.number().min(1,'el monto total debe ser minimo 1').required('monto total es requerido').nullable()}),
            //monto:Yup.number().min(1,'el monto debe ser minimo 1').required('monto es requerido').nullable(),
            //tipoIVA
            //montoTotal:Yup.number().when([],{is:() =>tabIndex===0, then: Yup.number().required('monto es requerido')}),
            //step 2
            //importeFactura:Yup.number().when([],{is:()=> tabIndex===1, then: Yup.number().min(Yup.ref('monto'),'la factura excede al monto ingresado').required('importe requerido').nullable()}),
            // importeAsociado:Yup.number().when([],{is:()=> tabIndex===1, then: Yup.number().min(0,'el importe asociar no debe ser 0').max(Yup.ref('montoTotal'),'el importe asociar no debe ser  mayor a monto total o importe asociar').required('importe requerido').nullable()}),
            // importePendiente:Yup.number().when([],{is:()=> tabIndex===1, then: Yup.number().min(0,'el importe no debe ser negativo').required('importe requerido').nullable()}),
        })
    });
    const onChange = (name: string, value: any) => {
        if (value === null) { return; }
        formInvoice.setFieldValue(name, value);
    };
    const itemsDialogSteps: MenuItem[] = [
        {
          label: 'Asociación',
          command: () => {
            setTabIndex(0);
          },
    
        },
        {
          label: 'Factura',
          command: () => {
            setTabIndex(1);
          },
        },
        // {
        //   label: 'Archivos',
        //   command: () => {
        //     props.setTabIndex(2);
        //   },
        // }
    
      ];
    const onSubmit = async(e:Event)=>{
        
        //setTabIndex(tabIndex + 1);
        try {
            
            e.preventDefault();
            e.stopPropagation();
            
            try {
                await formInvoice.submitForm(); } catch (error) {}
            try { AdapterValidator.validate(await formInvoice.validateForm()); } catch (error) { AdapterGenerico.createMessage('Incompleto', (error as Error).message, 'warning', false); return null; }
            try {
                let validationStep1 = await (!!formInvoice.touched['importeAsociado'] && !formInvoice.errors['importeAsociado']);
              
                setTabIndex(tabIndex + 1);
                if((tabIndex===0 && !validationStep1) && formInvoice.isValid ){
                    setTabIndex(tabIndex + 1);
                    
                    await AdapterGenerico.createMessage('Alerta', 'Estimado Proveedor, Debe llenar los campos necesarios', 'warning');
                    return;
                }

                if(tabIndex===itemsDialogSteps.length-1){
                    onHideDialogSteps();
                    formInvoice.resetForm();

                    dispatch(addLoading({ textLoading: 'Cargando...' }));
                    await setTimeout(() => {
                        dispatch(removeLoading());
                    
                        AdapterGenerico.createMessage('Exitoso', `<p>La solicitud de usuario se ha <b>realizado exitosamente</b>.</p>`, 'success');
                    }, 5000);
                }
                
                
            } catch (error) {
                
            }
        } catch (error) {
            
        }
    }
    const onChangeTipoIva = (e:any) => {
        
         formInvoice.setValues((values:any)=> ({
             ...values,
             tipoIva:e.value,
             importeIva: (e.value.VAT * formInvoice.values.importe/100),
             importeTotal: (formInvoice.values.importe + (e.value.VAT * formInvoice.values.importe/100))
             
        //     importeAsociado:sumImporteAsociado,
        //     importe:sumImporteAsociado,
        //     importeFactura:sumImporteAsociado,
            
        }));
    }
    /** export excel */
    const exportExcel = () => {        
        import('xlsx').then((xlsx) => {
          const worksheet = xlsx.utils.json_to_sheet(transformDataExcel(selectedValorizaciones));
          const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
          const excelBuffer = xlsx.write(workbook, {
            bookType: 'xlsx',
            type: 'array'
          });
    
          saveAsExcelFile(excelBuffer, 'products');
        });
      };
      const saveAsExcelFile = (buffer: any, fileName: any) => {
        import('file-saver').then((module) => {
    
          if (module && module.default) {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
              type: EXCEL_TYPE
            });
    
            module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
          }
        });
      };
      const transformDataExcel = (valorizaciones:Valorization[])=>{
        let newValorizations:any[] = valorizaciones.map(value=>{
            return {
                Fecha: formatDate(value.Dates.Registry.Date),
                "Codigo Valorizacion": value.CodeValorizacion,
                Observacion: value.Observacion,
                Importe: value.Importe,
                "Importe Asociar": value.ImporteAsociar
                
            }
        });
        return newValorizations;
        
      }
      const onCellEditCompleteImporte = (e: ColumnEvent) => {
        let { rowData, newValue, field, originalEvent: event } = e;
        let sumImporteAsociado=0;
        switch (field) {
          case 'ImporteAsociar':
            setImporteAsociado(newValue);
            rowData[field] = newValue;
            break;
          default:
            if (newValue.trim().length > 0) rowData[field] = newValue;
        }
        sumImporteAsociado = selectedValorizaciones.reduce((accumulator:any, current:any) => accumulator + (current?.ImporteAsociar === undefined ? 0 : current?.ImporteAsociar), 0);
        formInvoice.setValues((values:any)=> ({
            ...values,
            importeAsociado:sumImporteAsociado,
            importe:sumImporteAsociado,
            importeFactura:sumImporteAsociado,
            importeTotal:(formInvoice.values.tipoIva===null ? sumImporteAsociado : (sumImporteAsociado + (formInvoice.values.tipoIva['VAT'] * sumImporteAsociado/100))),
            importeIva: (formInvoice.values.tipoIva===null ? 0 : (formInvoice.values.tipoIva['VAT']*sumImporteAsociado/100))
            
        }));
        
      }
      /**
       *
       */
      const toCapitalize = (s: any) => {
        return s.charAt(0).toUpperCase() + s.slice(1);
      }
      const importExcel = (e: any) => {        
        let file = e.files[0];
        import('xlsx').then(xlsx => {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            const wb = xlsx.read(e?.target.result, { type: 'array' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = xlsx.utils.sheet_to_json(ws, { header: 1 });
    
            // Prepare DataTable
            const cols: any = data[0];
            data.shift();
    
            let _importedCols = cols.map((col: any) => ({ field: col, header: toCapitalize(col) }));

            let _importedData = data.map((d: any) => {

              return cols.reduce((obj: any, c: any, i: any) => {
                if(c==="Codigo Valorizacion"){
                    c="CodeValorizacion";
                  }
                  if(c==="Importe Asociar"){
                    c="ImporteAsociar"
                  }
                obj[c] = d[i];
                return obj;               
                
              }, {});
            });
            if (_importedData.length !== selectedValorizaciones.length) {
              //fileUploadRefXML.current?.clear();
    
              //props.toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Exceso de data en el excel', life: 3000 });
              return;
    
            } /*else if (_importedData.map((value: any) => {
              
            })) {
              fileUploadRefXML.current?.clear();
    
              toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Negativos de data en el excel', life: 3000 });
              //return;
            }*/
    
            //setImportedCols(_importedCols);
            //setContratos(_importedData);
            //setImportedData(_importedData);
            let newImported = selectedValorizaciones.map((value:Valorization,index:number)=>{
             
              if(value.CodeValorizacion!==_importedData[index].CodeValorizacion || value.Importe !== _importedData[index].Importe ){
                //props.toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Codigos o importe incorrectos', life: 3000 });
                return {...value}
              }
            
              return {
                ...value,
                ImporteAsociar:_importedData[index].ImporteAsociar
              }
            })
            
            let sumImporteAsociado = newImported.reduce((accumulator:any, current:any) => accumulator + (current?.ImporteAsociar === undefined ? 0 : current?.ImporteAsociar), 0);
            
            formInvoice.setValues((values:any)=> ({
                ...values,
                importeAsociado:sumImporteAsociado,
                importe:sumImporteAsociado,
                importeFactura:sumImporteAsociado,
                importeTotal:(formInvoice.values.tipoIva===null ? sumImporteAsociado : (sumImporteAsociado + (formInvoice.values.tipoIva['VAT'] * sumImporteAsociado/100))),
                importeIva: (formInvoice.values.tipoIva===null ? 0 : (formInvoice.values.tipoIva['VAT']*sumImporteAsociado/100))
                
            }));
            setSelectedValorizaciones(newImported);
          };
          e.files = null;
          reader.readAsArrayBuffer(file);
          
        });
      }
      const handleFileUpload2 = (e: ChangeEvent<HTMLInputElement>) => {
        //setFile(e.target.files);
        if (e.target.files) {
          let reader = new FileReader();
          reader.readAsText(e.target.files[0], 'windows-1251');
          reader.onloadend = async () => {
            try {
              const parser = new xml2js.Parser();
              
              parser.parseString(reader.result as string, function (err, result) {
                if (result["Invoice"]["cbc:UBLVersionID"] != "2.1" || result["Invoice"]["cbc:UBLVersionID"] != "2.0") {
                  //props.toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Versión UBL no admitido', life: 3000 });
                  return;
                }
                
                let companyRuc = result["Invoice"]["cac:AccountingCustomerParty"][0]["cac:Party"][0]["cac:PartyIdentification"][0]["cbc:ID"][0]["_"];
    
                //let tipoIva=result["Invoice"]['cac:TaxTotal'][0]["cac:TaxSubtotal"][0]['cac:TaxCategory'][0]['cac:TaxScheme'][0]['cbc:ID'][0]['_'];
                let tipoIva:any = tipoIVA?.filter((dat: any) => (dat.CodSunat === result["Invoice"]["cac:TaxTotal"][0]["cac:TaxSubtotal"][0]["cac:TaxCategory"][0]["cac:TaxScheme"][0]["cbc:ID"][0]["_"]) && (dat.VATBus_PostingGroup === user.usuario.datosContabilidad.VATBus.Key) && dat.VAT===18);
                if(tipoIva.length<0){
                    return;
                }
                let iva = result["Invoice"]["cac:TaxTotal"][0]["cac:TaxSubtotal"][0]["cbc:TaxAmount"][0]["_"];
                
                if(iva===null){
                    return;
                }
                let importe = result["Invoice"]["cac:TaxTotal"][0]["cac:TaxSubtotal"][0]["cbc:TaxableAmount"][0]["_"];
                if(importe===null){
                    return;
                }
                let importeTotal = result["Invoice"]["cac:LegalMonetaryTotal"][0]["cbc:PayableAmount"][0]["_"];
                if(importeTotal ===null){
                    return;
                }
                
                
                
                //props.formInvoice.setFieldValue('TipoIVA', tipoIva[0]);
                let tipoMoneda = result["Invoice"]["cbc:DocumentCurrencyCode"][0]["_"];
                if(tipoMoneda===null){
                    return;
                }
                let serieDocumentoTributario = (result["Invoice"]["cbc:ID"])[0].split("-")[0];
                
                let documentoTributario = result["Invoice"]["cbc:ID"][0];
                
                
                let nroDocumentoTributario = (result["Invoice"]["cbc:ID"])[0].split("-")[1];
                let fechaEmision = result["Invoice"]["cbc:IssueDate"][0];
                formInvoice.setValues((values:any)=> ({
                    ...values,
                    numeroDocumentoTributario: documentoTributario,
                    importeIva: iva,
                    importeTotal: importeTotal,
                    importe: importe,
                    fechaEmision: fechaEmision,
                    tipoIva: tipoIva[0],
                    importeFactura: importe,
                    
                }));
                setDisabled(true);
                // props.formInvoice.setFieldValue('numeroDocumentoTributario', documentoTributario);
                // props.formInvoice.setFieldValue('monto', monto);
                // props.formInvoice.setFieldValue('IVA', iva);
                // props.formInvoice.setFieldValue('montoTotal', montoTotal);
              });
              
            //  props.setDisabled(true);
            } catch (error) {
    
            }
          }
        }
        /*
    
      reader.onloadend = async() => {
        try{
          
          
            setXmlData(result);
            
            
            
            
            
      }catch(error){
        
      }*/
    
      }
    return {
        init,
        countProcess,
        data,
        formatDate,
        formatCurrency,
        openDocument,
        visibleDocument,
        onHideDocument,
        dataText,
        setSelectedValorizaciones,
        selectedValorizaciones,
        globalFilter,
        setGlobalFilter,
        isSelectable,
        tabIndex,
        setTabIndex,
        onHideDialogSteps,
        visibleDialogSteps,
        items,
        home,
        onIngresoFactura,
        formInvoice,
        onChange,
        importeAsociado,
        setImporteAsociado,
        onSubmit,
        exportExcel,
        tipoDocumentoTributario,
        tipoIVA,
        onChangeTipoIva,
        onCellEditCompleteImporte,
        importExcel,
        handleFileUpload2,
        disabled,
        itemsDialogSteps
    }
}