import { Route, Routes } from 'react-router-dom';
import { Page404 } from './Page404';

interface Props {
    children: JSX.Element[] | JSX.Element;
};

export const RoutesWithNotFound = ({ children }: Props) => {
    return (
        <Routes>
            {children}
        </Routes>
    );
}
