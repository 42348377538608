import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { MenuItem } from 'primereact/menuitem';
import { Steps } from 'primereact/steps';
import { TabPanel, TabView } from 'primereact/tabview';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import { TipoIVA } from '../Domain/TipoIVA';
import { Reception } from '../Domain/Reception';
import { TipoDocumentoTributario } from '../Domain/TipoDocumentoTributario'

interface PropsViewDialogBill {
    visibleBill: boolean;
    onHideBill: Function;
    dataBill:any;
    setTabIndex:Function;
    tabIndex:number;
    itemsDialogSteps:any;
    onSubmit:Function;
    formatCurrency: Function;
    formatCurrenc_step:Function;
    formatDate:Function;
    onChange:Function;
    selectedValorizaciones: Reception[] | any;
    disabled:boolean;
    tipoIVA:TipoIVA[] | null|undefined;
    onChangeTipoIva:Function;
    disabledImporteIva:boolean;
    onChangeValueImporteIva:any;
    onCellEditCompleteImporte:Function;
    formInvoice: { values: any; touched: any; errors: any; handleBlur: Function; handleChange: Function; handleSubmit: Function; setFieldValue: Function; };

    tipoDocumentoTributario:TipoDocumentoTributario[] | null;
    setFacturaFile: any;
    //onHideDialogSteps:Function;

    setAnexoFile:any;
    onSelectAnexo:any;
    onRemoveAnexo:any;
    onClearAnexo:any;
    ListaControlDocumental:any;
    onSelectDocuementoFactura:Function;
    onRemoveDocuementoFactura:Function;
    onClearDocuementoFactura:Function;

    verDocumento:Function;
    CargarFileControlDocumental:Function
    setDocumentosFile:Function;
    eliminarDocumento:Function;
    AlertSizeFileUpload:Function;
    AlertTypeFileUpload:Function;

}

export const DialogEditFactura = (props: PropsViewDialogBill) => {
    const items: MenuItem[] = [
        {
          label: 'Asociación',
          command: () => {
            props.setTabIndex(0);
          },
    
        },
        {
          label: 'Factura',
          command: () => {
            props.setTabIndex(1);
          },
        }    
      ];
          const footerContent = () => {
        if (props.tabIndex === 0) {
          return (
            <div>
              <Button 
                    label="Cancelar" 
                     onClick={(e)=>props.onHideBill(e)} 
                    severity="danger" 
                />
              <Button 
                label="Siguiente"
                onClick={(e)=>props.onSubmit(e)}
                type='button'
                className='bg-gia' 
              />
            </div>
          );
        } else if (props.tabIndex === items.length - 1) {
          return (
            <div>
              <Button label="Atras" onClick={() => props.setTabIndex(props.tabIndex - 1)} severity="danger" />
              <Button label="Guardar" className='bg-gia' type='button'
               onClick={(e)=>props.onSubmit(e)} 
              />
            </div>
          );
        }
        else {
          return (
            <div>
              <Button label="Atras" onClick={() => props.setTabIndex(props.tabIndex - 1)} severity="danger" />
              <Button label="Siguiente" 
                onClick={()=>props.onSubmit()} 
                className='bg-gia' 
            />
            </div>
          );
        }
      }
    

    
    return (
        
            <Dialog
            header="Editar Factura" visible={props.visibleBill} style={{ width: '55vw', zIndex: "1085"}} onHide={()=>props.onHideBill()}  className='form-dialog custom-dialog-content ' footer={footerContent}
            >
                <div className='dialog-form-steps-invoice custom-dialog-content'>    
                    <Steps
                        model={props.itemsDialogSteps}
                        activeIndex={props.tabIndex}
                        onSelect={(e) => props.setTabIndex(e.index)}
                    />

                  <TabView
                      activeIndex={props.tabIndex}
                      onTabChange={(e) => props.setTabIndex(e.index)}
                    >
                      <TabPanel disabled headerStyle={{ display: "none", border: 'none'}}>
                        <StepOne
                          formInvoice={props.formInvoice}
                          formatCurrency={props.formatCurrency}
                          formatCurrenc_step= {props.formatCurrenc_step}
                          formatDate={props.formatDate}
                          onChange={props.onChange}
                          selectedValorizaciones={props.selectedValorizaciones}
                          dataBill={props.dataBill}
                          //importeAsociado={props.importeAsociado}
                          //setImporteAsociado={props.setImporteAsociado}
                          //exportExcel={props.exportExcel}
                          onCellEditCompleteImporte={props.onCellEditCompleteImporte}
                          //importExcel={props.importExcel}
                          //handleFileUpload2={props.handleFileUpload2}
                          disabled={props.disabled}
                          tipoIVA={props.tipoIVA}
                          onChangeTipoIva={props.onChangeTipoIva}
                          disabledImporteIva={props.disabledImporteIva}
                          onChangeValueImporteIva={props.onChangeValueImporteIva}
                        />
                      </TabPanel>
                      <TabPanel disabled headerStyle={{ display: "none", border: 'none'}}>
                        <StepTwo
                            formInvoice={props.formInvoice}
                            onChange={props.onChange}
                            selectedValorizaciones={props.selectedValorizaciones}
                            tipoDocumentoTributario={props.tipoDocumentoTributario}
                            setFacturaFile={props.setFacturaFile}
                            disabled={props.disabled}
                            setAnexoFile={props.setAnexoFile}
                            onSelectAnexo={props.onSelectAnexo}
                            onRemoveAnexo={props.onRemoveAnexo}
                            onClearAnexo={props.onClearAnexo}
                            ListaControlDocumental={props.ListaControlDocumental}
                            onSelectDocuementoFactura={props.onSelectDocuementoFactura}
                            onRemoveDocuementoFactura={props.onRemoveDocuementoFactura}
                            onClearDocuementoFactura={props.onClearDocuementoFactura}
                            verDocumento={props.verDocumento}
                            CargarFileControlDocumental={props.CargarFileControlDocumental}
                            setDocumentosFile={props.setDocumentosFile}
                            eliminarDocumento={props.eliminarDocumento}
                            AlertSizeFileUpload={props.AlertSizeFileUpload}
                            AlertTypeFileUpload={props.AlertTypeFileUpload}
                            //onHideDialogSteps={props.onHideDialogSteps}
                            onHideBill={props.onHideBill}

                        />
                      </TabPanel>

                  </TabView>


                </div>
            </Dialog>
     
    )
}


