import { Card } from 'primereact/card'
import React from 'react'
import { DataTable  } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import './Style.scss';
import { Tag } from 'primereact/tag';
import { DtoResponseInvoice } from '../Domain/DtoResponseInvoice';
import { BreadCrumb } from 'primereact/breadcrumb';
import {MenuItem } from 'primereact/menuitem';

interface propsViewMain {
  data: DtoResponseInvoice[];
  reloadData: Function;
  globalFilter:string;
  setGlobalFilter:Function;
  items:MenuItem[];
  home:MenuItem;
  getStatus:Function;
  formatCurrency:Function;
  TransformMoneda:Function;
  TransformDate:Function;
  openEditFactura:Function;
  openVerMotivoRechazo:Function;
  openEnviarDevolucion:Function;
}

  
export const ViewMain = (props:propsViewMain) => {



  const startContent = (
    <React.Fragment>
        
    </React.Fragment>
  );
  
  const endContent = (
    <React.Fragment>
        <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText placeholder="Buscar..." onInput={(e) => props.setGlobalFilter(e.currentTarget.value)}/>
            </span>
    </React.Fragment>
  );
  const statusBodyTemplate = (rowData: DtoResponseInvoice) => {
      const arr_status=['En Aprobación', 'Aprobado', 'Pagado','Anulado','Rechazado'];
      let status='';
      let color_status = rowData.Status?.Nav.IdStatus;
      if(rowData.Status?.Solicitud.IdStatus===1){
        
        status=arr_status[1];
      }
      if(rowData.Status?.Nav.IdStatus===0){
        if(rowData.Status.Solicitud.IdStatus===2){
          if(rowData.Users.Reverted){
            status=arr_status[4];
            color_status = 21;
          }else{
            status=arr_status[0];
          }        
        }else{
          status=arr_status[0];
        }
      }
      if(rowData.Status?.Nav.IdStatus===103){
        status=arr_status[1];
      }
      if(rowData.Status?.Nav.IdStatus===101){
        status=arr_status[2];
      }
      if(rowData.Status?.Nav.IdStatus===4 || rowData.Status?.Solicitud.IdStatus===11){
        status=arr_status[3];
        if(rowData.Status?.Solicitud.IdStatus===11){
          color_status = rowData.Status?.Solicitud.IdStatus;
        }
        
      }
      
      // return <Tag value={rowData.Status?.Solicitud?.Status} severity={props.getStatus(rowData.Status?.Solicitud?.Status)} />;
      return <Tag value={status} severity={props.getStatus(color_status)} />;
  };
  const dateBodyTemplate = (rowData: DtoResponseInvoice) => {
      return props.TransformDate(rowData.Dates.Registry.Date);
  };
  const priceBodyTemplate = (rowData: DtoResponseInvoice) => {
        return props.TransformMoneda(rowData) +" "+ props.formatCurrency(rowData.DatosPago?.Importe?.Monto);
  };
  const buttonStyle = {
    cursor: 'pointer' 
  };
  const DocumentoBodyTemplate = (rowData:DtoResponseInvoice)=>{

    if(rowData.Status?.Nav.IdStatus===0){        
      if(rowData.Status.Solicitud.IdStatus===2){
        if(rowData.Users.Reverted){
          return (
            <div>
              <Button icon="pi pi-file-edit" style={buttonStyle} rounded text aria-label="Editar Factura" onClick={(e)=> {
                props.openEditFactura(rowData);
              }}/>
              <Button icon="pi pi-info-circle" style={buttonStyle} rounded text aria-label="Motivo Rechazo" onClick={(e)=> {
                props.openVerMotivoRechazo(rowData);
              }}/>
              <Button icon="pi pi-send" style={buttonStyle} rounded text aria-label="Enviar Devolucion" onClick={(e)=> {
                props.openEnviarDevolucion(rowData);
              }}/>
            </div>
          )
        }      
      }
    }
    //comentario!!
    // if(rowData.Status?.Nav.IdStatus===4){
    //   return (
    //     <Button icon="pi pi-file-edit" style={buttonStyle} rounded text aria-label="Editar Factura" onClick={(e)=> {
    //         props.openEditFactura(rowData);
    //     }}/>
    //   )
    // }  


}
  const viewDetailTemplate = (rowData:DtoResponseInvoice) => {
    return <Button icon={'pi pi-file'} rounded aria-label="ver detalle" outlined severity="info" raised tooltip='ver detalle'/>
  }
  const paginatorLeft = <Button type="button" icon="pi pi-refresh" text onClick={()=>props.reloadData()}/>;
  const paginatorRigth = <Button type="button" icon="" text />;
    
  return (
    <div className='flex justify-content-center flex-column px-5'>
      <BreadCrumb home={props.home} model={props.items} className='border mb-3'/>
      <Card
        //title='Estado Factura'
        className='scrollpanel-demo'
        >
        <div className='mb-3'>
          <Toolbar className='bg-white border-none py-1 px-0' end={endContent} start={startContent}/>
        </div>
        <DataTable 
          paginatorLeft={paginatorLeft}
          paginatorRight={paginatorRigth}
          paginatorClassName="paginator-gia"
          value={props.data} 
          dataKey="id" 
          tableStyle={{ minWidth: '60rem', border:'1px solid #dee2e6' }}
          paginator 
          rows={10}
          globalFilter={props.globalFilter}
          scrollable
          scrollHeight="450px"
          size='small'
          rowsPerPageOptions={[10, 20, 30, 40,50,100]}
          className='custombar1'
          resizableColumns
        >
          
          <Column sortable className='lg:w-1' bodyClassName={'text-sm text-striped'} field="Codigo" header="Código" headerClassName='bg-gia text-white text-white text-sm'></Column>
          <Column sortable className='lg:w-1' bodyClassName={'text-sm text-striped'} field="Dates.Registry.Date" header="Fecha Registro" headerClassName='bg-gia text-white text-sm' body={dateBodyTemplate}></Column>
          <Column sortable className='lg:w-1' bodyClassName={'text-sm text-striped'} field="DatosDocumento.TipoCompra.Description" header="Tipo Compra" headerClassName='bg-gia text-white text-sm'></Column>
          <Column sortable className='lg:w-1' bodyClassName={'text-sm text-striped'} field="Status.Solicitud.Status" header="Estado Solicitud" headerClassName='bg-gia text-white text-sm' body={statusBodyTemplate}></Column>
        
          <Column sortable className='lg:w-1' bodyClassName={'text-sm text-striped'} field="DatosPago.Importe.Monto" header="Importe" headerClassName='bg-gia text-white text-sm' body={priceBodyTemplate}></Column>
          <Column className='lg:w-1' bodyClassName={'text-sm'} header="" headerClassName='bg-gia text-white text-white text-sm' body={DocumentoBodyTemplate}></Column>

        </DataTable>

      </Card>
      
    </div>
  )
}
