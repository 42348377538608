import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { AdapterStorage } from "../../context/shared/Infraestructure/AdapterStorage";
import { RootState } from "../../context/shared/Infraestructure/AdapterStore";
import { addLoading, removeLoading, changeOnline, changeCountProcess, removeLoadingMaestros, changeAvailableUpdate } from "../../context/shared/Infraestructure/SliceGenerico";
import { EntityParams } from "../Domain/EntityParams";
import { AdapterConfigure } from "./AdapterConfigure";
import { RepositoryImplMain } from "./RepositoryImplMain";
import { UseCaseInitialService } from "../Application/UseCaseInitialService";
import { AdapterGenerico } from "../../context/shared/Infraestructure/AdapterGenerico";
import { EntityInformationDataInitial } from "../../context/shared/Domain/EntityInformationDataInitial";

import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';
import { useState } from "react";
import { EntityComparative } from "../../context/shared/Domain/EntityComparative";
import { UseCaseExecuteProcess } from "../Application/UseCaseExecuteProcess";
import { EntityFactura } from "../../context/shared/Domain/EntityFactura";

export const Controller = () => {
    const { websocket, dbLocal, loadingMaestros, availableUpdate } = useSelector((state: RootState) => state.generico);
    const dispatch: Dispatch = useDispatch();

    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);

    const SERVICESCALLED_NAME_FROM_LOCAL_STORAGE = "servicesCalleds";

    let [sw, setSW] = useState<ServiceWorkerRegistration | undefined>();

    let intervalVerifyRDI: NodeJS.Timer;

    const init = async () => {
        if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
            let service: ServiceWorkerRegistration | undefined = await serviceWorkerRegistration.register({ onUpdate(registration) { dispatch(changeAvailableUpdate(true)); }, });
            setSW(service);
            dispatch(changeAvailableUpdate(!!service?.waiting));
        }
        let { servicesCalleds }: { servicesCalleds: EntityInformationDataInitial | null } = AdapterStorage.get(SERVICESCALLED_NAME_FROM_LOCAL_STORAGE);
        if (servicesCalleds === null) {
            servicesCalleds = {

            };
            AdapterStorage.set(SERVICESCALLED_NAME_FROM_LOCAL_STORAGE, servicesCalleds);
        }

        window.addEventListener('online', onOnline);
        window.addEventListener('offline', onOffline);

        await callToAllInitialServices();
        dispatch(removeLoadingMaestros());

        intervalVerifyRDI = setInterval(verifyProcess, 60000);
    };

    const callToAllInitialServices = async () => {
        let params: EntityParams = {};
        try {
            dispatch(addLoading({ textLoading: 'Cargando...' }));
            await new UseCaseInitialService(repository).exec(params);
        } catch (error) {
            AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    };

    const onOffline = () => {
        AdapterGenerico.createToast('Sin conexión', 'warning');
        dispatch(changeOnline(false));
    };

    const onOnline = async () => {
        websocket.init();

        await verifyProcess(true);

        dispatch(changeOnline(true));
        AdapterGenerico.createToast('Conexión establecida', 'success');
    };

    const verifyProcess = async (force: boolean = false) => {
        let count: number = 0;
        let countComparative: number = await verifyProcessComparative(force);
        let countInvoice: number = await verifyProcessInvoice(force);

        count += countComparative + countInvoice;
        dispatch(changeCountProcess(count));
    }

    const verifyProcessComparative = async (force: boolean = false): Promise<number> => {
        if (!force && !navigator.onLine) { return 0; }

        let list: Array<EntityComparative> = await dbLocal.selectAllStore('Comparativo');
        list = list.filter(row => row.pendingSend);

        let countProcess: number = list.length;

        for (let row of list) {
            try {
                let url: string = '';
                if (row.dataSend?.extraConfig.typeSave === 'approve') url = `/navision/aprobarPreOrdenWorkFlow`;        
                if (row.dataSend?.extraConfig.typeSave === 'return') url = `/navision/retornarPreOrdenWorkFlow`;

                let result: any = await (new UseCaseExecuteProcess<any>(repository)).exec({ type: 'api', body: JSON.stringify(row.dataSend?.paramsSend), method: 'POST', typeAuth: 'bearer', typeRequest: 'json', typeResponse: 'json', url });
                if (!!result) await dbLocal.deleteByIndexStore({ nameStore: 'Comparativo', value: row._id });

                countProcess -= 1;
            } catch (error) {
                console.error(error);
            }
        }

        return countProcess;
    };

    const verifyProcessInvoice = async (force: boolean = false): Promise<number> => {
        if (!force && !navigator.onLine) { return 0; }

        let list: Array<EntityFactura> = await dbLocal.selectAllStore('Factura');
        list = list.filter(row => row.pendingSend);

        let countProcess: number = list.length;

        for (let row of list) {
            try {
                let url: string = '';
                if (row.dataSend?.extraConfig.typeSave === 'approve') url = `/navision/aprobarPreRegistroWorkFlow`;      
                if (row.dataSend?.extraConfig.typeSave === 'return') url = `/navision/retornarPreRegistroWorkFlow`;

                let result: any = await (new UseCaseExecuteProcess<any>(repository)).exec({ type: 'api', body: JSON.stringify(row.dataSend?.paramsSend), method: 'POST', typeAuth: 'bearer', typeRequest: 'json', typeResponse: 'json', url });
                if (!!result) await dbLocal.deleteByIndexStore({ nameStore: 'Factura', value: row._id });

                countProcess -= 1;
            } catch (error) {
                console.error(error);
            }
        }

        return countProcess;
    };

    const end = async () => {
        clearInterval(intervalVerifyRDI);
        window.removeEventListener('online', onOnline);
        window.removeEventListener('offline', onOffline);
    };

    const updateApp = async () => {
        if (!sw) { return; }
        if (sw.waiting) {
            sw.waiting.postMessage({ type: "SKIP_WAITING", });
            window.location.reload();
        }
    };

    return {
        init,
        end,

        availableUpdate,
        loadingMaestros,

        updateApp,
        callToAllInitialServices,
    };
}