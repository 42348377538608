const SCHEMA: string = 'Maestro';
const ENTITY: string = 'Pais';

const TIMEZONE: string = 'America/Lima';
const URL_HELP: string = process.env.REACT_APP_URL_HELPCENTER || '';
const URL_GLPI: string = process.env.REACT_APP_URL_GLPI || '';
const URL_FILES_DOWNLOAD: string = process.env.REACT_APP_URL_FILES_DOWNLOAD || '';
const URL_FILES_UPLOAD: string = process.env.REACT_APP_URL_FILES_UPLOAD || '';

export const AdapterConfigure = {
    TIMEZONE,

    SCHEMA,
    ENTITY,
    URL_HELP,
    URL_GLPI,
    URL_FILES_DOWNLOAD,
    URL_FILES_UPLOAD
};