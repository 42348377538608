import * as React from 'react';
import { Controller } from './Infraestructure/Controller';
import { ViewDataTable } from './Components/ViewDataTable';
import { DialogViewDocument } from './Components/DialogViewDocument';

const ManagerEntity = () => {

    const controller = Controller();

    React.useEffect(() => {
         controller.init();
         
         
        return () => {
        }
        // eslint-disable-next-line
    }, [controller.countProcess]);

    return (
        <div>
            <ViewDataTable
                
                data={controller.data}
                formatDate = {controller.formatDate}
                formatCurrency={controller.formatCurrency}
                openDocument={controller.openDocument}
                //onHideDocument={controller.onHideDocument}
                //visibleDocument={controller.visibleDocument}
                globalFilter={controller.globalFilter}
                setGlobalFilter={controller.setGlobalFilter}
                home={controller.home}
                items={controller.items}
                selectedValorizaciones={controller.selectedValorizaciones}
                setSelectedValorizaciones={controller.setSelectedValorizaciones}
                isSelectable={controller.isSelectable}
                onIngresoFactura={controller.onIngresoFactura}
                onHideDialogSteps={controller.onHideDialogSteps}
                setTabIndex={controller.setTabIndex}
                tabIndex={controller.tabIndex}
                visibleDialogSteps={controller.visibleDialogSteps}
                formInvoice={controller.formInvoice}
                onChange={controller.onChange}
                importeAsociado={controller.importeAsociado}
                setImporteAsociado={controller.setImporteAsociado}
                onSubmit={controller.onSubmit}
                exportExcel={controller.exportExcel}
                tipoDocumentoTributario={controller.tipoDocumentoTributario}
                tipoIVA={controller.tipoIVA}
                onChangeTipoIva={controller.onChangeTipoIva}
                onCellEditCompleteImporte={controller.onCellEditCompleteImporte}
                importExcel={controller.importExcel}
                handleFileUpload2={controller.handleFileUpload2}
                disabled={controller.disabled}
                itemsDialogSteps={controller.itemsDialogSteps}
                disabledImporteIva={controller.disabledImporteIva}
                onChangeValueImporteIva={controller.onChangeValueImporteIva}
                onSelectionChange={controller.onSelectionChange}
                setFacturaFile={controller.setFacturaFile}
                setAnexoFile={controller.setAnexoFile}
                onSelectAnexo={controller.onSelectAnexo}
                loadData={controller.loadData}
                onRemoveAnexo={controller.onRemoveAnexo}
                onClearAnexo={controller.onClearAnexo}
                ListaControlDocumental={controller.ListaControlDocumental}
                onSelectDocuementoFactura={controller.onSelectDocuementoFactura}
                onRemoveDocuementoFactura={controller.onRemoveDocuementoFactura}
                onClearDocuementoFactura={controller.onClearDocuementoFactura}
                verDocumento={controller.verDocumento}
                CargarFileControlDocumental={controller.CargarFileControlDocumental}
                setDocumentosFile={controller.setDocumentosFile}
                eliminarDocumento={controller.eliminarDocumento}
                AlertSizeFileUpload={controller.AlertSizeFileUpload}
                AlertTypeFileUpload={controller.AlertTypeFileUpload}

            />
            <DialogViewDocument
                pdf={controller.pdf}
                onHideDocument={controller.onHideDocument}
                visibleDocument={controller.visibleDocument}
                textValorizacion={controller.dataText.textValorizacion}
                
            />
        </div>
        
    )

}

export default ManagerEntity;