import React, { useState } from 'react';
import { DataTable, DataTableSelectionChangeEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Reception } from '../Domain/Reception';
import { Comparativo } from '../Domain/Comparativo';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { DialogViewDocument } from './DialogViewDocument';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { DialogViewSteps } from './DialogViewSteps';
import { TipoDocumentoTributario } from '../Domain/TipoDocumentoTributario';
import { TipoIVA } from '../Domain/TipoIVA';
import { Valorization } from '../Domain/Valorization';
import { Tag } from 'primereact/tag';


import { ListaControlDocumental } from '../Domain/ListaControlDocumental';

interface PropsViewDialogForm {
    data: Reception[];
    formatDate:Function;
    formatCurrency: Function;
    openDocument:any;
    selectedValorizaciones: Reception[] | any;
    setSelectedValorizaciones: Function;
    onSelectionChange:any;
    setGlobalFilter: Function;
    home:any;
    items:any;
    globalFilter:string;
    isSelectable:Function;
    onIngresoFactura:Function;
    //dialog visible
    visibleDialogSteps:boolean;
    onHideDialogSteps:Function;
    setTabIndex:Function;
    tabIndex:number;
    formInvoice: { values: any; touched: any; errors: any; handleBlur: Function; handleChange: Function; handleSubmit: Function; setFieldValue: Function;};
    onChange:any;
    importeAsociado:number;
    setImporteAsociado:any;
    onSubmit:Function;
    exportExcel:Function;
    tipoDocumentoTributario:TipoDocumentoTributario[] | null;
    tipoIVA:TipoIVA[] | null | undefined;
    onChangeTipoIva: Function;
    onCellEditCompleteImporte:Function;
    importExcel:Function;
    handleFileUpload2:Function;
    disabled:boolean;
    itemsDialogSteps:any;
    disabledImporteIva:boolean;
    onChangeValueImporteIva:any;
    setFacturaFile:any;
    setAnexoFile:any;
    onSelectAnexo:any;
    loadData:any;
    onRemoveAnexo:any;
    onClearAnexo:any;
    ListaControlDocumental:any;
    onSelectDocuementoFactura:Function;
    onRemoveDocuementoFactura:Function;
    onClearDocuementoFactura:Function;

    verDocumento:Function;
    CargarFileControlDocumental:Function
    setDocumentosFile:Function;
    eliminarDocumento:Function;
    AlertSizeFileUpload:Function;
    AlertTypeFileUpload:Function;

}
export const ViewDataTable = (props: PropsViewDialogForm) => {
    const [fileInputKey, setFileInputKey] = useState<number>(0);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileInput = event.target;
        const parametroCodigo = fileInput.dataset.parametro;//fileInput.getAttribute('data-parametro');
        const parametroDocumento = fileInput.dataset.documento;//fileInput.getAttribute('data-parametro');
        
        const fileList = event.target.files;
        if (fileList && fileList.length > 0) {
            const selectedFile = fileList[0];
            const fileNameParts = selectedFile.name.split('.');
            const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();
  
            if(selectedFile.size>50000000){
              props.AlertSizeFileUpload();
              return;
            }else{
              if (fileExtension !== 'pdf') {
                props.AlertTypeFileUpload();
                return;
              }else{
                console.log("parametroCodigo", parametroCodigo);
                props.CargarFileControlDocumental(selectedFile,parametroCodigo,parametroDocumento);  
              }
            }
            // Aquí puedes manejar el archivo seleccionado
            
        }
        // Restablecer el valor del input para permitir la selección del mismo archivo nuevamente
        //setFileInputKey(fileInputKey + 1);
    };
    const DocumentoBodyTemplate = (rowData:ListaControlDocumental)=>{
  
        return (
          <div>
            <Button icon="pi pi-file-pdf" style={{ cursor: 'pointer' }}  rounded text severity={`${rowData.EstadoCarga==="Cargado"? 'danger':'secondary'}`} aria-label="Ver Documento" onClick={(e)=> {
                  if(rowData.EstadoCarga==="Cargado"){
                    props.verDocumento(rowData);
                  }
            }}/>
  
              {rowData.EstadoCarga==="Cargado" && ( 
                <Button icon="pi pi-trash" style={{ cursor: 'pointer' }}  rounded text severity='danger' aria-label="Eliminar Archivo" onClick={(e)=> {
  
                    props.eliminarDocumento(rowData);
                
                }}/>
              )}
          
            {(rowData.EstadoCarga==="Pendiente" || rowData.EstadoCarga==='Vencido') && ( 
              <Button icon="pi pi-upload" style={{ cursor: 'pointer' }}  rounded text severity='info' aria-label="Cargar Documento" onClick={(e)=> {
                const fileInput = document.getElementById("fileInput") as HTMLInputElement;
                //console.log("atributo",rowData);
                fileInput.dataset.parametro = rowData.Codigo;
                fileInput.dataset.documento = rowData.Documento;
                if (fileInput) {
                    fileInput.click();
                }
              }} /> 
            )}
  
            
            <input
                key={fileInputKey}
                id="fileInput"
                type="file"
                accept=".pdf"
                style={{ display: 'none' }}
                onChange={handleFileChange}
             />
          </div>
  
            
            
        )
    }

    const isRowSelectable = (event: any) => (event.data ? props.isSelectable(event.data) : true);
    const rowClassName = (data: any) => (props.isSelectable(data) ? '' : 'p-disabled');
    const paginatorLeft = <Button type="button" icon="pi pi-refresh" text onClick={()=>props.loadData()}/>;
    const paginatorRigth = <Button type="button" icon="" text />;
    const startContent = (
        <React.Fragment>

        </React.Fragment>
      );
      const endContent = (
        <React.Fragment>
            <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText placeholder="Buscar..." onInput={(e) => props.setGlobalFilter(e.currentTarget.value)}/>
                </span>
        </React.Fragment>
      );
    return (
        <div className='flex justify-content-center flex-column px-5'>
            <BreadCrumb home={props.home} model={props.items} className='border mb-3' />
            <div className='mb-3'>
                    <Toolbar className='bg-white border-none py-1 px-0' start={startContent} end={endContent}/>
            </div>
            <DataTable 
                paginatorLeft={paginatorLeft}
                paginatorRight={paginatorRigth}
                paginatorClassName="paginator-gia"
                value={props.data} 
                dataKey="id" 
                tableStyle={{ minWidth: '50rem', border:'1px solid #dee2e6' }}
                tableClassName='custombar1 mt-2'
                paginator
                rows={10}
                scrollable
                scrollHeight="420px"
                isDataSelectable={isRowSelectable}
                rowClassName={rowClassName}
                size={'small'}
                //showGridlines 
                rowsPerPageOptions={[10, 20, 30, 40]}
                //columnResizeMode="expand" 
                resizableColumns
                globalFilter={props.globalFilter}
            >
   
            <Column className='lg:w-1' bodyClassName={'text-sm'} header="" headerClassName='bg-gia text-white text-white text-sm' body={DocumentoBodyTemplate} ></Column>
            <Column sortable className='lg:w-1' bodyClassName={'text-sm text-striped'} field="Documento" header="Documento" headerClassName='bg-gia text-white text-white text-sm'></Column>
            <Column sortable className='lg:w-1' bodyClassName={'text-sm text-striped'} field="EstadoVencimiento" header="Estado Vencimiento" headerClassName='bg-gia text-white text-white text-sm'></Column>
            <Column sortable className='lg:w-1' bodyClassName={'text-sm text-striped'} field="FechaVencimiento" header="Fecha Vencimietno" headerClassName='bg-gia text-white text-sm' body=''></Column>
            <Column sortable className='lg:w-1' bodyClassName={'text-sm text-striped'} field="Operacion" header="Operacion" headerClassName='bg-gia text-white text-sm'></Column>
            <Column sortable className='lg:w-1' bodyClassName={'text-sm text-striped'} field="FechaCarga" header="Fecha Carga" headerClassName='bg-gia text-white text-sm' body=''></Column>
            <Column sortable className='lg:w-1' bodyClassName={'text-sm text-striped'} field="Obligatoriedad" header="Obligatoriedad" headerClassName='bg-gia text-white text-sm' body=''></Column>
            <Column sortable className='lg:w-1' bodyClassName={'text-sm text-striped'} field="EstadoCarga" header="Estado" headerClassName='bg-gia text-white text-sm' body=''></Column>

      </DataTable>
     



  </div>
        // <div className='flex justify-content-center flex-column px-5'>
        //     <BreadCrumb home={props.home} model={props.items} className='border mb-3' />
        //     <Card
                
        //     >
        //         <div className='mb-3'>
        //             <Toolbar className='bg-white border-none py-1 px-0' start={startContent} end={endContent}/>
        //         </div>
        //         <DataTable
        //             paginatorLeft={paginatorLeft}
        //             paginatorRight={paginatorRigth}
        //             paginatorClassName="paginator-gia"
        //             value={props.data}
        //             //value={tabSelectedTable(codeMoneda, tipoCompraCode)}
        //             selectionMode='multiple'
        //             selection={props.selectedValorizaciones}
        //             onSelectionChange={(e: DataTableSelectionChangeEvent<Reception | any>) => { props.onSelectionChange(e);  }}
        //             //dataKey="id" 
        //             tableStyle={{ minWidth: '50rem' }}
        //             tableClassName='custombar1 mt-2'
        //             paginator
        //             rows={10}
        //             scrollable
        //             scrollHeight="420px"
        //             isDataSelectable={isRowSelectable}
        //             rowClassName={rowClassName}
        //             size={'small'}
        //             //showGridlines 
        //             rowsPerPageOptions={[10, 20, 30, 40]}
        //             //columnResizeMode="expand" 
        //             resizableColumns
        //             globalFilter={props.globalFilter}
                    

        //         >
                    
        //             <Column selectionMode="multiple" headerStyle={{ width: '3em' }} headerClassName={`bg-gia text-white ${(props.selectedValorizaciones === null || props.selectedValorizaciones?.length === 0) ? 'p-disabled' : ''}`}></Column>
        //             <Column className='pr-0' bodyClassName={'text-sm'} header="" headerClassName='bg-gia text-white text-white text-sm' body={DocumentoBodyTemplate}></Column>
        //             <Column sortable field="Dates.Registry.Date" className='pr-0' bodyClassName={'text-sm'} header="Fecha" headerClassName='bg-gia text-white text-white text-sm' body={dateBodyTemplate}></Column>
        //             <Column sortable field="CodigoFactura" className='pr-0' bodyClassName={'text-sm'} header="Código" headerClassName='bg-gia text-white text-white text-sm'></Column>
        //             <Column sortable field="Observacion" className='pr-0' bodyClassName={'text-sm'} header="Observación" headerClassName='bg-gia text-white text-white text-sm'></Column>
        //             <Column sortable field="" className='pr-0' bodyClassName={'text-sm'} header="Documento" headerClassName='bg-gia text-white text-white text-sm' body={TagValorizacionRecepcionTemplate}></Column>
        //             <Column sortable field="Pendiente" className='pr-0' bodyClassName={'text-sm'} header="Importe" headerClassName='bg-gia text-white text-white text-sm' body={ImporteBodyTemplate}></Column>
                    
        //         </DataTable>
        //     </Card>

        //     {/* <DialogViewSteps
        //         onChange={props.onChange}
        //         visibleDialogSteps={props.visibleDialogSteps}
        //         selectedValorizaciones={props.selectedValorizaciones}
        //         onHideDialogSteps={props.onHideDialogSteps}
        //         setTabIndex={props.setTabIndex}
        //         tabIndex={props.tabIndex}
        //         formatDate={props.formatDate}
        //         formatCurrency={props.formatCurrency}
        //         formInvoice={props.formInvoice}
        //         importeAsociado={props.importeAsociado}
        //         setImporteAsociado={props.setImporteAsociado}
        //         onSubmit={props.onSubmit}
        //         exportExcel={props.exportExcel}
        //         tipoDocumentoTributario={props.tipoDocumentoTributario}
        //         tipoIVA={props.tipoIVA}
        //         onChangeTipoIva={props.onChangeTipoIva}
        //         onCellEditCompleteImporte={props.onCellEditCompleteImporte}
        //         importExcel={props.importExcel}
        //         handleFileUpload2={props.handleFileUpload2}
        //         disabled={props.disabled}
        //         itemsDialogSteps={props.itemsDialogSteps}
        //         disabledImporteIva={props.disabledImporteIva}
        //         onChangeValueImporteIva={props.onChangeValueImporteIva}
        //         setFacturaFile={props.setFacturaFile}
        //         setAnexoFile={props.setAnexoFile}
        //         onSelectAnexo={props.onSelectAnexo}
        //         onRemoveAnexo={props.onRemoveAnexo}
        //         onClearAnexo={props.onClearAnexo}
        //         ListaControlDocumental={props.ListaControlDocumental}
        //         onSelectDocuementoFactura={props.onSelectDocuementoFactura}
        //         onRemoveDocuementoFactura={props.onRemoveDocuementoFactura}
        //         onClearDocuementoFactura={props.onClearDocuementoFactura}
        //         verDocumento={props.verDocumento}
        //         CargarFileControlDocumental={props.CargarFileControlDocumental}
        //         setDocumentosFile={props.setDocumentosFile}
        //         eliminarDocumento={props.eliminarDocumento}
        //         AlertSizeFileUpload={props.AlertSizeFileUpload}
        //         AlertTypeFileUpload={props.AlertTypeFileUpload}
        //     /> */}
        // </div>
    )
}
