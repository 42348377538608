import { RootState } from '../../../shared/Infraestructure/AdapterStore';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { RepositoryImplMain } from './RepositoryImplMain';
import { AdapterConfigure } from './AdapterConfigure';
import { useRef, useState } from 'react';
import { AdapterStorage } from '../../../shared/Infraestructure/AdapterStorage';
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico';
import { addLoading, changeSaludo, hideIconMenu, removeLoading } from '../../../shared/Infraestructure/SliceGenerico';
import { DtoRequestComparativo } from '../Domain/DtoRequestComparativo';
import { UseCaseComparativos } from '../Application/UseCaseComparativos';
import { Comparativo } from '../Domain/Comparativo';
import { UseCaseTipoDocumentoTributario } from '../Application/UseCaseTipoDocumentoTributario';
import { UseCaseTipoIVA } from '../Application/UseCaseTipoIVA';
import { MenuItem } from 'primereact/menuitem';
import { Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import { Toast } from 'primereact/toast';
import { AdapterValidator } from '../../../shared/Infraestructure/AdapterValidator';
import { DtoRequestInvoice } from '../Domain/DtoRequestInvoice';
import { UseCaseNuevoIngresoFactura } from '../Application/UseCaseNuevoIngresoFactura';

export const Controller = () => {
    //const { auth, user } = useSelector((state: RootState) => state.auth);
    const { generico: { websocket, dbLocal, countProcess }, auth: { user } } = useSelector((state: RootState) => state);
    const dispatch: Dispatch = useDispatch();
    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);
    const [showLanguage, setShowLanguage] = useState<boolean>(false);
    const { language } = AdapterStorage.get('language');
    const [data, setData] = useState<Array<any>>([]);
    const [tipoDocumentoTributario, setTipoDocumentoTributario] = useState<Array<any>>([]);
    const [tipoIVA, setTipoIVA] = useState<Array<any>>([]);
    const [visible, setVisible] = useState<boolean>(false);
    const [selectedComparativos, setSelectedComparativos] = useState<Comparativo[] | null | any>(null);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [tabIndex, setTabIndex] = useState<number>(0);
    const [globalFilter, setGlobalFilter] = useState<string>('');
    const [contratos,setContratos] = useState({});
    const toast = useRef<Toast>(null);
    const [visibleNuevo,setVisibleNuevo] = useState(false);
    const [disabled,setDisabled] =useState<boolean>();
    const [resultTab,setResultTab]=useState<any>();
    const init = async () => {
        try {
            dispatch(changeSaludo(false));
            dispatch(hideIconMenu());
            dispatch(addLoading('Cargando datos...'));
            await loadData();

            dispatch(removeLoading())
        } catch (error) {
            dispatch(removeLoading())
            AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning');
        }
    };

    const loadData = async () => {
        
        try {
            const param: DtoRequestComparativo = {
                //_id: "60fa1751aa6ab548fc8fbcf4"
               _id:user.usuario._id
               
            }
            dispatch(addLoading({ textLoading: 'Cargando...' }));
            const response = await (new UseCaseComparativos(repository).exec(param));
            if (response === null) return;
            setData(response);
            let dataFilter:any[] = []; 
            
            let filter:any = []
            dataFilter= response.map((val)=>{
                return {
                    codeMoneda:val.DatosPago.Moneda.Codigo,
                    tipoCompraCode: val.Valorizaciones.length===0 ? '01' : '02'
                }
            })
            
            dataFilter.forEach(function(elemento, indice, array) {
                // Discriminación de elementos iguales
                let a = filter.find((p:any)=>p.tipoCompraCode.toLowerCase() == elemento.tipoCompraCode.toLowerCase()) == undefined;
                let b = filter.find((p:any)=>p.codeMoneda.toLowerCase() == elemento.codeMoneda.toLowerCase()) == undefined;
                if( a || b )
                {
                    filter.push(elemento);
                }
            })
            setResultTab(filter);
        } catch (err) {

        } finally {
            dispatch(removeLoading());
        }




    };
    const loadTipoDocumentoTributario = async () => {
        try {
            const param: any = {
                cdPais: "504"
            }
            dispatch(addLoading({ textLoading: 'Cargando...' }));
            const response = await (new UseCaseTipoDocumentoTributario(repository).exec(param));
            if (response === null) return;
            setTipoDocumentoTributario(response);
        } catch (err) {
        } finally {
            dispatch(removeLoading());
        }
    }

    const loadTipoIVA = async () => {
        try {
            const param: any = {
                cdPais: "504"
            }
            dispatch(addLoading({ textLoading: 'Cargando...' }));
            const response = await (new UseCaseTipoIVA(repository).exec(param));
            if (response === null) return;
            setTipoIVA(response);
        } catch (err) {
        } finally {
            dispatch(removeLoading());
        }
    }
    const items: MenuItem[] = [{ label: 'Ingreso Factura', icon: 'pi pi-file' }];
    const home: MenuItem = { icon: 'pi pi-home', url: '/main' }
    const transformMoneda = (rowData: Comparativo) => {
        return rowData.DatosPago.Moneda.Codigo.length === 0 ? 'PEN' : rowData.DatosPago.Moneda.Codigo;
    };
    const transformCurrency = (value: number) => {
        return value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    };
    const TransformDate = (value: string) => {
        let a = new Date(value);
        return a.toLocaleString("es-MX", { dateStyle: 'short' });
    };
    const isSelectable = (data: Comparativo) => {
        if (selectedComparativos === null || selectedComparativos === undefined || selectedComparativos.length === 0) return true;
        return data.TipoCompra.Code === selectedComparativos[0]?.TipoCompra?.Code && data?.DatosPago?.Moneda?.Codigo === selectedComparativos[0]?.DatosPago?.Moneda?.Codigo;

    };
    const onClickNuevo = (e:any) => {
        let contracts:any=[];
        setVisible(true);
        if(selectedComparativos[0].MovimientosRecepcion.length!==0){
            selectedComparativos.map((row:any) => {
            row.MovimientosRecepcion.map((row2:any)=>{
          
                return contracts=contracts.concat(row2);
            });
        });
    }
    else if(selectedComparativos[0].Valorizaciones.length!==0){
        selectedComparativos.map((row:any) => {
            row.Valorizaciones.map((row2:any)=>{
          
                return contracts=contracts.concat(row2);
            });
        });
      
    }else{
      contracts=[];
    }
    let d = {
      fechaEmision: new Date().toISOString().slice(0,10),
      moneda: selectedComparativos[0].DatosPago.Moneda.Codigo.length===0 ? 'PEN' : selectedComparativos[0].DatosPago.Moneda.Codigo,
      
      
      
      contracts: contracts,
    }
    setContratos(d);
    }
    //#region Modal Ingreso Registro
    const formInvoice = useFormik({
        initialValues: {
            //step 1
            fechaEmision: new Date().toISOString().slice(0, 10),
            TipoDocumentoTributario: null,
            numeroDocumentoTributario: '',
            monto: 0,
            TipoIVA: null,
            IVA: 0,
            montoTotal: 0,
            observaciones: '',
            //step 2
            //importeFactura:0, pasa igual a montototal
            importeAsociado:0,
            importePendiente:0,

        },

        onSubmit(values, formikHelpers) {

        },
        
        validationSchema: Yup.object({
            numeroDocumentoTributario:Yup.string().when([], {is: () => (tabIndex===0 ), then: Yup.string().required('Número documento tributario es requerido')}),
            TipoDocumentoTributario:Yup.object().when([], {is: ()=> tabIndex===0, then: Yup.object().required('Seleccione un tipo de documento tributario')}),
            monto:Yup.number().when([],{is:() =>tabIndex===0, then: Yup.number().min(1,'el monto debe ser minimo 1').required('monto es requerido').nullable()}),
            TipoIVA:Yup.object().when([], {is: ()=> tabIndex===0, then: Yup.object().required('Seleccione un tipo de IVA')}),
            IVA:Yup.number().when([],{is:() =>tabIndex===0, then: Yup.number().required('IVA es requerido').nullable()}),
            montoTotal:Yup.number().when([],{is:() =>tabIndex===0, then: Yup.number().min(1,'el monto total debe ser minimo 1').required('monto total es requerido').nullable()}),
            //monto:Yup.number().min(1,'el monto debe ser minimo 1').required('monto es requerido').nullable(),
            //tipoIVA
            //montoTotal:Yup.number().when([],{is:() =>tabIndex===0, then: Yup.number().required('monto es requerido')}),
            //step 2
            //importeFactura:Yup.number().when([],{is:()=> tabIndex===1, then: Yup.number().min(Yup.ref('monto'),'la factura excede al monto ingresado').required('importe requerido').nullable()}),
            importeAsociado:Yup.number().when([],{is:()=> tabIndex===1, then: Yup.number().min(0,'el importe asociar no debe ser 0').max(Yup.ref('montoTotal'),'el importe asociar no debe ser  mayor a monto total o importe asociar').required('importe requerido').nullable()}),
            importePendiente:Yup.number().when([],{is:()=> tabIndex===1, then: Yup.number().min(0,'el importe no debe ser negativo').required('importe requerido').nullable()}),
        })
    });
    const onChange = (name: string, value: any) => {
        if (value === null) { return; }
        formInvoice.setFieldValue(name, value);
    };
    const onSubmit = async (e: Event) => {
        
        try {
            e.preventDefault();
            e.stopPropagation();
            try{ 
                
                await formInvoice.submitForm(); } catch(error) {}
            try { AdapterValidator.validate(await formInvoice.validateForm()); } catch (error) { AdapterGenerico.createMessage('Incompleto', (error as Error).message, 'warning', false); return null; }
            try {
                
                
                let validationStep1 = await (!!formInvoice.touched['numeroDocumentoTributario'] && !formInvoice.errors['numeroDocumentoTributario']) && (!!formInvoice.touched['monto'] && !formInvoice.errors['monto']);
                let validationStep2 = await (!!formInvoice.touched['importeAsociado'] && !formInvoice.errors['importeAsociado']);
                setTabIndex(tabIndex + 1);
                if((tabIndex===0 && !validationStep1) && formInvoice.isValid ){
                    await AdapterGenerico.createMessage('Alerta', 'Estimado Proveedor, Debe llenar los campos necesarios', 'warning');
                    return;
                }else if(tabIndex===1 && !validationStep2){

                }else if(tabIndex===2){
                    let transform:DtoRequestInvoice;
                    transform={
                        _idProveedor:"60fa172faa6ab548fc8fa6ac",
                        tipoDocumentoTributario:formInvoice.values.TipoDocumentoTributario!=null ? formInvoice.values.TipoDocumentoTributario['COdigo'] : formInvoice.values.TipoDocumentoTributario,
                        monedaKey: "",
                        numeroDocumentoTributario:formInvoice.values.numeroDocumentoTributario,
                        importe: formInvoice.values.monto,
                        iva: formInvoice.values.TipoIVA!=null ? formInvoice.values.TipoIVA['VAT']/100 : formInvoice.values.TipoIVA,
                        tipoIvaKey: formInvoice.values.TipoIVA!=null ? formInvoice.values.TipoIVA['VATProd_PostingGroup'] : formInvoice.values.TipoIVA,
                        importeIva: formInvoice.values.IVA,
                        importeTotal: formInvoice.values.montoTotal,
                        observacion:formInvoice.values.observaciones,
                        detalle:[],
                        anexo:[],
                        factura:null,
                        

                    }

                    formInvoice.resetForm();
                    onClickCancel();
                    dispatch(addLoading({ textLoading: 'Cargando...' }));
                    await setTimeout(() => {
                        dispatch(removeLoading());
                    
                        AdapterGenerico.createMessage('Exitoso', `<p>La solicitud de usuario se ha <b>realizado exitosamente</b>.</p>`, 'success');
                    }, 5000);
                    
                }
                
                
            } catch (error) {
                
            }
            
        } catch (error) {

        }
    }
    
    const onChangeValueMontoFormInvoice = (e:any) => {
        
        formInvoice.setValues((values:any)=> ({
            ...values,
            monto: e.value,
            
        }));
        if(formInvoice.values.TipoIVA!==null && formInvoice.values.TipoIVA['VAT']!==undefined){            
            formInvoice.setFieldValue('IVA',(isNaN((e?.value * formInvoice.values.TipoIVA['VAT'])/100)?0:((e?.value * formInvoice.values.TipoIVA['VAT'])/100)));
            formInvoice.setFieldValue('montoTotal',(e?.value + (formInvoice.values.TipoIVA ? ((e?.value * formInvoice.values.TipoIVA['VAT'])/100):0) ));
        }else{
            formInvoice.setFieldValue('IVA', 0);
            formInvoice.setFieldValue('montoTotal', e.value);
        }
                     
    }
    const onChangeValueTipoIVAFormInvoice = (e:any) => {
        formInvoice.setValues((values:any)=> ({
            ...values,
            TipoIVA: e.value,
            IVA: isNaN(((e?.value?.VAT * formInvoice.values.monto)/100))?0:((e?.value?.VAT * formInvoice.values.monto)/100),
            montoTotal: (formInvoice.values.monto + ((e?.value?.VAT * formInvoice.values.monto)/100))
        }));
    }
    
    const showError = () =>{
        toast?.current?.show({severity:'warn',summary:'Falta completar datos',detail:'',life:3000})
      }
    const onNextStep= async (validacion:boolean,stepActual:Number)=>{
        if(!validacion && tabIndex===stepActual){             
            showError();
            return;
        }
        setTabIndex(tabIndex + 1);
    } 
    const onSave= async (validacion:boolean)=>{
        if(!validacion){
            showError();
            return;
        }
        formInvoice.resetForm()
    } 
    const onClickCancel = () => {
        formInvoice.resetForm();
        setVisible(false);
        setDisabled(false);
        setTabIndex(0);
    }
    const onClickNextStep = (e:any) => {
        
    }
    //#endregion

    //#region Exportación
    return {
        init,
        loadData,
        countProcess,
        data,
        tipoDocumentoTributario,
        loadTipoDocumentoTributario,
        tipoIVA,
        loadTipoIVA,
        user,
        home,
        items,
        visible,
        setVisible,
        transformMoneda,
        transformCurrency,
        TransformDate,
        selectedComparativos,
        setSelectedComparativos,
        isSelectable,
        formInvoice,
        onSubmit,
        onChangeValueMontoFormInvoice,
        activeIndex,
        setActiveIndex,
        tabIndex,
        setTabIndex,
        globalFilter,
        setGlobalFilter,
        onNextStep,
        toast,
        onSave,
        onChange,
        onChangeValueTipoIVAFormInvoice,
        onClickCancel,
        onClickNextStep,
        onClickNuevo,
        visibleNuevo,
        setVisibleNuevo,
        disabled,
        setDisabled,
        resultTab,
    }

    //#endregion
}