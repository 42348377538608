import { AdapterEncrypt } from "./AdapterEncrypt";
import { AdapterGenerico } from "./AdapterGenerico";

export class AdapterIndexedDB {
    private name: string;
    private version: number;
    private stores: Array<{ name: string; pk: string; index: Array<{ key: string; unique: boolean; }>; encrpyt: boolean }>;
    private online: boolean = false;

    constructor(name: string, version: number, stores: Array<{ name: string; pk: string; index: Array<{ key: string; unique: boolean; }>; encrpyt: boolean }>) {
        this.name = name;
        this.version = version;
        this.stores = stores;
    }

    public run() {
        return new Promise((resolve, reject) => {
            let req = indexedDB.open(this.name, this.version);

            req.onsuccess = evt => {
                this.version = req.result.version;
                this.online = true;
                resolve(true);
            };

            req.onerror = evt => { reject(req.error); };

            req.onupgradeneeded = evt => {
                if (this.stores.length > 0) {
                    for (let store of this.stores) {
                        if (!req.result.objectStoreNames.contains(store.name)) {
                            let newStore = req.result.createObjectStore(store.name, { keyPath: store.pk, autoIncrement: false });
                            this.stores.push(store);
                            newStore.createIndex(store.pk, store.pk, { unique: true });

                            if (store.index.length > 0) {
                                for (let row of store.index) {
                                    newStore.createIndex(row.key, row.key, { unique: row.unique });
                                }
                            }
                        }
                    }
                }
            }
        });
    }

    public drop() {
        return new Promise((resolve, reject) => {
            let req = indexedDB.deleteDatabase(this.name);
            req.onsuccess = evt => { this.online = false; resolve(true); };
            req.onerror = evt => { reject(req.error); };
        });
    }

    public existsStore(nameStore: string) {
        return new Promise((resolve, reject) => {
            let req = indexedDB.open(this.name);

            req.onsuccess = (evt) => { resolve(!req.result.objectStoreNames.contains(nameStore) ? false : true); };
            req.onerror = (evt) => { reject(req.error); };
        });
    }

    public async insertDataStore(params: Array<{ nameStore: string; data: Array<Object> | Object }> | { nameStore: string; data: Array<Object> | Object }) {
        try {
            let promisesInsert = [];
            let data: any = [];

            if (Array.isArray(params)) {
                for (let row of params) { promisesInsert.push(this.operationDataStore(row.nameStore, 'insert', row.data)); }
                data = await Promise.all(promisesInsert);
            }
            else { data = await this.operationDataStore(params.nameStore, 'insert', params.data); }
            return data;
        } catch (error) {
            throw error;
        }
    }

    public async selectAllStore(params: Array<string> | string) {
        try {
            let promisesSelect = [];
            let data: any = [];

            if (Array.isArray(params)) {
                for (let row of params) { promisesSelect.push(this.operationDataStore(row, 'selectAll', undefined)); }
                data = await Promise.all(promisesSelect);
            }
            else {
                data = await this.operationDataStore(params, 'selectAll', undefined);
            }
            return data;
        } catch (error) {
            throw error;
        }
    }

    public async countStore(params: Array<string> | string) {
        try {
            let promisesSelect = [];
            let data: any = [];

            if (Array.isArray(params)) {
                for (let row of params) { promisesSelect.push(this.operationDataStore(row, 'count', undefined)); }
                data = await Promise.all(promisesSelect);
            }
            else {
                data = await this.operationDataStore(params, 'count', undefined);
            }
            return data;
        } catch (error) {
            throw error;
        }
    }

    public async selectByIndexStore(params: Array<{ nameStore: string; value: any }> | { nameStore: string; value: any }) {
        try {
            let promisesSelect = [];
            let data: any = [];

            if (Array.isArray(params)) {
                for (let row of params) { promisesSelect.push(this.operationDataStore(row.nameStore, 'selectIndex', row.value)); }
                data = await Promise.all(promisesSelect);
            }
            else { data = await this.operationDataStore(params.nameStore, 'selectIndex', params.value); }
            return data;
        } catch (error) {
            throw error;
        }
    }

    public async updateByIndexStore(params: Array<{ nameStore: string; value: any }> | { nameStore: string; value: any }) {
        try {
            let promisesUpdate = [];
            let data: any = [];

            if (Array.isArray(params)) {
                for (let row of params) { promisesUpdate.push(this.operationDataStore(row.nameStore, 'update', row.value)); }
                data = await Promise.all(promisesUpdate);
            }
            else { data = await this.operationDataStore(params.nameStore, 'update', params.value); }
            return data;
        } catch (error) {
            throw error;
        }
    }

    public async deleteByIndexStore(params: Array<{ nameStore: string; value: any }> | { nameStore: string; value: any }) {
        try {
            let promisesDelete = [];
            let data: any = [];

            if (Array.isArray(params)) {
                for (let row of params) { promisesDelete.push(this.operationDataStore(row.nameStore, 'delete', row.value)); }
                data = await Promise.all(promisesDelete);
            }
            else { data = await this.operationDataStore(params.nameStore, 'delete', params.value); }
            return data;
        } catch (error) {
            throw error;
        }
    }

    public async clearStore(params: Array<string> | string) {
        try {
            let promisesDelete = [];
            let data: any = [];

            if (Array.isArray(params)) {
                for (let row of params) { promisesDelete.push(this.operationDataStore(row, 'clear', undefined)); }
                data = await Promise.all(promisesDelete);
            }
            else { data = await this.operationDataStore(params, 'clear', undefined); }
            return data;
        } catch (error) {
            throw error;
        }
    }

    private operationDataStore(nameStore: string, actionStore: 'insert' | 'selectIndex' | 'selectAll' | 'update' | 'delete' | 'clear' | 'count', value: any) {
        return new Promise((resolve, reject) => {
            let req = indexedDB.open(this.name);

            req.onsuccess = evt => {
                try {
                    let transac = req.result.transaction(nameStore, 'readwrite');
                    let store = transac.objectStore(nameStore);

                    let dataStore = this.stores.find(row => row.name === nameStore);
                    if (!dataStore) { resolve(null); }

                    let key: string = dataStore?.pk as string;
                    let newRow: any = null;

                    switch (actionStore) {
                        case 'insert':
                            if (Array.isArray(value)) {
                                for (let row of value) {
                                    if (process.env?.REACT_APP_REQUIRE_ENCRYPT === '1' && dataStore?.encrpyt) {
                                        newRow = { [key]: row[key], value: AdapterEncrypt.encrypt(JSON.stringify(row), process.env?.REACT_APP_KEY_ENCRYPT as string) }
                                        store.put(newRow);
                                    }
                                    else {
                                        store.put(row);
                                    }
                                }
                            }
                            else {
                                if (process.env?.REACT_APP_REQUIRE_ENCRYPT === '1' && dataStore?.encrpyt) {
                                    newRow = { [key]: value[key], value: AdapterEncrypt.encrypt(JSON.stringify(value), process.env?.REACT_APP_KEY_ENCRYPT as string) }
                                    store.put(newRow);
                                }
                                else {
                                    store.put(value);
                                }
                            }
                            resolve(value);
                            break;
                        case 'selectIndex':
                            let reqSelectIndex = store.get(value);
                            reqSelectIndex.onsuccess = evtIndex => {
                                if (process.env?.REACT_APP_REQUIRE_ENCRYPT === '1' && dataStore?.encrpyt) {
                                    newRow = AdapterEncrypt.decrypt(reqSelectIndex.result.value, process.env?.REACT_APP_KEY_ENCRYPT as string);
                                    newRow = AdapterGenerico.isJSON(newRow) ? JSON.parse(newRow) : newRow;
                                    resolve(newRow);
                                }
                                else {
                                    resolve(reqSelectIndex.result);
                                }
                            };
                            reqSelectIndex.onerror = evtIndex => { reject(reqSelectIndex.error); }
                            break;
                        case 'selectAll':
                            let reqAll = store.getAll();
                            reqAll.onsuccess = evtAll => {
                                newRow = [];
                                if (process.env?.REACT_APP_REQUIRE_ENCRYPT === '1' && dataStore?.encrpyt) {
                                    for (let row of reqAll.result) {
                                        row = AdapterEncrypt.decrypt(row.value, process.env?.REACT_APP_KEY_ENCRYPT as string);
                                        row = AdapterGenerico.isJSON(row) ? JSON.parse(row) : row;
                                        newRow.push(row)
                                    }
                                    resolve(newRow);
                                }
                                else {
                                    resolve(reqAll.result);
                                }
                            };
                            reqAll.onerror = evtAll => { reject(reqAll.error); };
                            break;
                        case 'update':
                            if (process.env?.REACT_APP_REQUIRE_ENCRYPT === '1' && dataStore?.encrpyt) {
                                newRow = { [key]: value[key], value: AdapterEncrypt.encrypt(JSON.stringify(value), process.env?.REACT_APP_KEY_ENCRYPT as string) }
                                // store.put(newRow);
                            }
                            else {
                                newRow = value;
                                // store.put(value);
                            }
                            let reqUpdate = store.put(newRow);
                            reqUpdate.onsuccess = evtUpdate => { resolve(value); }
                            reqUpdate.onerror = evtUpdate => { reject(reqUpdate.error); }
                            break;
                        case 'delete':
                            let reqDelete = store.delete(value);
                            reqDelete.onsuccess = evtDelete => { resolve(reqDelete.result); }
                            reqDelete.onerror = evtDelete => { reject(reqDelete.error); }
                            break;
                        case 'clear':
                            let reqClear = store.clear();
                            reqClear.onsuccess = evtDelete => { resolve(reqClear.result); }
                            reqClear.onerror = evtDelete => { reject(reqClear.error); }
                            break;
                        case 'count':
                            let reqCount = store.count();
                            reqCount.onsuccess = evtAll => {
                                resolve(reqCount.result);
                            }
                            reqCount.onerror = evtAll => { reject(reqCount.error); };
                            break;
                        default:
                            break;
                    }
                }
                catch (error) {
                    reject(error);
                }
            };

            req.onerror = (evt) => { reject(req.error); };
        });
    }

}