import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { DtoRequestRecuperarPassword } from "../Domain/DtoRequestRecuperarPassword";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { removeLoading } from "../../../shared/Infraestructure/SliceGenerico";
//import { AdapterConfigure } from "./AdapterConfigure";
//import { Buffer } from 'buffer'

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {
    public async recuperarPassword(params: DtoRequestRecuperarPassword): Promise<void> {
        return this._recuperarPassword(params);
    }

    private async _recuperarPassword(params: DtoRequestRecuperarPassword): Promise<void> {
        if (!navigator.onLine) {
            this.dispatch(removeLoading());
            AdapterGenerico.createMessage('Sin Conexión', 'No posee conexión a internet', 'warning');
            return;
        }
        return await this.service.call<any>("POST", `${this.urlBase}/catalogo/proveedor/resetearContrasenia`, JSON.stringify(params), "basic", "json", "json", {}, 0);
    }
}