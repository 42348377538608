import { DtoReqChangePassword } from "../Domain/DtoReqChangePassword";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseChangePassword {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(params: DtoReqChangePassword): Promise<void> {
        await this._exec(params);
    }

    private async _exec(params: DtoReqChangePassword): Promise<void> {
        await this.repository.changePassword(params);
    }
}