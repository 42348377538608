import { DtoRequestControlDocumental } from "../Domain/DtoRequestControlDocumental";
import { RepositoryMain } from "../Domain/RepositoryMain";
import { ListaControlDocumental } from "../Domain/ListaControlDocumental";
export class UseCaseListaControlDocumental {
   private repository: RepositoryMain;
   
   constructor (_repository: RepositoryMain){
    this.repository = _repository;
   }

   public async exec(params: DtoRequestControlDocumental): Promise<ListaControlDocumental[] | null>{
    return await this._exec(params);
   }

   public async _exec(params: DtoRequestControlDocumental): Promise<ListaControlDocumental[] | null>{
      return await this.repository.getListControlDocumental(params);
   }

}