import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { DtoRequestNuevoProveedor } from "../Domain/DtoRequestNuevoProveedor";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { removeLoading } from "../../../shared/Infraestructure/SliceGenerico";
import { Buffer } from 'buffer'

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {
    public async nuevoProveedor(params: DtoRequestNuevoProveedor): Promise<void> {
        return this._nuevoProveedor(params);
    }

    private async _nuevoProveedor(params: DtoRequestNuevoProveedor): Promise<void> {
        if (!navigator.onLine) {
            this.dispatch(removeLoading());
            AdapterGenerico.createMessage('Sin Conexión', 'No posee conexión a internet', 'warning');
            return;
        }
        let myHeaders = new Headers();
        myHeaders.append("request-decrypt-response", "yes");
        myHeaders.append("Authorization", `Basic ${Buffer.from(process.env.REACT_APP_AUTH_BASIC_USER + ':' + process.env.REACT_APP_AUTH_BASIC_PASS).toString('base64')}`);

        let formdata = new FormData();
        formdata.append("identificacion", params.identificacion);
        formdata.append("email", params.email);
        formdata.append("telefono", params.telefono);
        formdata.append("pais", params.pais);
        params.archivos.forEach((archivo, index) => {
            formdata.append(`file${index}`, archivo.file);
        });
   
        
       
    }
}