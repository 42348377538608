import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { MenuItem } from 'primereact/menuitem';
import { Steps } from 'primereact/steps';
import { TabPanel, TabView } from 'primereact/tabview';
import React from 'react'
import { Reception } from '../Domain/Reception';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import { TipoDocumentoTributario } from '../Domain/TipoDocumentoTributario';
import { TipoIVA } from '../Domain/TipoIVA';
interface PropsViewDialogForm {
    // formInvoice: { values: any; touched: any; errors: any; handleBlur: Function; handleChange: Function; handleSubmit: Function; setFieldValue: Function;};
    // onSubmit: Function;
    // onChangeValueMontoFormInvoice: Function;
    // TransformDate:Function;
    // transformCurrency:Function;
    // onNextStep:Function;
    // onSave:Function;
    // onChange:Function;
    // onChangeValueTipoIVAFormInvoice:Function;
    // onClickCancel:Function;
    // disabled:boolean|any;
    // setDisabled:Function;
    setTabIndex:Function;
    tabIndex:number;
    visibleDialogSteps:boolean;
    onHideDialogSteps:Function;
    

    //stepOne
    formatDate:Function;
    selectedValorizaciones:Reception;
    formatCurrency:Function;
    formInvoice:any;
    onChange:any;
    importeAsociado:number;
    setImporteAsociado:any;
    onSubmit:Function;
    exportExcel:Function;
    tipoDocumentoTributario:TipoDocumentoTributario[] | null;
    tipoIVA:TipoIVA[] | null|undefined;
    onChangeTipoIva:Function;
    onCellEditCompleteImporte:Function;
    importExcel:Function;
    handleFileUpload2:Function;
    disabled:boolean;
    itemsDialogSteps:any;
    }
export const DialogViewSteps = (props : PropsViewDialogForm) => {
    const items: MenuItem[] = [
        {
          label: 'Asociación',
          command: () => {
            props.setTabIndex(0);
          },
    
        },
        {
          label: 'Factura',
          command: () => {
            props.setTabIndex(1);
          },
        },
        // {
        //   label: 'Archivos',
        //   command: () => {
        //     props.setTabIndex(2);
        //   },
        // }
    
      ];
      const footerContent = () => {
        if (props.tabIndex === 0) {
          return (
            <div>
              <Button 
                    label="Cancelar" 
                     onClick={(e)=>props.onHideDialogSteps(e)} 
                    severity="danger" 
                />
              <Button 
                label="Siguiente"
                onClick={(e)=>props.onSubmit(e)}
                type="submit"
                className='bg-gia' 
              />
            </div>
          );
        } else if (props.tabIndex === items.length - 1) {
          return (
            <div>
              <Button label="Atras" onClick={() => props.setTabIndex(props.tabIndex - 1)} severity="danger" />
              <Button label="Guardar" className='bg-gia' type='submit' 
                onClick={(e)=>props.onSubmit(e)} 
              />
            </div>
          );
        }
        else {
          return (
            <div>
              <Button label="Atras" onClick={() => props.setTabIndex(props.tabIndex - 1)} severity="danger" />
              <Button label="Siguiente" 
                onClick={()=>props.onSubmit()} 
                className='bg-gia' 
            />
            </div>
          );
        }
      }
  return (
    <Dialog header="Ingreso de Factura" visible={props.visibleDialogSteps} style={{ width: '50vw',zIndex:"1059" }} onHide={()=>props.onHideDialogSteps()}  className='form-dialog' footer={footerContent}>
      <div className='dialog-form-steps-invoice'>
        <Steps
            model={props.itemsDialogSteps}
            activeIndex={props.tabIndex}
            onSelect={(e) => props.setTabIndex(e.index)}
          />
          <TabView
            activeIndex={props.tabIndex}
            onTabChange={(e) => props.setTabIndex(e.index)}
          >
              <TabPanel disabled headerStyle={{ display: "none", border: 'none' }}>
                <StepOne
                //   formInvoice={props.formInvoice}
                //   onChangeValueMontoFormInvoice={props.onChangeValueMontoFormInvoice}
                //   TransformDate={props.TransformDate}
                //   transformCurrency={props.transformCurrency}
                //   onChangeValueTipoIVAFormInvoice={props.onChangeValueTipoIVAFormInvoice}    
                //   disabled={props.disabled}
                //   setDisabled={props.setDisabled}
                //   user={props.user}
                //   tipoIVA={props.tipoIVA}
                //   tipoDatosTributarios={props.tipoDatosTributarios}
                //   onChange={props.onChange}
                //   formData={props.formData}
                formInvoice={props.formInvoice}
                formatCurrency={props.formatCurrency}
                formatDate={props.formatDate}
                onChange={props.onChange}
                selectedValorizaciones={props.selectedValorizaciones}
                importeAsociado={props.importeAsociado}
                setImporteAsociado={props.setImporteAsociado}
                exportExcel={props.exportExcel}
                onCellEditCompleteImporte={props.onCellEditCompleteImporte}
                importExcel={props.importExcel}
                handleFileUpload2={props.handleFileUpload2}
                  disabled={props.disabled}
                />
              </TabPanel>
              <TabPanel disabled headerStyle={{ display: "none", border: 'none' }}>
                <StepTwo
                    formInvoice={props.formInvoice}
                    onChange={props.onChange}
                    selectedValorizaciones={props.selectedValorizaciones}
                    tipoDocumentoTributario={props.tipoDocumentoTributario}
                    tipoIVA={props.tipoIVA}
                    onChangeTipoIva={props.onChangeTipoIva}
                    disabled={props.disabled}

                
                />
              </TabPanel>

            </TabView>
          
      </div>

    </Dialog>
  )
}

